import { AlertsType } from './alerts-type'
import { wellDefinedAlerts } from "@utils/well-defined-alerts"

export interface Alert {
  id?: string
  type?: AlertsType
  message_key?: string
  title?: string
  lines?: string[]
}

export function getMessage(alert: Alert): Alert {
  const { type, title, lines, message_key, id } = alert

  if (type && title) {
    return {
      ...alert,
      type, title, lines: lines || []
    }
  }

  if (message_key && wellDefinedAlerts[message_key]) {
    return {
      ...alert,
      ...wellDefinedAlerts[message_key]
    }
  }

  if (message_key && message_key.startsWith("http_error:")) {
    // "http_error:4xx" ou "http_error:5xx"
    const httpErrorClassKey = message_key.slice(0, 12) + "xx"
    if (wellDefinedAlerts[httpErrorClassKey]) {
      return {
        ...alert,
        ...wellDefinedAlerts[httpErrorClassKey]
      }
    }
  }

  return { id: id, type: "INFO", title: "Ação ocorrida:", lines: message_key ? [message_key] : [] }
}
