import React, { FunctionComponent } from "react"
import { UserScopeMapping } from "@models/user-scope-mapping"
import { PermissionChanges } from "@models/permission-changes"
import { useDispatch, useSelector } from "react-redux"
import { Dispatch } from "redux"
import { ReducerAction, ReducerState } from "@store/index"
import {
  SCOPE_NAMES,
  ScopeDescription,
  ScopeName,
  RESOURCE_ADMIN_SCOPE,
} from "@models/scope-description"
import { describeScope } from "@utils/describe-scope"
import {
  SET_PERMISSION_CHANGE,
  DELETE_AS_USER,
} from "@store/as-admin"
import ToggleCheckbox from "../shared/toggle-checkbox"
import { useStaticQuery, graphql } from "gatsby"
import { ProblemError } from "@models/problem-error"

import IconTrash from "@icons/icon-trash.svg"
import { buildScopeDescriptionsSorter } from "@utils/as-permissions/sort-scope-description-builder"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        asAuthorization: {
          baseUrl: string
        }
      }
    }
  }
}

interface ReducerSelected {
  asAdminPermissionList: UserScopeMapping[] | null
  asAdminPermissionChanges: PermissionChanges
  asAdminDeletedUserScopes: UserScopeMapping[]
  asPermissionsError: ProblemError | null
}

const sortScopeDescriptions = buildScopeDescriptionsSorter({
  showAdminScopeFirst: true,
})

const UserPermissions: FunctionComponent = () => {
  /* GraphQL data */
  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          externalServices {
            asAuthorization {
              baseUrl
            }
          }
        }
      }
    }
  `)
 

  /* Redux Hooks */
  const {
    asAdminPermissionList,
    asAdminPermissionChanges,
    asAdminDeletedUserScopes,
    asPermissionsError,
  } = useSelector<ReducerState, ReducerSelected>((state) => {
    return {
      asAdminPermissionList: state.asAdmin.asAdminPermissionList,
      asAdminPermissionChanges: state.asAdmin.asAdminPermissionChanges,
      asAdminDeletedUserScopes: state.asAdmin.asAdminDeletedUserScopes,
      asPermissionsError: state.asAdmin.asPermissionsError,
    }
  })
  const dispatch = useDispatch<Dispatch<ReducerAction>>()

  if (asPermissionsError) {
    return (
      <div>
        {/* TODO: Display error */}
        Não foi possível obter as permissões.
      </div>
    )
  }

  if (!asAdminPermissionList) {
    return null
  }

  const scopeDescriptions = (
    [...SCOPE_NAMES].map(describeScope).filter(Boolean) as ScopeDescription[]
  ).sort(sortScopeDescriptions)

  const createToggle = (userMapping: UserScopeMapping, scope: ScopeName) => {
    let value =
      userMapping.scopes.includes(RESOURCE_ADMIN_SCOPE) ||
      userMapping.scopes.includes(scope)
    const defaultValue =
      scope === RESOURCE_ADMIN_SCOPE
        ? value
        : !userMapping.scopes.includes(RESOURCE_ADMIN_SCOPE) &&
          userMapping.scopes.includes(scope)
    if (userMapping.user in asAdminPermissionChanges) {
      const userChanges = asAdminPermissionChanges[userMapping.user]
      if (userChanges[RESOURCE_ADMIN_SCOPE]) {
        value = true
      } else if (
        !(RESOURCE_ADMIN_SCOPE in userChanges) &&
        userMapping.scopes.includes(RESOURCE_ADMIN_SCOPE)
      ) {
        value = true
      } else if (scope in userChanges) {
        value = userChanges[scope] as boolean
      } else {
        value = userMapping.scopes.includes(scope)
      }
    }
    const isActive =
      scope === RESOURCE_ADMIN_SCOPE ||
      !(userMapping.user in asAdminPermissionChanges &&
      RESOURCE_ADMIN_SCOPE in asAdminPermissionChanges[userMapping.user]
        ? asAdminPermissionChanges[userMapping.user][RESOURCE_ADMIN_SCOPE]
        : userMapping.scopes.includes(RESOURCE_ADMIN_SCOPE))
    return (
      <ToggleCheckbox
        value={value}
        defaultValue={defaultValue}
        isActive={isActive}
        onClick={() =>
          dispatch({
            type: SET_PERMISSION_CHANGE,
            user: userMapping.user,
            scope: scope,
            isGranted: !value,
          })
        }
      />
    )
  }

  const deletedUsers: string[] = asAdminDeletedUserScopes.map(
    (mapping) => mapping.user
  )
  const shownMappings: UserScopeMapping[] = asAdminPermissionList.filter(
    (mapping) => !deletedUsers.includes(mapping.user)
  )

  return (
    <div>
      <div className="mx-3">
        {shownMappings.length > 0 ? (
          <ul className="space-y-6">
            {shownMappings.map((mapping) => (
              <li key={mapping.user}>
                <div className="text-base text-slate-900 font-medium border-b-2 border-slate-400">
                  <div className="inline-block">{mapping.user}</div>
                  <button
                    className="px-3 py-2 inline-block text-slate-700 hover:text-blue-500 focus:outline-none focus:ring"
                    onClick={() =>
                      dispatch({ type: DELETE_AS_USER, user: mapping.user })
                    }
                  >
                    <IconTrash className="h-4 w-4" />
                  </button>
                </div>
                <ul>
                  {scopeDescriptions.map((description) => (
                    <li
                      key={description.scope}
                      className="flex mt-3 mb-5 sm:mt-2 sm:mb-3 flex-no-wrap"
                    >
                      <div className="flex items-baseline flex-wrap sm:flex-no-wrap flex-shrink">
                        <div className="text-slate-800 leading-snug sm:leading-6 w-full mr-3 mb-1 sm:mb-0 flex-none">
                          {description.name}
                        </div>
                        <div className="text-sm text-slate-800 leading-normal sm:leading-6 sm:flex-shrink">
                          {description.permissionDescription}
                        </div>
                      </div>
                      <div className="ml-4 sm:ml-8 mr-1 flex-none self-center">
                        {createToggle(mapping, description.scope)}
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        ) : (
          <div className="mx-4 px-10 mt-10 text-xl font-semibold text-center text-slate-600 leading-tight">
            Nenhum usuário possui mais permissões para este sistema autônomo.
          </div>
        )}
      </div>
    </div>
  )
}

export default UserPermissions
