import { getStateNameByUF } from "./get-state-name-by-uf";

const IxLocations = {
  SE: "Aracaju",
  BEL: "Belém",
  MG: "Belo Horizonte",
  RR: "Boa Vista",
  DF: "Brasília",
  CAC: "Cascavel",
  CPV: "Campina Grande",
  CAS: "Campinas",
  CGR: "Campo Grande",
  CGB: "Cuiabá",
  CXJ: "Caxias do Sul",
  PR: "Curitiba",
  SC: "Florianópolis",
  CE: "Fortaleza",
  IGU: "Foz do Iguaçu",
  GYN: "Goiânia",
  JPA: "João Pessoa",
  LAJ: "Lajeado",
  LDA: "Londrina",
  MCZ: "Maceió",
  MAO: "Manaus",
  MGF: "Maringa",
  NAT: "Natal",
  TO: "Palmas",
  RS: "Porto Alegre",
  PE: "Recife",
  RJ: "Rio de Janeiro",
  BA: "Salvador",
  RIA: "Santa Maria",
  SJC: "São José dos Campos",
  SJP: "São José do Rio Preto",
  SLZ: "São Luis",
  SP: "São Paulo",
  THE: "Teresina",
  VIX: "Vitória",
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  PA: 'Pará',
  PB: 'Paraíba',
  PI: 'Piauí',
  RN: 'Rio Grande do Norte',
  RO: 'Rondônia'
}

export function getIXLocationByCode(locationCode?: string): string{
  if (!locationCode){
    return '';
  }

  const splitLocation = getStateNameByUF(IxLocations, locationCode.split("-")[1])

  if(!splitLocation){
    return locationCode
  }

  return "IX " + splitLocation
}