import React, { FunctionComponent } from "react"
import { AsInfo } from "@models/as-info"
import { Transition } from "@headlessui/react"

interface Props {
  onCloseCard: () => void
  onRequestAdminAccess: () => void
  showCard: boolean
  autonomousSystem: AsInfo
}

const AsnQueryCard: FunctionComponent<Props> = ({
  onCloseCard,
  onRequestAdminAccess,
  showCard,
  autonomousSystem,
}) => {
  return (
    <div className="relative">
      <Transition
        show={showCard}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="absolute w-full mt-6 p-4 rounded-md shadow-md bg-slate-200">
          <div className="flex w-full items-center justify-between space-x-6">
            <div className="flex-1">
              <div className="flex">
                <h3 className="truncate font-medium text-slate-900">
                  {autonomousSystem.asName}
                </h3>
                <p className="flex-shrink-0 ml-1 text-slate-500">
                  AS{autonomousSystem.asn}
                </p>
              </div>
              <p className="truncate text-slate-500">
                {autonomousSystem.asAdminEmail}
              </p>
              <p className="text-sm text-slate-600 mt-4 max-w-lg leading-snug">
                Ao solicitar vínculo com o sistema autônomo, um email será
                enviado ao responsável. Converse antes com esta pessoa e obtenha
                permissão.
              </p>
            </div>
          </div>

          <div className="mt-4 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-4">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-offset-slate-200 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
              onClick={() => onRequestAdminAccess()}
            >
              Solicitar vínculo
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-slate-700 shadow-sm hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-slate-500 ring-offset-slate-200 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
              onClick={() => onCloseCard()}
            >
              Fechar
            </button>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default AsnQueryCard
