import React, { Dispatch, ReactElement, useEffect } from "react"
import IspAppSelection from "@components/index/isp-app-selection"
import ASAcceptedUserRequestTable from "@components/settings-user-as/as-accepted-user-request-table"
import { useKeycloak } from "@react-keycloak/web"
import ASPendigUserRequestTable from "@components/settings-user-as/as-pending-user-request-table"
import { DELETE_ALERT } from "@store/navigation-store"
import { useDispatch } from "react-redux"
import { ReducerAction } from "@store/index"
import { PageDocsSidebarContentGeneric } from "@models/page-docs-sidebar-content-type"

const SettingsUserASPage: () => ReactElement = () => {
  /* React Hooks */
  const { keycloak } = useKeycloak()
  const isAdmin = keycloak?.hasRealmRole("admin")

  const dispatch = useDispatch<Dispatch<ReducerAction>>()

  useEffect(() => {
    // Clear error
    dispatch({
      type: DELETE_ALERT,
      alert: null,
    })
    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="py-6 sm:px-3 h-full">
        <div className="flex-grow mx-auto mt-2">
          <IspAppSelection />
        </div>
        {!isAdmin ? (
          <>
            <div className="flex-grow mx-auto mt-2 pt-10 px-2 ml-3">
              <ASPendigUserRequestTable />
            </div>
            <div className="flex-grow mx-auto mt-2 pt-10 px-2 ml-3">
              <ASAcceptedUserRequestTable />
            </div>
          </>
        ) : (
          <div className="flex-grow mx-auto mt-2 pt-10 px-2 ml-3">
            <p>
              Você é administrador do Simet e está associado à todos os Sistemas
              Autônomos
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default SettingsUserASPage

export const getPageSidebarContent = (): PageDocsSidebarContentGeneric => {
  return {
    title: "Gerenciar sistemas autônomos",
    abstract: "Gerenciar seu vínculo com o sistema autônomo",
    content: (
      <div>
        <h2 className="flex font-medium text-slate-900">
          {/* <svg className='mr-1.5 w-5 h-5 text-slate-700' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 17.25v-.228a4.5 4.5 0 00-.12-1.03l-2.268-9.64a3.375 3.375 0 00-3.285-2.602H7.923a3.375 3.375 0 00-3.285 2.602l-2.268 9.64a4.5 4.5 0 00-.12 1.03v.228m19.5 0a3 3 0 01-3 3H5.25a3 3 0 01-3-3m19.5 0a3 3 0 00-3-3H5.25a3 3 0 00-3 3m16.5 0h.008v.008h-.008v-.008zm-3 0h.008v.008h-.008v-.008z" />
          </svg> */}
          Solicitação de Vínculo
        </h2>
        <p className="leading-snug mt-2">
          <ul className="space-y-2">
            <li>
              Informe o número do asn que deseja solicitar o vínculo, e aperta
              no ícone em buscar, e em sequência solicitar vinculo.
            </li>
            <li>
              Após isso aparecerá em vínculos pendentes a sua solicitação para o
              administrador do sistema autônomo aceitar, vale ressaltar que cada
              pedido tem validade de 24 horas, depois desse prazo sera removido
              das solicitações pendentes e com isso solicitar um novo vínculo
              para aquele sistema autônomo.
            </li>
          </ul>
        </p>

        <h2 className="mt-3 flex font-medium text-slate-900">
          {/* <svg className='mr-1.5 w-5 h-5 text-slate-700' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 17.25v-.228a4.5 4.5 0 00-.12-1.03l-2.268-9.64a3.375 3.375 0 00-3.285-2.602H7.923a3.375 3.375 0 00-3.285 2.602l-2.268 9.64a4.5 4.5 0 00-.12 1.03v.228m19.5 0a3 3 0 01-3 3H5.25a3 3 0 01-3-3m19.5 0a3 3 0 00-3-3H5.25a3 3 0 00-3 3m16.5 0h.008v.008h-.008v-.008zm-3 0h.008v.008h-.008v-.008z" />
          </svg> */}
          Vínculos Ativos
        </h2>
        <p className="leading-snug mt-2">
          <ul className="space-y-2">
            <li>
              Visualizar os seus vínculos ativos com os sistemas autônomos
            </li>
          </ul>
        </p>
      </div>
    ),
  }
}
