import { combineReducers, Reducer } from "redux"
import { antiSpoofingReducer as antiSpoofing } from "./anti-spoofing-store"
import { asAdminReducer as asAdmin } from "./as-admin"
import { asScopeMappingReducer as asScopeMapping } from "./as-scope-mapping"
import { autonomousSystemsReducer as autonomousSystems } from "./autonomous-systems-store"
import { measurementsReducer as measurements } from "./measurements-store"
import { navigationReducer as navigation } from "./navigation-store"
import { simetasProbesStatusReducer as simetasProbesStatus } from "./simetas-probes-status-store"
import { simetasProbesReducer as simetasProbes } from "./simetas-probes-store"
import { measurementsReducer as simetAS } from "./simetas-store"

const reducer = combineReducers({
  antiSpoofing,
  asAdmin,
  asScopeMapping,
  autonomousSystems,
  measurements,
  navigation,
  simetasProbesStatus,
  simetasProbes,
  simetAS
})

export type ReducerState = typeof reducer extends Reducer<infer S, any>
  ? S
  : never
export type ReducerAction = typeof reducer extends Reducer<any, infer A>
  ? A
  : never

export default reducer
