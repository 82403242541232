import { PaginationBar } from '@models/pagination';
import React, { Dispatch, FunctionComponent } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { ReducerState, ReducerAction} from '@store/index';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline"
import { useQueryParams } from "use-query-params"
import { QueryParamConfig } from "@utils/query-params"
import { SET_FILTER_CURSOR } from '@store/navigation-store';

interface ReducerSelected {
  pagination?: PaginationBar
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

const PaginationBarComponent: FunctionComponent = ({ }) => {
  const { pagination } = useSelector<
    ReducerState,
    ReducerSelected
  >((state) => {
    return {
      pagination: state.navigation.pagination
    }
  })

  const dispatch = useDispatch<Dispatch<ReducerAction>>()

  const [_, setSearchParams] = useQueryParams(QueryParamConfig)

  // const { cursor: cursorParams } = _searchParams

  // const [cursor, setCursor] = useState<string | null>(cursorParams ?? (pagination?.current.cursor ?? null))

  // useEffect(() => {
  //   // setSearchParams({ cursor: cursor })
  //   console.log('pagination cursor:', cursor)
  // }, [cursor, cursorParams, pagination])

  const disabledPrevious = !pagination?.previous.cursor
  const disabledNext = !pagination?.next.cursor

  return (
    <div className="flex justify-between mt-6 mb-3">
      <button
        className={classNames("relative inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-500",
          disabledPrevious ? 'opacity-25 cursor-not-allowed' : '')}
        disabled={disabledPrevious}
        onClick={() => {
          dispatch({
            type: SET_FILTER_CURSOR,
            filterCursor: pagination?.previous.cursor ?? null,
          })
          setSearchParams({cursor:  pagination?.previous.cursor})
        }}
      >
        <ChevronLeftIcon className="w-3.5 h-3.5 mr-0.5 -ml-0.5" strokeWidth="3" />
        {/* Medições posteriores */}
        Anterior
      </button>
      <button
        type="button"
        className={classNames("relative inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-500",
          disabledNext ? 'opacity-25 cursor-not-allowed' : '')}
        disabled={disabledNext}
        onClick={() => {
          dispatch({
            type: SET_FILTER_CURSOR,
            filterCursor: pagination?.next.cursor ?? null,
          })
          setSearchParams({cursor:  pagination?.next.cursor})
        }}
      >
        {/* Medições anteriores */}
        Próximo
        <ChevronRightIcon className="w-3.5 h-3.5 ml-0.5" strokeWidth="3" />
      </button>
    </div>
  )
}

export default PaginationBarComponent