import { ElementType } from "@_types/element-type"

export const ANTI_SPOOFING = [
  "ALL",
  "NONE",
  "PRIVATE_IP_UNFILTERED",
  "PUBLIC_IP_UNFILTERED",
] as const

export type AntiSpoofingType = ElementType<typeof ANTI_SPOOFING>

export function formatAntiSpoofing(AntiSpoofing: AntiSpoofingType | undefined): string {
  switch (AntiSpoofing) {
    case "PRIVATE_IP_UNFILTERED":
      return "Sem filtragem de IP privado spoofado"
    case "PUBLIC_IP_UNFILTERED":
      return "Sem filtragem de IP público spoofado"
    case "ALL":
      return "Todas as medições"
    default:
      return "Sem filtragem antispoofing"
  }
  // Assert that all known types are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = AntiSpoofing
  throw new Error("Unknown AntiSpoofing")
}

// be careful, dont change order.
const ANTI_SPOOFING_FILTERING_TYPE = [
  "PRIVATE",
  "PUBLIC_OTHER_AS",
  "TARGET_IP",
  "ALL",
  "NONE"
] as const

type AntiSpoofingFilteringType = ElementType<typeof ANTI_SPOOFING_FILTERING_TYPE>

export function desearializeAntiSpoofing(AntiSpoofing: AntiSpoofingType):AntiSpoofingFilteringType[]{
  switch(AntiSpoofing){
    case "PRIVATE_IP_UNFILTERED":
      // PRIVATE
      return [ANTI_SPOOFING_FILTERING_TYPE[0]]
    case "PUBLIC_IP_UNFILTERED":
      // PUBLIC_OTHER_AS , TARGET_IP
      return [ANTI_SPOOFING_FILTERING_TYPE[1], ANTI_SPOOFING_FILTERING_TYPE[2]]
    case "ALL":
      // ALL
      return [ANTI_SPOOFING_FILTERING_TYPE[3]]
    case "NONE":
      // NONE
      return [ANTI_SPOOFING_FILTERING_TYPE[4]]
  }
    // Assert that all known types are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = AntiSpoofing
  throw new Error("Unknown AntiSpoofing")
}