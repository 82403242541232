
import React, { Fragment, useState, FunctionComponent, memo } from 'react'
import { Menu, Transition, Dialog } from '@headlessui/react'
import { DotsVerticalIcon, BookOpenIcon } from '@heroicons/react/solid'
import ShareButton from './share-button'
import { PageMetadataObject } from '@models/page-metadata-type'
import { XIcon } from '@heroicons/react/outline'


interface Props {
  pageMetadata: PageMetadataObject
}


const PageDocsSidebar: FunctionComponent<Props> = ({ pageMetadata }) => {
  const [openDoc, setOpenDoc] = useState(false)

  return (
    <>
      <div className="flex flex-shrink-0 self-center">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-64 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  <button
                    className="flex w-full items-center text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
                    onClick={() => setOpenDoc(true)}
                  >
                    <BookOpenIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Sobre esta página
                  </button >
                </Menu.Item>
                <Menu.Item>
                  <ShareButton />
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      <Transition as={Fragment} show={openDoc}>
        <Dialog as="div" className="relative z-20" onClose={setOpenDoc}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                // leave="transform transition ease-in-out duration-500 sm:duration-700"
                // leaveFrom="translate-x-0"
                // leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="bg-blue-600 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {
                              pageMetadata.pageDocsSidebarContent?.title ?? ''
                            }
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-blue-600 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setOpenDoc(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-blue-200 leading-snug">
                            {
                              pageMetadata.pageDocsSidebarContent?.abstract ?? ''
                            }
                          </p>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <div className="absolute inset-0 px-4 sm:px-6 text-sm">
                          <div className="h-full space-y-8 text-slate-600" aria-hidden="true" >
                            <>
                              {pageMetadata.pageDocsSidebarContent && "content" in pageMetadata.pageDocsSidebarContent ? (
                                pageMetadata.pageDocsSidebarContent.content
                              ) : (
                                <>
                                  <div>
                                    <h2 className='flex font-medium text-slate-900'>
                                      <svg className='mr-1.5 w-5 h-5 text-slate-700' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 17.25v-.228a4.5 4.5 0 00-.12-1.03l-2.268-9.64a3.375 3.375 0 00-3.285-2.602H7.923a3.375 3.375 0 00-3.285 2.602l-2.268 9.64a4.5 4.5 0 00-.12 1.03v.228m19.5 0a3 3 0 01-3 3H5.25a3 3 0 01-3-3m19.5 0a3 3 0 00-3-3H5.25a3 3 0 00-3 3m16.5 0h.008v.008h-.008v-.008zm-3 0h.008v.008h-.008v-.008z" />
                                      </svg>
                                      Medidores
                                    </h2>
                                    <p>
                                      {
                                        pageMetadata.pageDocsSidebarContent?.measurementClients?.join(", ") ?? ''
                                      }
                                    </p>
                                  </div>
                                  <div>
                                    <h2 className='flex font-medium text-slate-900'>
                                      <svg className="mr-1.5 h-5 w-5 text-slate-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                                      </svg>
                                      Método de medição
                                    </h2>
                                    {
                                      pageMetadata.pageDocsSidebarContent?.methodOfMeasurement?.map((line, index) => <p key={index} className="leading-snug mt-2" dangerouslySetInnerHTML={{ __html: line }} />) ?? ''
                                    }
                                  </div>
                                  <div>
                                    <h2 className='flex font-medium text-slate-900'>
                                      <svg className="mr-1.5 h-5 w-5 text-slate-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z" />
                                      </svg>
                                      Próximos passos
                                    </h2>
                                    <p className="leading-snug mt-2">
                                      <ul className="space-y-2">
                                        {
                                          pageMetadata.pageDocsSidebarContent?.nextSteps?.map((line, index) => <li key={index} dangerouslySetInnerHTML={{ __html: line }} />) ?? ''
                                        }
                                      </ul>
                                    </p>
                                  </div>
                                </>
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default memo(PageDocsSidebar)
