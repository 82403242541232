import React,{ FunctionComponent } from "react";

const EmptyStateTable: FunctionComponent = ()=> {
  return (
    <div className="flex flex-col items-center my-16">
      <div className="text-sm font-semibold text-slate-600 mb-1">
        Período sem medição.
      </div>
      <div className="text-slate-500 text-sm text-center mt-6">
        Aumente seus testes do SIMET com estas estratégias:
      </div>
      <ul className="mt-4 space-y-1.5 text-slate-500 text-sm">
        <li className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
          </svg>
          Adotar o medidor
          <a href="https://simet.nic.br" target="_blank" className="mx-1 text-blue-600 font-medium" rel="noreferrer">
            simet.nic.br
          </a>
          no atendimento ao usuário.
        </li>
        <li className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
          </svg>
          Utilizar o <a href="https://simet.nic.br/simetbox.html" target="_blank" className="mx-1 text-blue-600 font-medium" rel="noreferrer">
            SimetBox
          </a> sob demanda em conexões problemáticas.
        </li>
        <li className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
          </svg>
          Monitorar a experiência dos clientes continuamente com o
          <a href="https://simet.nic.br/simetbox.html" target="_blank" className="ml-1 text-blue-600 font-medium" rel="noreferrer">
            SimetBox
          </a>
          .
        </li>
      </ul>
    </div>
  )
}

export default EmptyStateTable;