import React, { useState, useEffect, ReactElement } from "react"
import Header from "@components/shared/header"
import { graphql, PageProps } from "gatsby"
import axios from "axios"
import Helmet from "react-helmet"
import { parse as queryParse } from "query-string"
import { ConfirmationResponse } from "@models/confirmation-response"
import { ProblemError } from "@models/problem-error"
import ProblemErrorComponent from "@components/shared/problem-error-component"
import Footer from "@components/shared/footer"
import { GraphQLResponse } from "@_types/graphql-response"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        asAuthorization: {
          baseUrl: string
        }
      }
    }
  }
}

const ConfirmationPage: (_: PageProps<GraphQLProps>) => ReactElement = ({
  data,
}) => {
  /* GraphQL data */
  const asAuthorizationUrl =
    data.site.siteMetadata.externalServices.asAuthorization.baseUrl

  /* React Hooks */
  const [isValidatingToken, setValidatingToken] = useState(false)
  const [isFinishedValidating, setFinishedValidating] = useState(false)
  const [confirmationResponse, setConfirmationResponse] =
    useState<ConfirmationResponse>()
  const [confirmationError, setConfirmationError] = useState<ProblemError>()
  const isBrowser = typeof window !== "undefined"
  const confirmToken = () => {
    let token: string
    try {
      token = getTokenCode()
    } catch (err) {
      // console.log('getTokenCode error', err);
      setConfirmationError({
        detail:
          "Nenhum token válido foi informado. Verifique a URL e tente novamente.",
      })
      setFinishedValidating(true)
      return
    }
    setTimeout(
      () =>
        (async () => {
          try {
            setValidatingToken(true)
            const response = await axios.post<
              GraphQLResponse<{ confirmation: ConfirmationResponse }>
            >(`${asAuthorizationUrl}`, {
              query:
                `` +
                `mutation ($token: String!) { ` +
                `  confirmation(token: $token) { ` +
                `    type ` +
                `    ... on AsAdminAccessConfirmationResponse { ` +
                `      newAdmin asn ` +
                `    } ` +
                `  } ` +
                `}`,
              variables: {
                token,
              },
            })
            if (response.data.errors) {
              if (response.data.errors[0]) {
                setConfirmationError(
                  JSON.parse(response.data.errors[0].message)
                )
                return
              } else {
                throw new Error("Unknown error")
              }
            }
            if (response.data.data?.confirmation?.type) {
              setConfirmationResponse(response.data.data.confirmation)
            } else {
              setConfirmationError({
                title: "Erro ao validar token",
                detail:
                  "Ocorreu um erro desconhecido. Tente novamente mais tarde.",
              })
            }
          } catch (err: any) {
            // console.log('confirmToken error', err);
            if (err.response?.data?.errors && err.response?.data?.errors[0]) {
              setConfirmationError(err.response.data.errors[0])
            } else {
              setConfirmationError({
                title: "Erro ao validar token",
                detail:
                  "Ocorreu um erro de conexão. Verifique sua Internet e tente novamente mais tarde.",
              })
            }
          } finally {
            setValidatingToken(false)
            setFinishedValidating(true)
          }
        })().catch(console.error),
      100
    )
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(confirmToken, [])

  /* Helpers */
  const getTokenCode = () => {
    const { token } = queryParse(window.location.search)
    if (!token) {
      throw new Error("Missing token parameter.")
    }
    if (typeof token !== "string") {
      throw new Error("Invalid token format.")
    }
    return token
  }

  const renderConfirmationResponse = () => {
    switch (confirmationResponse?.type) {
      case "AS_ADMIN_ACCESS":
        return `Sucesso! ${confirmationResponse.newAdmin} agora é um(a) administrador(a) do AS${confirmationResponse.asn}.`
      default:
        return "Sucesso! A permissão foi concedida."
    }
  }

  return (
    <div className="flex flex-col min-h-screen h-full bg-slate-200">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Confirmação - PAS</title>
      </Helmet>
      {/* <Header /> */}
      <div className="flex-grow max-w-xl mx-auto mt-16 pb-32">
        <div className="px-6 text-xl text-center text-slate-900 font-normal leading-tight">
          {isValidatingToken ? (
            isFinishedValidating ? (
              confirmationResponse ? (
                renderConfirmationResponse()
              ) : (
                <ProblemErrorComponent
                  problemError={{
                    detail:
                      "Ocorreu um erro desconhecido. Tente recarregar a página.",
                  }}
                  titleClassName="text-3xl text-red-600 font-semibold mb-3"
                  detailClassName="text-red-600"
                />
              )
            ) : (
              "Validando..."
            )
          ) : isFinishedValidating ? (
            confirmationResponse ? (
              renderConfirmationResponse()
            ) : confirmationError ? (
              <ProblemErrorComponent
                problemError={confirmationError}
                titleClassName="text-3xl text-red-600 font-semibold mb-3"
                detailClassName="text-red-600"
              />
            ) : (
              <ProblemErrorComponent
                problemError={{
                  detail:
                    "Ocorreu um erro desconhecido. Tente recarregar a página.",
                }}
                titleClassName="text-3xl text-red-600 font-semibold mb-3"
                detailClassName="text-red-600"
              />
            )
          ) : (
            " "
          )}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default ConfirmationPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        externalServices {
          asAuthorization {
            baseUrl
          }
        }
      }
    }
  }
`
