import { validate } from "uuid"

export const shortenUUID = (uuid: string | undefined): string | undefined => {
  if (!uuid) {
    return undefined
  }

  if (!validate(uuid)) {
    return undefined
  }

  const parsedUUIDBegin = uuid.slice(0, 6)
  const parsedUUIDEnd = uuid.slice(-6)

  const finalUUID = parsedUUIDBegin + "..." + parsedUUIDEnd

  return finalUUID
}
