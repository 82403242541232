import React, { FunctionComponent, memo } from "react"
import { SimetasProbeStatus } from "@models/simetas-probe-status"
import { add, format } from "date-fns"

const STATUS_OK = "ok"
const STATUS_ERROR = "error"
const STATUS_WAITING = "waiting"

interface PropsServers {
  apiResponse: SimetasProbeStatus[]
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

const Servers: FunctionComponent<PropsServers> = ({ apiResponse }) => {
  return (
    <div className="">
      <div className="mb-6 text-xl font-medium text-slate-600">
        Servidores de Teste e Sondas de medição
      </div>
      <div className="items-start justify-start grid grid-cols-2 gap-6 gap-y-6">
        {apiResponse?.map((probesStatus) => (
          <VmCard probesStatus={probesStatus} />
        ))}
      </div>
    </div>
  )
}

export default memo(Servers)

function getStatus(probesStatus: SimetasProbeStatus) {
  const config = new Map<string, string | null>()

  switch (probesStatus?.status) {
    case STATUS_OK:
      // return "Ativado"
      config.set("status", "Ativado")
      config.set("class", "bg-green-100")
      config.set("date", localDate(probesStatus.activatedAt))
      config.set("previst_date", null)
      break
    case STATUS_ERROR:
      config.set("status", "Com pendência")
      config.set("class", "bg-red-100")
      config.set("date", localDate(probesStatus.errorAt))
      config.set("previst_date", null)
      // return "Com pendência"
      break
    case STATUS_WAITING:
      config.set("status", "Em análise")
      config.set("class", "bg-yellow-400")
      config.set("date", localDate(probesStatus.receivedAt))
      const date = new Date(probesStatus.receivedAt)
      const previst_date = add(date, { days: 2 })
      config.set("previst_date", format(previst_date, "dd/MM/yyyy"))
      // return "Em análise"
      break
    default:
      config.set("status", "Desconhecido")
      config.set("class", "bg-indigo-100")
      config.set("date", localDate(probesStatus.receivedAt))
      config.set("previst_date", null)
      // return "Desconhecido"
      break
  }
  return config
}

function getFilterOnPort(status_description: string) {
  const port = status_description.split("_")[3]
  return "Filtro na porta " + port
}

function issue(status_description: string) {
  switch (status_description) {
    case "ping_fail":
    case "no_external_connection":
      return "VM sem conectividade externa"
    case "insufficient_vcpu":
      return "VM com CPU insuficiente"
    case "insufficient_memory":
      return "VM com memória insuficiente"
    case "private_network":
      return "VM foi configurada com rede privada"
    case "no_network_interface_speed":
      return "VM sem leitura da velocidade da interface de rede"
    case "no_ix_available":
      return "Não esta conectado em nenhum IX"
    default:
      // Verifica se o erro esta na porta
      if (status_description.includes("filter_on_port")) {
        return getFilterOnPort(status_description)
      }
      return "Desconhecida"
  }
}

function localDate(dateString: string) {
  const last_seen = new Date(dateString)
  return dateString !== "" ? last_seen.toLocaleString("pt-BR") : "--"
}

interface PropsVmCard {
  probesStatus: SimetasProbeStatus
}

const VmCard: FunctionComponent<PropsVmCard> = ({ probesStatus }) => {
  const getConfig = getStatus(probesStatus)

  const previst_date = getConfig.get("previst_date")

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg max-w-xl shadow-symmetric h-full">
      <div className="px-4 py-5 sm:px-6">
        <div className="text-lg font-medium leading-6 text-slate-900 inline-flex">
          {probesStatus?.description}
        </div>
      </div>
      <div className="border-t border-slate-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-slate-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-slate-500">Status</dt>
            <dd className="mt-1 sm:col-span-2 sm:mt-0 flex flex-col space-y-2 items-start">
              <span
                className={classNames(
                  "inline-flex items-center rounded-full px-3 py-0.5 mr-3 text-sm font-medium",
                  getConfig.get("class") || ""
                )}
              >
                {getConfig.get("status")}
              </span>
              <span className="inline-flex items-center rounded-full py-0.5 text-sm font-medium">
                Desde: {getConfig.get("date")}
              </span>
              {previst_date !== null && (
                <span className="inline-flex items-center rounded-full py-0.5 text-sm font-medium">
                  Prazo previsto: {previst_date}
                </span>
              )}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-slate-500">VM ID</dt>
            <dd className="mt-1 text-sm text-slate-900 sm:col-span-2 sm:mt-0">
              {probesStatus.vmID}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-slate-500">
              Último contato
            </dt>
            <dd className="mt-1 text-sm text-slate-900 sm:col-span-2 sm:mt-0">
              {localDate(probesStatus.lastSeen)}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-slate-500">
              Endereço IPv4
            </dt>
            <dd className="mt-1 text-sm text-slate-900 sm:col-span-2 sm:mt-0">
              {probesStatus?.ipv4}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-slate-500">
              Endereço IPv6
            </dt>
            <dd className="mt-1 text-sm text-slate-900 sm:col-span-2 sm:mt-0">
              {probesStatus?.ipv6}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-slate-500">Nome DNS</dt>
            <dd className="mt-1 text-sm text-slate-900 sm:col-span-2 sm:mt-0">
              {probesStatus?.name}
            </dd>
          </div>
          {probesStatus?.status == "error" && (
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-slate-500">Pendências</dt>
              <dd className="mt-1 text-sm text-slate-900 sm:col-span-2 sm:mt-0 font-medium text-red-700">
                <ul>
                  {probesStatus?.statusDescription.map((desc) => (
                    <li>{issue(desc)}</li>
                  ))}
                </ul>
              </dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  )
}
