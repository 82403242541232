import React, { FunctionComponent, memo } from "react"
import { PageMetadataObject } from "@models/page-metadata-type"
import HeaderPageNavigation from "./header-page-navigation"
import HeaderVisualPath from "./header-visual-path"
import PageDocsSidebar from "../page-docs-sidebar"

interface Props {
  pageMetadata: PageMetadataObject
}

const Header: FunctionComponent<Props> = ({ pageMetadata }) => {
  return (
    <>
      {/* Page title & actions */}
      <div className="flex flex-col border-b border-slate-200 px-4 pt-6 sm:px-6 lg:px-8">
        {/* <div className="min-w-0 flex-1">
          <h1 className="flex items-center text-xl font-semibold leading-6 text-slate-900 sm:truncate">
            <HeaderVisualPath pageMetadata={pageMetadata} />
          </h1>
        </div> */}

        <div className="flex justify-between items-end">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <HeaderPageNavigation pageMetadata={pageMetadata} />
          </nav>
          <PageDocsSidebar pageMetadata={pageMetadata} />
        </div>
      </div>
    </>
  )
}

export default memo(Header)
