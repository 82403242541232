import React, { FunctionComponent } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { ReducerAction, ReducerState } from '@store/index';
import SingleAlert from './single-alert'
import { Dispatch } from "redux"
import { DELETE_ALERT } from '@store/navigation-store';
import { getMessage, Alert } from '@models/alert';

interface ReducerSelected {
  alertList: Alert[]
}


const AlertsComponent: FunctionComponent = () => {
  const {
    alertList
  } = useSelector<ReducerState, ReducerSelected>((state) => {
    return {
      alertList: state.navigation.alertList,
    }
  })
  const dispatch = useDispatch<Dispatch<ReducerAction>>()

  return (
    <div className='absolute z-40 top-0 left-1/2 transform -translate-x-1/2 w-full max-w-2xl px-4'>
      {alertList.map(getMessage).map((message, index) => (
        <SingleAlert
          key={index}
          message={message}
          dismiss={() => {
            dispatch({
              type: DELETE_ALERT,
              alert: message
            })
          }}
        />
      ))}
    </div>
  )
}

export default AlertsComponent;
