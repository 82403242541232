// TODO: REMOVER QUANDO TUDO ESTIVER FUNCIONAL
// @ts-nocheck

import React, { FunctionComponent } from "react"

import { useEffect, useState } from "react"

import { Tab } from "@headlessui/react"
import clsx from "clsx"

import { Container } from "./Container"

const features = [
  {
    title: "Experiência do usuário",
    description:
      "Saiba como anda a experiência de qualidade dos seu usuários. Acesse as medições SIMET realizadas na sua rede.",
    image: "/images/landing/screenshots/metrics-dashboard.png",
  },
  {
    title: "Conexão ao IX.br",
    description:
      "Monitore sua conexão ao IX.br. Acompanhe o alcance dos principais sistemas autônomos e de CDNs.",
    image: "/images/landing/screenshots/ix-connection.png",
  },
  {
    title: "Conformidade técnica",
    description:
      "Descubra se você implementa corretamente os padrões modernos da Internet como IPv6 e BCP-38. Receba orientação como implementar.",
    image: "/images/landing/screenshots/compliance-antispoofing.png",
  },
  {
    title: "Roteamento Inter-AS",
    description:
      "Feche uma sessão BGP conosco e receba uma análise da sua conectivide com peers, transito e Internet.",
    image: "/images/landing/screenshots/routing-stats.png",
  },
]

export const Features: FunctionComponent = () => {
  let [tabOrientation, setTabOrientation] = useState("horizontal")

  useEffect(() => {
    let lgMediaQuery = window.matchMedia("(min-width: 1024px)")
    // TODO: Verificar se dá problemas. Podemos testar passar ...matches ao invés de matches, mas precisamos nos certificar que esse é o efeito desejado.
    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? "vertical" : "horizontal")
    }

    onMediaQueryChange(lgMediaQuery)
    lgMediaQuery.addEventListener("change", onMediaQueryChange)

    return () => {
      lgMediaQuery.removeEventListener("change", onMediaQueryChange)
    }
  }, [])

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-blue-600 pt-20 pb-28 sm:py-32"
    >
      <img
        className="absolute top-1/2 left-1/2 max-w-none transform -translate-x-44 -translate-y-42"
        src="/images/landing/background-features.jpg"
        alt=""
        width={2245}
        height={1636}
      />
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            Insights para operar seu sistema autônomo.
          </h2>
          <p className="mt-6 text-lg tracking-tight text-blue-100">
            Acesse todos projetos de medição do NIC.br em um único portal
            integrado com dados e dicas acionáveis.
          </p>
        </div>
        <Tab.Group
          as="div"
          className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === "vertical"}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={clsx(
                        "group relative rounded-full py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6",
                        selectedIndex === featureIndex
                          ? "bg-white lg:bg-opacity-10 lg:ring-1 lg:ring-inset lg:ring-white lg:ring-opacity-10"
                          : "hover:bg-white hover:bg-opacity-10 lg:hover:bg-white lg:hover:bg-opacity-5"
                      )}
                    >
                      <h3>
                        <Tab
                          className={clsx(
                            "font-display text-lg focus:outline-none",
                            selectedIndex === featureIndex
                              ? "text-blue-600 lg:text-white"
                              : "text-blue-100 hover:text-white lg:text-white"
                          )}
                        >
                          <span className="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={clsx(
                          "mt-2 hidden text-sm lg:block",
                          selectedIndex === featureIndex
                            ? "text-white"
                            : "text-blue-100 group-hover:text-white"
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={false}>
                    <div className="relative sm:px-6 lg:hidden">
                      <div className="absolute -inset-x-4 -top-26 -bottom-18 bg-white bg-opacity-10 ring-1 ring-inset ring-white ring-opacity-10 sm:inset-x-0 sm:rounded-t-xl" />
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    <div className="mt-10 w-45rem overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900 sm:w-auto lg:mt-0 lg:w-67rem">
                      <img
                        className="w-full"
                        src={feature.image}
                        alt=""
                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      />
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </Container>
    </section>
  )
}
