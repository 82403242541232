import React, {
  FunctionComponent,
  ReactElement,
  Suspense,
  useEffect,
  useContext,
} from "react"
import { Head } from "@components/head"
import Header from "@components/header"
import RequireAuthentication from "@components/shared/require-authentication"
import Sidebar from "@components/sidebar"
import { useKeycloak } from "@react-keycloak/web"
import AlertComponent from "@components/alerts"
import { parseURLtoPageMetadata } from "@utils/parse-url-to-page-metadata"
import { useSiteMetadata } from "@hooks/use-site-metadata"
import Banner from "@components/shared/banner"
import { CurrentContext } from "@components/context"
import FeedbackButtonComponent from "@components/shared/feedback-button-component"

const DefaultLoggedInLayout: FunctionComponent<{ location: string }> = ({
  children,
  location,
}) => {
  const { keycloak } = useKeycloak()

  const {
    pageMetadata,
    setPageMetadata,
    availableAutonomousSystemsList,
    scopeMapList,
    isAvailableAutonomousSystemsListReady,
    isScopeMapListReady,
  } = useContext(CurrentContext)
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    setPageMetadata(
      parseURLtoPageMetadata(availableAutonomousSystemsList, keycloak)
    )
  }, [availableAutonomousSystemsList, keycloak, location])

  const data = useSiteMetadata()

  return (
    <>
      {pageMetadata && pageMetadata.isReady && (
        <>
          <Head asn={pageMetadata.asn} />
          <RequireAuthentication>
            <div className="min-h-full relative">
              <AlertComponent />
              {isScopeMapListReady &&
                isAvailableAutonomousSystemsListReady &&
                isBrowser && (
                  <>
                    <Sidebar
                      pageMetadata={pageMetadata}
                      scopeMappingList={scopeMapList}
                      availableAutonomousSystems={
                        availableAutonomousSystemsList
                      }
                      externalServices={data.externalServices}
                    />
                    <div className="bg-white">
                      <div className="flex flex-col lg:pl-64">
                        <main className="flex-1">
                          {/* <Banner /> */}
                          <Header pageMetadata={pageMetadata} />
                          <FeedbackButtonComponent />
                          {/* <Suspense fallback={"loading ..."}> */}
                          {children}
                          {/* </Suspense> */}
                        </main>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </RequireAuthentication>
        </>
      )}
    </>
  )
}

export default DefaultLoggedInLayout
