import { AutonomousSystem } from "@models/autonomous-system"
import { Reducer } from "redux"
import { rearrangeArrayBySelection } from "@utils/rearrange-array-by-selection"
import { UserScopeMapping } from "@models/user-scope-mapping"
import { PermissionChanges } from "@models/permission-changes"
import { ScopeName } from "@models/scope-description"
import { ProblemError } from "@models/problem-error"

/* State */

export interface AsAdminState {
  asAdminList: AutonomousSystem[] | null
  selectedAutonomousSystem: AutonomousSystem | null
  isLoadingAsAdminList: boolean
  asAdminListError: ProblemError | null

  asAdminPermissionList: UserScopeMapping[] | null
  isLoadingAsAdminPermissionList: boolean
  asPermissionsError: ProblemError | null

  asAdminPermissionChanges: PermissionChanges
  asAdminDeletedUserScopes: UserScopeMapping[]
  hasPendingPermissionChanges: boolean
}

const INITIAL_STATE: AsAdminState = {
  asAdminList: null,
  selectedAutonomousSystem: null,
  isLoadingAsAdminList: true,
  asAdminListError: null,

  asAdminPermissionList: null,
  isLoadingAsAdminPermissionList: true,
  asPermissionsError: null,

  asAdminPermissionChanges: {},
  asAdminDeletedUserScopes: [],
  hasPendingPermissionChanges: false,
}

/* Actions */

export const BEGIN_FETCHING_AUTONOMOUS_SYSTEM_LIST =
  "as-admin/BEGIN_FETCHING_AUTONOMOUS_SYSTEM_LIST"

export interface BeginFetchingAutonomousSystemListAction {
  type: typeof BEGIN_FETCHING_AUTONOMOUS_SYSTEM_LIST
}

export const STOP_FETCHING_AUTONOMOUS_SYSTEM_LIST =
  "as-admin/STOP_FETCHING_AUTONOMOUS_SYSTEM_LIST"

export interface StopFetchingAutonomousSystemListAction {
  type: typeof STOP_FETCHING_AUTONOMOUS_SYSTEM_LIST
}

export const SET_AUTONOMOUS_SYSTEM_LIST = "as-admin/SET_AUTONOMOUS_SYSTEM_LIST"

export interface SetAutonomousSystemListAction {
  type: typeof SET_AUTONOMOUS_SYSTEM_LIST
  autonomousSystemList: AutonomousSystem[]
}

export const SELECT_AUTONOMOUS_SYSTEM = "as-admin/SELECT_AUTONOMOUS_SYSTEM"

export interface SelectAutonomousSystemAction {
  type: typeof SELECT_AUTONOMOUS_SYSTEM
  autonomousSystem: AutonomousSystem | null
}

export const CLEAR_AUTONOMOUS_SYSTEM = "as-admin/CLEAR_AUTONOMOUS_SYSTEM"

export interface ClearAutonomousSystemAction {
  type: typeof CLEAR_AUTONOMOUS_SYSTEM
}

export const SET_AS_ADMIN_LIST_ERROR =
  "as-scope-mapping/SET_AS_ADMIN_LIST_ERROR"

export interface SetAsAdminListErrorAction {
  type: typeof SET_AS_ADMIN_LIST_ERROR
  asAdminListError: ProblemError | null
}

export const BEGIN_FETCHING_AS_ADMIN_PERMISSION_LIST =
  "as-admin/BEGIN_FETCHING_AS_ADMIN_PERMISSION_LIST"

export interface BeginFetchingAsAdminPermissionListAction {
  type: typeof BEGIN_FETCHING_AS_ADMIN_PERMISSION_LIST
}

export const STOP_FETCHING_AS_ADMIN_PERMISSION_LIST =
  "as-admin/STOP_FETCHING_AS_ADMIN_PERMISSION_LIST"

export interface StopFetchingAsAdminPermissionListAction {
  type: typeof STOP_FETCHING_AS_ADMIN_PERMISSION_LIST
}

export const SET_AS_ADMIN_PERMISSION_LIST =
  "as-admin/SET_AS_ADMIN_PERMISSION_LIST"

export interface SetAsAdminPermissionListAction {
  type: typeof SET_AS_ADMIN_PERMISSION_LIST
  asPermissionList: UserScopeMapping[]
}

export const CLEAR_AS_ADMIN_PERMISSION_LIST =
  "as-admin/CLEAR_AS_ADMIN_PERMISSION_LIST"

export interface ClearAsAdminPermissionListAction {
  type: typeof CLEAR_AS_ADMIN_PERMISSION_LIST
}

export const SET_AS_PERMISSIONS_ERROR =
  "as-scope-mapping/SET_AS_PERMISSIONS_ERROR"

export interface SetAsPermissionsErrorAction {
  type: typeof SET_AS_PERMISSIONS_ERROR
  asPermissionsError: ProblemError | null
}

export const SET_PERMISSION_CHANGE = "as-admin/SET_PERMISSION_CHANGE"

export interface SetPermissionChangeAction {
  type: typeof SET_PERMISSION_CHANGE
  user: string
  scope: ScopeName
  isGranted: boolean
}

export const CLEAR_PERMISSION_CHANGE_LIST =
  "as-admin/CLEAR_PERMISSION_CHANGE_LIST"

export interface ClearPermissionChangeListAction {
  type: typeof CLEAR_PERMISSION_CHANGE_LIST
}

export const ADD_USER_TO_PERMISSION_CHANGE =
  "as-admin/ADD_USER_TO_PERMISSION_CHANGE"

export interface AddUserToPermissionChangeAction {
  type: typeof ADD_USER_TO_PERMISSION_CHANGE
  user: string
}

export const DELETE_AS_USER = "as-admin/DELETE_AS_USER"

export interface DeleteAsUserAction {
  type: typeof DELETE_AS_USER
  user: string
}

export type AsAdminAction =
  | BeginFetchingAutonomousSystemListAction
  | StopFetchingAutonomousSystemListAction
  | SetAutonomousSystemListAction
  | SelectAutonomousSystemAction
  | ClearAutonomousSystemAction
  | SetAsAdminListErrorAction
  | BeginFetchingAsAdminPermissionListAction
  | StopFetchingAsAdminPermissionListAction
  | SetAsAdminPermissionListAction
  | ClearAsAdminPermissionListAction
  | SetAsPermissionsErrorAction
  | SetPermissionChangeAction
  | ClearPermissionChangeListAction
  | AddUserToPermissionChangeAction
  | DeleteAsUserAction

/* Reducer */

export const asAdminReducer: Reducer<AsAdminState, AsAdminAction> = (
  state: AsAdminState = INITIAL_STATE,
  action: AsAdminAction
) => {
  if (action.type === BEGIN_FETCHING_AUTONOMOUS_SYSTEM_LIST) {
    const newState: AsAdminState = {
      ...state,
      isLoadingAsAdminList: true,
    }
    return newState
  }

  if (action.type === STOP_FETCHING_AUTONOMOUS_SYSTEM_LIST) {
    const newState: AsAdminState = {
      ...state,
      isLoadingAsAdminList: false,
    }
    return newState
  }

  if (action.type === SET_AUTONOMOUS_SYSTEM_LIST) {
    let { autonomousSystemList: asAdminList } = action
    if (state.selectedAutonomousSystem) {
      asAdminList = rearrangeArrayBySelection(
        asAdminList,
        state.selectedAutonomousSystem,
        (resource) => resource.asn
      )
    }
    const newState: AsAdminState = {
      ...state,
      isLoadingAsAdminList: false,
      asAdminList,
    }
    return newState
  }

  if (action.type === SELECT_AUTONOMOUS_SYSTEM) {
    const { autonomousSystem: selectedAutonomousSystem } = action
    let { asAdminList } = state
    if (selectedAutonomousSystem) {
      asAdminList = rearrangeArrayBySelection(
        asAdminList,
        selectedAutonomousSystem,
        (resource) => resource.asn
      )
    }
    const newState: AsAdminState = {
      ...state,
      selectedAutonomousSystem,
      asAdminList,
    }
    return newState
  }

  if (action.type === CLEAR_AUTONOMOUS_SYSTEM) {
    const newState: AsAdminState = {
      ...state,
      selectedAutonomousSystem: null,
    }
    return newState
  }

  if (action.type === SET_AS_ADMIN_LIST_ERROR) {
    const { asAdminListError } = action
    const newState: AsAdminState = {
      ...state,
      asAdminListError,
    }
    return newState
  }

  if (action.type === BEGIN_FETCHING_AS_ADMIN_PERMISSION_LIST) {
    const newState: AsAdminState = {
      ...state,
      isLoadingAsAdminPermissionList: true,
    }
    return newState
  }

  if (action.type === STOP_FETCHING_AS_ADMIN_PERMISSION_LIST) {
    const newState: AsAdminState = {
      ...state,
      isLoadingAsAdminPermissionList: false,
    }
    return newState
  }

  if (action.type === SET_AS_ADMIN_PERMISSION_LIST) {
    const newState: AsAdminState = {
      ...state,
      asAdminPermissionList: action.asPermissionList,
      asAdminPermissionChanges: {},
      asAdminDeletedUserScopes: [],
      hasPendingPermissionChanges: false,
    }
    return newState
  }

  if (action.type === CLEAR_AS_ADMIN_PERMISSION_LIST) {
    const newState: AsAdminState = {
      ...state,
      asAdminPermissionList: null,
      asAdminPermissionChanges: {},
      asAdminDeletedUserScopes: [],
      hasPendingPermissionChanges: false,
    }
    return newState
  }

  if (action.type === SET_AS_PERMISSIONS_ERROR) {
    const { asPermissionsError } = action
    const newState: AsAdminState = {
      ...state,
      asPermissionsError,
    }
    return newState
  }

  if (action.type === SET_PERMISSION_CHANGE) {
    if (state.asAdminPermissionList) {
      const userMapping = state.asAdminPermissionList.find(
        (mapping) => mapping.user === action.user
      )
      if (userMapping) {
        const newAsAdminPermissionChanges: PermissionChanges = {
          ...state.asAdminPermissionChanges,
          [action.user]: {
            ...(state.asAdminPermissionChanges[action.user] || {}),
            [action.scope]: action.isGranted,
          },
        }
        // Delete redundant changes (i.e. no difference to asAdminPermissionList)
        if (action.isGranted === userMapping.scopes.includes(action.scope)) {
          delete newAsAdminPermissionChanges[action.user][action.scope]
          if (
            Object.keys(newAsAdminPermissionChanges[action.user]).length === 0
          ) {
            delete newAsAdminPermissionChanges[action.user]
          }
        }
        const newState: AsAdminState = {
          ...state,
          asAdminPermissionChanges: newAsAdminPermissionChanges,
          hasPendingPermissionChanges:
            Object.keys(newAsAdminPermissionChanges).length > 0,
        }
        return newState
      }
    }
    return state
  }

  if (action.type === CLEAR_PERMISSION_CHANGE_LIST) {
    const newState: AsAdminState = {
      ...state,
      asAdminPermissionList:
        state.asAdminPermissionList?.filter(
          (mapping) => mapping.scopes.length > 0
        ) || null,
      asAdminPermissionChanges: {},
      asAdminDeletedUserScopes: [],
      hasPendingPermissionChanges: false,
    }
    return newState
  }

  if (action.type === ADD_USER_TO_PERMISSION_CHANGE) {
    const deletedScopeIndex = state.asAdminDeletedUserScopes.findIndex(
      (mapping) => mapping.user === action.user
    )
    const existingMapping = state.asAdminPermissionList?.find(
      (mapping) => mapping.user === action.user
    )
    if (
      state.asAdminPermissionList &&
      (deletedScopeIndex > -1 || !existingMapping)
    ) {
      const newAsAdminPermissionChanges: PermissionChanges = {}
      for (const [user, record] of Object.entries(
        state.asAdminPermissionChanges
      )) {
        if (user !== action.user) {
          newAsAdminPermissionChanges[user] = record
        }
      }
      const newState: AsAdminState = {
        ...state,
        asAdminPermissionList: existingMapping
          ? state.asAdminPermissionList
          : [
              deletedScopeIndex > -1
                ? state.asAdminDeletedUserScopes[deletedScopeIndex]
                : {
                    user: action.user,
                    scopes: [],
                  },
              ...state.asAdminPermissionList,
            ],
        asAdminDeletedUserScopes:
          deletedScopeIndex > -1
            ? [
                ...state.asAdminDeletedUserScopes.slice(0, deletedScopeIndex),
                ...state.asAdminDeletedUserScopes.slice(deletedScopeIndex + 1),
              ]
            : state.asAdminDeletedUserScopes,
        asAdminPermissionChanges: newAsAdminPermissionChanges,
        hasPendingPermissionChanges:
          Object.keys(newAsAdminPermissionChanges).length > 0,
      }
      return newState
    }
    return state
  }

  if (action.type === DELETE_AS_USER) {
    const newAsAdminPermissionChanges = state.asAdminPermissionChanges
    delete newAsAdminPermissionChanges[action.user]
    const previousUserPermissions = state.asAdminPermissionList?.find(
      (mapping) => mapping.user === action.user
    )
    if (previousUserPermissions && previousUserPermissions.scopes.length > 0) {
      newAsAdminPermissionChanges[action.user] = {} as any
      for (const scope of previousUserPermissions.scopes) {
        newAsAdminPermissionChanges[action.user][scope] = false
      }
    }
    const newState: AsAdminState = {
      ...state,
      asAdminPermissionChanges: newAsAdminPermissionChanges,
      asAdminDeletedUserScopes: previousUserPermissions
        ? [...state.asAdminDeletedUserScopes, previousUserPermissions]
        : state.asAdminDeletedUserScopes,
      hasPendingPermissionChanges:
        Object.keys(newAsAdminPermissionChanges).length > 0,
    }
    return newState
  }

  // Assert that all known actions are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = action
  return state
}
