export const MockKeycloakInstance: any = {
  authenticated: true,
  init: () => Promise.resolve(false),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  accountManagement: () => Promise.resolve(),
  createLoginUrl: () => null,
  createLogoutUrl: () => null,
  createRegisterUrl: () => null,
  createAccountUrl: () => null,
  isTokenExpired: () => true,
  updateToken: () => Promise.resolve(false),
  clearToken: () => {},
  hasRealmRole: () => true,
  hasResourceRole: () => false,
  loadUserProfile: () => Promise.resolve({}),
  loadUserInfo: () => Promise.resolve({}),
}
