import { PermissionChangesAction } from "@models/permission-changes-action"
import { PermissionChanges } from "@models/permission-changes"
import { UserScopeMapping } from "@models/user-scope-mapping"
import {
  RESOURCE_ADMIN_SCOPE,
  ScopeName,
  SCOPE_NAMES,
} from "@models/scope-description"

const NON_ADMIN_SCOPES = SCOPE_NAMES.filter(
  (scope) => scope !== RESOURCE_ADMIN_SCOPE
)

export function buildPermissionChangesAction(
  existingScopeMappings: UserScopeMapping[],
  appliedChanges: PermissionChanges
): PermissionChangesAction {
  const addAdmin: string[] = []
  const removeAdmin: string[] = []
  const addRegularScope: Record<string, ScopeName[]> = {}
  const removeRegularScope: Record<string, ScopeName[]> = {}

  for (const [user, changedScopes] of Object.entries(appliedChanges)) {
    if (RESOURCE_ADMIN_SCOPE in changedScopes) {
      if (changedScopes[RESOURCE_ADMIN_SCOPE]) {
        addAdmin.push(user)
      } else {
        removeAdmin.push(user)
        if (
          existingScopeMappings
            .find((mapping) => mapping.user === user)
            ?.scopes?.includes(RESOURCE_ADMIN_SCOPE)
        ) {
          const userNewScopes: ScopeName[] = []
          for (const availableScope of NON_ADMIN_SCOPES) {
            if (
              !(availableScope in changedScopes) ||
              changedScopes[availableScope]
            ) {
              userNewScopes.push(availableScope)
            }
          }
          if (userNewScopes.length > 0) {
            addRegularScope[user] = userNewScopes
          }
        }
      }
    } else {
      const addedScopes = Object.entries(changedScopes)
        .filter(([, action]) => action)
        .map(([scope]) => scope) as ScopeName[]
      const removedScopes = Object.entries(changedScopes)
        .filter(([, action]) => !action)
        .map(([scope]) => scope) as ScopeName[]
      if (addedScopes.length > 0) {
        addRegularScope[user] = addedScopes
      }
      if (removedScopes.length > 0) {
        removeRegularScope[user] = removedScopes
      }
    }
  }

  return {
    addAdmin,
    removeAdmin,
    addRegularScope,
    removeRegularScope,
  }
}
