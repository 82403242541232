import { compareAsc } from "date-fns"
import { AGENT_FAMILIES } from "@models/agent-family"
import { IP_FAMILIES } from "@models/ip-family"
import { Measurement } from "@models/measurement"
import { MEASUREMENT_SUBCATEGORIES } from "@models/measurement-subcategory"

function getSortingIndexInList<T>(
  value: T | undefined,
  list: readonly T[]
): number {
  if (!value) {
    return list.length
  }
  const index = list.indexOf(value)
  return index < 0 ? list.length : index
}

export function compareMeasurementDateAsc(
  first: Measurement,
  second: Measurement
): number {
  return compareAsc(first.datetime, second.datetime)
}

export function compareMeasurementAgentFamilyAsc(
  first: Measurement,
  second: Measurement
): number {
  return (
    getSortingIndexInList(first.agentFamily, AGENT_FAMILIES) -
    getSortingIndexInList(second.agentFamily, AGENT_FAMILIES)
  )
}

export function compareMeasurementAgentIdAsc(
  first: Measurement,
  second: Measurement
): number {
  if (!second.agentId) {
    return -1
  }
  if (!first.agentId) {
    return 1
  }
  return first.agentId?.localeCompare(second.agentId)
}

export function compareMeasurementSubcategoriesAsc(
  first: Measurement,
  second: Measurement
): number {
  return (
    getSortingIndexInList(first.subcategory, MEASUREMENT_SUBCATEGORIES) -
    getSortingIndexInList(second.subcategory, MEASUREMENT_SUBCATEGORIES)
  )
}

export function compareMeasurementIpVersionAsc(
  first: Measurement,
  second: Measurement
): number {
  return (
    getSortingIndexInList(first.ipFamily, IP_FAMILIES) -
    getSortingIndexInList(second.ipFamily, IP_FAMILIES)
  )
}
