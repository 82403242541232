import { Fragment, FunctionComponent, useEffect, useState } from "react"
import { Transition, Dialog } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import React from "react"
import UserComponent from "./user-component"
import SidebarRouter from "./sidebar-router"
import { useKeycloak } from "@react-keycloak/web"
import { navigate } from "gatsby"
import { AutonomousSystem } from "@models/autonomous-system"
import { AutonomousSystemScopeMapping } from "@models/autonomous-system-scope-mapping"
import { PageMetadataObject } from "@models/page-metadata-type"

interface Props {
  externalServices: {
    anomalyDetection?: {
      baseUrl: string
    }
    asMeasurements?: {
      baseUrl: string
    }
    simetAs?: {
      baseUrl: string
    }
    asManagement?: {
      baseUrl: string
    }
    asAuthorization?: {
      baseUrl: string
    }
    asMeasurementsApi?: {
      baseUrl: string
    }
  }
  pageMetadata: PageMetadataObject
  scopeMappingList: AutonomousSystemScopeMapping[] | null
  availableAutonomousSystems: AutonomousSystem[] | null
}

const Sidebar: FunctionComponent<Props> = ({
  externalServices,
  pageMetadata,
  scopeMappingList,
  availableAutonomousSystems,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()

  const selectedAutonomousSystem = availableAutonomousSystems?.find(
    (autonomousSystem) => autonomousSystem.asn === pageMetadata.asn
  )

  const isAdmin = isKeycloakInitialized && keycloak.hasRealmRole("admin")
  let active: boolean
  useEffect(() => {
    if (
      pageMetadata.appName === "management" &&
      pageMetadata.page === "account" &&
      pageMetadata.pageCategory === "autonomous-systems"
    ) {
      active = true
    }
  })

  useEffect(() => {
    if (isKeycloakInitialized && !keycloak.authenticated) {
      let href = window.location.href
      if (!window.location.pathname.endsWith("/")) {
        href = `${window.location.origin}${window.location.pathname}/${window.location.search}${window.location.hash}`
      }
      if (!keycloak.authenticated) {
        keycloak.login({
          redirectUri: href,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isKeycloakInitialized])

  useEffect(() => {
    if (
      !isAdmin &&
      pageMetadata.asn &&
      availableAutonomousSystems &&
      availableAutonomousSystems.length > 0
    ) {
      if (
        !availableAutonomousSystems.find((as) => as.asn === pageMetadata.asn)
      ) {
        navigate("/404")
      }
    }
  }, [availableAutonomousSystems, isAdmin, pageMetadata])

  useEffect(() => {
    if (
      isKeycloakInitialized &&
      keycloak.authenticated &&
      !isAdmin &&
      availableAutonomousSystems &&
      !window.location.pathname.includes("/account/autonomous-systems/")
    ) {
      if (availableAutonomousSystems.length < 1) {
        navigate("/account/autonomous-systems/")
      }
    }
  }, [availableAutonomousSystems, isAdmin, isKeycloakInitialized, keycloak])

  const provedor = selectedAutonomousSystem?.name
  active = false

  return (
    <>
      <div className="hidden">version v3.0.0</div>
      {/*
        This example requires updating your template:

        ```
        <html className="h-full bg-slate-100">
        <body className="h-full">
        ```
      */}

      {/* Off-canvas menu for mobile */}
      {/* <div className="hidden">!!! Off-canvas menu for mobile !!!</div> */}

      {/* AS permissions service */}

      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-slate-50">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <SidebarRouter
                  isMobile={true}
                  externalServices={externalServices}
                  pageMetadata={pageMetadata}
                  scopeMappingList={scopeMappingList}
                />
                <UserComponent
                  isMobile={true}
                  provedor={provedor}
                  isActive={active}
                />
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="z-30 hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-r lg:border-slate-200 lg:bg-slate-50 lg:pt-6">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 w-full">
          <SidebarRouter
            isMobile={false}
            externalServices={externalServices}
            pageMetadata={pageMetadata}
            scopeMappingList={scopeMappingList}
          />
          <UserComponent
            isMobile={false}
            provedor={provedor}
            isActive={active}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="sticky top-0 z-10 lg:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-slate-500 hover:text-slate-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </>
  )
}

export default Sidebar
