import {
  SerializedAntiSpoofingHistory
} from "@models/serialized/serialized-measurement"
import { AntiSpoofingHistory } from "@models/measurement"
import { parseISO } from "date-fns"

export function deserializeAntiSpoofingHistory(
  data: SerializedAntiSpoofingHistory
): AntiSpoofingHistory {
  return {
    ...data,
    datetime: parseISO(data.datetime),
  }
}
