import React, { FunctionComponent, ReactElement } from "react"

const DefaultLoggedOutLayout: FunctionComponent<{ location: string }> = ({
  children,
  location,
}) => {
  return <>{children}</>
}

export default DefaultLoggedOutLayout
