import { parseISO } from "date-fns"
import { SerializedAnomalyDetection } from "@models/serialized/serialized-anomaly-detection"
import { AnomalyDetection } from "@models/anomaly-detection"

export function deserializeAnomalyDetection(
  data: SerializedAnomalyDetection
): AnomalyDetection {
  return {
    ...data,
    datetime: parseISO(data.datetime),
  }
}
