import { Reducer } from "redux"
import { Measurement } from "@models/measurement"
import { IpFamily, IP_FAMILIES } from "@models/ip-family"
import {
  MeasurementSubcategory,
  MEASUREMENT_SUBCATEGORIES,
} from "@models/measurement-subcategory"
import { SelectedMeasurementGeolocation } from "@models/geolocation/selected-measurement-geolocation"
import { AgentFamily, AGENT_FAMILIES } from "@models/agent-family"
import { ProblemError } from "@models/problem-error"
import { MeasurementTableOrdering } from "@models/measurements/measurement-table-ordering"
import { sortMeasurements } from "@utils/measurements/sort-measurements"
import { ValueType, VALUE_TYPE } from "@models/value-type"
import { MeasurementTimeSeries } from "@models/measurements/measurement-time-series"

/* State */

export interface MeasurementsState {
  isWaitingForData: boolean
  isWaitingForTimeSeriesData: boolean
  error: ProblemError | null
  errorTimeSeries: ProblemError | null
  measurementsList: Measurement[] | null
  measurementsTimeSeriesList: MeasurementTimeSeries[] | null
  selectedMeasurement: Measurement | null
  showSelectedMeasurement: boolean
  filterIpVersions: IpFamily
  filterSubcategories: MeasurementSubcategory[]
  filterAgentFamilies: AgentFamily[]
  filterValueType: ValueType
  filterAgentId: string | null
  filterRealTime: boolean
  tableOrdering: MeasurementTableOrdering
  tableOrderingAsc: boolean
  isWaitingForGeolocationData: boolean
  geolocationError: string | null
  geolocationData: SelectedMeasurementGeolocation | null
}

const INITIAL_STATE: MeasurementsState = {
  isWaitingForData: true,
  isWaitingForTimeSeriesData: true,
  error: null,
  errorTimeSeries: null,
  measurementsList: [],
  measurementsTimeSeriesList: [],
  selectedMeasurement: null,
  showSelectedMeasurement: false,
  filterIpVersions: IP_FAMILIES[0],
  filterSubcategories: MEASUREMENT_SUBCATEGORIES.slice(),
  filterAgentFamilies: AGENT_FAMILIES.slice(),
  filterValueType: VALUE_TYPE[1],
  filterAgentId: null,
  filterRealTime: false,
  tableOrdering: "DATE",
  tableOrderingAsc: false,
  isWaitingForGeolocationData: false,
  geolocationError: null,
  geolocationData: null,
}

/* Actions */

export const CLEAR_MEASUREMENTS_AND_FILTERS =
  "measurements/CLEAR_MEASUREMENTS_AND_FILTERS"

interface ClearMeasurementsAndFiltersAction {
  type: typeof CLEAR_MEASUREMENTS_AND_FILTERS
}

export const SET_FILTER_SUBCATEGORIES = "measurements/SET_FILTER_SUBCATEGORIES"

interface SetMeasurementsFilterSubcategoriesAction {
  type: typeof SET_FILTER_SUBCATEGORIES
  filterSubcategories: MeasurementSubcategory[]
}

export const SET_FILTER_IP_VERSIONS = "measurements/SET_FILTER_IP_VERSIONS"

interface SetMeasurementsFilterIpVersionsAction {
  type: typeof SET_FILTER_IP_VERSIONS
  filterIpVersions: IpFamily
}

export const SET_FILTER_AGENT_FAMILIES =
  "measurements/SET_FILTER_AGENT_FAMILIES"

interface SetMeasurementsFilterAgentFamiliesAction {
  type: typeof SET_FILTER_AGENT_FAMILIES
  filterAgentFamilies: AgentFamily[]
}

export const SET_FILTER_VALUE_TYPE = "measurements/SET_FILTER_VALUE_TYPE"

interface SetMeasurementsFilterValueTypeAction {
  type: typeof SET_FILTER_VALUE_TYPE
  filterValueType: ValueType
}

export const SET_FILTER_AGENT_ID = "measurements/SET_FILTER_AGENT_ID"

interface SetMeasurementsFilterAgentIdAction {
  type: typeof SET_FILTER_AGENT_ID
  filterAgentId: string | null
}

export const SET_FILTER_REALTIME = "measurements/SET_FILTER_REALTIME"

interface SetMeasurementsFilterRealTimeAction {
  type: typeof SET_FILTER_REALTIME
  filterRealTime: boolean
}

export const UPDATE_MEASUREMENTS_WITH_FILTERS =
  "measurements/UPDATE_MEASUREMENTS_WITH_FILTERS"

interface UpdateMeasurementsWithFiltersAction {
  type: typeof UPDATE_MEASUREMENTS_WITH_FILTERS
}

export const SET_MEASUREMENTS_TIME_SERIES =
  "measurements/SET_MEASUREMENTS_TIME_SERIES"

interface SetMeasurementsTimeSeriesAction {
  type: typeof SET_MEASUREMENTS_TIME_SERIES
  measurementsTimeSeries: MeasurementTimeSeries[] | null
}

export const SET_MEASUREMENTS = "measurements/SET_MEASUREMENTS"

interface SetMeasurementsAction {
  type: typeof SET_MEASUREMENTS
  measurements: Measurement[] | null
}

export const SET_WAITING_FOR_MEASUREMENTS_DATA =
  "measurements/SET_WAITING_FOR_MEASUREMENTS_DATA"

interface SetWaitingForMeasurementsDataAction {
  type: typeof SET_WAITING_FOR_MEASUREMENTS_DATA
  isWaitingForData: boolean
}

export const SET_WAITING_FOR_MEASUREMENTS_TIME_SERIES_DATA =
  "measurements/SET_WAITING_FOR_MEASUREMENTS_TIME_SERIES_DATA"

interface SetWaitingForMeasurementsTimeSeriesDataAction {
  type: typeof SET_WAITING_FOR_MEASUREMENTS_TIME_SERIES_DATA
  isWaitingForTimeSeriesData: boolean
}

export const SELECT_MEASUREMENT = "measurements/SELECT_MEASUREMENT"

interface SelectMeasurementAction {
  type: typeof SELECT_MEASUREMENT
  measurement: Measurement
}

export const HIDE_SELECTED_MEASUREMENT =
  "measurements/HIDE_SELECTED_MEASUREMENT"

interface HideSelectedMeasurementAction {
  type: typeof HIDE_SELECTED_MEASUREMENT
}

// export const SET_MEASUREMENTS_ERROR = "measurements/SET_MEASUREMENTS_ERROR"

// interface SetMeasurementsErrorAction {
//   type: typeof SET_MEASUREMENTS_ERROR
//   error: ProblemError | null
// }

// export const SET_MEASUREMENTS_TIME_SERIES_ERROR = "measurements/SET_MEASUREMENTS_TIME_SERIES_ERROR"

// interface SetMeasurementsTimesSeriesErrorAction {
//   type: typeof SET_MEASUREMENTS_TIME_SERIES_ERROR
//   errorTimeSeries: ProblemError | null
// }

export const SET_TABLE_ORDERING = "measurements/SET_TABLE_ORDERING"

interface SetTableOrderingAction {
  type: typeof SET_TABLE_ORDERING
  tableOrdering: MeasurementTableOrdering
  tableOrderingAsc: boolean
}

export const SET_GEOLOCATION_DATA = "measurements/SET_GEOLOCATION_DATA"

interface SetGeolocationDataAction {
  type: typeof SET_GEOLOCATION_DATA
  geolocationData: SelectedMeasurementGeolocation | null
}

export const SET_WAITING_FOR_GEOLOCATION_DATA =
  "measurements/SET_WAITING_FOR_GEOLOCATION_DATA"

interface SetWaitingForGeolocationDataAction {
  type: typeof SET_WAITING_FOR_GEOLOCATION_DATA
  isWaitingForGeolocationData: boolean
}

export const SET_GEOLOCATION_ERROR = "measurements/SET_GEOLOCATION_ERROR"

interface SetGeolocationErrorAction {
  type: typeof SET_GEOLOCATION_ERROR
  geolocationError: string | null
}

export type MeasurementsAction =
  | ClearMeasurementsAndFiltersAction
  | SetMeasurementsFilterSubcategoriesAction
  | SetMeasurementsFilterIpVersionsAction
  | SetMeasurementsFilterAgentFamiliesAction
  | SetMeasurementsFilterAgentIdAction
  | SetMeasurementsFilterValueTypeAction
  | UpdateMeasurementsWithFiltersAction
  | SetMeasurementsAction
  | SetWaitingForMeasurementsDataAction
  | SetWaitingForMeasurementsTimeSeriesDataAction
  | SelectMeasurementAction
  | HideSelectedMeasurementAction
  // | SetMeasurementsErrorAction
  | SetMeasurementsFilterRealTimeAction
  | SetTableOrderingAction
  | SetGeolocationDataAction
  | SetWaitingForGeolocationDataAction
  | SetGeolocationErrorAction
  | SetMeasurementsTimeSeriesAction
// | SetMeasurementsTimesSeriesErrorAction

/* Reducer */

export const measurementsReducer: Reducer<
  MeasurementsState,
  MeasurementsAction
> = (state = INITIAL_STATE, action) => {
  if (action.type === CLEAR_MEASUREMENTS_AND_FILTERS) {
    return INITIAL_STATE
  }

  if (action.type === SET_FILTER_SUBCATEGORIES) {
    const { filterSubcategories } = action
    const newState: MeasurementsState = {
      ...state,
      filterSubcategories: Array.from(new Set(filterSubcategories)),
    }
    return newState
  }

  if (action.type === SET_FILTER_IP_VERSIONS) {
    const { filterIpVersions } = action
    const newState: MeasurementsState = {
      ...state,
      filterIpVersions: filterIpVersions,
    }
    return newState
  }

  if (action.type === SET_FILTER_AGENT_FAMILIES) {
    const { filterAgentFamilies } = action
    const newState: MeasurementsState = {
      ...state,
      filterAgentFamilies: Array.from(new Set(filterAgentFamilies)),
    }
    return newState
  }

  if (action.type === SET_FILTER_VALUE_TYPE) {
    const { filterValueType } = action
    const newState: MeasurementsState = {
      ...state,
      filterValueType: filterValueType,
    }
    return newState
  }

  if (action.type === SET_FILTER_AGENT_ID) {
    const { filterAgentId } = action
    const newState: MeasurementsState = {
      ...state,
      filterAgentId,
    }
    return newState
  }

  if (action.type === SET_FILTER_REALTIME) {
    const { filterRealTime } = action
    const newState: MeasurementsState = {
      ...state,
      filterRealTime,
    }
    return newState
  }

  if (action.type === UPDATE_MEASUREMENTS_WITH_FILTERS) {
    let { measurementsList } = state
    if (!measurementsList) {
      return state
    }
    if (state.filterSubcategories.length < MEASUREMENT_SUBCATEGORIES.length) {
      measurementsList = measurementsList.filter(
        (measurement) =>
          measurement.subcategory &&
          state.filterSubcategories?.includes(measurement.subcategory)
      )
    }
    if (state.filterIpVersions.length < IP_FAMILIES.length) {
      measurementsList = measurementsList.filter(
        (measurement) =>
          measurement.ipFamily &&
          state.filterIpVersions?.includes(measurement.ipFamily)
      )
    }
    if (state.filterAgentFamilies.length < AGENT_FAMILIES.length) {
      measurementsList = measurementsList.filter(
        (measurement) =>
          measurement.agentFamily &&
          state.filterAgentFamilies?.includes(measurement.agentFamily)
      )
    }
    if (state.filterAgentId !== null) {
      measurementsList = measurementsList.filter(
        (measurement) => measurement.agentId === state.filterAgentId
      )
    }
    const newState: MeasurementsState = {
      ...state,
      measurementsList,
    }
    return newState
  }

  if (action.type === SET_MEASUREMENTS) {
    let { measurements } = action

    // if(state.filterRealTime){
    //   measurements = state.measurementsList?.concat(measurements ?? []) ?? []
    // }

    const newState: MeasurementsState = {
      ...state,
      // isWaitingForData: false,
      error: null,
      measurementsList: measurements
        ? sortMeasurements(
            measurements,
            state.tableOrdering,
            state.tableOrderingAsc
          )
        : null,
    }
    return newState
  }

  if (action.type === SET_MEASUREMENTS_TIME_SERIES) {
    const { measurementsTimeSeries } = action
    const newState: MeasurementsState = {
      ...state,
      // isWaitingForTimeSeriesData: false,
      errorTimeSeries: null,
      measurementsTimeSeriesList: measurementsTimeSeries,
    }
    return newState
  }

  if (action.type === SET_WAITING_FOR_MEASUREMENTS_DATA) {
    const { isWaitingForData } = action
    const newState: MeasurementsState = {
      ...state,
      isWaitingForData,
    }
    return newState
  }

  if (action.type === SET_WAITING_FOR_MEASUREMENTS_TIME_SERIES_DATA) {
    const { isWaitingForTimeSeriesData } = action
    const newState: MeasurementsState = {
      ...state,
      isWaitingForTimeSeriesData,
    }
    return newState
  }

  if (action.type === SELECT_MEASUREMENT) {
    const { measurement } = action
    const newState: MeasurementsState = {
      ...state,
      selectedMeasurement: measurement,
      showSelectedMeasurement: true,
    }
    return newState
  }

  if (action.type === HIDE_SELECTED_MEASUREMENT) {
    const newState: MeasurementsState = {
      ...state,
      showSelectedMeasurement: false,
    }
    return newState
  }

  // if (action.type === SET_MEASUREMENTS_ERROR) {
  //   const { error } = action
  //   const newState: MeasurementsState = {
  //     ...state,
  //     error,
  //     isWaitingForData: error ? true : state.isWaitingForData,
  //   }
  //   return newState
  // }

  // if (action.type === SET_MEASUREMENTS_TIME_SERIES_ERROR) {
  //   const { errorTimeSeries } = action
  //   const newState: MeasurementsState = {
  //     ...state,
  //     errorTimeSeries,
  //     isWaitingForTimeSeriesData: errorTimeSeries ? true : state.isWaitingForTimeSeriesData,
  //   }
  //   return newState
  // }

  if (action.type === SET_TABLE_ORDERING) {
    const { tableOrdering, tableOrderingAsc } = action
    const newState: MeasurementsState = {
      ...state,
      tableOrdering,
      tableOrderingAsc,
      measurementsList: state.measurementsList
        ? sortMeasurements(
            state.measurementsList,
            tableOrdering,
            tableOrderingAsc
          )
        : null,
    }
    return newState
  }

  if (action.type === SET_GEOLOCATION_DATA) {
    const { geolocationData } = action
    const newState: MeasurementsState = {
      ...state,
      isWaitingForGeolocationData: false,
      geolocationError: null,
      geolocationData,
    }
    return newState
  }

  if (action.type === SET_WAITING_FOR_GEOLOCATION_DATA) {
    const { isWaitingForGeolocationData } = action
    const newState: MeasurementsState = {
      ...state,
      isWaitingForGeolocationData,
    }
    return newState
  }

  if (action.type === SET_GEOLOCATION_ERROR) {
    const { geolocationError } = action
    const newState: MeasurementsState = {
      ...state,
      geolocationError,
      isWaitingForGeolocationData: geolocationError
        ? true
        : state.isWaitingForGeolocationData,
    }
    return newState
  }

  // Assert that all known actions are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = action
  return state
}
