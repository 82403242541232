import React, { FunctionComponent } from "react"

const PaddedParagraph: FunctionComponent<{ className: string }> = ({
  className,
  children,
}) => (
  <p className={className}>
    <span className="float-left w-8 sm:w-10">&nbsp;</span>
    {children}
  </p>
)

export default PaddedParagraph
