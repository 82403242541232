import React, { FunctionComponent, ReactNode, createRef } from "react"
import { buildURI, toCSV } from "./core"

interface Props {
  data: string | any[] | (() => any)
  headers: { label: string; key: string }[]
  target: string
  separator: string
  filename?: string
  uFEFF: boolean
  onClick: (_: Event, arg?: any) => any
  asyncOnClick: boolean
  enclosingCharacter: string
  children: ReactNode
}

// android networkInfo / transport Info / wifi info

const CSVReportDownload: FunctionComponent<Props> = ({
  data,
  headers,
  target,
  separator,
  uFEFF,
  enclosingCharacter,
  filename,
  onClick,
  asyncOnClick,
  children,
}) => {
  /**
   * In IE11 this method will trigger the file download
   */

  function handleLegacy(event: Event, isAsync = false) {
    // If this browser is IE 11, it does not support the `download` attribute
    if (window.navigator.msSaveOrOpenBlob) {
      // Stop the click propagation
      event.preventDefault()

      const csvData = isAsync && typeof data === "function" ? data() : data

      let blob = new Blob([
        uFEFF ? "\uFEFF" : "",
        toCSV(csvData, headers, separator, enclosingCharacter),
      ])
      window.navigator.msSaveBlob(blob, filename)

      return false
    }
  }

  function handleAsyncClick(event: Event) {
    const done = (proceed: boolean) => {
      if (proceed === false) {
        event.preventDefault()
        return
      }
      handleLegacy(event, true)
    }

    onClick(event, done)
  }

  function handleSyncClick(event: Event) {
    const stopEvent = onClick(event) === false
    if (stopEvent) {
      event.preventDefault()
      return
    }
    handleLegacy(event)
  }

  function handleClick() {
    return (event: Event) => {
      if (typeof onClick === "function") {
        return asyncOnClick ? handleAsyncClick(event) : handleSyncClick(event)
      }
      handleLegacy(event)
    }
  }

  const isNodeEnvironment = typeof window === "undefined"
  const href = isNodeEnvironment
    ? ""
    : buildURI(data, uFEFF, headers, separator, enclosingCharacter)

  const refAttr = createRef<HTMLAnchorElement>()

  return (
    <a
      download={filename}
      // {...rest}
      ref={refAttr}
      target={target}
      href={href}
      //   onClick={handleClick}
    >
      {children}
    </a>
  )
}

export default CSVReportDownload
