import React, { FunctionComponent } from 'react';
import { XIcon } from '@heroicons/react/solid'
import {
  listing,
  wrapper,
  title,
  closeButton
} from './utils'
import IconComponent from './utils'
import { Alert } from '@models/alert';


interface Props {
  message: Alert
  dismiss: () => void
}


const SingleAlert: FunctionComponent<Props> = ({ message, dismiss }) => {
  return (
    <div className={`mt-6 w-full rounded p-4 shadow-xl  border-l-4 ${wrapper(message.type)}`} >
      <div className="flex">
        <div className="flex-shrink-0">
          <IconComponent type={message.type} />
        </div>
        <div className="ml-3 -mt-0.5">
          <h3 className={`text-sm font-medium leading-snug ${title(message.type)}`}>
            {message.title}
          </h3>
          {message.lines && <div className={`mt-2 text-sm  ${listing(message.type)}`}>
            <ul role="list" className="list-disc space-y-1 pl-5 leading-snug">
              {message.lines?.map((value, idx) => <li key={idx} dangerouslySetInnerHTML={{ __html: value }} />)}
            </ul>
          </div>}
        </div>
        <div className="ml-auto pl-16">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={`inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ${closeButton(message.type)}`}
              onClick={dismiss}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}
export default SingleAlert