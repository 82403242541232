import { MeasurementTableOrdering } from "@models/measurements/measurement-table-ordering"
import {
  compareMeasurementLocalityAsc,
  compareMeasurementIpVersionAsc,
  compareMeasurementAvailabilityPercentageAsc,
} from "./compare-simetas"

import {
  MeasurementGroupedIpFamily,
  MeasurementIpFamily,
} from "@models/serialized/serialized-simet-as"
import { MeasurementHistory as Measurement } from "@models/simet-as"

export function sortSimetAS(
  measurements: Measurement[],
  orderBy: MeasurementTableOrdering,
  isAscending: boolean
): Measurement[] {
  let sortFn: (first: Measurement, second: Measurement) => number
  // TODO: Cleanup code to avoid duplication
  switch (orderBy) {
    case "LOCALITY":
      sortFn = (first, second) =>
        (isAscending
          ? compareMeasurementLocalityAsc(first, second)
          : -compareMeasurementLocalityAsc(first, second)) ||
        compareMeasurementIpVersionAsc(first, second) ||
        compareMeasurementAvailabilityPercentageAsc(first, second)
      break
    case "IP_VERSION":
      sortFn = (first, second) =>
        (isAscending
          ? compareMeasurementIpVersionAsc(first, second)
          : -compareMeasurementIpVersionAsc(first, second)) ||
        compareMeasurementLocalityAsc(first, second) ||
        compareMeasurementAvailabilityPercentageAsc(first, second)
      break
    case "AVAILABILITY_PERCENTAGE":
      sortFn = (first, second) =>
        (isAscending
          ? compareMeasurementAvailabilityPercentageAsc(first, second)
          : -compareMeasurementAvailabilityPercentageAsc(first, second)) ||
        compareMeasurementLocalityAsc(first, second) ||
        compareMeasurementIpVersionAsc(first, second)
      break
    default:
      // Assert that all known types are used
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let _: never = orderBy
      throw new Error("Unknown MeasurementTableOrdering")
  }
  return measurements.sort(sortFn)
}

export function sortSimetASIpFamily(
  measurements: MeasurementGroupedIpFamily[] | MeasurementIpFamily[],
  orderBy: MeasurementTableOrdering,
  isAscending: boolean
): MeasurementGroupedIpFamily[] | MeasurementIpFamily[] {
  let sortFn: (
    first: MeasurementGroupedIpFamily | MeasurementIpFamily,
    second: MeasurementGroupedIpFamily | MeasurementIpFamily
  ) => number
  // TODO: Cleanup code to avoid duplication
  switch (orderBy) {
    case "IP_VERSION":
      sortFn = (first, second) =>
        isAscending
          ? compareMeasurementIpVersionAsc(first, second)
          : -compareMeasurementIpVersionAsc(first, second)
      break
    default:
      // Assert that all known types are used
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let _: never = orderBy
      throw new Error("Unknown MeasurementTableOrdering")
  }
  return measurements.sort(sortFn)
}
