import { ElementType } from "@_types/element-type"

export const VALUE_TYPE = [
  "ALL",
  "ANOMALY"
] as const

export type ValueType = ElementType<typeof VALUE_TYPE>

export function formatValueType(valueType: ValueType): string {
  switch (valueType) {
    case "ANOMALY":
      return "Inconformidades RQUAL"
    case "ALL":
      return "Todas as Medições"
  }
  // Assert that all known types are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = valueType
  throw new Error("Unknown AgentFamily")
}

export function getValueTypeFromString(valueType: string): ValueType {
  return (VALUE_TYPE as any)[valueType]
}