import React, { FunctionComponent } from "react"
import { Link } from "gatsby"

interface Props {
  to: string
  isActive?: boolean
  disablePreloading?: boolean
  isInternal?: boolean
  activeClass: string
  className: string
  children: any
}

const CustomLink: FunctionComponent<Props> = ({
  to,
  isActive,
  isInternal,
  activeClass,
  className,
  children,
}) => {
  if (isInternal) {
    return (
      <Link
        to={to}
        className={isActive ? activeClass : className}
        // activeClassName={activeClass}
        // partiallyActive={Boolean(isActive)}
        state={{ clearCursor: true }}
      >
        {children}
      </Link>
    )
  }

  return (
    <a href={to} className={`${isActive ? activeClass : className}`}>
      {children}
    </a>
  )
}

export default CustomLink
