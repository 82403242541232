import React,{ FunctionComponent } from "react"

const EmptyStateTimeSeries:FunctionComponent = () =>{
  return (
    <div className='absolute inset-0 flex items-center justify-center'>
      <div className="flex justify-center -mt-8">
        <div className="text-slate-400 text-sm font-medium">
          Período sem medição.
        </div>
      </div>
    </div>
  )
}
 export default EmptyStateTimeSeries;