import { AutonomousSystemScopeMapping } from "@models/autonomous-system-scope-mapping"
import { GraphQLResponse } from "@_types/graphql-response"
import axios from "axios"

export function scopeMappingListService(url: string, keycloak: any) {
  /* GraphQL data */

  const asAuthorizationUrl = url

  // Fetch new data from API
  const fetchScopeMappingList = async () => {
    try {
      const response = await axios.post<
        GraphQLResponse<{ userPermissions: AutonomousSystemScopeMapping[] }>
      >(
        `${asAuthorizationUrl}`,
        {
          query:
            `query { ` +
            `  userPermissions { ` +
            `    scopes resource { ` +
            `      asn name ` +
            `    } ` +
            `  } ` +
            `}`,
        },
        {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        }
      )
      if (response.data.errors) {
        if (response.data.errors[0]) {
          console.log(`scopeMappingsError: ${JSON.parse(response.data.errors[0].message)}`)
          console.log(`message_key: "graphql_error:" + ${JSON.parse(response.data.errors[0].message).title_key}`)
          return null
        }
      }
      if (response.data.data?.userPermissions) {
        return response.data.data?.userPermissions
      } else {
        console.log(`title: "Erro ao obter permissões"`)
        console.log(`"Ocorreu um erro desconhecido. Tente novamente mais tarde."`)
        console.log(`message_key: "http_error:" + ${response.status}`)
        return null
      }
    } catch (error: any) {
      console.error("Error when fetching scope mapping list", error)
      if (
        error.response?.data?.errors &&
        error.response?.data?.errors[0]?.message
      ) {
        console.log(`scopeMappingsError: ${JSON.parse(
          error.response.data.errors[0].message
        )}`)
      } else {
        console.log(`title: "Erro ao obter permissões"`)
        console.log(`detail: "Ocorreu um erro de conexão. Verifique sua Internet e tente novamente mais tarde."`)
        console.log(`message_key: "http_error:" + ${error?.response?.status}`)
      }
      return null
    }
  }
  return fetchScopeMappingList()
}