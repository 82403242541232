import React, { FunctionComponent } from "react"
import clsx from "clsx"

interface Props {
  className?: string
  props?: any
}

export const Container: FunctionComponent<Props> = ({
  className,
  ...props
}) => {
  return (
    <div
      className={clsx("mx-auto px-4 sm:px-6 lg:px-8", className)}
      {...props}
    />
  )
}
