import React, { useState, FunctionComponent, useEffect } from "react"
import { QueryParamConfig } from "@utils/query-params"
import { useQueryParams } from "use-query-params"
import { StatusOnlineIcon, StatusOfflineIcon } from "@heroicons/react/outline"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

const RealtimeInput: FunctionComponent = () => {
  const [_searchParams, setSearchParams] = useQueryParams(QueryParamConfig)

  const { realtime } = _searchParams

  const [realtimeState, setRealtimeState] = useState<boolean>(
    realtime === "true"
  )

  useEffect(() => {
    setSearchParams({ realtime: String(realtimeState) })
  }, [realtimeState, realtime])

  return (
    <button
      onClick={() => setRealtimeState((x) => !x)}
      className="ml-8 group inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 focus:ring-blue-500"
    >
      {realtimeState ? (
        <StatusOnlineIcon
          className={classNames(
            "h-5 w-5 mr-2 flex-none",
            realtimeState ? "animate-pulse" : ""
          )}
        />
      ) : (
        <StatusOfflineIcon className="h-5 w-5 mr-2 flex-none" />
      )}
      Tempo Real
    </button>
  )
}

export default RealtimeInput
