import React, { FunctionComponent, memo, useEffect, useState } from "react"
import { Menu } from "@headlessui/react"
import { QueryParamConfig } from "@utils/query-params"
import { useQueryParams } from "use-query-params"
import {
  AgentFamily,
  AGENT_FAMILIES,
} from "@models/agent-family"
import AgentFamilyToggle from "./agentFamilyToggle"
import AgentIdComponent from "../agentId-input"

interface Props {
  showFamilies?: boolean
  showAgentId?: boolean
}

const AgentFamilyComponent: FunctionComponent<Props> = ({ showFamilies, showAgentId }) => {

  const [_searchParams, setSearchParams] = useQueryParams(QueryParamConfig)

  const [display, setDisplay] = useState(showFamilies ? "Todos os medidores" : "Selecione um medidor")

  const [customOpen, setCustomOpen] = useState(false)

  const { agentId: agentIdSearch, agentFamily } = _searchParams

  const [controlsAgentFamilies, setControlsAgentFamilies] = useState<AgentFamily[]>(AGENT_FAMILIES.slice())

  const [agentIdInternal, setAgentIdInternal] = useState(agentIdSearch ?? '')

  useEffect(() => {
    setControlsAgentFamilies(decodeURIComponent(agentFamily ?? AGENT_FAMILIES.slice().join(',')).split(',') as AgentFamily[])
  }, [agentFamily])

  useEffect(() => {
    if(customOpen)return
    if ((agentIdInternal ?? '') !== '') {
      setDisplay(`Medidor ${agentIdInternal}`)
      // return;
    } else {
      setDisplay("Selecione um medidor")
    }
    // if (!showFamilies)
    //   return

    if (controlsAgentFamilies.filter(Boolean).length == AGENT_FAMILIES.length) {
      setDisplay("Todos os medidores")
    } else if (controlsAgentFamilies.filter(Boolean).length === 0) {
      setDisplay("Selecione um medidor")
    } else {
      let medidores = "Medidores"
      let label = ""

      controlsAgentFamilies.map((agent) => {
        if (agent === AGENT_FAMILIES[0]) {
          label += " desktop,"
        }
        if (agent === AGENT_FAMILIES[1]) {
          label += " embarcados,"
        }
        if (agent === AGENT_FAMILIES[2]) {
          label += " mobile,"
        }
        if (agent === AGENT_FAMILIES[3]) {
          label += " serviços,"
        }
        if (agent === AGENT_FAMILIES[4]) {
          label += " web,"
        }
        if (agent === AGENT_FAMILIES[5]) {
          label += " desconhecidos,"
        }
      })
      medidores += label
      setDisplay(medidores.slice(0, -1))
    }

    if (showFamilies) {
      setSearchParams({ agentFamily: controlsAgentFamilies?.toString() })
      // return
    }

    setSearchParams({ agentId: agentIdInternal })
    setSearchParams({ cursor: undefined })

  }, [
    customOpen,
    agentIdInternal,
    controlsAgentFamilies,
    agentFamily,
    agentIdSearch
  ])

  // useEffect(() => {

  // }, [controlsAgentFamilies, agentFamily, agentIdSearch, agentIdInternal])

  return (
    <Menu as="div" className="z-20 relative">
      {({ open }) => {
        return (
          <>
            <Menu.Button
              onClick={() => setCustomOpen(true)}
              className="group inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 focus:ring-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2 flex-none"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              {display}
            </Menu.Button>
            {(open && customOpen) ? (
              <Menu.Items className="flex flex-col items-start font-normal origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg py-6 px-4 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none space-y-6 z-20">
                <>
                  {showFamilies && (
                    <>
                      <div className="space-y-6">
                        <AgentFamilyToggle
                          agentFamilies={controlsAgentFamilies}
                          setAgentFamilies={setControlsAgentFamilies}
                        />
                      </div>
                    </>
                  )}
                  {showFamilies && showAgentId && (
                    <div className="flex items-center w-full">
                      <span className="flex-grow border-b"></span>
                      <span className="mx-2 text-sm font-medium">OU</span>
                      <span className="flex-grow border-b"></span>
                    </div>
                  )}
                  {showAgentId && (
                    <AgentIdComponent
                      setCustomOpen={setCustomOpen}
                      agentID={agentIdInternal}
                      setAgentID={setAgentIdInternal}
                    />
                  )}
                </>
              </Menu.Items>
            ) : (<>
              {!open && setCustomOpen(false)}
            </>
            )}
          </>
        )
      }}
    </Menu>
  )
}

AgentFamilyComponent.defaultProps = {
  showAgentId: true,
  showFamilies: true,
}

export default memo(AgentFamilyComponent);
