import React from "react";

interface Props{
  asn: number | null,
}

export function Head(Props: Props) {
  return (
    <>
    <title>PAS {Props.asn ? Props.asn : ""}- Vínculos com Sistemas Autônomos</title>
    </>
  )
}