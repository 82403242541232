import { ElementType } from "@_types/element-type"

export const MEASUREMENT_SUBCATEGORIES = [
  "PACKET_LOSS",
  "RTT",
  "JITTER_UPLOAD",
  "JITTER_DOWNLOAD",
  "UPLOAD",
  "DOWNLOAD",
  "JITTER_RTT"
] as const

// export const MEASUREMENT_SUBCATEGORIES = [
//   "UPLOAD",
//   "DOWNLOAD",
//   "RTT",
//   "PACKET_LOSS",
//   "JITTER_UPLOAD",
//   "JITTER_DOWNLOAD",
//   "JITTER_RTT",
// ] as const

export type MeasurementSubcategory = ElementType<
  typeof MEASUREMENT_SUBCATEGORIES
>

export function formatSubcategory(subcategory: MeasurementSubcategory): string {
  switch (subcategory) {
    case "DOWNLOAD":
      return "Vazão TCP"
    case "UPLOAD":
      return "Vazão TCP"
    case "RTT":
      return "Latência"
    case "JITTER_DOWNLOAD":
      return "Jitter"
    case "JITTER_UPLOAD":
      return "Jitter"
    case "JITTER_RTT":
      return "Variação de latência bidirecional"
    case "PACKET_LOSS":
      return "Perda"
  }
  // Assert that all known types are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = subcategory
  throw new Error("Unknown MeasurementSubcategory")
}

export function formatSubcategoryHistoricalByAgent(subcategory: MeasurementSubcategory){
  switch (subcategory) {
    case "DOWNLOAD":
      return "Vazão TCP Download"
    case "UPLOAD":
      return "Vazão TCP Upload"
    case "RTT":
      return "Latência"
    case "JITTER_DOWNLOAD":
      return "Jitter Download"
    case "JITTER_UPLOAD":
      return "Jitter Upload"
    case "JITTER_RTT":
      return "Variação de latência bidirecional"
    case "PACKET_LOSS":
      return "Perda"
  }
  // Assert that all known types are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = subcategory
  throw new Error("Unknown MeasurementSubcategory")
}

export function unitByMetric(metric: MeasurementSubcategory): [string, string] {
  switch (metric) {
    case "PACKET_LOSS": return ['%', '%'];
    case "RTT":
    case "JITTER_DOWNLOAD":
    case "JITTER_RTT":
    case "JITTER_UPLOAD": return ['μs', 'ms'];
    case "UPLOAD":
    case "DOWNLOAD": return ['mbps', 'mbps'];
    default: return ['μs', 'ms'];
  }
}