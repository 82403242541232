import React, { ReactElement, useState, useEffect, useCallback } from "react"
import { PageProps } from "gatsby"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { Dispatch } from "redux"
import { useKeycloak } from "@react-keycloak/web"
import { Duration as DateDuration } from "date-fns"
import { ReducerAction, ReducerState } from "@store/index"
import RequireAuthentication from "@components/shared/require-authentication"
import PageSpinner from "@components/shared/page-spinner"
import MeasurementsService from "@services/measurements-service"
import { AutonomousSystem } from "@models/autonomous-system"
import { useAsnParameter } from "@hooks/use-asn-parameter"
import { SET_FILTER_CURSOR, DELETE_ALERT } from "@store/navigation-store"

import Ipfamily from "@components/filter/ipFamily-input"
import PeriodInput from "@components/filter/period-input"
import AgentComponent from "@components/filter/agentFamily"
import ValueTypeComponent from "@components/filter/valueType"
import { useQueryParams } from "use-query-params"
import { QueryParamConfig, selectedPeriod } from "@utils/query-params"
import {
  SET_FILTER_AGENT_FAMILIES,
  SET_FILTER_AGENT_ID,
  SET_FILTER_IP_VERSIONS,
  SET_FILTER_REALTIME,
  SET_FILTER_VALUE_TYPE,
} from "@store/measurements-store"
import { IpFamily, IP_FAMILIES } from "@models/ip-family"
import { AgentFamily, AGENT_FAMILIES } from "@models/agent-family"
// import AvailableAutonomousSystemService from "@services/available-autonomous-system-service"
import { ValueType, VALUE_TYPE } from "@models/value-type"
import { PeriodType } from "@models/period-type"
import PaginationBarComponent from "@components/controls/pagination-bar-component"
import TableLayoutInput from "@components/filter/table-layout-input"
import { PageDocsSidebarContentMethodology } from "@models/page-docs-sidebar-content-type"
// import MeasurementsSubscriptions from "../components/shared/services/measurements-subscriptions"
import TableWrapper from "@components/table/table-wrapper"
import RealtimeInput from "@components/filter/realtime-input"

const DEFAULT_DURATION: DateDuration = { hours: 1 }

interface ReducerSelected {
  isWaitingForData: boolean
}

interface IndexLocationState {
  availableAutonomousSystems?: AutonomousSystem[]
  clearCursor: boolean
}

const IndexPage: (
  _: PageProps<object, object, IndexLocationState> & {
    asn?: string
  }
) => ReactElement = ({ asn: asnString, location }) => {
  /* React Hooks */
  const asn = useAsnParameter(asnString)

  const { isWaitingForData } = useSelector<ReducerState, ReducerSelected>(
    (state) => {
      return {
        isWaitingForData: state.autonomousSystems.isWaitingForData,
      }
    }
  )

  const dispatch = useDispatch<Dispatch<ReducerAction>>()
  const [_searchParams, setSearchParams] = useQueryParams(QueryParamConfig)

  useEffect(() => {
    // Clear error
    dispatch({
      type: DELETE_ALERT,
      alert: null,
    })

    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // if (
    //   location.state?.availableAutonomousSystems !== undefined &&
    //   location.state?.availableAutonomousSystems !== null &&
    //   location.state?.availableAutonomousSystems.length > 0
    // ) {
    //   dispatch({
    //     type: SET_AVAILABLE_AUTONOMOUS_SYSTEMS,
    //     autonomousSystems: location.state.availableAutonomousSystems,
    //   })
    // }

    if (location.state?.clearCursor) setSearchParams({ cursor: undefined })

    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const {
    periodo,
    from,
    to,
    ipFamily,
    agentId,
    agentFamily,
    valueType,
    cursor,
    realtime,
  } = _searchParams

  const [minDate, setMinDate] = useState<Date | DateDuration>()
  const [maxDate, setMaxDate] = useState<Date>()

  useEffect(() => {
    const [min, max] = selectedPeriod(
      (periodo as PeriodType) ?? "TODAY",
      from,
      to,
      true
    )

    const agentFamilies = agentFamily
      ?.split(",")
      .filter(Boolean)
      .map((agent) => {
        return agent as AgentFamily
      })

    setMinDate(min as Date)
    setMaxDate(max as Date)

    dispatch({
      type: SET_FILTER_IP_VERSIONS,
      filterIpVersions: (ipFamily as IpFamily) ?? IP_FAMILIES[0],
    })

    dispatch({
      type: SET_FILTER_AGENT_ID,
      filterAgentId: agentId ?? null,
    })

    dispatch({
      type: SET_FILTER_AGENT_FAMILIES,
      filterAgentFamilies: agentFamilies ?? AGENT_FAMILIES.slice(),
    })

    dispatch({
      type: SET_FILTER_VALUE_TYPE,
      filterValueType: (valueType as ValueType) ?? VALUE_TYPE[0],
    })

    dispatch({
      type: SET_FILTER_CURSOR,
      filterCursor: cursor ?? null,
    })

    dispatch({
      type: SET_FILTER_REALTIME,
      filterRealTime: Boolean(realtime === "true") ?? false,
    })
  }, [
    periodo,
    from,
    to,
    ipFamily,
    agentId,
    agentFamily,
    valueType,
    cursor,
    realtime,
    dispatch,
  ])

  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()

  const isAdmin = isKeycloakInitialized && keycloak.hasRealmRole("admin")

  return (
    <>
      <RequireAuthentication>
        <div className="pt-6 px-4 sm:px-6 lg:px-8">
          {/* Filters */}
          <div className="flex w-full text-sm font-medium justify-between">
            <div className="flex space-x-8">
              <Ipfamily />
              <ValueTypeComponent />
              <AgentComponent />
              <PeriodInput />
            </div>
            <div className="flex space-x-8">
              <RealtimeInput />
            </div>
          </div>
          {/* Pagination */}
          <PaginationBarComponent />
          <div className=" -mx-8">
            {/* <AvailableAutonomousSystemService /> */}
            {asn !== undefined && periodo !== undefined && periodo !== null && (
              /* Data query services */
              <>
                <MeasurementsService
                  asn={asn}
                  minDate={minDate}
                  maxDate={maxDate}
                  defaultDuration={DEFAULT_DURATION}
                  setMinDate={setMinDate}
                />
              </>
            )}
            {isWaitingForData && !isAdmin ? (
              <div className="pt-6">
                <PageSpinner />
              </div>
            ) : (
              asn && (
                <>
                  {/* Measurement Table */}
                  <TableWrapper asn={asn} />
                </>
              )
            )}
          </div>
        </div>
      </RequireAuthentication>
      {/* <Footer /> */}
    </>
  )
}

export default IndexPage

export const getPageSidebarContent = (): PageDocsSidebarContentMethodology => {
  return {
    title: "Medições do Simet",
    abstract:
      "Medir a qualidade da Internet, a partir do ponto de acesso do usuário, com as métricas de vazão, perda, latência e jitter.",
    measurementClients: [
      "Simet Box, Simet Web, Simet Android e iOS, Simet Windows e Linux",
    ],
    methodOfMeasurement: [
      `A vazão ("banda" ou "velocidade"), é aferida com transferência de dados através de múltiplas conexão TCP parallelas.
      Somente o medidor <span class="font-medium">Simet Web</span> realiza a transferência através de mútliplas conexões HTTPS parallelas.
      A velocidade é aferida em intervalos de 500ms, durante um período de 11 segundos.
      A velocidade reportada é a mediana (percentil 50) destas aferições, eliminando efeitos de burst e de ramp up.`,

      `Perda, latência e jitter são aferidos através de um trem de pacotes UDP entre cliente e servidor de medição.
      Somente o medidor <span class="font-medium">Simet Web</span> envia o trem de pacotes UDP por um canal WebRTC entre cliente e servidor.
      Latência e jitter são aferidos para cada pacote UDP e o valor reportado é a mediana (percentil 50) destas aferições, eliminando valores discrepantes.
      A perda é reportada como fração dos pacotes que foram perdidos.`,

      `O atraso para que uma nova medição aparece no portal PAS é em torno de 60 segundos.`,

      `<span class="font-medium text-slate-900">Identificação do Cliente</span>`,
      `Informamos o IP público e a porta do cliente usados na comunicação com o sistema SIMET, junto com o horario desta comunicação,
      permitindo ao provedor identificar o cliente, consultando seu registro de alocações. 
      Caso o endereço IP estiver desconhecido, é informado somente o ID do medidor SIMET.`,
    ],
    nextSteps: [
      `Realizar uma medição com o <a href="https://simet.nic.br/" target="_blank" class="text-blue-600 font-medium cursor-pointer hover:underline" >Simet Web</a>.`,
    ],
  }
}
