import React, { FunctionComponent, useEffect, useRef } from "react"
import { useKeycloak } from "@react-keycloak/web"
import { graphql, useStaticQuery } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { Dispatch } from "redux"
import axios, { CancelTokenSource } from "axios"
import { Duration as DateDuration, isBefore, formatISO } from "date-fns"
import { ReducerAction, ReducerState } from "@store/index"
import {
  SET_MEASUREMENTS,
  SET_MEASUREMENTS_HISTORY,
  SET_WAITING_FOR_MEASUREMENTS_DATA,
  SET_WAITING_FOR_MEASUREMENTS_HISTORY_DATA,
  // UPDATE_MEASUREMENTS_WITH_FILTERS,
} from "@store/simetas-store"
import { GraphQLResponse } from "@_types/graphql-response"
import { getQueryDateInterval } from "@utils/get-query-date-interval"
import {
  SerializedHistoryMeasurement,
  SerializedMeasurement,
} from "@models/serialized/serialized-simet-as"
import {
  deserializeMeasurementHistory,
} from "../serdes/deserialize-simet-as"
import { IpFamily } from "@models/ip-family"
import { isEqualToDateDuration } from "@utils/is-equal-to-date-duration"
import { SET_ALERT } from "@store/navigation-store"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        asSimetasApi: {
          baseUrl: string
        }
      }
    }
  }
}

interface Props {
  minDate?: Date | DateDuration
  maxDate?: Date
  defaultDuration?: DateDuration
  setMinDate?: (_?: Date | DateDuration) => void
  asn: number
}

interface ReducerSelected {
  filterIpVersion: IpFamily | null
  filterProbeId: string | null
}

const MeasurementsService: FunctionComponent<Props> = ({
  minDate,
  maxDate,
  defaultDuration,
  setMinDate,
  asn,
}) => {
  /* GraphQL data */
  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          externalServices {
            asSimetasApi {
              baseUrl
            }
          }
        }
      }
    }
  `)
  const { baseUrl } = data.site.siteMetadata.externalServices.asSimetasApi

  /* React Hooks */
  const { filterIpVersion, filterProbeId } = useSelector<
    ReducerState,
    ReducerSelected
  >((state) => {
    return {
      filterIpVersion: state.simetAS.filterIpVersion,
      filterProbeId: state.simetAS.filterProbeId,
    }
  })
  const dispatch = useDispatch<Dispatch<ReducerAction>>()
  const { keycloak } = useKeycloak()
  const prevFilterProbeId = useRef<string | null>(filterProbeId)
  const prevAsn = useRef<number | null>(null)
  const prevMinDate = useRef<Date | DateDuration | undefined>(minDate)
  const prevMaxDate = useRef<Date | undefined>(maxDate)
  
  const cancelToken = useRef<CancelTokenSource>()
  const requestId = useRef<number>(0)
  const firstCall = useRef(true)
  
  const cancelTokenHistory = useRef<CancelTokenSource>()
  const requestIdHistory = useRef<number>(0)
  const firstCallHistory = useRef(true)

  if (!keycloak?.authenticated) {
    throw new Error("Cannot use MeasurementsService without authentication")
  }

  const isAdmin = keycloak.hasRealmRole("admin")

  useEffect(() => {
    if(firstCall.current
    ){
      cancelToken.current?.cancel("MeasurementsService request canceled")
      cancelToken.current = undefined
      if(
        firstCall.current
      ){
        ; (async () => {
          const currentRequestId = requestId.current + 1
          requestId.current = currentRequestId
          try {
            dispatch({
              type: SET_WAITING_FOR_MEASUREMENTS_DATA,
              isWaitingForData: true,
            })

            const newCancelToken = axios.CancelToken.source()
            cancelToken.current = newCancelToken
      
            const query = await axios.post<
              GraphQLResponse<{ availability: SerializedMeasurement }>
            >(
              baseUrl,
              {
                // TODO: Remove unnecessary fields
                query:
                  `query ( ` +
                  `  $asn: Int!, $ipVersion: IPVersion ` +
                  `) { ` +
                  `  availability( ` +
                  `    asn: $asn, ipVersion: $ipVersion ` +
                  `  ) { ` +
                  `    locality {` +
                  `       probeID locality lastChange ` +
                  `        ipFamily {` +
                  `          ipVersion unavailabilityMinutes connected         }` +
                  `     }` +
                  `     }}`,
                variables: {
                  asn: asn,
                  ipVersion: null,
                },
              },
              {
                cancelToken: newCancelToken.token,
                headers: {
                  Authorization: `Bearer ${keycloak.token}`,
                },
              }
            )
      
            if (query?.data?.errors) {
              if (query.data.errors[0]) {
                dispatch({
                  type: SET_ALERT,
                  alert: {
                    message_key: "graphql_error:" + JSON.parse(query.data.errors[0].message).title_key
                  },
                })
                return 
              } else {
                throw new Error("Unknown error")
              }
            }
      
            if (requestId.current !== currentRequestId) {
              return 
            }
      
            if (query?.data?.data?.availability) {
              // Re-apply filters to be safe (in case of races with setting filters)
              // dispatch({
              //   type: UPDATE_MEASUREMENTS_WITH_FILTERS,
              // })
              dispatch({
                type: SET_MEASUREMENTS,
                // current
                measurements: query?.data?.data?.availability,
              })
            }else{
              dispatch({
                type: SET_ALERT,
                alert: {
                  message_key: "http_error:" + query.status
                },
              })
            }
      
          } catch (error) {
            if (axios.isCancel(error)) {
              console.log("MeasurementsService request canceled")
              return
            }
            if (requestId.current !== currentRequestId) {
              return
            }
            dispatch({
              type: SET_ALERT,
              alert: {
                message_key: "http_error:" + (error?.response?.status ?? error?.message),
              },
            })
          }finally{
            dispatch({
              type: SET_WAITING_FOR_MEASUREMENTS_DATA,
              isWaitingForData: false,
            })
          }
        })()
      }
      firstCall.current = false    }
  },[
    dispatch,
    baseUrl,
    keycloak,
    asn
  ])

  useEffect(() =>{
    if (filterProbeId !== null && (
      firstCallHistory.current ||
      prevFilterProbeId.current !== filterProbeId ||
      prevMinDate.current !== minDate ||
      prevMaxDate.current !== maxDate)
    ) {
      cancelTokenHistory.current?.cancel("MeasurementHistoryService request canceled")
      cancelTokenHistory.current = undefined
      // Fetch new data from API
      if (
        prevFilterProbeId.current !== filterProbeId ||
        !isEqualToDateDuration(prevMinDate.current, minDate) || // TODO: Evaluate specificity on minDate?
        !isEqualToDateDuration(prevMaxDate.current, maxDate) || // TODO: Evaluate specificity on maxDate?
        firstCallHistory.current
      ) {
        ; (async () => {
          const [queryMinDate, queryMaxDate] = getQueryDateInterval(
            minDate ?? defaultDuration,
            maxDate
          )
          if (
            setMinDate &&
            minDate instanceof Date &&
            isBefore(queryMinDate, minDate)
          ) {
            prevMinDate.current = undefined // Avoid refetching on the next effect update
            setMinDate(undefined)
          }
          const currentRequestId = requestIdHistory.current + 1
          requestIdHistory.current = currentRequestId
          try {
            dispatch({
              type: SET_WAITING_FOR_MEASUREMENTS_HISTORY_DATA,
              isWaitingForHistoryData: true,
            })
            const newCancelToken = axios.CancelToken.source()
            cancelTokenHistory.current = newCancelToken

            const query = await axios.post<
              GraphQLResponse<{ availabilityHistory: SerializedHistoryMeasurement[] }>
            >(
              baseUrl,
              {
                // TODO: Remove unnecessary fields
                query:
                  `query ( ` +
                  `  $probeID: UUID!, $minDate: DateTime!, $maxDate: DateTime!, $ipVersion: IPVersion ` +
                  `) { ` +
                  `  availabilityHistory( ` +
                  `    probeID: $probeID, minDate: $minDate, maxDate: $maxDate, ipVersion: $ipVersion ` +
                  `  ) { ` +
                  `    probeID locality startDate endDate ` +
                  ` ipFamilies {`+
                  `    availabilityPercentage timeConnectedMinutes localityAvailableMinutes totalSessions ipVersion totalDays unavailability ` +
                  `    unavailabilityPercentage unavailabilityVerification ` +
                  `    historyUnconnectedWindows { ` +
                  `      start end minutes   ` +
                  `    } } ` +
                  `  } ` +
                  `}`,
                variables: {
                  probeID: filterProbeId,
                  minDate: formatISO(queryMinDate),
                  maxDate: formatISO(queryMaxDate),
                  ipVersion: null,
                },
              },
              {
                cancelToken: newCancelToken.token,
                headers: {
                  Authorization: `Bearer ${keycloak.token}`,
                },
              }
            )
      
            if (query?.data?.errors) {
              if (query.data.errors[0]) {
                dispatch({
                  type: SET_ALERT,
                  alert: {
                    message_key: "graphql_error:" + JSON.parse(query.data.errors[0].message).title_key
                  },
                })
                console.error(JSON.parse(query.data.errors[0].message))
                return null
              }
            }
            
            if (requestIdHistory.current !== currentRequestId) {
              return null
            }
      
            if (query?.data?.data?.availabilityHistory) {
              // Re-apply filters to be safe (in case of races with setting filters)
              // dispatch({
              //   type: UPDATE_MEASUREMENTS_WITH_FILTERS,
              // })
      
              dispatch({
                type: SET_MEASUREMENTS_HISTORY,
                // current
                // history
                measurementsHistory: query?.data?.data?.availabilityHistory?.map(
                  deserializeMeasurementHistory
                ),
              })
            }else{
              dispatch({
                type: SET_ALERT,
                alert: {
                  message_key: "http_error:" + query.status
                },
              })
            }      
      
          } catch (error) {
            if (axios.isCancel(error)) {
              console.log("MeasurementsServiceHistory request canceled")
              return
            }
            if (requestIdHistory.current !== currentRequestId) {
              return
            }
            dispatch({
              type: SET_ALERT,
              alert: {
                message_key: "http_error:" + (error?.response?.status ?? error?.message),
              },
            })
          }finally{
            dispatch({
              type: SET_WAITING_FOR_MEASUREMENTS_HISTORY_DATA,
              isWaitingForHistoryData: false,
            })
          }
        })()
      }
      firstCallHistory.current = false
      prevMinDate.current = minDate
      prevMaxDate.current = maxDate
      prevFilterProbeId.current = filterProbeId
    }
  },[
    dispatch,
    baseUrl,
    keycloak,
    asn,
    filterProbeId,
    minDate,
    maxDate,
    defaultDuration,
    setMinDate,
  ])

  // Cleanup
  useEffect(() => {
    return () => {
      // Abort any current request
      requestId.current = 0
      requestIdHistory.current = 0

      cancelToken.current?.cancel("MeasurementsService request canceled")
      cancelTokenHistory.current?.cancel("MeasurementsServiceHistory request canceled")

    }
  }, [dispatch])

  return <></>
}

export default MeasurementsService
