import React, { FunctionComponent, memo } from 'react';
import { XCircleIcon, InformationCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'
import { AlertsType } from '@models/alerts-type'


export const wrapper = (type: AlertsType) => {
  switch (type) {
    case 'ERROR': return 'bg-red-100 border-red-400';
    case 'SUCCESS': return 'bg-green-100 border-green-400';
    case 'INFO': return 'bg-blue-100 border-blue-400';
    default: return 'bg-blue-100 border-blue-400';
  }
}


export const title = (type: AlertsType) => {
  switch (type) {
    case 'ERROR': return 'text-red-800';
    case 'SUCCESS': return 'text-green-800';
    case 'INFO': return 'text-blue-800';
    default: return 'text-blue-800';
  }
}


export const listing = (type: AlertsType) => {
  switch (type) {
    case 'ERROR': return 'text-red-700';
    case 'SUCCESS': return 'text-green-700';
    case 'INFO': return 'text-blue-700';
    default: return 'text-blue-700';
  }
}


export const closeButton = (type: AlertsType) => {
  switch (type) {
    case 'ERROR': return 'text-red-500 hover:bg-red-300 focus:ring-red-600 focus:ring-offset-red-100';
    case 'SUCCESS': return 'text-green-500 hover:bg-green-300 focus:ring-green-600 focus:ring-offset-green-100';
    case 'INFO': return 'text-blue-500 hover:bg-blue-300 focus:ring-blue-600 focus:ring-offset-blue-100';
    default: return 'text-blue-500 hover:bg-blue-300 focus:ring-blue-600 focus:ring-offset-blue-100';
  }
}


interface Props {
  type: AlertsType;
}


const IconComponent: FunctionComponent<Props> = ({ type }) => {
  let component = <></>

  switch (type) {
    case 'ERROR':
      component = <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />;
      break
    case 'SUCCESS':
      component = <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />;
      break
    case 'INFO':
      component = <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />;
      break
    default:
      component = <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />;
      break
  }

  return (
    <>
      {component}
    </>
  )
}

export default memo(IconComponent)