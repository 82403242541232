import React, { FunctionComponent } from "react"
import { Transition } from "@headlessui/react"
import { ProblemError } from "@models/problem-error"
import ProblemErrorComponent from "./problem-error-component"
import IconCheckmark from "@icons/icon-checkmark.svg"
import IconWarning from "@icons/icon-warning.svg"
import IconSpinner from "@icons/icon-spinner.svg"

interface Props {
  onCloseModal: () => void
  onConfirmRequest: () => void
  showModal: boolean
  isBusy: boolean
  isDone: boolean
  dialogTitle: string
  dialogMessage: React.ReactElement | string
  successMessage: React.ReactElement | string
  confirmationError?: ProblemError
  confirmButtonText?: string
  cancelButtonText?: string
  closeButtonText?: string
}

const ConfirmationModal: FunctionComponent<Props> = ({
  onCloseModal,
  onConfirmRequest,
  showModal,
  isBusy,
  isDone,
  dialogTitle,
  dialogMessage,
  successMessage,
  confirmationError,
  confirmButtonText,
  cancelButtonText,
  closeButtonText,
}) => {
  return (
    <Transition show={showModal}>
      <div className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div>
          {/* Background overlay */}
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-slate-800 opacity-75"></div>
            </div>
          </Transition.Child>
          {/* Modal panel */}
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="bg-white rounded-lg overflow-hidden transform transition-all sm:max-w-lg sm:w-full"
              role="dialog"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  {isDone ? (
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-200 sm:mx-0 sm:h-10 sm:w-10">
                      <IconCheckmark className="h-6 w-6 text-green-500" />
                    </div>
                  ) : (
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-200 sm:mx-0 sm:h-10 sm:w-10">
                      <IconWarning className="h-6 w-6 text-yellow-500" />
                    </div>
                  )}
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-slate-800"
                      id="modal-headline"
                    >
                      {dialogTitle}
                    </h3>
                    <div className="mt-2">
                      <div className="text-sm leading-5 text-slate-600">
                        {isDone ? (
                          successMessage
                        ) : (
                          <>
                            <div>{dialogMessage}</div>
                            {confirmationError && (
                              <div className="mt-3 mb-1">
                                <ProblemErrorComponent
                                  problemError={{
                                    ...confirmationError,
                                    title:
                                      confirmationError.title &&
                                      `${confirmationError.title}:`,
                                  }}
                                  titleClassName="text-base text-red-600 font-semibold mr-2 mb-1"
                                  detailClassName="text-red-600"
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-slate-200 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                {isDone ? (
                  <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                      onClick={() => onCloseModal()}
                      type="button"
                      className="inline-flex justify-center w-full text-base px-4 py-3 sm:py-2 leading-none border rounded text-slate-700 border-slate-700 hover:border-transparent hover:text-white hover:bg-slate-700 focus:outline-none focus:ring"
                    >
                      {closeButtonText || "Fechar"}
                    </button>
                  </span>
                ) : (
                  <>
                    <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                      <button
                        onClick={() => !isBusy && onConfirmRequest()}
                        type="button"
                        className={`relative inline-flex justify-center w-full text-base px-4 py-3 sm:py-2 leading-none border rounded ${
                          isBusy
                            ? "cursor-not-allowed text-blue-100 bg-blue-400"
                            : "text-white bg-blue-600 border-blue-400 hover:border-transparent hover:text-white hover:bg-blue-500"
                        } focus:outline-none focus:ring`}
                      >
                        <span
                          className={isBusy ? "text-white text-opacity-0" : ""}
                        >
                          {confirmButtonText || "Confirmar"}
                        </span>
                        <IconSpinner
                          className={`absolute animate-spin h-4 w-4 object-center ${
                            isBusy ? "" : "opacity-0"
                          }`}
                        />
                      </button>
                    </span>
                    <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                      <button
                        onClick={() => !isBusy && onCloseModal()}
                        type="button"
                        className={`inline-flex justify-center w-full text-base px-4 py-3 sm:py-2 leading-none border rounded ${
                          isBusy
                            ? "cursor-not-allowed text-white text-opacity-0 bg-slate-500"
                            : "text-slate-700 border-slate-700 hover:border-transparent hover:text-white hover:bg-slate-700"
                        } focus:outline-none focus:ring`}
                      >
                        {cancelButtonText || "Cancelar"}
                      </button>
                    </span>
                  </>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  )
}

export default ConfirmationModal
