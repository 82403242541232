import React, { ReactElement, useState, useEffect } from "react"
import Helmet from "react-helmet"
import { PageProps } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { Duration as DateDuration } from "date-fns"
import { ReducerAction, ReducerState } from "@store/index"
import RequireAuthentication from "@components/shared/require-authentication"
import { DELETE_ALERT } from "@store/navigation-store"
import { AutonomousSystem } from "@models/autonomous-system"
import { useAsnParameter } from "@hooks/use-asn-parameter"
import PeriodInput from "@components/filter/period-input"
import AntiSpoofingInput from "@components/filter/antiSpoofing-input"

import { useQueryParams } from "use-query-params"
import { QueryParamConfig, selectedPeriod } from "@utils/query-params"
import {
  SET_FILTER_AGENT_ID,
  SET_FILTER_ANTI_SPOOFING,
} from "@store/simetas-store"
import { SET_FILTER_CURSOR } from "@store/navigation-store"
// import AvailableAutonomousSystemService from "@services/available-autonomous-system-service"
import { SimetasProbe } from "@models/simetas-probe"
import AntiSpoofingService from "@services/anti-spoofing-service"
import CardAntiSpoofingComponent from "@components/shared/cardAntiSpoofing"
import CardAntiSpoofingHistoryComponent from "@components/shared/cardAntiSpoofingHistory"
import AntiSpoofingHistoryService from "@services/anti-spoofing-history-service"
import AgentFamilyComponent from "@components/filter/agentFamily"
import { PeriodType } from "@models/period-type"
import PaginationBarComponent from "@components/controls/pagination-bar-component"
import SimetasProbesService from "@services/simetas-probes-service"
import EmptyStateSimetASAntiSpoofing from "@components/shared/empty-state-simetas-antispoofing"
import { AntiSpoofingType, ANTI_SPOOFING } from "@models/anti-spoofing-type"
import PageSpinner from "@components/shared/page-spinner"
import { PageDocsSidebarContentMethodology } from "@models/page-docs-sidebar-content-type"

export interface IndexLocationState {
  availableAutonomousSystems: AutonomousSystem[]
  clearCursor: boolean
}

interface ReducerSelected {
  isWaitingForData: boolean
  availableAutonomousSystems: AutonomousSystem[] | null
  simetasProbes: SimetasProbe[] | null
  isWaitingForDataSimetasProbes: boolean
  isWaitingForAntiSpoofingData: boolean
  isWaitingForAntiSpoofingHistoryData: boolean
}

const AntiSpoofingPage: (
  _: PageProps<object, object, IndexLocationState> & {
    asn?: string
  }
) => ReactElement = ({ asn: asnString, location }) => {
  const asn = useAsnParameter(asnString)
  const dispatch = useDispatch<Dispatch<ReducerAction>>()
  const [_searchParams, setSearchParams] = useQueryParams(QueryParamConfig)

  useEffect(() => {
    // Clear error
    dispatch({
      type: DELETE_ALERT,
      alert: null,
    })

    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    isWaitingForData,
    availableAutonomousSystems,
    simetasProbes,
    isWaitingForDataSimetasProbes,
    isWaitingForAntiSpoofingData,
    isWaitingForAntiSpoofingHistoryData,
  } = useSelector<ReducerState, ReducerSelected>((state) => {
    return {
      isWaitingForData: state.autonomousSystems.isWaitingForData,
      availableAutonomousSystems:
        state.autonomousSystems.availableAutonomousSystems,
      simetasProbes: state.simetasProbes.simetasProbes,
      isWaitingForDataSimetasProbes: state.simetasProbes.isWaitingForData,
      isWaitingForAntiSpoofingData: state.antiSpoofing.isWaitingForData,
      isWaitingForAntiSpoofingHistoryData:
        state.antiSpoofing.isWaitingForHistoryData,
    }
  })

  useEffect(() => {
    // if (location.state?.availableAutonomousSystems !== undefined) {
    //   dispatch({
    //     type: SET_AVAILABLE_AUTONOMOUS_SYSTEMS,
    //     autonomousSystems: location.state.availableAutonomousSystems,
    //   })
    // }
    if (location.state?.clearCursor) setSearchParams({ cursor: undefined })

    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const { periodo, from, to, agentId, antiSpoofing, cursor } = _searchParams

  const [minDate, setMinDate] = useState<Date | DateDuration>()
  const [maxDate, setMaxDate] = useState<Date>()

  useEffect(() => {
    const [min, max] = selectedPeriod(
      (periodo as PeriodType) ?? "TODAY",
      from,
      to,
      true
    )

    setMinDate(min as Date)
    setMaxDate(max as Date)

    dispatch({
      type: SET_FILTER_AGENT_ID,
      filterAgentId: agentId ?? null,
    })

    dispatch({
      type: SET_FILTER_ANTI_SPOOFING,
      filterAntiSpoofing:
        (antiSpoofing as AntiSpoofingType) ?? ANTI_SPOOFING[0],
    })

    dispatch({
      type: SET_FILTER_CURSOR,
      filterCursor: cursor ?? null,
    })
  }, [periodo, from, to, agentId, antiSpoofing, cursor])

  const DEFAULT_DURATION: DateDuration = { days: 7 }

  return (
    <>
      <Helmet>
        <title>PAS - Filtragem Antispoofing</title>
      </Helmet>
      <RequireAuthentication>
        <div className="pt-6 px-4 sm:px-6 lg:px-8">
          <div className="contents">
            {/* <AvailableAutonomousSystemService /> */}
            {asn && (
              <>
                <SimetasProbesService asn={asn} />
                <AntiSpoofingService asn={asn} />
                <AntiSpoofingHistoryService
                  minDate={minDate}
                  maxDate={maxDate}
                  defaultDuration={DEFAULT_DURATION}
                  setMinDate={setMinDate}
                  asn={asn}
                />

                {(isWaitingForDataSimetasProbes || isWaitingForData) && (
                  <PageSpinner />
                )}

                {!isWaitingForDataSimetasProbes &&
                !isWaitingForData &&
                !isWaitingForAntiSpoofingHistoryData &&
                !isWaitingForAntiSpoofingData &&
                simetasProbes &&
                simetasProbes.length == 0 ? (
                  <EmptyStateSimetASAntiSpoofing />
                ) : (
                  simetasProbes &&
                  simetasProbes.length > 0 && (
                    <>
                      {/* Data query service  */}

                      <div className="mb-6 text-xl font-medium text-slate-600">
                        Conformidade atual
                      </div>
                      <div className="flex flex-wrap">
                        {/* Dados atuais */}
                        <CardAntiSpoofingComponent />
                      </div>

                      {/* Dados Historicos */}
                      <div className="text-xl font-medium text-slate-600 mb-6 mt-12">
                        Medições de Antispoofing
                      </div>
                      {/* Filtros */}
                      <div className="flex space-x-8">
                        <AgentFamilyComponent showFamilies={false} />
                        <AntiSpoofingInput />
                        <PeriodInput />
                      </div>
                      {/* Pagination */}
                      <PaginationBarComponent />
                      {!isWaitingForAntiSpoofingHistoryData ? (
                        <CardAntiSpoofingHistoryComponent />
                      ) : (
                        <PageSpinner />
                      )}
                    </>
                  )
                )}
              </>
            )}
          </div>
        </div>
      </RequireAuthentication>
    </>
  )
}

export default AntiSpoofingPage

export const getPageSidebarContent = (): PageDocsSidebarContentMethodology => {
  return {
    title: "Filtragem Antispoofing",
    abstract:
      "Verificar se o sistema autônomo pratica a filtragem de pacotes spoofados conforme a prática operacional BCP-38 e o manifesto MANRS.",
    measurementClients: ["Simet-AS"],
    methodOfMeasurement: [
      `A medição é realizada com um pacote UDP, cujo endereço de origem é modificado como spoofado, e que é enviado pelo medidor a um servidor âncora no AS14026.
      Se o servidor recebe o pacote é provado que o provedor ainda não pratica uma filtragem efetiva de pacote spoofados.
      Já se o pacote não alcança o servidor pode se concluir que o provedor está filtrando pacotes spoofados, salvo a situação de perda de pacote, mitigada pelo envio de múltiplos pacotes.
      O teste é realizado tanto com IP público spoofado (não pertencente ao AS do provedor), quanto com IP privado spoofado.`,
      `A medição é capaz de detectar filtragens de pacotes quando são implementadas na rede do provedor entre o medidor e o roteador de borda.
      Como a sonda de medição SIMET-AS é instalada dentro da rede do provedor, não se detecta uma eventual filtragem implementada na rede de acesso.
      Para detectar filtragem antispoofing em toda extensão da rede, futuramente a medicão será realizada pelo medidor SimetBox.`,
    ],
    nextSteps: [
      `Aprender a <a href="https://bcp.nic.br/antispoofing" target="_blank" class="text-blue-600 font-medium cursor-pointer hover:underline" >configurar filtros antispoofing</a> em roteadores MikroTik, Juniper e Cisco.`,
    ],
  }
}
