import { StringParam } from "use-query-params"
import { ptBR as ptbrLocale } from "date-fns/locale"
import { 
  parse,
  format,
  add,
  startOfYesterday,
  endOfYesterday,
  startOfToday,
  endOfToday,
  startOfMonth,
  endOfMonth,
} from "date-fns"
import { PeriodType } from "@models/period-type"

const QueryParamConfig = {
  conformidade: StringParam,
  realtime: StringParam,
  ix: StringParam,
  periodo: StringParam,
  from: StringParam,
  to: StringParam,
  ipFamily: StringParam,
  probeId: StringParam,
  agentId: StringParam,
  agentFamily: StringParam,
  valueType: StringParam,
  antiSpoofing: StringParam,
  cursor: StringParam,
}

export { QueryParamConfig }

export const nowForDay = () => {
  const browserOffset = new Date().getTimezoneOffset() * 60
  return new Date(new Date().getTime() + browserOffset * 1000)
}

export const formatISO = (
  date: Date | string | undefined | null,
  returnAsDate?: boolean,
  formatDate: string = `yyyy-MM-dd`
) => {
  let date_ = date

  let date_formated: Date = (date_ as Date) ?? startOfToday()

  if (returnAsDate && date_ instanceof Date) return date_formated

  if (typeof date_ === "string") {
    date_formated = parseDate(date_)

    if (returnAsDate) return date_formated
  }
  return formatDateWithLocale(date_formated, formatDate)
}

export const parseDate = (date: string) =>
  parse(date, "yyyy-MM-dd", new Date(), { locale: ptbrLocale })

const shiftMonths = (date: Date, months: number) => {
  const newDate = new Date(date.getTime())
  const d = newDate.getDate()
  newDate.setMonth(newDate.getMonth() + +months)
  if (newDate.getDate() !== d) {
    newDate.setDate(0)
  }
  return newDate
}

export function shiftDays(date: Date, days: number) {
  const newDate = new Date(date.getTime())
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

export function selectedPeriod(
  select: PeriodType,
  from?: string | undefined | null,
  to?: string | undefined | null,
  returnAsDate?: boolean
): [string | Date, string | Date] {
  const formatedEndOfToday = formatISO(endOfToday(), returnAsDate)
  const formatedEndOfYesterday = formatISO(endOfYesterday(), returnAsDate)

  switch (select) {
    case "TODAY":
      return [formatISO(startOfToday(), returnAsDate), formatedEndOfToday]
    case "YESTERDAY":
      return [formatISO(startOfYesterday(), returnAsDate), formatedEndOfYesterday]
    case "7D":
      return [formatISO(add(startOfYesterday(), {days: -6}), returnAsDate), formatedEndOfYesterday]
    case "30D":
      return [formatISO(add(startOfYesterday(), {days: -29}), returnAsDate), formatedEndOfYesterday]
    case "CURRENT_MONTH": 
      return [formatISO(startOfMonth(startOfToday()), returnAsDate), formatISO(endOfMonth(endOfToday()), returnAsDate)]
    case "LAST_MONTH": 
      let lastMonth = add(startOfToday(), {months: -1})
      return [formatISO(startOfMonth(lastMonth), returnAsDate), formatISO(endOfMonth(lastMonth), returnAsDate)]
    case "CUSTOM":
      return [formatISO(from, returnAsDate), formatISO(to, returnAsDate)]
    default:
      return [formatISO(startOfToday(), returnAsDate), formatedEndOfToday]
  }
}

const MEASUREMENT_TIME_FORMAT = "dd/MM HH:mm:ss"

export const formatDateWithLocale = (
  date: Date,
  formatDate: string = MEASUREMENT_TIME_FORMAT
) =>
  format(date, formatDate, {
    locale: ptbrLocale,
  })
