import React, { FunctionComponent } from "react"
import { useSelector } from "react-redux"
import { Measurement, MeasurementHistory } from "@models/simet-as"
import { ProblemError } from "@models/problem-error"
import { ReducerState } from "@store/index"
import RingChart from "./ringChart"
import { sortSimetASIpFamily } from "@utils/simet-as/sort-simetas"
import { formatDateWithLocale, parseDate } from "@utils/query-params"
import { getIXLocationByCode } from "@utils/ix-location-map"
import { parseISO } from "date-fns"
import { formatIpFamily } from "@models/ip-family"
import { parseMinutes } from "@utils/string-to-duration"
import { MeasurementGroupedIpFamily } from "@models/serialized/serialized-simet-as"
import { compareMeasurementLocalityAsc } from "@utils/simet-as/compare-simetas"

interface Props {
  isHistorical: boolean
}

interface ReducerSelected {
  measurementsError: ProblemError | null
  measurementsList: Measurement | null
  measurementsHistoryList: MeasurementHistory[] | null
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

const CardIxComponent: FunctionComponent<Props> = ({ isHistorical }) => {
  const { measurementsError, measurementsList, measurementsHistoryList } =
    useSelector<ReducerState, ReducerSelected>((state) => {
      return {
        measurementsError: state.simetAS.error,
        measurementsList: state.simetAS.measurementsList,
        measurementsHistoryList: state.simetAS.measurementsHistoryList,
      }
    })

  return (
    <>
      {!isHistorical ? (
        <>
          {measurementsList?.locality
            .sort(compareMeasurementLocalityAsc)
            .map((ixs) => {
              return (
                <>
                  <div className="w-[25rem] mr-16 mb-16 flex-grow flex flex-col items-stretch bg-white rounded-lg shadow-xl ring-1 ring-gray-200">
                    <div className="font-medium text-2xl rounded-t-lg p-3 text-center text-gray-900 border-b">
                      {/* {getStateNameByUF(ixs.locality.split('-')[1]) ?? ixs.locality} */}
                      {getIXLocationByCode(ixs.locality)}
                    </div>
                    <div className="flex aspect-[4/1] h-full">
                      {(
                        sortSimetASIpFamily(
                          ixs.ipFamily,
                          "IP_VERSION",
                          true
                        ) as MeasurementGroupedIpFamily[]
                      ).map((res, idx) => {
                        return (
                          <div
                            key={idx}
                            className={classNames(
                              idx === 0 ? "rounded-bl-xl" : "rounded-br-xl",
                              ixs.ipFamily.length === 1 ? "w-full" : "w-1/2",
                              "flex flex-col items-center justify-between p-3 text-white border-r ",
                              res.connected ? "bg-green-600" : "bg-red-600"
                            )}
                          >
                            <div className="flex items-center flex-grow text-3xl font-medium">
                              {formatIpFamily(res.ipVersion)}
                            </div>
                            <div className="flex items-center text-sm font-medium leading-tight">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 mr-1 flex-shrink-0 text-white-200"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              {res.connected
                                ? `${parseMinutes(
                                    res.unavailabilityMinutes,
                                    ixs.lastChange,
                                    undefined,
                                    true
                                  )} de conexão`
                                : `${parseMinutes(
                                    res.unavailabilityMinutes,
                                    ixs.lastChange,
                                    undefined,
                                    true
                                  )} sem conexão`}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </>
              )
            })}
        </>
      ) : (
        <>
          {measurementsHistoryList
            ?.sort(compareMeasurementLocalityAsc)
            .map((ixs, idx) => {
              return (
                <>
                  <div
                    key={idx}
                    className="w-[25rem] mr-16 mb-16 flex-grow flex flex-col bg-white rounded-lg shadow-xl ring-1 ring-gray-200"
                  >
                    <div className="font-medium text-2xl rounded-t-lg pt-3 pb-1 text-center text-gray-900">
                      {getIXLocationByCode(ixs.locality)}
                    </div>
                    <div className="flex justify-around font-medium text-lg pb-1 rounded-t-lg text-center text-gray-900 border-b">
                      {ixs.ipFamilies.map((res) => (
                        <div className="">{res.ipVersion}</div>
                      ))}
                    </div>
                    <div className="flex h-full">
                      {ixs.ipFamilies.map((ipFamily) => {
                        const unconnectedWindowsLength =
                          ipFamily.historyUnconnectedWindows.length
                        const availabilityPercentage = parseFloat(
                          String(ipFamily.availabilityPercentage ?? 0)
                        )

                        return (
                          <>
                            <div
                              className={classNames(
                                "flex flex-col items-center pt-6 border-r",
                                ixs.ipFamilies.length === 1 ? "w-full" : "w-1/2"
                              )}
                            >
                              <RingChart
                                percentage={availabilityPercentage}
                                ringClass="w-40 h-40"
                                labelClass="text-2xl text-gray-900"
                              />
                              {/* Historico */}
                              <table className="mt-2 leading-none border-separate border-spacing-y-2">
                                <tbody>
                                  <tr>
                                    <td className="pr-1 font-semibold text-right">
                                      {ipFamily.timeConnectedMinutes > 0 ? (
                                        <>
                                          {parseMinutes(
                                            ipFamily.timeConnectedMinutes,
                                            ixs.startDate.toISOString()
                                          )}
                                        </>
                                      ) : (
                                        <>{"0m"}</>
                                      )}
                                    </td>
                                    <td>com conexão</td>
                                  </tr>
                                  <tr>
                                    <td className="pr-1 font-semibold text-right">
                                      {ipFamily.unavailability > 0 ? (
                                        <>
                                          {parseMinutes(
                                            ipFamily.unavailability,
                                            ixs.startDate.toISOString()
                                          )}
                                        </>
                                      ) : (
                                        <>{"0m"}</>
                                      )}
                                    </td>
                                    <td>sem conexão</td>
                                  </tr>
                                  <tr>
                                    <td className="pt-6 pr-1 font-semibold text-right">
                                      {unconnectedWindowsLength}
                                    </td>
                                    <td className="pt-6">
                                      {unconnectedWindowsLength === 1
                                        ? "desconexão"
                                        : "desconexões"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              {/* Tabela de Historicos */}
                              <div className="self-start w-full mt-1 text-sm text-gray-700">
                                {ipFamily.historyUnconnectedWindows.length >
                                  0 &&
                                  ipFamily.historyUnconnectedWindows.map(
                                    (history, historyIdx) => {
                                      return (
                                        <div
                                          key={historyIdx}
                                          className={`flex items-center justify-between px-3 py-2 ${
                                            historyIdx % 2 === 1
                                              ? "bg-white"
                                              : "bg-gray-200"
                                          }`}
                                        >
                                          <div>
                                            {history?.start
                                              ? formatDateWithLocale(
                                                  parseISO(history.start)
                                                )
                                              : "--"}
                                          </div>
                                          <div>
                                            {history?.minutes
                                              ? "por " +
                                                parseMinutes(
                                                  history.minutes,
                                                  history.start,
                                                  history.end
                                                )
                                              : "--"}
                                          </div>
                                        </div>
                                      )
                                    }
                                  )}
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>
                </>
              )
            })}
        </>
      )}
    </>
  )
}

export default CardIxComponent
