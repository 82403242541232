import React, { FunctionComponent, memo, useEffect, useState } from "react"
import { QueryParamConfig } from "@utils/query-params"
import { useQueryParams } from "use-query-params"
import {
  VALUE_TYPE,
  formatValueType,
  ValueType
} from "@models/value-type"
import { Transition, Listbox } from "@headlessui/react"
import { LightningBoltIcon } from "@heroicons/react/outline"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

const ValueTypeComponent: FunctionComponent = ({ }) => {

  const [_searchParams, setSearchParams] = useQueryParams(QueryParamConfig)

  const { valueType } = _searchParams

  const [controlValueType, setControlValueType] = useState(valueType as ValueType ?? VALUE_TYPE[0])

  useEffect(() => {
    setSearchParams({ cursor: undefined })

    setSearchParams({ valueType: controlValueType.toString() })
  }, [controlValueType, valueType])

  return (
    <Listbox
      value={controlValueType}
      onChange={setControlValueType}
      as="div"
      className="z-20 relative"
    >
      {({ open }) => (
        <>
          <Listbox.Button className="group inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 focus:ring-blue-500">
            <LightningBoltIcon className="h-5 w-5 mr-2 flex-none" />
            {/* Últimos 7 dias */}
            {formatValueType(controlValueType)}
            {/* <SelectorIcon
            aria-hidden="true"
            className="h-5 w-5 mr-2 text-slate-400"
          /> */}
          </Listbox.Button>
          <Transition
            // as={Fragment}
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Listbox.Options className="font-normal origin-top-right absolute right-0 mt-2 w-52 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none space-y-1 z-20">
              {VALUE_TYPE.slice().map((res, idx) => (
                <Listbox.Option
                  key={idx}
                  value={res}
                // as={Fragment}
                >
                  {({ active, selected }) => (
                    <>
                      <div
                        className={
                          classNames(
                            active ? "bg-slate-100" : "",
                            "block px-4 py-2 text-sm text-slate-700"
                          )}
                      >
                        <div>
                          <span
                            className={`block truncate ${selected ? "font-medium" : "font-normal"
                              }`}
                          >
                            {formatValueType(res)}
                          </span>
                          {/* {selected ? (
                          <span className="flex-shrink-0 ml-4 pb-3">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ): null } */}
                        </div>
                      </div>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  )
}

export default memo(ValueTypeComponent);
