// TODO: solve localization of date values for xFormat (tooltip) and axisBottom (x axis)
// https://github.com/plouc/nivo/issues/1946
// https://stackoverflow.com/questions/34437526/d3-time-formatting-locale
// https://github.com/d3/d3-time-format/blob/main/src/defaultLocale.js
import { MeasurementSubcategory } from "@models/measurement-subcategory"
import React, { FunctionComponent } from "react"
import EmptyStateTimeSeries from "./empty-state-timeSeries"
import PageSpinnerTimeSeries from "./page-spinner-timeSeries"
import TimeSeriesChart from "./time-series-chart"
import { GraphicComponentFragmentType } from "./utils"
import { LinesTimesSeriesData } from "@models/LinesTimesSeriesData"

interface Props {
  title: string
  metric: MeasurementSubcategory
  config: GraphicComponentFragmentType | undefined
  isWaitingForData: boolean
  onFormatLines: (metric: MeasurementSubcategory) => {
    lines: LinesTimesSeriesData[]
    pointY: number[]
  }
}

const TimeSeriesChartWrapper: FunctionComponent<Props> = ({
  title,
  metric,
  config,
  isWaitingForData,
  onFormatLines,
}) => {
  const { lines, pointY } = onFormatLines(metric)

  // set max value for y axis

  const maxValue = Math.max(...(pointY ?? [0]))

  const verificaTimeSeriesLength = lines.reduce((acc, value) => {
    return acc + value.data.length
  }, 0)

  return (
    // ATTENTION: Define shadow-symmetric in tailwind.config.js (extend theme)
    <div className="relative p-2 md:p-4 shadow-symmetric rounded-lg">
      <div className="text-center font-medium mb-2">{title}</div>
      {isWaitingForData && <PageSpinnerTimeSeries />}
      {!isWaitingForData && verificaTimeSeriesLength === 0 && (
        <EmptyStateTimeSeries />
      )}
      {/* ajustar com o aspect ratio */}
      <div className="h-64">
        {!isWaitingForData && verificaTimeSeriesLength > 0 && (
          <TimeSeriesChart
            data={lines}
            metric={metric}
            maxValue={maxValue}
            config={config}
          />
        )}
      </div>
    </div>
  )
}
export default TimeSeriesChartWrapper
