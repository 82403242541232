import React from "react"

export function Footer() {
  return (
    <footer className="inset-x-0 w-full bg-gray-200 p-7 lg:px-10">
      {/* 1ª Linha */}
      <div className="my-2 flex w-full flex-wrap justify-between pb-2 sm:items-center">
        {/* Iniciativa + Logos */}
        <div className="flex  w-full flex-col flex-wrap items-center sm:w-1/3 md:mb-auto md:w-2/5 md:flex-row">
          <div className=" mr-auto py-2  text-xs font-medium uppercase tracking-widest text-slate-600">
            Iniciativa
          </div>
          {/* logos institucionais */}
          <div className="flex justify-center space-x-2 py-2 sm:mr-auto ">
            <a
              href="https://www.ceptro.br"
              title="Ir para página - CEPTRO.br"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/logo/ceptro.svg"
                alt="Logo CEPTRO.br"
                className="h-7"
              />
            </a>

            <div className="w-px border-r-2 border-slate-300"></div>

            <a
              href="https://www.nic.br"
              title="Ir para página - NIC.br"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/logo/NIC-logo-footer.svg"
                alt="Logo NIC.br"
                className="h-7"
              />
            </a>

            <div className="w-px border-r-2 border-slate-300"></div>

            <a
              href="https://www.cgi.br"
              title="Ir para página - CGI.br"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/logo/CGI-logo-footer.svg"
                alt="Logo CGI.br"
                className="h-7"
              />
            </a>
          </div>
          {/* logos institucionais */}
        </div>
        {/* Fim Iniciativa + Logos */}

        {/* Texto Iniciativa */}
        <div className="mt-3 text-xs tracking-wider text-slate-500 sm:mt-0 sm:w-2/3 sm:pl-8 md:w-3/5">
          O Portal do Sistema Autônomo (PAS) é um portal desenvolvido pelo
          NIC.br para entregar ao provedor de Internet brasileiro o resultado de
          todos os projetos de medição do NIC.br.
        </div>
        {/* Texto Iniciativa */}
      </div>
      {/* Fim 1ª Linha */}

      <div className="h-px w-full bg-slate-300"></div>

      {/* 2ª Linha (Social Icons) */}
      <div className=" my-5 flex w-full flex-col flex-wrap gap-y-2 sm:flex-row sm:items-center sm:justify-between">
        <div className="text-xs uppercase tracking-widest opacity-80 text-black">
          Siga o Nic.br
        </div>

        {/* Icons */}
        <ul className="sm:mr-auto flex flex-wrap gap-x-2 sm:ml-4">
          <li>
            <a
              href="https://www.youtube.com/nicbrvideos/"
              target="_blank"
              title="Youtube NIC.br"
              rel="noreferrer"
            >
              <img
                src="/logo/social-media/youtube-logo.svg"
                alt="Logo youtube"
                className="h-5"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.twitter.com/comunicbr/"
              target="_blank"
              title="Twitter NIC.br"
              rel="noreferrer"
            >
              <img
                src="/logo/social-media/x-logo.svg"
                alt="Logo Twitter"
                className="h-5"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/nic.br/"
              target="_blank"
              title="Facebook NIC.br"
              rel="noreferrer"
            >
              <img
                src="/logo/social-media/facebook-logo.svg"
                alt="Logo facebook"
                className="h-5"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.flickr.com/photos/nicbr/"
              target="_blank"
              title="Flickr NIC.br"
              rel="noreferrer"
            >
              <img
                src="/logo/social-media/flickr-logo.svg"
                className="h-5"
                alt="Logo flickr"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.telegram.me/nicbr/"
              target="_blank"
              title="Telegram NIC.br"
              rel="noreferrer"
            >
              <img
                src="/logo/social-media/telegram-logo.svg"
                className="h-5"
                alt="Logo telegram"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/nic-br/"
              target="_blank"
              title="Linkedin NIC.br"
              rel="noreferrer"
            >
              <img
                src="/logo/social-media/linkedin-logo.svg"
                className="h-5"
                alt="Logo linkedin"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/nicbr/"
              target="_blank"
              title="Instagram NIC.br"
              rel="noreferrer"
            >
              <img
                src="/logo/social-media/instagram-logo.svg"
                className="h-5"
                alt="Logo instagram"
              />
            </a>
          </li>

          <li>
            <a
              href="https://nic.br/noticias/feed.rss"
              target="_blank"
              title="Feed RSS - NIC.br"
              rel="noreferrer"
            >
              <img
                src="/logo/social-media/rss-logo.svg"
                className="h-5"
                alt="Logo rss"
              />
            </a>
          </li>
        </ul>
        {/* Icons */}

        <a
          className="ml-auto align-middle text-xs tracking-wider text-black opacity-80 sm:ml-0"
          href="mailto:medicoes@simet.nic.br"
          title="Contate-nos"
        >
          Contate-nos
        </a>
      </div>
      {/* Fim 2ª Linha (Social Icons) */}

      <div className="h-px w-full bg-slate-300"></div>

      {/* 3ª Linha (infos) */}
      <div className=" mt-4 flex w-full flex-col space-y-6 text-xs tracking-wide sm:flex-row-reverse sm:items-center sm:justify-between sm:space-y-0">
        <ul className="flex flex-col gap-y-2 text-slate-500 md:flex-row md:gap-x-6">
          <li>
            <a
              href="https://nic.br/politica-de-privacidade-e-termos-de-uso/"
              title="Privacidade"
            >
              Privacidade e termos
            </a>
          </li>

          <li>
            <a
              href="https://creativecommons.org/licenses/by-sa/4.0/deed.pt_BR"
              title="Creative Commons - Licença"
            >
              Creative Commons
            </a>
          </li>
        </ul>

        <ul className="flex flex-col gap-y-3 ">
          <li>NIC.BR - Núcleo de Informação e Coordenação do Ponto BR</li>
          <li>CNPJ:05.506.560/0001-36</li>
        </ul>
      </div>
      {/* Fim 3ª Linha (infos) */}
    </footer>
  )
}
