
import React, { FunctionComponent, useState } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { AdjustmentsIcon } from "@heroicons/react/outline"
import { formatSubcategoryHistoricalByAgent, MeasurementSubcategory, MEASUREMENT_SUBCATEGORIES } from "@models/measurement-subcategory"


const SubcategoryInput: FunctionComponent<{onSelectSubcategory: (_:MeasurementSubcategory)=> void}> = ({onSelectSubcategory}) => {
  const [selected, setSelected] = useState<MeasurementSubcategory>(MEASUREMENT_SUBCATEGORIES[1])

  return (
    <Listbox
      value={selected}
      onChange={(evt)=>{
        setSelected(evt)
        onSelectSubcategory(evt)
      }}
      as="div"
      className="z-20 relative"
    >
      {({ open }) => (
        <>
          <Listbox.Button className="group inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 focus:ring-blue-500">
            <AdjustmentsIcon className="h-5 w-5 mr-2 flex-none" />

            {formatSubcategoryHistoricalByAgent(selected)}
          </Listbox.Button>
          <Transition
            // as={Fragment}
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Listbox.Options className="font-normal origin-top-right z-20 absolute left-0 mt-2 w-40 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none space-y-1">
              {MEASUREMENT_SUBCATEGORIES.slice().map((res, idx) => (
                <Listbox.Option
                  key={idx}
                  className={({ active }) =>
                    `block px-4 py-2 text-sm text-slate-700" ${active ? "bg-slate-100" : ""
                    } `
                  }
                  value={res}
                >
                  {({ selected }) => (
                    <div className="">
                      <span
                        className={`block truncate ${selected ? "font-medium" : "font-normal"
                          }`}
                      >
                        {formatSubcategoryHistoricalByAgent(res)}
                      </span>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  )
}

export default SubcategoryInput;
