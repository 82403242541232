// @ts-nocheck

import React, { Fragment, FunctionComponent } from "react"
import { Popover, Transition } from "@headlessui/react"
import clsx from "clsx"
import { useKeycloak } from "@react-keycloak/web"
import { Button } from "./Button"
import { Container } from "./Container"
import { Link } from "./Link"
// import Banner from "@components/shared/banner"

const MobileLink: FunctionComponent<{ href: string; children: any }> = ({
  href,
  children,
}) => {
  return (
    <Popover.Button as={Link} href={href} className="block w-full p-2">
      {children}
    </Popover.Button>
  )
}

const MobileNavIcon: FunctionComponent<{ open: boolean }> = ({ open }) => {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-current text-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          "origin-center transition",
          open && "scale-90 opacity-0"
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          "origin-center transition",
          !open && "scale-90 opacity-0"
        )}
      />
    </svg>
  )
}

function MobileNavigation() {
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()
  let kCHref
  if (isKeycloakInitialized && keycloak) {
    kCHref = keycloak?.createRegisterUrl()
  }
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center focus:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300 bg-opacity-50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900 ring-opacity-5"
          >
            <MobileLink href="/">Pagina Inicial</MobileLink>
            <MobileLink href="/blog">Blog</MobileLink>
            <MobileLink href="/docs/usuarios">Documentação</MobileLink>
            <hr className="m-2 border-slate-300 border-opacity-40" />
            <MobileLink href={kCHref}>Registrar-se</MobileLink>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}

export const Header: FunctionComponent = () => {
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()
  let kCHref
  if (isKeycloakInitialized && keycloak) {
    kCHref = keycloak?.createRegisterUrl()
  }

  return (
    <>
      <header className="justify-between fixed left-0 right-0 top-0 z-50 bg-white p-2 shadow">
        <Container>
          <nav className="relative z-50 flex gap-x-5 md:gap-x-8 items-center font-semibold justify-between">
            <Link href="/" aria-label="Home" className="">
              <div className="justify-between space-x-2 flex flex-shrink-0 text-blue-600">
                <a
                  href="https://www.nic.br"
                  className="flex flex-shrink-0"
                  title="Ir para página - NIC.br"
                  target="_blank" rel="noreferrer"
                >
                  <img
                    src="/logo/NIC-logo-header.svg"
                    alt="Logo NIC.br"
                    className="h-8"
                  />
                </a>

                <div className="h-12 border-r-2 border-slate-300 w-px"></div>

                <svg
                  className="h-11 w-auto fill-current"
                  fill="none"
                  viewBox="0 0 35 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.258 26.865a4.043 4.043 0 01-1.133 2.917A4.006 4.006 0 0111.253 31a3.992 3.992 0 01-2.872-1.218 4.028 4.028 0 01-1.133-2.917c.009-.698.2-1.382.557-1.981.356-.6.863-1.094 1.47-1.433-.024.109.09-.055 0 0l1.86-1.652a8.495 8.495 0 002.304-5.793c0-2.926-1.711-5.901-4.17-7.457.094.055-.036-.094 0 0A3.952 3.952 0 017.8 7.116a3.975 3.975 0 01-.557-1.98 4.042 4.042 0 011.133-2.918A4.006 4.006 0 0111.247 1a3.99 3.99 0 012.872 1.218 4.025 4.025 0 011.133 2.917 8.521 8.521 0 002.347 5.832l.817.8c.326.285.668.551 1.024.798.621.33 1.142.826 1.504 1.431a3.902 3.902 0 01-1.504 5.442c.033-.067-.063.036 0 0a8.968 8.968 0 00-3.024 3.183 9.016 9.016 0 00-1.158 4.244zM19.741 5.123c0 .796.235 1.575.676 2.237a4.01 4.01 0 001.798 1.482 3.99 3.99 0 004.366-.873 4.042 4.042 0 00.869-4.386 4.02 4.02 0 00-1.476-1.806 3.994 3.994 0 00-5.058.501 4.038 4.038 0 00-1.175 2.845zM23.748 22.84c-.792 0-1.567.236-2.226.678a4.021 4.021 0 00-1.476 1.806 4.042 4.042 0 00.869 4.387 3.99 3.99 0 004.366.873A4.01 4.01 0 0027.08 29.1a4.039 4.039 0 00-.5-5.082 4 4 0 00-2.832-1.18zM34 15.994c0-.796-.235-1.574-.675-2.236a4.01 4.01 0 00-1.798-1.483 3.99 3.99 0 00-4.367.873 4.042 4.042 0 00-.869 4.387 4.02 4.02 0 001.476 1.806 3.993 3.993 0 002.226.678 4.003 4.003 0 002.832-1.18A4.04 4.04 0 0034 15.993z M5.007 11.969c-.793 0-1.567.236-2.226.678a4.021 4.021 0 00-1.476 1.807 4.042 4.042 0 00.869 4.386 4.001 4.001 0 004.366.873 4.011 4.011 0 001.798-1.483 4.038 4.038 0 00-.5-5.08 4.004 4.004 0 00-2.831-1.181z"></path>
                </svg>
                <div className="ml-2">
                  <div className="text-3xl font-bold leading-none tracking-tight">
                    PAS
                  </div>
                  <div className="-mt-1 text-base leading-snug font-semibold">
                    Portal do AS
                  </div>
                </div>
              </div>
            </Link>

            <div className="hidden flex-grow md:flex md:gap-x-6 items-end justify-end">
              <a
                href="/docs/usuarios"
                className="inline-block rounded-lg py-1 mr-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 leading-tight"
              >
                Documentação
              </a>
              <a
                href="/blog"
                className="inline-block rounded-lg py-1 mr-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 leading-tight"
              >
                Blog
              </a>
            </div>

            <div className="flex-shrink-0 flex items-end gap-x-5 md:gap-x-8">
              {isKeycloakInitialized ? (
                !keycloak.authenticated ? (
                  <>
                    <div className="hidden md:block">
                      <Link href={kCHref} className="leading-tight py-2">
                        Registrar-se
                      </Link>
                    </div>
                    <Button
                      onClick={() => {
                        window.localStorage.removeItem("asn")
                        keycloak?.login()
                      }}
                      color="blue"
                      className="px-8 py-1.5 -mt-1"
                    >
                      <span>Entrar</span>
                    </Button>
                  </>
                ) : (
                  <>
                    <div className="hidden md:block">
                      <Link
                        href={keycloak.createLogoutUrl()}
                        className="leading-tight"
                      >
                        Desconectar
                      </Link>
                    </div>
                    <Button
                      onClick={() => {
                        window.location.href = "/"
                      }}
                      color="blue"
                      className="px-8 py-1.5"
                    >
                      <span>Dashboard</span>
                    </Button>
                  </>
                )
              ) : (
                <></>
              )}
              <div className="-mr-1 md:hidden">
                <MobileNavigation />
              </div>
            </div>
          </nav>
        </Container>
      </header>
      {/* <div className="mt-16 py-3">
        <Banner />
      </div> */}
    </>
  )
}
