import { PeriodType } from '@models/period-type'
import { ScaleTimeSpec } from '@nivo/scales'
import { MeasurementSubcategory } from '@models/measurement-subcategory'


export interface GraphicComponentFragmentType {
  xFormat: string
  xScale: ScaleTimeSpec
  axisBottom: { tickValues: string, format: string, min?: string | Date, max?: string | Date }
}

export type GraphicsDataType = {
  [key in MeasurementSubcategory]?: {
    x: string
    count: number
    y: number
  }[]
}

export function convertToUTCDate(stringDate: string): Date {
  const newDate = new Date(stringDate)
  const offset = newDate.getTimezoneOffset()
  newDate.setMinutes(newDate.getMinutes() + offset)

  return newDate;
}

export function configByPeriodAndMetric(period: PeriodType, minDate: Date, maxDate: Date, historical:boolean = false): GraphicComponentFragmentType {
  let config: GraphicComponentFragmentType = {
    xFormat: "time:%-Hh",
    xScale: { type: 'time', precision: "hour", min: minDate, max: maxDate },
    axisBottom: { tickValues: "every 2 hour", format: "%-Hh" },
  };

  switch (period) {
    case "TODAY":
      config = {
        xFormat: "time:%-Hh",
        xScale: { type: 'time', precision: "hour", min: minDate, max: maxDate },
        axisBottom: { tickValues: "every 2 hour", format: "%-Hh" },
      };
      break;
    case "YESTERDAY":
      config = {
        xFormat: "time:%-Hh",
        xScale: { type: 'time', precision: "hour", min: minDate, max: maxDate},
        axisBottom: { tickValues: "every 2 hour", format: "%-Hh" },
      };
      break;
    case "7D":
      if(historical){
        config = {
          xFormat: "time:%b %d %-Hh%-Mm",
          xScale: { type: 'time', precision: "minute" },
          axisBottom: { tickValues: "every 1 day", format: "%b %d" },
        };
      }
      else{
        config = {
          xFormat: "time:%b %d",
          xScale: { type: 'time', precision: "day" },
          axisBottom: { tickValues: "every 1 day", format: "%b %d" },
        };  
      }
      break;
    case "30D":
      config = {
        xFormat: "time:%b %d",
        xScale: { type: 'time', precision: "day" },
        axisBottom: { tickValues: "every 3 day", format: "%b %d" },
      };
      break;
    case "CURRENT_MONTH":
      config = {
        xFormat: "time:%b %d",
        xScale: { type: 'time', precision: "day", },
        axisBottom: { tickValues: "every 3 day", format: "%b %d" },
      };
      break;
    case "LAST_MONTH":
      config = {
        xFormat: "time:%b %d",
        xScale: { type: 'time', precision: "day" },
        axisBottom: { tickValues: "every 3 day", format: "%b %d" },
      };
      break;
  }

  if (period !== "TODAY" && period !== "YESTERDAY") {
    config.xScale.min = convertToUTCDate(minDate.toISOString());
    config.xScale.max = convertToUTCDate(maxDate.toISOString());
  }

  if (period === "CURRENT_MONTH") {
    // config.xScale.min = convertToUTCDate(minDate.toISOString());
    config.xScale.max = maxDate
  }

  return config;
}
