import { AutonomousSystem } from "@models/autonomous-system"
import { GraphQLResponse } from "@_types/graphql-response"
import axios from "axios"
import {
  AutonomousSystemsAction,
  SET_AVAILABLE_AUTONOMOUS_SYSTEMS,
  SET_WAITING_FOR_AVAILABLE_AUTONOMOUS_SYSTEMS_DATA,
} from "@store/autonomous-systems-store"
import { Dispatch } from "redux"
import { NavigationAction, SET_ALERT } from "@store/navigation-store"

export function availableAutonomousSystemsService(
  baseUrl: string,
  token: string,
  dispatch: Dispatch<AutonomousSystemsAction | NavigationAction>
) {
  const fetchAvailableAutonomousSystems = async () => {
    try {
      dispatch({
        type: SET_WAITING_FOR_AVAILABLE_AUTONOMOUS_SYSTEMS_DATA,
        isWaitingForData: true,
      })
      const query = await axios.post<
        GraphQLResponse<{
          availableAutonomousSystems: AutonomousSystem[] | null
        }>
      >(
        baseUrl,
        {
          query:
            `query { ` +
            `  availableAutonomousSystems { ` +
            `    asn name ` +
            `  } ` +
            `}`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (query.data.errors) {
        if (query.data.errors[0]) {
          dispatch({
            type: SET_ALERT,
            alert: {
              message_key:
                "graphql_error:" +
                JSON.parse(query.data.errors[0].message).title_key,
            },
          })
          console.log(
            `message_key: "graphql_error:" + ${
              JSON.parse(query.data.errors[0].message).title_key
            }`
          )
          return null
        }
      }
      if (query.data.data?.availableAutonomousSystems) {
        dispatch({
          type: SET_AVAILABLE_AUTONOMOUS_SYSTEMS,
          autonomousSystems: query.data.data.availableAutonomousSystems,
        })
        return query.data.data.availableAutonomousSystems
      } else {
        dispatch({
          type: SET_ALERT,
          alert: {
            message_key: "http_error:" + query.status,
          },
        })
        console.log(`message_key: "http_error:" + ${query.status}`)
        return null
      }
    } catch (error: any) {
      dispatch({
        type: SET_ALERT,
        alert: {
          message_key:
            "http_error:" + (error?.response?.status ?? error?.message),
        },
      })
      console.error("Error when fetching available autonomous systems", error)
      console.log(`message_key: "http_error:" + ${error?.response?.status}`)
      return null
    } finally {
      dispatch({
        type: SET_WAITING_FOR_AVAILABLE_AUTONOMOUS_SYSTEMS_DATA,
        isWaitingForData: false,
      })
    }
  }
  return fetchAvailableAutonomousSystems()
}
