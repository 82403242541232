import { Duration as DateDuration, isEqual, add } from "date-fns"

/**
 * Check if the two input Date | DateDuration values are equivalent.
 */
export function isEqualToDateDuration(
  a?: Date | DateDuration,
  b?: Date | DateDuration
): boolean {
  if (typeof a === "undefined" || typeof b === "undefined") {
    return a === b
  }
  if (a instanceof Date) {
    if (b instanceof Date) {
      return isEqual(a, b)
    }
    return false
  }
  if (b instanceof Date) {
    return false
  }
  return isEqual(add(0, a), add(0, b))
}
