import React, { FunctionComponent, useEffect } from "react"
import { useKeycloak } from "@react-keycloak/web"
import { graphql, useStaticQuery } from "gatsby"
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import axios from "axios"
import { ReducerAction } from "@store/index"
import { GraphQLResponse } from "@_types/graphql-response"
import {
  SET_USER_ASSOCIATIONS,
  SET_USER_ASSOCIATIONS_ERROR,
  SET_WAITING_FOR_USER_ASSOCIATIONS_DATA,
} from "@store/autonomous-systems-store"
import { ASUserAssociationMapping } from "@models/as-user-association-mapping"
import { SET_ALERT } from "@store/navigation-store"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        asMeasurementsApi: {
          baseUrl: string
        },
        asAuthorization: {
          baseUrl: string
        }
      }
    }
  }
}

const UserAssociationService: FunctionComponent = () => {
  /* GraphQL data */
  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          externalServices {
            asMeasurementsApi {
              baseUrl
            },
            asAuthorization {
              baseUrl
            }
          }
        }
      }
    }
  `)
  const { baseUrl } = data.site.siteMetadata.externalServices.asAuthorization

  /* React Hooks */
  const dispatch = useDispatch<Dispatch<ReducerAction>>()
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()
  if (!isKeycloakInitialized || !keycloak?.authenticated) {
    throw new Error(
      "Cannot use UserAssociationService without authentication"
    )
  }

  const isAdmin = keycloak.hasRealmRole("admin")

  useEffect(() => {
    // Don't fetch data for admin user
    if (isAdmin) {
      return
    }
    // Fetch new data from API
    const fetchUserAssociations = async () => {
      try {
        dispatch({
          type: SET_WAITING_FOR_USER_ASSOCIATIONS_DATA,
          isWaitingForData: true,
        })
        const query = await axios.post<
          GraphQLResponse<{ userAssociationRequest: ASUserAssociationMapping }>
        >(
          baseUrl,
          {
            query: `query {userAssociationRequest { userScopeMapsList { resource{asn name} scopes } userAssociationDateList { code asn admin_contact_email requester_email creation_date validation_date expiration_date } aSInfoList {   asn asName }}}`
          },
          {
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
            },
          }
        )
        if (query.data.errors) {
          if (query.data.errors[0]) {
            dispatch({
              type: SET_USER_ASSOCIATIONS_ERROR,
              error: JSON.parse(query.data.errors[0].message),
            })
            dispatch({
              type: SET_ALERT,
              alert: {
                message_key: "graphql_error:" + JSON.parse(query.data.errors[0].message).title_key
              },
            })
            return
          }
        }
        if (query.data.data?.userAssociationRequest) {
          dispatch({
            type: SET_USER_ASSOCIATIONS,
            userAssociationMapping: query.data.data.userAssociationRequest,
          })
        } else {
          dispatch({
            type: SET_USER_ASSOCIATIONS_ERROR,
            error: {
              title: "Erro ao obter sistemas autônomos",
              detail:
                "Ocorreu um erro desconhecido ao obter os sistemas autônomos disponíveis. Tente novamente mais tarde.",
            },
          })
          dispatch({
            type: SET_ALERT,
            alert: {
              message_key: "http_error:" + query.status
            },
          })
        }
      } catch (error: any) {
        console.error("Error when fetching ", error)
        dispatch({
          type: SET_USER_ASSOCIATIONS_ERROR,
          error: {
            title: "Erro ao obter sistemas autônomos",
            detail:
              "Não foi possível obter os sistemas autônomos disponíveis. Verifique sua conexão, " +
              "aguarde alguns minutos e recarregue a página.",
          },
        })
        dispatch({
          type: SET_ALERT,
          alert: {
            message_key: "http_error:" + (error?.response?.status ?? error?.message),
          },
        })
      }
    }
    fetchUserAssociations()
  }, [isAdmin, dispatch, baseUrl, keycloak])

  return <></>
}

export default UserAssociationService
