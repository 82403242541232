import React, {memo, FunctionComponent} from 'react';

const EmptyStateDevices:FunctionComponent = () => {
  return (
    <div className="flex flex-col">
      <h3 className="text-lg font-medium text-slate-600 mb-1">Servidor SIMET</h3>
      <p className="text-sm text-slate-500 max-w-lg">
      Seu sistema autônomo ainda não implantou o servidor de teste SIMET. 
      Instale o servidor para terminar as medições SIMET dos seus clientes na rede local, sem onerar o trânsito ou a conexão ao IX.br.
      </p>
      <p className="text-sm text-slate-500 max-w-lg mt-4">
        Saiba mais em: https://pas.nic.br/docs/servidor-simet.
      </p>
      <h3 className="text-lg font-medium text-slate-600 mb-1 mt-8">
        Downloads
      </h3>
      <ul className="mt-2 space-y-1.5 text-slate-600 text-sm">
        <li className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2 text-blue-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
              clipRule="evenodd"
            />
          </svg>
          <a
            className="text-blue-600 font-medium"
            href="https://download.simet.nic.br/medidores/isp/simet-isp.qcow2"
            target="_blank"
            rel="noreferrer"
          >
            Imagens qcow2 (KVM, Xen, OpenStack)
          </a>
        </li>
        <li className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2 text-blue-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
              clipRule="evenodd"
            />
          </svg>
          <a
            className="text-blue-600 font-medium"
            href="https://download.simet.nic.br/medidores/isp/simet-isp.vdi"
            target="_blank"
            rel="noreferrer"
          >
            Imagem vdi (Virtualbox)
          </a>
        </li>
        <li className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2 text-blue-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
              clipRule="evenodd"
            />
          </svg>
          <a
            className="text-blue-600 font-medium"
            href="https://download.simet.nic.br/medidores/isp/simet-isp.vhd"
            target="_blank"
            rel="noreferrer"
          >
            Imagem vhd (Hyper-V)
          </a>
        </li>
        <li className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2 text-blue-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
              clipRule="evenodd"
            />
          </svg>
          <a
            className="text-blue-600 font-medium"
            href="https://download.simet.nic.br/medidores/isp/simet-isp.vhdx"
            target="_blank"
            rel="noreferrer"
          >
            Imagem vhdx (Hyper-V)
          </a>
        </li>
        <li className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2 text-blue-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
              clipRule="evenodd"
            />
          </svg>
          <a
            className="text-blue-600 font-medium"
            href="https://download.simet.nic.br/medidores/isp/simet-isp.vmdk"
            target="_blank"
            rel="noreferrer"
          >
            Imagem vmdk (VMware)
          </a>
        </li>
        <li className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2 text-blue-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
              clipRule="evenodd"
            />
          </svg>
          <a
            className="text-blue-600 font-medium"
            href="https://download.simet.nic.br/medidores/isp/simet-isp_esxi.vmdk"
            target="_blank"
            rel="noreferrer"
          >
            Imagem esxi.vmdk (VMware)
          </a>
        </li>
      </ul>
    </div>
  )
}

export default memo(EmptyStateDevices)