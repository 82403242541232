import { Reducer } from "redux"
import { SimetasProbeStatus } from "@models/simetas-probe-status"
import { ProblemError } from "@models/problem-error"

/* State */

export interface SimetasProbesStatusState {
  isWaitingForData: boolean
  error: ProblemError | null
  simetasProbesStatus: SimetasProbeStatus[] | null
}

const INITIAL_STATE: SimetasProbesStatusState = {
  isWaitingForData: true,
  error: null,
  simetasProbesStatus: null,
}

/* Actions */

export const SET_SIMETAS_PROBES_STATUS =
  "SimetasProbesStatus/SET_AVAILABLE_SIMETAS_PROBES"

interface SetSimetasProbesStatusAction {
  type: typeof SET_SIMETAS_PROBES_STATUS
  simetasProbesStatus: SimetasProbeStatus[]
}

export const CLEAR_SIMETAS_PROBES_STATUS =
  "SimetasProbesStatus/CLEAR_SIMETAS_PROBES_STATUS"

interface ClearSimetasProbesStatusAction {
  type: typeof CLEAR_SIMETAS_PROBES_STATUS
}

export const SET_WAITING_FOR_SIMETAS_PROBES_STATUS_DATA =
  "SimetasProbesStatus/SET_WAITING_FOR_SIMETAS_PROBES_STATUS_DATA"

interface SetWaitingForSimetasProbesStatusAction {
  type: typeof SET_WAITING_FOR_SIMETAS_PROBES_STATUS_DATA
  isWaitingForData: boolean
}

export const SET_SIMETAS_PROBES_STATUS_ERROR =
  "SimetasProbesStatus/SET_SIMETAS_PROBES_STATUS_ERROR"

interface SetSimetasProbesStatusErrorAction {
  type: typeof SET_SIMETAS_PROBES_STATUS_ERROR
  error: ProblemError | null
}

export type SimetasProbesStatusAction =
  | SetSimetasProbesStatusAction
  | ClearSimetasProbesStatusAction
  | SetWaitingForSimetasProbesStatusAction
  | SetSimetasProbesStatusErrorAction

/* Reducer */

export const simetasProbesStatusReducer: Reducer<
  SimetasProbesStatusState,
  SimetasProbesStatusAction
> = (state = INITIAL_STATE, action) => {
  if (action.type === CLEAR_SIMETAS_PROBES_STATUS) {
    return INITIAL_STATE
  }

  if (action.type === SET_SIMETAS_PROBES_STATUS) {
    const newState: SimetasProbesStatusState = {
      ...state,
      simetasProbesStatus: action.simetasProbesStatus.sort((first, second) =>
        (first.probeID ?? "").localeCompare(second.probeID ?? "")
      ),
      // isWaitingForData: false,
      error: null,
    }
    return newState
  }

  if (action.type === SET_WAITING_FOR_SIMETAS_PROBES_STATUS_DATA) {
    const { isWaitingForData } = action
    const newState: SimetasProbesStatusState = {
      ...state,
      isWaitingForData,
    }
    return newState
  }

  if (action.type === SET_SIMETAS_PROBES_STATUS_ERROR) {
    const { error } = action
    const newState: SimetasProbesStatusState = {
      ...state,
      error,
      isWaitingForData: error ? true : state.isWaitingForData,
    }
    return newState
  }

  // Assert that all known actions are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = action
  return state
}
