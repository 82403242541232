import React, { FunctionComponent } from "react"
import { CallToAction } from "./CallToAction"
import { Faqs } from "./Faqs"
import { ValueProposition } from "./ValueProposition"
import { Features } from "./Features"
import { UseCases } from "./UseCases"
import { Solution } from "./Solution"
import { Footer } from "./Footer"
import { Header } from "./Header"

interface Props {}

export const LandingPageContent: FunctionComponent<Props> = () => {
  return (
    <>
      <Header />
      <main>
        <ValueProposition />
        <Features />
        <UseCases />
        <CallToAction />
        <Solution />
        <Faqs />
      </main>
      <Footer />
    </>
  )
}
