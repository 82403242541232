import React, { FunctionComponent } from "react"
import EmptyStateSimetAS from "./empty-state-simetas";

const EmptyStateSimetASDisponibilidade: FunctionComponent = () => {
  return (
    <div className="flex flex-col">
      <h3 className="text-lg font-medium text-slate-600 mb-1">Conexão ao IX.br</h3>
      <p className="text-sm text-slate-500 max-w-lg">
        O PAS monitora a conexão do sistema autônomo com as localidades do IX.br onde o provedor possui presença.
        Este monitoramento ocorre em layer 3, entre uma sonda na rede do provedor
        e um servidor âncora no IX.br. Com isto o PAS consegue detectar uma
        indisponibilidade de qualquer um dos enlaces no caminho entre o provedor e o IX.br.
      </p>
      <p className="text-sm text-slate-500 max-w-lg mt-4">
        O PAS realiza a medição de disponibilidade através da <span className="font-semibold">sonda de medição SIMET-AS</span>, um componente
        do servidor SIMET-ISP.
      </p>
      <EmptyStateSimetAS />
    </div>
  )
}

export default EmptyStateSimetASDisponibilidade;