import { graphql, useStaticQuery } from "gatsby"
import React, { FunctionComponent } from "react"
import { useSelector } from "react-redux"
import urljoin from "url-join"
import { ReducerState } from "@store/index"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        simetAs: {
          baseUrl: string
        }
      }
    }
  }
}

interface ReducerSelected {
  prefix?: string
}

const EmptyStateSimetAS: FunctionComponent = () => {
  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          externalServices {
            simetAs {
              baseUrl
            }
          }
        }
      }
    }
  `)

  const { baseUrl } = data.site.siteMetadata.externalServices.simetAs

  const {
    prefix,
  } = useSelector<
    ReducerState,
    ReducerSelected
  >((state) => {
    return {
      prefix: state.navigation.sidebarPrefix,
    }
  })

  return (
    <p className="text-sm text-slate-500 max-w-lg mt-4">
      {/* ATENCAO LINK DINAMICO: pas.nic.br OU pas.lab.simet.nic.br */}
      <a
        href={urljoin(baseUrl, "/management/", prefix ?? '', "/devices/servers")}
        className="text-blue-600 font-semibold" >
        Veja como instalar o servidor SIMET-ISP.
      </a>
    </p>
  )
}

export default EmptyStateSimetAS;