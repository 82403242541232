import React, { FunctionComponent } from "react"
import { KeycloakConfig } from "keycloak-js"
import { withPrefix, graphql, useStaticQuery } from "gatsby"
import { ReactKeycloakProvider } from "@react-keycloak/web"
import loadable from "@loadable/component"
import { MockKeycloakInstance } from "@utils/mock-keycloak-instance"
// import urljoin from "url-join"

const KeycloakLib: any = loadable.lib(() => import("keycloak-js"))

interface GraphQLProps {
  site: {
    siteMetadata: {
      keycloakConfig: KeycloakConfig
    }
  }
}

const KeycloakWrapper: FunctionComponent = ({ children }) => {
  /* GraphQL data */
  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          keycloakConfig {
            url
            realm
            clientId
          }
        }
      }
    }
  `)

  return (
    <KeycloakLib
      fallback={
        <ReactKeycloakProvider
          authClient={MockKeycloakInstance}
          isLoadingCheck={() => false}
        >
          {children}
        </ReactKeycloakProvider>
      }
    >
      {({ default: Keycloak }: any) => (
        <ReactKeycloakProvider
          authClient={new Keycloak(data.site.siteMetadata.keycloakConfig)}
          initOptions={{
            onLoad: "check-sso",
            silentCheckSsoRedirectUri:
              window.location.protocol +
              "//" +
              window.location.hostname +
              (window.location.port ? ":" + window.location.port : "") +
              withPrefix("/silent-check-sso.xhtml"),
            silentCheckSsoFallback: false,
            pkceMethod: "S256",
            flow: "standard",
          }}
          isLoadingCheck={() => false}
        >
          {children}
        </ReactKeycloakProvider>
      )}
    </KeycloakLib>
  )
}

export default KeycloakWrapper
