import React, { useState, useEffect, FunctionComponent, memo } from "react"

interface Props {
  percentage: number
  ringClass: string
  labelClass: string
}

const formatPercentage = (percentage: number): string => {
  // const percentage = availabilityPercentage <= 98 ? Math.round(availabilityPercentage) : Number(availabilityPercentage.toFixed(2))

  let parsePercentage = percentage.toFixed(1).replace(".0", '')

  // for 0 ou 100, no parse
  if (
    (percentage === 0 || percentage === 100) &&
    parsePercentage !== percentage.toString()) {
      return "~ " + parsePercentage
  }

  return parsePercentage
}

const RingChart: FunctionComponent<Props> = ({
  percentage,
  ringClass,
  labelClass,
}) => {
  return (
    <div className={`relative ${ringClass}`}>
      <svg className="w-full h-full" viewBox="0 0 40 40">
        <circle
          className="text-gray-100 stroke-current"
          cx="20"
          cy="20"
          r="15.91549430918954"
          fill="transparent"
          strokeWidth="4"
        ></circle>
        <circle
          style={{ strokeDasharray: `${percentage},${100 - percentage}` }}
          className={`transition-[stroke-dasharray] duration-[2000ms] stroke-current text-blue-300 saturate-[.8] origin-center`}
          cx="20"
          cy="20"
          r="15.91549430918954"
          fill="transparent"
          strokeWidth="4"
          strokeDashoffset="25"
        ></circle>
      </svg>
      <div
        className={`absolute inset-0 flex flex-col items-center justify-center ${labelClass}`}
      >
        <span className="text-xl text-slate-900 font-semibold">
          {formatPercentage(percentage)}%
        </span>
        <span className="text-base text-slate-90 leading-none">
          conectado
        </span>
      </div>
    </div>
  )
}

export default memo(RingChart)
