import React, { FunctionComponent, useState } from "react"
import { useSelector } from "react-redux"
import { AutonomousSystemScopeMapping } from "@models/autonomous-system-scope-mapping"
import { ProblemError } from "@models/problem-error"
import { ReducerState } from "@store/index"
import { AutonomousSystem } from "@models/autonomous-system"
import RequireAuthentication from "../shared/require-authentication"
import UserAssociationService from "@services/user-association-service"
import { ASUserAssociationMapping } from "@models/as-user-association-mapping"

interface Props {
  // scopeMapList: AutonomousSystemScopeMapping
}

interface ReducerSelected {
  scopeMappingList: AutonomousSystemScopeMapping[] | null
  isFetchingScopeMappings: boolean
  scopeMappingsError: ProblemError | null
  availableAutonomousSystems: AutonomousSystem[] | null
  userAssociationRequests: ASUserAssociationMapping | null
}

const ASAcceptedUserRequestTable: FunctionComponent<Props> = () => {
  /* Redux Hooks */
  const { userAssociationRequests } = useSelector<
    ReducerState,
    ReducerSelected
  >((state) => {
    return {
      scopeMappingList: state.asScopeMapping.scopeMappingList,
      isFetchingScopeMappings: state.asScopeMapping.isFetchingScopeMappings,
      scopeMappingsError: state.asScopeMapping.scopeMappingsError,
      availableAutonomousSystems:
        state.autonomousSystems.availableAutonomousSystems,
      userAssociationRequests: state.autonomousSystems.userAssociationMapping,
    }
  })

  const [setAsn] = useState<number>()
  let hasData = false
  var userScopePermissions: string[] = []
  return (
    <>
      <RequireAuthentication>
        <>
        <UserAssociationService />
        <div>
          <h3 className="text-lg font-medium text-slate-600 mb-1">
            Vínculos ativos
          </h3>
          {userAssociationRequests !== null &&
          userAssociationRequests !== undefined ? (
            <>
              {userAssociationRequests?.userScopeMapsList.map((userScope) => {
                let mergedList =
                  userAssociationRequests?.userAssociationDateList?.filter(
                    (associations) => {
                      return (
                        associations.asn === userScope.resource.asn &&
                        associations.validation_date !== null
                      )
                    }
                  ) ?? []
                mergedList.length > 0 ? (hasData = true) : (hasData = false)

                for (let i = 0; i < userScope.scopes.length; i++) {
                  switch (userScope.scopes[i]) {
                    case "as:measurements:view":
                    case "as:anomaly-detection:view":
                      userScopePermissions[i] = "Qualidade Internet: ver"
                      break
                    case "as:simet-as:view":
                      userScopePermissions[i] = "IX.br: ver"
                      break
                    case "resource-admin":
                      userScopePermissions[i] =
                        "Sistema Autônomo: ver, gerenciar"
                      break
                    default:
                      console.warn("Invalid permission. Skipping ...")
                      break
                  }
                }
                {
                  userScopePermissions = Array.from(
                    new Set(userScopePermissions)
                  )
                }
              })}
              {hasData ? (
                <>
                  <p className="text-sm text-slate-500 max-w-lg">
                    Você possui vínculo com os seguintes sistemas autônomos:
                  </p>
                  <table className="min-w-full divide-y divide-slate-300 mt-2">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-900 sm:pl-6 md:pl-0"
                        >
                          AS
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-slate-900"
                        >
                          Nome do provedor
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-slate-900"
                        >
                          Permissões
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-slate-900"
                        >
                          Data do vínculo
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-slate-200">
                      {userAssociationRequests?.userScopeMapsList?.map(
                        (userScope) => {
                          let mergedList =
                            userAssociationRequests?.userAssociationDateList?.filter(
                              (associations) => {
                                return (
                                  associations.asn === userScope.resource.asn &&
                                  associations.validation_date !== null
                                )
                              }
                            ) ?? []
                          return (
                            <tr key={JSON.stringify(userScope.resource.asn)}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-slate-900 sm:pl-6 md:pl-0">
                                {userScope.resource.asn}
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-sm text-slate-500">
                                {userScope.resource.name}
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-sm text-slate-500">
                                {Array.isArray(userScopePermissions)
                                  ? userScopePermissions.join(", ")
                                  : userScopePermissions}
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-sm text-slate-500">
                                {mergedList ? new Date(
                                  mergedList[0].validation_date
                                ).toLocaleDateString("pt-BR") :  '--'}
                              </td>
                            </tr>
                          )
                        }
                      )}
                    </tbody>
                  </table>
                </>
              ) : (
                <p className="text-sm text-slate-500 max-w-lg">
                  Você ainda não possui vínculos ativos com nenhum sistema
                  autônomo.
                </p>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        </>
      </RequireAuthentication>
    </>
  )
}
export default ASAcceptedUserRequestTable
