import React, { ReactElement, useState, useEffect, useCallback } from "react"
import { PageProps } from "gatsby"
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { Duration as DateDuration } from "date-fns"
import { ReducerAction, ReducerState } from "@store/index"
import RequireAuthentication from "@components/shared/require-authentication"
import { AutonomousSystem } from "@models/autonomous-system"
import { useAsnParameter } from "@hooks/use-asn-parameter"
import { DELETE_ALERT } from "@store/navigation-store"
import Ipfamily from "@components/filter/ipFamily-input"
import PeriodInput from "@components/filter/period-input"
import AgentComponent from "@components/filter/agentFamily"
import { useQueryParams } from "use-query-params"
import { QueryParamConfig, selectedPeriod } from "@utils/query-params"
import {
  SET_FILTER_AGENT_FAMILIES,
  SET_FILTER_IP_VERSIONS,
} from "@store/measurements-store"
import { IpFamily, IP_FAMILIES } from "@models/ip-family"
import { AgentFamily, AGENT_FAMILIES } from "@models/agent-family"
import { PeriodType } from "@models/period-type"
import TimeSeriesChartWrapper from "@components/graphics/time-series-chart-wrapper"
import MeasurementsTimeSeriesService from "@services/measurements-timeseries-service"
// import AvailableAutonomousSystemService from "@services/available-autonomous-system-service"
import {
  configByPeriodAndMetric,
  GraphicComponentFragmentType,
  convertToUTCDate,
} from "@components/graphics/utils"
import { PageDocsSidebarContentMethodology } from "@models/page-docs-sidebar-content-type"
import { MeasurementTimeSeries } from "@models/measurements/measurement-time-series"
import { shallowEqual, useSelector } from "react-redux"
import {
  MeasurementSubcategory,
  unitByMetric,
} from "@models/measurement-subcategory"
import { parseISO } from "date-fns"
import { convertUnits } from "@utils/convert-unit"

const DEFAULT_DURATION: DateDuration = { hours: 1 }

interface IndexLocationState {
  availableAutonomousSystems?: AutonomousSystem[]
  clearCursor: boolean
}

interface ReducerSelected {
  measurementsTimeSeriesList: MeasurementTimeSeries[] | null
  isWaitingForTimeSeriesData: boolean
}

const DashBoardPage: (
  _: PageProps<object, object, IndexLocationState> & {
    asn?: string
  }
) => ReactElement = ({ asn: asnString, location }) => {
  /* React Hooks */
  const asn = useAsnParameter(asnString)

  const dispatch = useDispatch<Dispatch<ReducerAction>>()
  const [_searchParams, setSearchParams] = useQueryParams(QueryParamConfig)

  const { measurementsTimeSeriesList, isWaitingForTimeSeriesData } =
    useSelector<ReducerState, ReducerSelected>((state) => {
      return {
        measurementsTimeSeriesList:
          state.measurements.measurementsTimeSeriesList,
        isWaitingForTimeSeriesData:
          state.measurements.isWaitingForTimeSeriesData,
      }
    }, shallowEqual)

  useEffect(() => {
    // Clear error
    dispatch({
      type: DELETE_ALERT,
      alert: null,
    })

    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // if (location.state?.availableAutonomousSystems !== undefined) {
    //   dispatch({
    //     type: SET_AVAILABLE_AUTONOMOUS_SYSTEMS,
    //     autonomousSystems: location.state.availableAutonomousSystems,
    //   })
    // }

    if (location.state?.clearCursor !== undefined)
      setSearchParams({ cursor: undefined })

    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const {
    periodo,
    from,
    to,
    ipFamily,
    agentId,
    agentFamily,
    valueType,
    cursor,
  } = _searchParams

  const [minDate, setMinDate] = useState<Date | DateDuration>()
  const [maxDate, setMaxDate] = useState<Date>()
  const [config, setConfig] = useState<GraphicComponentFragmentType>()
  const [isTodayOrYesterday, setIsTodayOrYesterday] = useState<boolean>(false)

  useEffect(() => {
    const [min, max] = selectedPeriod(
      (periodo as PeriodType) ?? "TODAY",
      from,
      to,
      true
    )

    const agentFamilies = agentFamily
      ?.split(",")
      .filter(Boolean)
      .map((agent) => {
        return agent as AgentFamily
      })

    setMinDate(min as Date)
    setMaxDate(max as Date)

    dispatch({
      type: SET_FILTER_IP_VERSIONS,
      filterIpVersions: (ipFamily as IpFamily) ?? IP_FAMILIES[0],
    })

    dispatch({
      type: SET_FILTER_AGENT_FAMILIES,
      filterAgentFamilies: agentFamilies ?? AGENT_FAMILIES.slice(),
    })

    setConfig(
      configByPeriodAndMetric(
        (periodo as PeriodType) ?? "TODAY",
        min as Date,
        max as Date
      )
    )

    setIsTodayOrYesterday(
      ["TODAY", "YESTERDAY"].includes((periodo as PeriodType) ?? "TODAY")
    )
  }, [
    periodo,
    from,
    to,
    ipFamily,
    agentId,
    isTodayOrYesterday,
    agentFamily,
    valueType,
    cursor,
    dispatch,
  ])

  const onFormatLines = useCallback(
    (metric: MeasurementSubcategory) => {
      console.log("rendering metrics")
      let pointY: number[] = []

      const [unit, unitExpected] = unitByMetric(metric)

      const data =
        measurementsTimeSeriesList?.map((list) => {
          let key = metric.toLowerCase() as MeasurementSubcategory

          let timesSerieslist = list.time_series[key]

          let data = timesSerieslist
            .sort(
              (a, b) => parseISO(a.time).getTime() - parseISO(b.time).getTime()
            )
            .map((series) => {
              let pointYFormated = "0"
              let _ = ""

              if (metric === "PACKET_LOSS") {
                pointYFormated = Number(series.aggregate / 10).toFixed(1)
              } else {
                ;[pointYFormated, _] = convertUnits(
                  series.aggregate.toString(),
                  unit
                )
              }

              pointY.push(Number(pointYFormated))

              return {
                x: isTodayOrYesterday
                  ? parseISO(series.time)
                  : convertToUTCDate(series.time),
                y: Number(pointYFormated),
                count: series.count,
              }
            })

          return {
            id: list.ip_family === 4 ? "IPv4" : "IPv6",
            data,
          }
        }) ?? []

      return { lines: data, pointY }
    },
    [isTodayOrYesterday, measurementsTimeSeriesList]
  )

  return (
    <RequireAuthentication>
      <div className="h-screen ">
        <div className="pt-6 px-4 sm:px-6 lg:px-8">
          {/* <AvailableAutonomousSystemService /> */}
          {asn !== undefined && periodo !== undefined && periodo !== null && (
            /* Data query/subscription services */

            <MeasurementsTimeSeriesService
              periodo={periodo as PeriodType}
              asn={asn}
              minDate={minDate}
              maxDate={maxDate}
              defaultDuration={DEFAULT_DURATION}
              setMinDate={setMinDate}
            />
          )}
          {/* Search filters */}
          <div className="flex w-full space-x-8 text-slate-700 text-sm font-medium">
            <Ipfamily />
            <AgentComponent showAgentId={false} />
            <PeriodInput />
          </div>
        </div>
        <div className="pt-6 px-4 sm:px-6 lg:px-8">
          <div className="mt-2 grid xl:grid-cols-2 gap-12">
            <TimeSeriesChartWrapper
              title="Latência"
              metric={"RTT"}
              config={config}
              isWaitingForData={isWaitingForTimeSeriesData}
              onFormatLines={onFormatLines}
            />
            <TimeSeriesChartWrapper
              title="Perda"
              metric={"PACKET_LOSS"}
              config={config}
              isWaitingForData={isWaitingForTimeSeriesData}
              onFormatLines={onFormatLines}
            />
            <TimeSeriesChartWrapper
              title="Jitter Download"
              metric={"JITTER_DOWNLOAD"}
              config={config}
              isWaitingForData={isWaitingForTimeSeriesData}
              onFormatLines={onFormatLines}
            />
            <TimeSeriesChartWrapper
              title="Jitter Upload"
              metric={"JITTER_UPLOAD"}
              config={config}
              isWaitingForData={isWaitingForTimeSeriesData}
              onFormatLines={onFormatLines}
            />
          </div>
        </div>
      </div>
    </RequireAuthentication>
  )
}

export default DashBoardPage

export const getPageSidebarContent = (): PageDocsSidebarContentMethodology => {
  return {
    title: "Serie temporal do Simet",
    abstract:
      "Entender como a qualidade da Internet dos usuários está evoluindo ao longo do tempo.",
    measurementClients: [
      "Simet Box, Simet Web, Simet Android e iOS, Simet Windows e Linux",
    ],
    methodOfMeasurement: [
      `As médições de um determinado período são agregadas e reporta-se para cada 
      intervalo o <span class="font-semibold">valor médio</span> (média aritmética).`,
      `Consultas até uma semana são agregadas por hora. Consultas a partir de uma 
      semana são agregadas por dia. E consultas a partir de um mês são agregadas por semana.`,
      `A agregação mantem em separado as medições das famílias IPv4 e IPv6.`,
    ],
    nextSteps: [
      `Analisar a qualidade da Internet em diferentes momentos e períodos.`,
      `Analisar como a qualidade da Internet varia em função do tipo de medidor. 
      (Os medidores Web e Mobile são usados predominantemente quando o usuário experimenta um problema com sua Internet. 
      Já o medidor embarcado realiza medições períodicas independentemente da condição da rede.)`,
    ],
  }
}
