import React, { ReactElement, useState, useEffect } from "react"
import RequireAuthentication from "@components/shared/require-authentication"
import { PageProps, graphql, navigate } from "gatsby"
import { useKeycloak } from "@react-keycloak/web"
import { ProblemError } from "@models/problem-error"
import { AutonomousSystem } from "@models/autonomous-system"
import {
  SELECT_AUTONOMOUS_SYSTEM,
  SET_AUTONOMOUS_SYSTEM_LIST,
  CLEAR_PERMISSION_CHANGE_LIST,
} from "@store/as-admin"
import { useDispatch, useSelector } from "react-redux"
import { Dispatch } from "redux"
import { ReducerAction, ReducerState } from "@store/index"
import axios from "axios"
import ProblemErrorComponent from "@components/shared/problem-error-component"
import { UserScopeMapping } from "@models/user-scope-mapping"
import UserPermissions from "@components/as-permissions/user-permissions"
import { buildPermissionChangesAction } from "@utils/as-permissions/build-permission-changes-action"
import { PermissionChanges } from "@models/permission-changes"
import ConfirmationModal from "@components/shared/confirmation-modal"
import { describeScope } from "@utils/describe-scope"
import {
  RESOURCE_ADMIN_SCOPE,
  ScopeDescription,
} from "@models/scope-description"
import { Transition } from "@headlessui/react"
import PageSpinner from "@components/shared/page-spinner"
import { ParsedToken } from "@models/parsed-token"
import IconSave from "@icons/icon-save.svg"
import IconCross from "@icons/icon-cross.svg"
import { GraphQLResponse } from "@_types/graphql-response"
import { useAsnParameter } from "@hooks/use-asn-parameter"
import AsAdminListService from "@services/as-admin-list-service"
import { DELETE_ALERT } from "@store/navigation-store"
// import AvailableAutonomousSystemService from "@services/available-autonomous-system-service"
import AsPermissionListService from "@services/as-permission-list-service"
import { Head } from "@components/head"
import { PageDocsSidebarContentGeneric } from "@models/page-docs-sidebar-content-type"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        asAuthorization: {
          baseUrl: string
        }
        asMeasurementsApi: {
          baseUrl: string
        }
      }
    }
  }
}

interface ReducerSelected {
  asAdminList: AutonomousSystem[] | null
  selectedAutonomousSystem: AutonomousSystem | null
  isLoadingAsAdminList: boolean
  asAdminPermissionList: UserScopeMapping[] | null
  asAdminListError: ProblemError | null
  isLoadingAsAdminPermissionList: boolean
  asAdminPermissionChanges: PermissionChanges
  hasPendingPermissionChanges: boolean
}

export interface AsPermissionsPageLocationState {
  asAdminList?: AutonomousSystem[]
}

const AsPermissionsPage: (
  _: PageProps<
    GraphQLProps,
    object,
    AsPermissionsPageLocationState | undefined
  > & { asn?: string }
) => ReactElement = ({ asn: asnString, data, location }) => {
  /* GraphQL data */
  const asAuthorizationUrl =
    data.site.siteMetadata.externalServices.asAuthorization.baseUrl

  /* React Hooks */
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()
  const [showModal, setShowModal] = useState(false)
  const [isApplyingPermissionsChanges, setApplyingPermissionsChanges] =
    useState(false)
  const [appliedPermissionChanges, setAppliedPermissionChanges] =
    useState(false)
  const [applyPermissionChangesError, setApplyPermissionChangesError] =
    useState<ProblemError>()
  const asn = useAsnParameter(asnString)

  const {
    asAdminList,
    selectedAutonomousSystem,
    isLoadingAsAdminList,
    asAdminPermissionList,
    asAdminListError,
    isLoadingAsAdminPermissionList,
    asAdminPermissionChanges,
    hasPendingPermissionChanges,
  } = useSelector<ReducerState, ReducerSelected>((state) => {
    return {
      asAdminList: state.asAdmin.asAdminList,
      selectedAutonomousSystem: state.asAdmin.selectedAutonomousSystem,
      isLoadingAsAdminList: state.asAdmin.isLoadingAsAdminList,
      asAdminPermissionList: state.asAdmin.asAdminPermissionList,
      asAdminListError: state.asAdmin.asAdminListError,
      isLoadingAsAdminPermissionList:
        state.asAdmin.isLoadingAsAdminPermissionList,
      asAdminPermissionChanges: state.asAdmin.asAdminPermissionChanges,
      hasPendingPermissionChanges: state.asAdmin.hasPendingPermissionChanges,
    }
  })
  const dispatch = useDispatch<Dispatch<ReducerAction>>()

  useEffect(() => {
    // Clear error
    dispatch({
      type: DELETE_ALERT,
      alert: null,
    })

    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.state?.asAdminList && !asAdminList) {
      dispatch({
        type: SET_AUTONOMOUS_SYSTEM_LIST,
        autonomousSystemList: location.state.asAdminList,
      })
    }
    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, asAdminList])

  const isAdmin = keycloak?.hasRealmRole("admin")

  useEffect(() => {
    const adminList = asAdminList ?? location.state?.asAdminList
    if (asn) {
      if (isAdmin && asn !== selectedAutonomousSystem?.asn) {
        dispatch({
          type: SELECT_AUTONOMOUS_SYSTEM,
          autonomousSystem: { asn, name: "" },
        })
        return
      }
      const autonomousSystem = adminList?.find(
        (autonomousSystem) => autonomousSystem.asn === asn
      )
      if (!autonomousSystem && asAdminList && !isLoadingAsAdminList) {
        navigate("/" + selectedAutonomousSystem?.asn + "/user-permissions/", {
          state: location.state,
        })
      } else if (autonomousSystem) {
        dispatch({
          type: SELECT_AUTONOMOUS_SYSTEM,
          autonomousSystem,
        })
        return
      }
    } else {
      dispatch({
        type: SELECT_AUTONOMOUS_SYSTEM,
        autonomousSystem: null,
      })
      return
    }
    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location,
    asn,
    isAdmin,
    selectedAutonomousSystem,
    asAdminList,
    isLoadingAsAdminList,
  ])

  const permChangesAction =
    asAdminPermissionList &&
    buildPermissionChangesAction(
      asAdminPermissionList,
      asAdminPermissionChanges
    )

  const applyPermissionChanges = async () => {
    setApplyPermissionChangesError(undefined)
    if (!keycloak.authenticated) {
      // console.log('applyPermissionChanges not authenticated', keycloak);
      setApplyPermissionChangesError({
        detail: "Usuário não autenticado.",
      })
      let href = window.location.href
      if (!window.location.pathname.endsWith("/")) {
        href = `${window.location.origin}${window.location.pathname}/${window.location.search}${window.location.hash}`
      }
      keycloak.login({ redirectUri: href })
      return
    }
    if (
      !permChangesAction ||
      (!permChangesAction.addAdmin &&
        !permChangesAction.removeAdmin &&
        !permChangesAction.addRegularScope &&
        !permChangesAction.removeRegularScope)
    ) {
      // console.log('applyPermissionChanges no permission changes in action');
      setApplyPermissionChangesError({
        detail: "Não há alterações de permissão pendentes.",
      })
      return
    }

    try {
      setApplyingPermissionsChanges(true)
      const response = await axios.post<
        GraphQLResponse<{ changeAsPermissions: boolean }>
      >(
        `${asAuthorizationUrl}`,
        {
          query:
            `` +
            `mutation ($asn: Int!, $addAdmin: [String!], $removeAdmin: [String!], $addRegularScope: [UserScopesMappingInput!], $removeRegularScope: [UserScopesMappingInput!]) { ` +
            `  changeAsPermissions(asn: $asn, addAdmin: $addAdmin, removeAdmin: $removeAdmin, addRegularScope: $addRegularScope, removeRegularScope: $removeRegularScope) ` +
            `}`,
          variables: {
            asn,
            addAdmin: permChangesAction.addAdmin,
            removeAdmin: permChangesAction.removeAdmin,
            addRegularScope: Object.entries(
              permChangesAction.addRegularScope
            ).map(([k, v]) => ({ user: k, scopes: v })),
            removeRegularScope: Object.entries(
              permChangesAction.removeRegularScope
            ).map(([k, v]) => ({ user: k, scopes: v })),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        }
      )
      if (response.data.errors) {
        if (response.data.errors[0]) {
          setApplyPermissionChangesError(
            JSON.parse(response.data.errors[0].message)
          )
          return
        } else {
          throw new Error("Unknown error")
        }
      }
      if (response.data.data?.changeAsPermissions) {
        setAppliedPermissionChanges(true)
      } else {
        setApplyPermissionChangesError({
          title: "Erro ao aplicar permissões",
          detail: "Ocorreu um erro desconhecido. Tente novamente mais tarde.",
        })
      }
    } catch (err: any) {
      // console.log('applyPermissionChanges error', err);
      if (
        err.response?.data?.errors &&
        err.response?.data?.errors[0]?.message
      ) {
        setApplyPermissionChangesError(
          JSON.parse(err.response.data.errors[0].message)
        )
      } else {
        setApplyPermissionChangesError({
          title: "Erro ao aplicar permissões",
          detail:
            "Ocorreu um erro de conexão. Verifique sua Internet e tente novamente mais tarde.",
        })
      }
    } finally {
      setApplyingPermissionsChanges(false)
    }
  }

  const currentUsername = (keycloak.tokenParsed as ParsedToken)
    ?.preferred_username

  const dialogMessage = permChangesAction ? (
    <>
      <p>As seguintes alterações de permissão serão aplicadas:</p>
      <ul className="my-2 ml-4 sm:ml-2 text-left list-disc list-inside">
        {permChangesAction.addAdmin.map((user) => (
          <li key={user}>
            <span className="text-slate-700 font-bold">{user}</span> se tornará
            um(a) administrador(a).
          </li>
        ))}
        {permChangesAction.removeAdmin.map((user) => (
          <li key={user}>
            <span className="text-slate-700 font-bold">{user}</span> deixará de
            ser um(a) administrador(a).
          </li>
        ))}
        {Object.entries(permChangesAction.addRegularScope).map(
          ([user, scopes]) => (
            <li key={user}>
              <span className="text-slate-700 font-bold">{user}</span> receberá{" "}
              {scopes.length > 1 ? "as permissões" : "a permissão"}:{" "}
              {(scopes.map(describeScope) as ScopeDescription[])
                .map((desc) => desc.name)
                .sort()
                .join("; ")}
              .
            </li>
          )
        )}
        {Object.entries(permChangesAction.removeRegularScope).map(
          ([user, scopes]) => (
            <li key={user}>
              <span className="text-slate-700 font-bold">{user}</span> deixará
              de ter {scopes.length > 1 ? "as permissões" : "a permissão"}:{" "}
              {(scopes.map(describeScope) as ScopeDescription[])
                .map((desc) => desc.name)
                .sort()
                .join("; ")}
              .
            </li>
          )
        )}
      </ul>
      {permChangesAction.addAdmin.length === 0 &&
      asAdminPermissionList &&
      asAdminPermissionList.filter(
        (mapping) =>
          mapping.scopes.includes(RESOURCE_ADMIN_SCOPE) &&
          !permChangesAction.removeAdmin.includes(mapping.user)
      ).length === 0 ? (
        <p className="font-semibold mb-1 text-red-700">
          {isAdmin
            ? "Aviso: O Sistema Autônomo não terá mais administradores."
            : "Aviso: O Sistema Autônomo não terá mais administradores, incluindo você."}
        </p>
      ) : (
        !isAdmin &&
        currentUsername &&
        permChangesAction.removeAdmin.includes(currentUsername) && (
          <p className="font-semibold mb-1 text-red-700">
            Aviso: Você deixará de ter acesso de administração deste Sistema
            Autônomo.
          </p>
        )
      )}
      <p>Deseja prosseguir?</p>
    </>
  ) : (
    <p>As alterações de permissão serão aplicadas. Deseja prosseguir?</p>
  )
  return (
    <>
      <Head asn={0} />
      <RequireAuthentication>
        <>
          {/* {isKeycloakInitialized && keycloak.authenticated && (
            <AvailableAutonomousSystemService />
          )} */}
          <div className="py-8 px-4 sm:px-6 lg:px-8 h-full">
            <div className="max-w-3xl mt-2">
              {/* Autonomous System admin list service */}
              {!isAdmin && <AsAdminListService />}
              {/* Autonomous System permission admin body */}
              <div className="pb-32">
                {asAdminListError && (
                  <ProblemErrorComponent
                    problemError={asAdminListError}
                    titleClassName="text-3xl text-red-600 font-semibold mb-3"
                    detailClassName="text-red-600"
                  />
                )}
                {!isAdmin &&
                  (isLoadingAsAdminList ||
                    (asn && isLoadingAsAdminPermissionList)) && (
                    <div className="py-2 text-blue-600">
                      <PageSpinner />
                    </div>
                  )}
                {!isAdmin &&
                  !isLoadingAsAdminList &&
                  asAdminList &&
                  !selectedAutonomousSystem && (
                    <div className="w-full px-6 text-2xl font-bold text-center text-slate-900 leading-tight">
                      {asAdminList.length > 0
                        ? "Selecione um Sistema Autônomo para visualizar as permissões."
                        : "Você não possui acesso de administração a nenhum Sistema Autônomo."}
                    </div>
                  )}
                {selectedAutonomousSystem && (
                  <>
                    {/* AS permissions service */}
                    <AsPermissionListService />
                    {/* AS permissions body */}
                    {!isLoadingAsAdminPermissionList && <UserPermissions />}
                  </>
                )}
                {
                  <Transition
                    show={hasPendingPermissionChanges}
                    enter="transition ease-in-out duration-200 transform"
                    enterFrom="translate-y-full opacity-0"
                    enterTo="translate-y-0 opacity-1"
                    leave="transition ease-in-out duration-200 transform"
                    leaveFrom="translate-y-0 opacity-1"
                    leaveTo="translate-y-full opacity-0"
                    className="flex flex-row-reverse items-center fixed bottom-0 right-0 pb-12 mr-8 sm:mr-12"
                  >
                    <button
                      className="ml-4 h-16 w-16 bg-green-600 rounded-full shadow-lg focus:outline-none focus:ring"
                      onClick={() => setShowModal(true)}
                    >
                      <IconSave className="h-8 w-8 mx-auto text-green-100" />
                    </button>
                    <button
                      className="h-12 w-12 bg-slate-100 rounded-full shadow-lg focus:outline-none focus:ring"
                      onClick={() =>
                        dispatch({ type: CLEAR_PERMISSION_CHANGE_LIST })
                      }
                    >
                      <IconCross className="h-6 w-6 mx-auto text-slate-800" />
                    </button>
                  </Transition>
                }
              </div>
            </div>
            <ConfirmationModal
              onCloseModal={() => {
                if (appliedPermissionChanges) {
                  window.location.reload()
                } else {
                  setShowModal(false)
                }
              }}
              onConfirmRequest={() => applyPermissionChanges()}
              showModal={showModal}
              isBusy={isApplyingPermissionsChanges}
              isDone={appliedPermissionChanges}
              dialogTitle={"Aplicar alterações de permissão"}
              dialogMessage={dialogMessage}
              successMessage={
                "Permissões aplicadas com sucesso! A página será recarregada."
              }
              confirmationError={applyPermissionChangesError}
            />
          </div>
        </>
      </RequireAuthentication>
    </>
  )
}

export default AsPermissionsPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        externalServices {
          asAuthorization {
            baseUrl
          }
          asMeasurementsApi {
            baseUrl
          }
        }
      }
    }
  }
`

export const getPageSidebarContent = (): PageDocsSidebarContentGeneric => {
  return {
    title: "Permissões dos Usuários",
    abstract:
      "Gerenciar permissões e vínculos de usuários com o sistema autônomo",
    content: (
      <div>
        <p className="leading-snug mt-2">
          <ul className="space-y-2">
            <li>
              Gerenciar permissões e vínculos de usuários com o sistema
              autônomo.
            </li>
            <li>
              Para desvincular um usuário permanentemente do sistema autônomo,
              basta clicar no ícone ao lado do nome do usuário.
            </li>
          </ul>
        </p>
      </div>
    ),
  }
}
