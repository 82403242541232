export const convertUnits = (value: string | undefined, unit: string | undefined): [string, string] => {
  if(value === undefined || unit === undefined) {
    return ["--", ""]
  }

  let formatedValue: number = Number(value)

  switch(unit){
    case "μs":
      formatedValue *= 0.001
      return [formatedValue.toFixed(1), "ms"]
    case "bit/s":
      formatedValue *= 1e-06
      return [formatedValue.toFixed(1), "Mb/s"]
    case "%":
      return [formatedValue.toString(), unit]
    default:
      return [formatedValue.toFixed(1), unit]
  }
}