import React, { FunctionComponent } from "react"
import { ProblemError } from "@models/problem-error"
import { parse as parseQuery } from "query-string"

interface Props {
  problemError: ProblemError
  titleClassName?: string
  detailClassName?: string
}

function formatStringBuilder(
  problemErrorInstance?: string
): (_: string) => string {
  const parsedQuery =
    (problemErrorInstance && parseQuery(problemErrorInstance)) || undefined
  if (!parsedQuery) {
    return (format: string) => format
  }
  return (format: string) =>
    Object.entries(parsedQuery).reduce((acc, [key, value]) => {
      if (value) {
        if (typeof value !== "string") {
          value = value[0]
        }
        return acc.replaceAll(`$\{${key}}`, value)
      }
      return acc
    }, format)
}

const ProblemErrorComponent: FunctionComponent<Props> = ({
  problemError,
  titleClassName,
  detailClassName,
}: Props) => {
  const formatString: (_: string) => string = formatStringBuilder(
    problemError?.instance
  )

  return (
    <>
      {problemError?.title && (
        <div className={titleClassName}>{formatString(problemError.title)}</div>
      )}
      {problemError?.detail && (
        <div className={detailClassName}>
          {formatString(problemError.detail)}
        </div>
      )}
    </>
  )
}

export default ProblemErrorComponent
