import React, { Dispatch, FunctionComponent, useRef, useEffect } from "react"
import { GraphQLResponse } from "@_types/graphql-response"
import { useKeycloak } from "@react-keycloak/web"
import axios, { CancelTokenSource } from "axios"
import { graphql, useStaticQuery } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { ReducerAction, ReducerState } from "@store/index"
import {
  SET_WAITING_FOR_ANTISPOOFING_HISTORY_DATA,
  SET_ANTISPOOFING_HISTORY,
} from "@store/anti-spoofing-store"
import { Duration as DateDuration, isBefore, formatISO } from "date-fns"
import { getQueryDateInterval } from "@utils/get-query-date-interval"
import { isEqualToDateDuration } from "@utils/is-equal-to-date-duration"
import { deserializeAntiSpoofingHistory } from "../serdes/deserialize-antispoofing"
import { SerializedAntiSpoofingHistoryNode } from "@models/serialized/serialized-simet-as"
import {
  AntiSpoofingType,
  desearializeAntiSpoofing,
} from "@models/anti-spoofing-type"
import { SET_ALERT, SET_PAGINATION } from "@store/navigation-store"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        asSimetasApi: {
          baseUrl: string
        }
      }
    }
  }
}

interface Props {
  asn: number
  minDate?: Date | DateDuration
  maxDate?: Date
  defaultDuration?: DateDuration
  setMinDate?: (_?: Date | DateDuration) => void
}

interface ReducerSelected {
  filterAgentId: string | null
  filterAntiSpoofing: string | null
  filterCursor: string | null
}

const AntiSpoofingHistoryService: FunctionComponent<Props> = ({
  asn,
  minDate,
  maxDate,
  defaultDuration,
  setMinDate,
}) => {
  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          externalServices {
            asSimetasApi {
              baseUrl
            }
          }
        }
      }
    }
  `)
  const { baseUrl } = data.site.siteMetadata.externalServices.asSimetasApi

  /* React Hooks */
  const dispatch = useDispatch<Dispatch<ReducerAction>>()
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()
  const prevAsn = useRef<number>(0)
  const prevFilterAgentId = useRef<string | null>(null)
  const prevFilterAntiSpoofing = useRef<string | null>(null)
  const prevFilterCursor = useRef<string | null>(null)
  const prevMinDate = useRef<Date | DateDuration | undefined>(minDate)
  const prevMaxDate = useRef<Date | undefined>(maxDate)
  const cancelToken = useRef<CancelTokenSource>()
  const requestId = useRef<number>(0)
  const firstCall = useRef(true)

  const { filterAgentId, filterAntiSpoofing, filterCursor } = useSelector<
    ReducerState,
    ReducerSelected
  >((state) => {
    return {
      filterAgentId: state.simetAS.filterAgentId,
      filterAntiSpoofing: state.simetAS.filterAntiSpoofing,
      filterCursor: state.navigation.filterCursor,
    }
  })

  if (!isKeycloakInitialized || !keycloak?.authenticated) {
    throw new Error(
      "Cannot use AntiSpoofingHistoryService without authentication"
    )
  }

  const isAdmin = keycloak.hasRealmRole("admin")

  useEffect(() => {
    // Check if ASN changed or if it is the initial data population
    if (
      filterAntiSpoofing !== null &&
      (firstCall.current ||
        prevAsn.current !== asn ||
        prevFilterAgentId.current !== filterAgentId ||
        prevFilterAntiSpoofing.current !== filterAntiSpoofing ||
        prevFilterCursor.current !== filterCursor ||
        prevMinDate.current !== minDate ||
        prevMaxDate.current !== maxDate)
    ) {
      // Abort any existing request
      cancelToken.current?.cancel("AntiSpoofingHistoryService request canceled")
      cancelToken.current = undefined
      // Fetch new data from API
      if (
        (prevFilterAgentId.current !== null &&
          prevFilterAgentId.current !== filterAgentId) ||
        (prevFilterCursor.current !== null &&
          prevFilterCursor.current !== filterCursor) ||
        (prevFilterAntiSpoofing.current !== null &&
          prevFilterAntiSpoofing.current !== filterAntiSpoofing) ||
        prevAsn.current !== asn ||
        !isEqualToDateDuration(prevMinDate.current, minDate) || // TODO: Evaluate specificity on minDate?
        !isEqualToDateDuration(prevMaxDate.current, maxDate) || // TODO: Evaluate specificity on maxDate?
        firstCall.current
      ) {
        // dispatch({
        //   type: SET_ANTISPOOFING_HISTORY_ERROR,
        //   errorHistory: null,
        // })
        ;(async () => {
          const [queryMinDate, queryMaxDate] = getQueryDateInterval(
            minDate ?? defaultDuration,
            maxDate
          )
          if (
            setMinDate &&
            minDate instanceof Date &&
            isBefore(queryMinDate, minDate)
          ) {
            prevMinDate.current = undefined // Avoid refetching on the next effect update
            setMinDate(undefined)
          }

          const currentRequestId = requestId.current + 1
          requestId.current = currentRequestId
          try {
            dispatch({
              type: SET_WAITING_FOR_ANTISPOOFING_HISTORY_DATA,
              isWaitingForHistoryData: true,
            })
            const newCancelToken = axios.CancelToken.source()
            cancelToken.current = newCancelToken
            const query = await axios.post<
              GraphQLResponse<{
                antispoofingHistory: SerializedAntiSpoofingHistoryNode
              }>
            >(
              baseUrl,
              {
                query:
                  `query($asn: Int!, $minDate: DateTime!, $maxDate: DateTime!, ` +
                  `$antispoofingFiltering: [AntiSpoofingFilteringType!], $agentID: UUID, $cursor: String){` +
                  `antispoofingHistory(asn: $asn, minDate: $minDate, maxDate: $maxDate,antispoofingFiltering: $antispoofingFiltering, agentID:$agentID,cursor: $cursor){` +
                  `pageCursors{` +
                  `previous{` +
                  `cursor` +
                  `}` +
                  `next{` +
                  `cursor` +
                  `}` +
                  `current{` +
                  `cursor` +
                  `}` +
                  `}` +
                  `node{` +
                  `agentID datetime asn ipPublicFiltered ipPrivateFiltered` +
                  `}` +
                  `}` +
                  `}`,
                variables: {
                  asn,
                  minDate: formatISO(queryMinDate),
                  maxDate: formatISO(queryMaxDate),
                  agentID: filterAgentId !== "" ? filterAgentId : null,
                  antispoofingFiltering: desearializeAntiSpoofing(
                    filterAntiSpoofing as AntiSpoofingType
                  ),
                  cursor: filterCursor !== "" ? filterCursor : null,
                },
                cancelToken: newCancelToken.token,
              },
              {
                headers: {
                  Authorization: `Bearer ${keycloak.token}`,
                },
              }
            )
            if (query.data.errors) {
              if (query.data.errors[0]) {
                dispatch({
                  type: SET_ALERT,
                  alert: {
                    message_key:
                      "graphql_error:" +
                      JSON.parse(query.data.errors[0].message).title_key,
                  },
                })
                return
              }
            }
            if (requestId.current !== currentRequestId) {
              return
            }
            if (query.data.data?.antispoofingHistory) {
              // paginação
              dispatch({
                type: SET_PAGINATION,
                pagination: query.data.data.antispoofingHistory?.pageCursors,
              })

              dispatch({
                type: SET_ANTISPOOFING_HISTORY,
                antiSpoofingHistory:
                  query.data.data.antispoofingHistory?.node?.map(
                    deserializeAntiSpoofingHistory
                  ),
              })
            } else {
              dispatch({
                type: SET_ALERT,
                alert: {
                  message_key: "http_error:" + query.status,
                },
              })
            }
          } catch (error) {
            if (axios.isCancel(error)) {
              console.log("SimetasProbesService request canceled")
              return
            }
            if (requestId.current !== currentRequestId) {
              return
            }
            console.error("Error when fetching SIMET-AS probes", error)
            dispatch({
              type: SET_ALERT,
              alert: {
                message_key:
                  "http_error:" + (error?.response?.status ?? error?.message),
              },
            })
          } finally {
            dispatch({
              type: SET_WAITING_FOR_ANTISPOOFING_HISTORY_DATA,
              isWaitingForHistoryData: false,
            })
          }
        })()
      }
      firstCall.current = false
      prevAsn.current = asn
      prevMinDate.current = minDate
      prevMaxDate.current = maxDate
      prevFilterAgentId.current = filterAgentId
      prevFilterAntiSpoofing.current = filterAntiSpoofing
      prevFilterCursor.current = filterCursor
    }
  }, [
    isAdmin,
    dispatch,
    baseUrl,
    keycloak,
    asn,
    filterAgentId,
    filterAntiSpoofing,
    filterCursor,
    minDate,
    maxDate,
    defaultDuration,
    setMinDate,
  ])

  // Cleanup
  useEffect(() => {
    return () => {
      // Abort any current request
      requestId.current = 0
      cancelToken.current?.cancel("antiSpoofingHistory request canceled")
    }
  }, [dispatch])

  return <></>
}

export default AntiSpoofingHistoryService
