import {
  SerializedHistoryMeasurement,
  SerializedMeasurement,
} from "../models/serialized/serialized-simet-as"
import { Measurement, MeasurementHistory } from "../models/simet-as"
import { parseDate } from "@utils/query-params"

import { sortSimetASIpFamily as sortMeasurements } from "@utils/simet-as/sort-simetas"

export function deserializeMeasurementHistory(
  data: SerializedHistoryMeasurement
): MeasurementHistory {
  return {
    ...data,
    ...sortMeasurements(data.ipFamilies, "IP_VERSION", true),
    startDate: parseDate(data.startDate),
    endDate: parseDate(data.endDate),
  }
}

export function deserializeMeasurement(
  data: SerializedMeasurement
): Measurement {
  return {
    ...data,
  }
}
