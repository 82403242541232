import React, { ReactElement, useState, useEffect } from "react"
import Helmet from "react-helmet"
import { PageProps } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { Duration as DateDuration } from "date-fns"
import { ReducerAction, ReducerState } from "@store/index"
import RequireAuthentication from "@components/shared/require-authentication"
import { DELETE_ALERT } from "@store/navigation-store"
import MeasurementsService from "@services/simet-as-service"
import { AutonomousSystem } from "@models/autonomous-system"
import { useAsnParameter } from "@hooks/use-asn-parameter"
import SimetasProbesService from "@services/simetas-probes-service"
import PeriodInput from "@components/filter/period-input"
import CardIxComponent from "@components/shared/cardIx"
import { useQueryParams } from "use-query-params"
import { QueryParamConfig, selectedPeriod } from "@utils/query-params"
import { SET_FILTER_PROBE_ID } from "@store/simetas-store"
import SimetasProbeSelector from "@components/controls/simetas-probe-selector"
// import AvailableAutonomousSystemService from "@services/available-autonomous-system-service"
import { SimetasProbe } from "@models/simetas-probe"
import { PeriodType, PERIOD_INTERVAL } from "@models/period-type"
import EmptyStateSimetASDisponibilidade from "@components/shared/empty-state-simetas-disponibilidade"
import PageSpinner from "@components/shared/page-spinner"
import { PageDocsSidebarContentMethodology } from "@models/page-docs-sidebar-content-type"

export interface IndexLocationState {
  availableAutonomousSystems: AutonomousSystem[]
}

interface ReducerSelected {
  isWaitingForData: boolean
  availableAutonomousSystems: AutonomousSystem[] | null
  simetasProbes: SimetasProbe[] | null
  isWaitingForDataSimetasProbes: boolean
  isWaitingForMeasurementHistoryData: boolean
  isWaitingForMeasurementData: boolean
}

const AsnPage: (
  _: PageProps<object, object, IndexLocationState> & {
    asn?: string
  }
) => ReactElement = ({ asn: asnString, location }) => {
  const asn = useAsnParameter(asnString)
  const dispatch = useDispatch<Dispatch<ReducerAction>>()

  useEffect(() => {
    // Clear error
    dispatch({
      type: DELETE_ALERT,
      alert: null,
    })

    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    isWaitingForData,
    availableAutonomousSystems,
    simetasProbes,
    isWaitingForDataSimetasProbes,
    isWaitingForMeasurementHistoryData,
    isWaitingForMeasurementData,
  } = useSelector<ReducerState, ReducerSelected>((state) => {
    return {
      isWaitingForData: state.autonomousSystems.isWaitingForData,
      availableAutonomousSystems:
        state.autonomousSystems.availableAutonomousSystems,
      simetasProbes: state.simetasProbes.simetasProbes,
      isWaitingForDataSimetasProbes: state.simetasProbes.isWaitingForData,
      isWaitingForMeasurementHistoryData: state.simetAS.isWaitingForHistoryData,
      isWaitingForMeasurementData: state.simetAS.isWaitingForData,
    }
  })

  const [_searchParams, setSearchParams] = useQueryParams(QueryParamConfig)

  const { periodo, from, to, probeId } = _searchParams

  const [minDate, setMinDate] = useState<Date | DateDuration>()
  const [maxDate, setMaxDate] = useState<Date>()

  useEffect(() => {
    const [min, max] = selectedPeriod(
      (periodo as PeriodType) ?? "YESTERDAY",
      from,
      to,
      true
    )

    setMinDate(min as Date)
    setMaxDate(max as Date)

    dispatch({
      type: SET_FILTER_PROBE_ID,
      filterProbeId: probeId ?? null,
    })
  }, [periodo, from, to, probeId])

  const DEFAULT_DURATION: DateDuration = { days: 7 }

  return (
    <>
      <Helmet>
        <title>PAS - Conexão ao IX.br</title>
      </Helmet>
      <RequireAuthentication>
        <div className="pt-6 px-4 sm:px-6 lg:px-8 h-full">
          <div className="contents">
            {/* <AvailableAutonomousSystemService /> */}
            {asn && (
              <>
                <SimetasProbesService asn={asn} />
                <MeasurementsService
                  minDate={minDate}
                  maxDate={maxDate}
                  defaultDuration={DEFAULT_DURATION}
                  setMinDate={setMinDate}
                  asn={asn}
                />

                {(isWaitingForDataSimetasProbes || isWaitingForData) && (
                  <PageSpinner />
                )}

                {!isWaitingForDataSimetasProbes &&
                !isWaitingForData &&
                !isWaitingForMeasurementHistoryData &&
                !isWaitingForMeasurementData &&
                simetasProbes &&
                simetasProbes.length == 0 ? (
                  <EmptyStateSimetASDisponibilidade />
                ) : (
                  simetasProbes &&
                  simetasProbes.length > 0 && (
                    <>
                      {/* Data query service measurement  */}

                      {/* Dados atuais */}
                      <div className="mb-6 text-xl font-medium text-slate-600">
                        Status Atual da Conexão
                      </div>
                      <div className="flex flex-wrap -mr-16 -mb-16">
                        <CardIxComponent isHistorical={false} />
                      </div>

                      {/* Dados Historicos */}
                      <div className="text-xl font-medium text-slate-600 mb-6 mt-12">
                        Histórico da Conexão{" "}
                        <span className="text-base font-medium text-slate-500">
                          (até ontem)
                        </span>
                      </div>

                      <div className="flex space-x-8 mb-8">
                        <SimetasProbeSelector />
                        <PeriodInput
                          period={PERIOD_INTERVAL.slice().filter(
                            (period) => period !== "TODAY"
                          )}
                        />
                      </div>
                      <div className="flex flex-wrap -mr-16 -mb-16">
                        {!isWaitingForMeasurementHistoryData ? (
                          <CardIxComponent isHistorical={true} />
                        ) : (
                          <PageSpinner />
                        )}
                      </div>
                    </>
                  )
                )}
              </>
            )}
          </div>
        </div>
      </RequireAuthentication>
    </>
  )
}

export default AsnPage

export const getPageSidebarContent = (): PageDocsSidebarContentMethodology => {
  return {
    title: "Conexão ao IX.br",
    abstract:
      "Monitorar a conexão ao IX.br nas localidades onde o provedor tiver presença.",
    measurementClients: ["Simet-AS"],
    methodOfMeasurement: [
      `O monitoramento da conexão é feita com uma conexão UDP`,
      `A medição é capaz de detectar filtragens de pacotes quando são implementadas na rede do provedor entre o medidor e o roteador de borda.
      Como a sonda de medição SIMET-AS é instalada dentro da rede do provedor, não se detecta uma eventual filtragem implementada na rede de acesso.
      Para detectar filtragem antispoofing em toda extensão da rede, futuramente a medicão será realizada pelo medidor SimetBox.`,
    ],
    nextSteps: [
      `Aprender a <a href="https://bcp.nic.br/antispoofing" target="_blank" class="text-blue-600 font-medium cursor-pointer hover:underline" >configurar filtros antispoofing</a> em roteadores MikroTik, Juniper e Cisco.`,
    ],
  }
}
