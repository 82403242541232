import { PaginationBar } from "@models/pagination"
import { ProblemError } from "@models/problem-error"
import { Alert } from "@models/alert"
import { Reducer } from "redux"
import {v4 as uuid} from "uuid";

/* State */

export interface NavigationState {
  currentApp?: "measurements" | "simet-as" | "settings" | "autonomous-system" | number | undefined
  currentPage?: "/" | "/asns" | "/landing-page" | "/user-as-associations" | "/users/permissions" |
    "/compliance/antispoofing" | "/dashboard" | "/user/autonomous-systems" | "/dashboard/timeseries" |
    "/measurements/measurements" | "/account/autonomous-systems" | "/ixbr/connection" | "/devices/servers"
  currentPageScope?: "global-scoped" | "as-scoped"
  selectedAsn?: number
  isSidebarExtended: boolean
  sidebarPrefix?: string
  sidebarSuffix?: string
  pagination?: PaginationBar
  currentCategory?: string
  filterCursor: string | null
  currentSubCategory?: string
  alertList: Alert[]
}

const INITIAL_STATE: NavigationState = {
  isSidebarExtended: false,
  filterCursor: null,
  alertList: []
}

/* Actions */

export const CLEAR_NAVIGATION_DATA = "navigation/CLEAR_NAVIGATION_DATA"

interface ClearAvailableAutonomousSystemsAction {
  type: typeof CLEAR_NAVIGATION_DATA
}

export const SET_CURRENT_APP = "navigation/SET_CURRENT_APP"

interface SetCurrentAppAction {
  type: typeof SET_CURRENT_APP
  currentApp?: NavigationState["currentApp"]
}

export const SET_CURRENT_PAGE = "navigation/SET_CURRENT_PAGE"

interface SetCurrentPageAction {
  type: typeof SET_CURRENT_PAGE
  currentPage?: NavigationState["currentPage"]
}

export const SET_CURRENT_PAGE_SCOPE = "navigation/SET_CURRENT_PAGE_SCOPE"

interface SetCurrentPageScopeAction {
  type: typeof SET_CURRENT_PAGE_SCOPE
  currentPageScope?: NavigationState["currentPageScope"]
}

export const SET_SELECTED_ASN = "navigation/SET_SELECTED_ASN"

interface SetSelectedAsnAction {
  type: typeof SET_SELECTED_ASN
  selectedAsn?: number
}

export const SET_SIDEBAR_EXTENDED = "navigation/SET_SIDEBAR_EXTENDED"

interface SetSidebarExtendedAction {
  type: typeof SET_SIDEBAR_EXTENDED
  isSidebarExtended: boolean
}

export const SET_SIDEBAR_PREFIX = "navigation/SET_SIDEBAR_PREFIX"

interface SetSidebarPrefixAction {
  type: typeof SET_SIDEBAR_PREFIX
  sidebarPrefix?: string
}

export const SET_SIDEBAR_SUFFIX = "navigation/SET_SIDEBAR_SUFFIX"

interface SetSidebarSuffixAction {
  type: typeof SET_SIDEBAR_SUFFIX
  sidebarSuffix?: string
}

export const SET_CURRENT_CATEGORY = "navigation/SET_CURRENT_CATEGORY"

interface SetCurrentCategoryAction {
  type: typeof SET_CURRENT_CATEGORY
  currentCategory?: string
}

export const SET_CURRENT_SUB_CATEGORY = "navigation/SET_CURRENT_SUB_CATEGORY"

interface SetCurrentSubCategoryAction {
  type: typeof SET_CURRENT_SUB_CATEGORY
  currentSubCategory?: string
}

export const SET_PAGINATION = "navigation/SET_PAGINATION"

interface SetPaginationAction {
  type: typeof SET_PAGINATION
  pagination?: PaginationBar
}

export const SET_FILTER_CURSOR = "navigation/SET_FILTER_CURSOR"

interface SetNavigationCursorAction {
  type: typeof SET_FILTER_CURSOR
  filterCursor: string | null
}

export const SET_ALERT= "navigation/SET_ALERT"

interface SetAlertAction {
  type: typeof SET_ALERT
  alert: Alert
}

export const DELETE_ALERT= "navigation/DELETE_ALERT"

interface DeleteAlertAction {
  type: typeof DELETE_ALERT
  alert: Alert | null
}

export type NavigationAction =
  | ClearAvailableAutonomousSystemsAction
  | SetCurrentAppAction
  | SetCurrentPageAction
  | SetCurrentPageScopeAction
  | SetSelectedAsnAction
  | SetSidebarExtendedAction
  | SetSidebarPrefixAction
  | SetSidebarSuffixAction
  | SetCurrentCategoryAction
  | SetCurrentSubCategoryAction
  | SetPaginationAction
  | SetNavigationCursorAction
  | SetAlertAction
  | DeleteAlertAction

/* Reducer */

export const navigationReducer: Reducer<NavigationState, NavigationAction> = (
  state = INITIAL_STATE,
  action
) => {
  if (action.type === CLEAR_NAVIGATION_DATA) {
    return INITIAL_STATE
  }

  if (action.type === SET_SELECTED_ASN) {
    const {selectedAsn} = action

    const newState: NavigationState = {
      ...state,
      selectedAsn: selectedAsn,
    }

    if(selectedAsn){
      localStorage.setItem("asn", JSON.stringify(selectedAsn))
    }

    return newState
  }

  if (action.type === SET_CURRENT_PAGE) {
    const newState: NavigationState = {
      ...state,
      currentPage: action.currentPage,
    }
    return newState
  }

  if (action.type === SET_CURRENT_PAGE_SCOPE) {
    const newState: NavigationState = {
      ...state,
      currentPageScope: action.currentPageScope,
    }
    return newState
  }

  if (action.type === SET_CURRENT_APP) {
    const newState: NavigationState = {
      ...state,
      currentApp: action.currentApp,
    }
    return newState
  }

  if (action.type === SET_SIDEBAR_EXTENDED) {
    const newState: NavigationState = {
      ...state,
      isSidebarExtended: action.isSidebarExtended,
    }
    return newState
  }

  if (action.type === SET_SIDEBAR_PREFIX) {
    const newState: NavigationState = {
      ...state,
      sidebarPrefix: action.sidebarPrefix,
    }
    return newState
  }

  if (action.type === SET_SIDEBAR_SUFFIX) {
    const newState: NavigationState = {
      ...state,
      sidebarSuffix: action.sidebarSuffix,
    }
    return newState
  }

  if (action.type === SET_CURRENT_CATEGORY) {
    const newState: NavigationState = {
      ...state,
      currentCategory: action.currentCategory,
    }
    return newState
  }

  if (action.type === SET_CURRENT_SUB_CATEGORY) {
    const newState: NavigationState = {
      ...state,
      currentSubCategory: action.currentSubCategory,
    }
    return newState
  }

  if (action.type === SET_PAGINATION) {
    const newState: NavigationState = {
      ...state,
      pagination: action.pagination,
    }
    return newState
  }

  if (action.type === SET_FILTER_CURSOR) {
    const { filterCursor } = action
    const newState: NavigationState = {
      ...state,
      filterCursor,
    }
    return newState
  }

  if(action.type === SET_ALERT){
    const { alert } = action

    state.alertList.push({
      ...alert,
      id: uuid(),
      type: alert.type ?? "ERROR"
    })

    const newState: NavigationState = {
      ...state,
    }
    return newState
  }

  if(action.type === DELETE_ALERT){
    const { alert } = action
    let idx = -1

    if(!alert){
      state.alertList = []
      return state
    }

    if(alert?.id){
      idx = state.alertList.findIndex((message)=>{
        return message.id === alert?.id
      })
    }
    
    if(idx > -1){
      state.alertList.splice(idx, 1)
    }

    const newState: NavigationState = {
      ...state,
    }
    return newState
  }

  // Assert that all known actions are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = action
  return state
}
