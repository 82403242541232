import { getIXLocationByCode } from "./ix-location-map"
import {
  Measurement,
} from "@models/measurement"
import { AutonomousSystem } from "@models/autonomous-system"

export const getMeasurementPeer = (measurement: Measurement, availableAutonomousSystems: AutonomousSystem[] | null) => {
  let peerFormated;

  switch (measurement.peerLocalType) {
    case "IX":
      peerFormated = getIXLocationByCode("IX-" + measurement.peerLocalName)
      break;
    case "Public":
      peerFormated = "NIC.br"
      break;
    case "AS":
      // TO DO : Encurtar o nome do autonomousSystem 
      // ex: Nucleo de informação e Coordenação do .br
      // encurtar para mais ou menos NIC.br
      peerFormated = "AS " + measurement.peerLocalName
      break;
  }
  return peerFormated ?? measurement.peer
}
