import { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { ASN_REGEX } from "@constants/asn-regex"

/** React Hook to retrieve ASN from a string parameter. If ASN is set to an invalid value, redirect to 404 page.
 *
 * @param asnParameter String to match for ASN.
 * @param callback Optional callback function to run on effect for successful ASN matching.
 */
export const useAsnParameter = (
  asnParameter?: string,
  callback?: (asn: number) => void
) => {
  const [asn, setAsn] = useState<number | undefined>(() => {
    if (asnParameter) {
      const asnMatch = ASN_REGEX.exec(asnParameter)
      if (asnMatch) {
        return parseInt(asnMatch[1], 10)
      }
    }
    return undefined
  })

  useEffect(() => {
    if (asnParameter) {
      const asnMatch = ASN_REGEX.exec(asnParameter)
      if (asnMatch) {
        const selectedAsn = parseInt(asnMatch[1], 10)
        setAsn(selectedAsn)
        if (callback) {
          callback(selectedAsn)
        }
      } else {
        navigate("/404")
      }
    }
  }, [asnParameter, callback])

  return asn
}
