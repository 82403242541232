import React, { FunctionComponent, useEffect } from "react"
import { useKeycloak } from "@react-keycloak/web"
import { graphql, useStaticQuery } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { Dispatch } from "redux"
import axios from "axios"
import { ReducerAction, ReducerState } from "@store/index"
import { AutonomousSystem } from "@models/autonomous-system"
import { GraphQLResponse } from "@_types/graphql-response"
import {
  BEGIN_FETCHING_AS_ADMIN_PERMISSION_LIST,
  CLEAR_AS_ADMIN_PERMISSION_LIST,
  SET_AS_ADMIN_PERMISSION_LIST,
  SET_AS_PERMISSIONS_ERROR,
  STOP_FETCHING_AS_ADMIN_PERMISSION_LIST,
} from "@store/as-admin"
import { UserScopeMapping } from "@models/user-scope-mapping"
import { SET_ALERT } from "@store/navigation-store"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        asAuthorization: {
          baseUrl: string
        }
      }
    }
  }
}

interface ReducerSelected {
  selectedAutonomousSystem: AutonomousSystem | null
}

const AsPermissionListService: FunctionComponent = () => {
  /* GraphQL data */
  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          externalServices {
            asAuthorization {
              baseUrl
            }
          }
        }
      }
    }
  `)
  const {
    baseUrl: asAuthorizationUrl,
  } = data.site.siteMetadata.externalServices.asAuthorization

  /* React Hooks */
  const { selectedAutonomousSystem } = useSelector<
    ReducerState,
    ReducerSelected
  >((state) => {
    return {
      selectedAutonomousSystem: state.asAdmin.selectedAutonomousSystem,
    }
  })
  const dispatch = useDispatch<Dispatch<ReducerAction>>()
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()

  if (!isKeycloakInitialized || !keycloak?.authenticated) {
    throw new Error("Cannot use AsAdminListService without authentication")
  }

  useEffect(() => {
    // Don't fetch data if there is no selected autonomous system
    if (!selectedAutonomousSystem) {
      return
    }
    // Fetch new data from API
    const fetchAsAdminList = async () => {
      try {
        dispatch({
          type: CLEAR_AS_ADMIN_PERMISSION_LIST,
        })
        dispatch({
          type: BEGIN_FETCHING_AS_ADMIN_PERMISSION_LIST,
        })
        const response = await axios.post<
          GraphQLResponse<{ asPermissions: UserScopeMapping[] }>
        >(
          `${asAuthorizationUrl}`,
          {
            query:
              `` +
              `query ($asn: Int!) { ` +
              `  asPermissions(asn: $asn) { ` +
              `    scopes user ` +
              `  } ` +
              `}`,
            variables: {
              asn: selectedAutonomousSystem.asn,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
            },
          }
        )
        if (response.data.errors) {
          if (response.data.errors[0]) {
            dispatch({
              type: SET_AS_PERMISSIONS_ERROR,
              asPermissionsError: JSON.parse(response.data.errors[0].message),
            })
            dispatch({
              type: SET_ALERT,
              alert: {
                message_key: "graphql_error:" + JSON.parse(response.data.errors[0].message).title_key
              },
            })
            return
          }
        }
        if (response.data.data?.asPermissions) {
          dispatch({
            type: SET_AS_ADMIN_PERMISSION_LIST,
            asPermissionList: response.data.data.asPermissions,
          })
        } else {
          dispatch({
            type: SET_AS_PERMISSIONS_ERROR,
            asPermissionsError: {
              title: "Erro ao obter permissões",
              detail:
                "Ocorreu um erro desconhecido. Tente novamente mais tarde.",
            },
          })
          dispatch({
            type: SET_ALERT,
            alert: {
              message_key: "http_error:" + response.status
            },
          })
        }
      } catch (error: any) {
        console.error("Error when fetching AS permission list", error)
        if (
          error.response?.data?.errors &&
          error.response?.data?.errors[0]?.message
        ) {
          dispatch({
            type: SET_AS_PERMISSIONS_ERROR,
            asPermissionsError: JSON.parse(
              error.response.data.errors[0].message
            ),
          })
        } else {
          dispatch({
            type: SET_AS_PERMISSIONS_ERROR,
            asPermissionsError: {
              title: "Erro ao obter permissões",
              detail:
                "Ocorreu um erro de conexão. Verifique sua Internet e tente novamente mais tarde.",
            },
          })
        }
        dispatch({
          type: SET_ALERT,
          alert: {
            message_key: "http_error:" + (error?.response?.status ?? error?.message),
          },
        })
      } finally {
        dispatch({
          type: STOP_FETCHING_AS_ADMIN_PERMISSION_LIST,
        })
      }
    }
    fetchAsAdminList()
    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asAuthorizationUrl, keycloak, selectedAutonomousSystem])

  return <></>
}

export default AsPermissionListService
