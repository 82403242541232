// @ts-nocheck
import React from "react"
import { Container } from "./Container"

function SwirlyDoodle({ className }) {
  return (
    <svg
      aria-hidden="true"
      viewBox="0 0 281 47"
      className={className}
      preserveAspectRatio="none"
    >
      <path
        transform="translate(0, 47) scale(1, -1) "
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.172 22.994c-8.007 1.246-15.477 2.23-31.26 4.114-18.506 2.21-26.323 2.977-34.487 3.386-2.971.149-3.727.324-6.566 1.523-15.124 6.388-43.775 9.404-69.425 7.31-26.207-2.14-50.986-7.103-78-15.624C10.912 20.7.988 16.143.734 14.657c-.066-.381.043-.344 1.324.456 10.423 6.506 49.649 16.322 77.8 19.468 23.708 2.65 38.249 2.95 55.821 1.156 9.407-.962 24.451-3.773 25.101-4.692.074-.104.053-.155-.058-.135-1.062.195-13.863-.271-18.848-.687-16.681-1.389-28.722-4.345-38.142-9.364-15.294-8.15-7.298-19.232 14.802-20.514 16.095-.934 32.793 1.517 47.423 6.96 13.524 5.033 17.942 12.326 11.463 18.922l-.859.874.697-.006c2.681-.026 15.304-1.302 29.208-2.953 25.845-3.07 35.659-4.519 54.027-7.978 9.863-1.858 11.021-2.048 13.055-2.145a61.901 61.901 0 0 0 4.506-.417c1.891-.259 2.151-.267 1.543-.047-.402.145-2.33.913-4.285 1.707-4.635 1.882-5.202 2.07-8.736 2.903-3.414.805-19.773 3.797-26.404 4.829Zm40.321-9.93c.1-.066.231-.085.29-.041.059.043-.024.096-.183.119-.177.024-.219-.007-.107-.079ZM172.299 26.22c9.364-6.058 5.161-12.039-12.304-17.51-11.656-3.653-23.145-5.47-35.243-5.576-22.552-.198-33.577 7.462-21.321 14.814 12.012 7.205 32.994 10.557 61.531 9.831 4.563-.116 5.372-.288 7.337-1.559Z"
      />
    </svg>
  )
}

export function Solution() {
  return (
    <section
      id="solution"
      aria-label="What our customers are saying"
      className="bg-slate-900 text-white py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-3xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            Parte da solução{" "}
            <span className="relative whitespace-nowrap">
              <SwirlyDoodle className="absolute top-2/3 left-0 h-1em w-full text-blue-400 fill-current" />
              <span className="relative">SIMET PROVEDOR</span>
            </span>{" "}
            .
          </h2>
          <p className="mt-6 text-lg tracking-tight text-slate-400">
            O PAS é parte da nossa solução integrada desenvolvida para o
            provedor. Obtenha os melhores resultados instalando o servidor de
            teste SIMET-ISP na sua rede e implantando os medidores da familia
            SIMET na casa do assinante.
          </p>
        </div>
        <div className="text-4xl tracking-wide">
          <div className="flex justify-around mt-16">
            <div className="w-64 h-48 flex items-center justify-around text-center rounded-3xl bg-blue-600 text-white border-slate-300 border">
              Portal PAS
            </div>
          </div>
          <div className="hidden sm:flex justify-around mt-12 md:mt-32 flex-wrap">
            <div className="relative w-64 h-48">
              <div className="absolute w-64 h-48 z-30 inset-0 flex items-center justify-around text-center rounded-3xl bg-blue-600 text-white border-slate-300 border shadow-symmetric">
                Servidor
                <br /> SIMET-ISP
              </div>
              <div className="absolute w-56 h-44 z-20 -mt-10 ml-16 inset-0 flex pt-1 justify-around text-center rounded-3xl bg-blue-600 text-white border-slate-300 border text-2xl shadow-symmetric">
                Simet-AS
              </div>
            </div>
            <div className="relative w-64 h-48">
              <div className="absolute w-64 h-48 z-30 inset-0 flex items-center justify-around text-center rounded-3xl bg-blue-600 text-white border-slate-300 border shadow-symmetric">
                Medidor
                <br /> SIMETBox
              </div>
              <div className="absolute w-56 h-44 z-20 -mt-10 ml-16 inset-0 flex pt-1 justify-around text-center rounded-3xl bg-blue-600 text-white border-slate-300 border text-2xl shadow-symmetric">
                SIMET Web
              </div>
              <div className="absolute w-48 h-36 z-10 -mt-20 ml-32 inset-0 flex pt-1 justify-around text-center rounded-3xl bg-blue-600 text-white border-slate-300 border text-xl shadow-symmetric">
                SIMET Mobile
              </div>
            </div>
          </div>
          <div className="sm:hidden flex justify-around mt-24">
            <div className="relative w-64 h-48">
              <div className="absolute w-64 h-48 z-30 inset-0 flex items-center justify-around text-center rounded-3xl bg-blue-600 text-white border-slate-300 border shadow-symmetric">
                Servidor
                <br /> SIMET-ISP
              </div>
              <div className="absolute w-56 h-44 z-20 -mt-10 ml-4 inset-0 flex pt-1 justify-around text-center rounded-3xl bg-blue-600 text-white border-slate-300 border text-2xl shadow-symmetric">
                Simet-AS
              </div>
            </div>
          </div>
          <div className="sm:hidden flex justify-around mt-36">
            <div className="relative w-64 h-48">
              <div className="absolute w-64 h-48 z-30 inset-0 flex items-center justify-around text-center rounded-3xl bg-blue-600 text-white border-slate-300 border shadow-symmetric">
                Medidor
                <br /> SIMETBox
              </div>
              <div className="absolute w-56 h-44 z-20 -mt-10 ml-4 inset-0 flex pt-1 justify-around text-center rounded-3xl bg-blue-600 text-white border-slate-300 border text-2xl shadow-symmetric">
                SIMET Web
              </div>
              <div className="absolute w-48 h-36 z-10 -mt-20 ml-8 inset-0 flex pt-1 justify-around text-center rounded-3xl bg-blue-600 text-white border-slate-300 border text-xl shadow-symmetric">
                SIMET Mobile
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
