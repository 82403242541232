import React, { ReactElement, useEffect } from "react"
import Helmet from "react-helmet"
import { PageProps } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { ReducerAction, ReducerState } from "@store/index"
import RequireAuthentication from "@components/shared/require-authentication"
import { DELETE_ALERT } from "@store/navigation-store"
import { AutonomousSystem } from "@models/autonomous-system"
import { useAsnParameter } from "@hooks/use-asn-parameter"
import SimetasProbesStatusService from "@services/simetas-probes-status-service"
import { useQueryParams } from "use-query-params"
import { QueryParamConfig } from "@utils/query-params"
// import AvailableAutonomousSystemService from "@services/available-autonomous-system-service"
import { SimetasProbeStatus } from "@models/simetas-probe-status"
import Servers from "@components/devices/helper"
import EmptyStateDevices from "@components/devices/empty-state-devices"
import PageSpinner from "@components/shared/page-spinner"
import { PageDocsSidebarContentGeneric } from "@models/page-docs-sidebar-content-type"

export interface IndexLocationState {
  availableAutonomousSystems: AutonomousSystem[]
}

interface ReducerSelected {
  isWaitingForData: boolean
  availableAutonomousSystems: AutonomousSystem[] | null
  simetasProbesStatus: SimetasProbeStatus[] | null
  isWaitingForSimetasProbesStatusData: boolean
}

const AsnPage: (
  _: PageProps<object, object, IndexLocationState> & {
    asn?: string
  }
) => ReactElement = ({ asn: asnString, location }) => {
  const asn = useAsnParameter(asnString)
  const dispatch = useDispatch<Dispatch<ReducerAction>>()

  useEffect(() => {
    // Clear error
    dispatch({
      type: DELETE_ALERT,
      alert: null,
    })

    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    isWaitingForData,
    availableAutonomousSystems,
    simetasProbesStatus,
    isWaitingForSimetasProbesStatusData,
  } = useSelector<ReducerState, ReducerSelected>((state) => {
    return {
      isWaitingForData: state.autonomousSystems.isWaitingForData,
      availableAutonomousSystems:
        state.autonomousSystems.availableAutonomousSystems,
      simetasProbesStatus: state.simetasProbesStatus.simetasProbesStatus,
      isWaitingForSimetasProbesStatusData:
        state.simetasProbesStatus.isWaitingForData,
    }
  })

  const [_searchParams] = useQueryParams(QueryParamConfig)

  return (
    <>
      <Helmet>
        <title>PAS - Conexão ao IX.br</title>
      </Helmet>
      <RequireAuthentication className="min-h-full relative">
        <div className="pt-6 px-4 sm:px-6 lg:px-8 h-full">
          <div className="contents">
            {/* <AvailableAutonomousSystemService /> */}
            {/* <LeaderBoard availableAutonoumsSystem={availableAutonomousSystems}/> */}
            {asn && (
              <>
                <SimetasProbesStatusService asn={asn} />
                {(isWaitingForData || isWaitingForSimetasProbesStatusData) && (
                  <PageSpinner />
                )}
                {!isWaitingForData &&
                  !isWaitingForSimetasProbesStatusData &&
                  (!simetasProbesStatus || simetasProbesStatus.length == 0) && (
                    <EmptyStateDevices />
                  )}
                {!isWaitingForSimetasProbesStatusData &&
                  !isWaitingForData &&
                  simetasProbesStatus &&
                  simetasProbesStatus.length > 0 && (
                    <Servers apiResponse={simetasProbesStatus} />
                  )}
              </>
            )}
          </div>
        </div>
      </RequireAuthentication>
    </>
  )
}

export default AsnPage

export const getPageSidebarContent = (): PageDocsSidebarContentGeneric => {
  return {
    title: "Gerenciamento de servidores",
    abstract:
      "Gerenciar os servidores Simet instalados na rede do seu sistema autônomo",
    content: (
      <div>
        <EmptyStateDevices />
      </div>
    ),
  }
}
