import { SerializedDayMeasurementStatisticsList } from '@models/day-measurement-statistics-list'
import { IpFamily } from '@models/ip-family'
import {UseQueryArgs}from 'urql'
import { useSiteMetadata } from './use-site-metadata'
import { useEffect, useRef, useState } from 'react'
import axios, { CancelTokenSource } from 'axios'
import { isEqualToDateDuration } from '@utils/is-equal-to-date-duration'
import { GraphQLResponse } from '@_types/graphql-response'
import { formatISO } from 'date-fns'
import { SET_ALERT } from '@store/navigation-store'
import { useDispatch } from "react-redux"
import { ReducerAction } from "@store/index"
import { Dispatch } from "redux"


const SubcategoryByAgent = `
  query(
      $agentId: UUID!,
      $minDate: DateTime!,
      $maxDate: DateTime!,
      $referentialDate: DateTime!,
      $asn: Int!,
      $ipFamily: [IPVersion!]
    ){
    dayMeasurementStatisticsList(
      agentId: $agentId
      minDate: $minDate
      maxDate: $maxDate
      referentialDate: $referentialDate
      asn: $asn
      ipFamily: $ipFamily
    ) {
      date
      dateTime
      ipFamily
      downloadMbps
      uploadMbps
      rttMs
      jitterRttMs
      jitterDownloadMs
      jitterUploadMs
      packetLossPercentage
    }
  }
`

type GraphQlProps = {
  agentId: string
  minDate: Date
  maxDate: Date
  referentialDate: Date
  asn: number
  ipFamily: IpFamily[]
}


export const useGetQuerySubcategory = (options: UseQueryArgs<GraphQlProps>['variables'], token: string) => {
  // console.log('calling useGetQuerySubcategory')
  // return useQuery<SerializedDayMeasurementStatisticsList[], GraphQlProps>({
  //   query: SubcategoryByAgent,
  //   variables: options,
  //   // requestPolicy: 'cache-and-network',
  //   context: {
  //     // refetchOnW/indowFocus: false,
  //     fetchOptions: {
  //       headers: {
  //         'Authorization': 'Bearer ' + token
  //       }
  //     }
  //   }
  // })
  const data =  useSiteMetadata()

  const {asMeasurementsApi} = data.externalServices

  const dispatch = useDispatch<Dispatch<ReducerAction>>()
  const prevReferentialDate = useRef<Date | null>(null)
  const prevFilterAgentId = useRef<string | null>(null)
  const prevAsn = useRef<number>(0)
  const prevMinDate = useRef<Date | Duration | undefined>(options.minDate)
  const prevMaxDate = useRef<Date | undefined>(options.maxDate)
  const cancelToken = useRef<CancelTokenSource>()
  const requestId = useRef<number>(0)
  const firstCall = useRef(true)

  const [result, setResult] = useState<SerializedDayMeasurementStatisticsList[] | undefined>()
  const [fetching, setFetching] = useState<boolean>(true)

  useEffect(() => {
    // Check if filters changed or if it is the initial data population
    if (
      firstCall.current ||
      prevFilterAgentId.current !== options.agentId ||
      prevAsn.current !== options.asn ||
      prevMinDate.current !== options.minDate ||
      prevMaxDate.current !== options.maxDate ||
      prevReferentialDate.current !== options.referentialDate
    ) {
      // Abort any existing request
      cancelToken.current?.cancel("useGetQuerySubcategory request canceled")
      cancelToken.current = undefined
      // Check if filters have become less specific or if it is the initial data population
      if (
        // -> Measurement subcategories: less specific if array was cleared or new array doesn't have all of previous types
        (prevFilterAgentId.current !== null &&
          prevFilterAgentId.current !== options.agentId) ||
        (prevReferentialDate.current !== null &&
          prevReferentialDate.current !== options.referentialDate) ||
        prevAsn.current !== options.asn ||
        !isEqualToDateDuration(prevMinDate.current, options.minDate) || // TODO: Evaluate specificity on minDate?
        !isEqualToDateDuration(prevMaxDate.current, options.maxDate) || // TODO: Evaluate specificity on maxDate?
        firstCall.current
      ) {
        // Fetch new data from API
        ; (async () => {
          const currentRequestId = requestId.current + 1
          requestId.current = currentRequestId
          try {
            const newCancelToken = axios.CancelToken.source()
            cancelToken.current = newCancelToken
            const query = await axios.post<
              GraphQLResponse<{ dayMeasurementStatisticsList: SerializedDayMeasurementStatisticsList[] }>
            >(
              asMeasurementsApi.baseUrl,
              {
                // TODO: Remove unnecessary fields
                query: SubcategoryByAgent,
                variables: {
                  agentId: options.agentId,
                  minDate: formatISO(options.minDate),
                  maxDate: formatISO(options.maxDate),
                  referentialDate: formatISO(options.referentialDate),
                  asn: options.asn,
                  ipFamily: ["IPV4", "IPV6"],
                },
                cancelToken: newCancelToken.token,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            if (query.data.errors) {
              if (query.data.errors[0]) {
                dispatch({
                  type: SET_ALERT,
                  alert: {
                    message_key:
                      "graphql_error:" +
                      JSON.parse(query.data.errors[0].message).title_key,
                  },
                })
                return
              }
            }
            if (requestId.current !== currentRequestId) {
              return
            }
            if (query.data.data?.dayMeasurementStatisticsList) {
              setResult(query.data.data.dayMeasurementStatisticsList)
              // Re-apply filters to be safe (in case of races with setting filters)
              // dispatch({
              //   type: UPDATE_MEASUREMENTS_WITH_FILTERS,
              // })
            } else {
              dispatch({
                type: SET_ALERT,
                alert: {
                  message_key: "http_error:" + query.status,
                },
              })
            }
          } catch (error: any) {
            if (axios.isCancel(error)) {
              console.log("MeasurementsService request canceled")
              return
            }
            if (requestId.current !== currentRequestId) {
              return
            }
            console.error("Error when fetching measurements", error)
            dispatch({
              type: SET_ALERT,
              alert: {
                message_key:
                  "http_error:" + (error?.response?.status ?? error?.message),
              },
            })
          } finally {
            setFetching(false)
          }
        })()
      }
      // else {
      //   // New data is subset of current data; filter locally, without a new API request
      //   dispatch({ type: UPDATE_MEASUREMENTS_WITH_FILTERS })
      // }
      firstCall.current = false
      prevFilterAgentId.current = options.agentId
      prevAsn.current = options.asn
      prevMinDate.current = options.minDate
      prevMaxDate.current = options.maxDate
      prevReferentialDate.current = options.referentialDate
    }
  }, [
    options,
    asMeasurementsApi.baseUrl,
    token,
  ])

  // Cleanup
  useEffect(() => {
    return () => {
      // Abort any current request
      requestId.current = 0
      cancelToken.current?.cancel("MeasurementsService request canceled")
    }
  }, [])
  
  return {result, fetching}
}
