import React, { FunctionComponent, memo } from "react"
import {
  AgentFamily,
  AGENT_FAMILIES,
} from "@models/agent-family"
import ToggleComponent from "../../shared/toggleComponent"
import { describeAgent } from "@utils/describe-agent"

interface Props {
  agentFamilies: AgentFamily[]
  setAgentFamilies: (value: AgentFamily[]) => void
}

const AgentFamilyToggle: FunctionComponent<Props> = ({
  agentFamilies,
  setAgentFamilies,
}) => {
  return (
    <>
      {AGENT_FAMILIES.slice().map((agentFamily, idx) => {
        const formattedAgentFamily = describeAgent(agentFamily)
        const isActive = agentFamilies.includes(agentFamily)
        return (
          <ToggleComponent 
            isActive={isActive}
            text={formattedAgentFamily}
            onChange={(active)=> {
              const agentFamilyIndex = agentFamilies.indexOf(agentFamily)
              if (active) {
                setAgentFamilies([
                  ...agentFamilies,
                  agentFamily
                ])
              } else {
                agentFamilies.splice(agentFamilyIndex,1)
                setAgentFamilies([
                  ...agentFamilies
                ])
              }}
            } 
          />
        )})
      }
    </>
  )
}

export default memo(AgentFamilyToggle)
