// TODO: solve localization of date values for xFormat (tooltip) and axisBottom (x axis)
// https://github.com/plouc/nivo/issues/1946
// https://stackoverflow.com/questions/34437526/d3-time-formatting-locale
// https://github.com/d3/d3-time-format/blob/main/src/defaultLocale.js
import React, { FunctionComponent, memo } from "react"
import { ResponsiveLine } from "@nivo/line"
import { GraphicComponentFragmentType } from "./utils"
import {
  MeasurementSubcategory,
  unitByMetric,
} from "@models/measurement-subcategory"
import { format } from "date-fns"

interface Props {
  data: any
  maxValue: number
  metric: MeasurementSubcategory
  config: GraphicComponentFragmentType | undefined
}

const TimeSeriesChart: FunctionComponent<Props> = ({
  data,
  maxValue,
  metric,
  config,
}) => {
  const [unit, unitExpected] = unitByMetric(metric)

  if (!config) return <></>

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 10, right: 20, bottom: 40, left: 60 }}
      xScale={{
        type: "time",
        useUTC: false,
        // format:'native',
        precision: config.xScale.precision,
        min: config.xScale.min,
        max: config.xScale.max,
      }}
      xFormat={config.xFormat}
      yScale={{
        type: "linear",
        min: 0,
        max: Math.floor(maxValue * 1.4),
        stacked: false,
        reverse: false,
      }}
      yFormat={(y) => `${y}${unitExpected}`}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickValues: config.axisBottom.tickValues,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: config.axisBottom.format,
        legend:
          config.xScale.min && config.xScale.max
            ? format(config.xScale.min, "dd-MM-yyyy") +
              " á " +
              format(config.xScale.max, "dd-MM-yyyy")
            : "",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        // tickValues: "left",
        tickSize: 0,
        tickPadding: 8,
        tickRotation: 0,
        format: (value) => `${value} ${unitExpected}`,
        legend: null,
        legendOffset: 0,
        legendPosition: "middle",
      }}
      colors={["#F59753", "#3b82f6"]}
      enableGridX={false}
      lineWidth={3}
      enablePoints={true}
      pointSize={5}
      pointColor={{ from: "color", modifiers: [] }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      isInteractive={true}
      useMesh={true}
      enableSlices="x"
      // enableSlices={false}
      animate={false}
      legends={[
        {
          anchor: "top-right",
          direction: "column",
          justify: false,
          translateX: 25,
          translateY: -7,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, 1)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      theme={{
        axis: {
          ticks: {
            text: {
              fontSize: 14,
              fill: "#6b7280",
            },
          },
        },
        legends: {
          text: {
            fontSize: 14,
            fill: "#374151",
          },
        },
      }}
      tooltip={({ point }) => {
        return (
          <div
            style={{
              background: "white",
              padding: "9px 12px",
              border: "1px solid #ccc",
              borderRadius: "3px",
            }}
          >
            <div
              style={{
                textAlign: "right",
                color: "#6b7280",
              }}
            >
              {point.data.xFormatted}
            </div>

            <div
              key={point.id}
              style={{
                color: point.serieColor,
                padding: "3px 0",
              }}
            >
              {point.data.yFormatted} {point.serieId}
            </div>
          </div>
        )
      }}
      sliceTooltip={({ slice }) => {
        return (
          <div
            style={{
              background: "white",
              padding: "9px 12px",
              border: "1px solid #ccc",
              borderRadius: "3px",
            }}
          >
            <div
              style={{
                textAlign: "right",
                color: "#6b7280",
              }}
            >
              {slice.points[0].data.xFormatted}
            </div>
            {slice.points.map((point) => (
              <div
                key={point.id}
                style={{
                  color: point.serieColor,
                  padding: "3px 0",
                }}
              >
                {point.data.yFormatted} {point.serieId}
              </div>
            ))}
          </div>
        )
      }}
    />
  )
}
export default TimeSeriesChart
