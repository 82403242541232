import { graphql, useStaticQuery } from "gatsby"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        asMeasurementsApi: {
          baseUrl: string
        }
        asAuthorization: {
          baseUrl: string
        }
        simetAs: {
          baseUrl: string
        }
        asMeasurements: {
          baseUrl: string
        }
        asManagement: {
          baseUrl: string
        }
        asMeasurementsSubscriptions: {
          graphql: string
          subscriptions: string
        }
        asSimetasApi: {
          baseUrl: string
        }
        leaderboardApi: {
          baseUrl: string
        }
      }
      pageData: {
        supportTicketMaxCharacters: number
      }
    }
  }
}

export const useSiteMetadata = () => {
  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          externalServices {
            asMeasurementsApi {
              baseUrl
            }
            asAuthorization {
              baseUrl
            }
            simetAs {
              baseUrl
            }
            asMeasurements {
              baseUrl
            }
            asManagement {
              baseUrl
            }
            asMeasurementsSubscriptions {
              graphql
              subscriptions
            }
            asSimetasApi {
              baseUrl
            }
            leaderboardApi {
              baseUrl
            }
          }
          pageData {
            supportTicketMaxCharacters
          }
        }
      }
    }
  `)

  return data.site.siteMetadata
}
