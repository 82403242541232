import React, { FunctionComponent, memo, useEffect, useRef } from "react"
import { useKeycloak } from "@react-keycloak/web"
import { graphql, useStaticQuery } from "gatsby"
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import axios, { CancelTokenSource } from "axios"
import { Duration as DateDuration, formatISO, isBefore } from "date-fns"
import { ReducerAction } from "@store/index"
import {
  SET_MEASUREMENTS_TIME_SERIES,
  SET_WAITING_FOR_MEASUREMENTS_TIME_SERIES_DATA,
} from "@store/measurements-store"
import { SET_ALERT } from "@store/navigation-store"
import { getQueryDateInterval } from "@utils/get-query-date-interval"
import { isEqualToDateDuration } from "@utils/is-equal-to-date-duration"
import { SerializedMeasurementTimeSeries } from "@models/serialized/serialized-measurement"
import { deserializeMeasurementTimeSeries } from "../serdes/deserialize-measurement"
import {
  formatIpVersion,
  IpFamily,
} from "@models/ip-family"
import {
  AgentFamily,
  formatAgentFamilyService,
} from "@models/agent-family"
import {
  formatPeriodTimeResolution,
  PeriodType,
} from "@models/period-type"
import { useFilterState } from "../store/use-filter-state"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        timeSeriesApi: {
          baseUrl: string
        }
      }
    }
  }
}

interface Props {
  asn: number
  periodo: PeriodType
  minDate?: Date | DateDuration
  maxDate?: Date
  defaultDuration?: DateDuration
  setMinDate?: (_?: Date | DateDuration) => void
}


const MeasurementsTimeSeriesService: FunctionComponent<Props> = ({
  asn,
  periodo,
  minDate,
  maxDate,
  defaultDuration,
  setMinDate,
}) => {
  /* GraphQL data */
  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          externalServices {
            timeSeriesApi {
              baseUrl
            }
          }
        }
      }
    }
  `)

  const { baseUrl } = data.site.siteMetadata.externalServices.timeSeriesApi

  /* React Hooks */
  const { filterIpVersions, filterAgentFamilies } = useFilterState()

  const dispatch = useDispatch<Dispatch<ReducerAction>>()
  const { keycloak } = useKeycloak()
  const prevFilterIpVersions = useRef<IpFamily | null>(null)
  const prevFilterAgentFamilies = useRef<AgentFamily[] | null>(null)
  const prevAsn = useRef<number>(0)
  const prevMinDate = useRef<Date | DateDuration | undefined>(minDate)
  const prevMaxDate = useRef<Date | undefined>(maxDate)
  const cancelToken = useRef<CancelTokenSource>()
  const requestId = useRef<number>(0)
  const firstCall = useRef(true)
  if (!keycloak?.authenticated) {
    throw new Error("Cannot use MeasurementsService without authentication")
  }

  async function getDataTimeSeries(
    url: string
  ): Promise<SerializedMeasurementTimeSeries[]> {
    return await axios
      .get<SerializedMeasurementTimeSeries>(url, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      })
      .then((res) => [res.data])
  }

  useEffect(() => {
    // Check if filters changed or if it is the initial data population
    if (
      firstCall.current ||
      prevFilterIpVersions.current !== filterIpVersions ||
      prevFilterAgentFamilies.current !== filterAgentFamilies ||
      prevAsn.current !== asn ||
      prevMinDate.current !== minDate ||
      prevMaxDate.current !== maxDate
    ) {
      // Abort any existing request
      cancelToken.current?.cancel("MeasurementsService request canceled")
      cancelToken.current = undefined
      // Check if filters have become less specific or if it is the initial data population
      if (
        // -> Measurement subcategories: less specific if array was cleared or new array doesn't have all of previous types
        (prevFilterIpVersions.current !== null &&
          prevFilterIpVersions.current !== filterIpVersions) || // -> Agent families: less specific if array was cleared or new array doesn't have all of previous types
        (prevFilterAgentFamilies.current !== null &&
          (filterAgentFamilies === null ||
            filterAgentFamilies.length !==
            prevFilterAgentFamilies?.current.length)) ||
        (prevFilterAgentFamilies.current?.length === 0 &&
          filterAgentFamilies.length > 0) ||
        prevAsn.current !== asn ||
        !isEqualToDateDuration(prevMinDate.current, minDate) || // TODO: Evaluate specificity on minDate?
        !isEqualToDateDuration(prevMaxDate.current, maxDate) || // TODO: Evaluate specificity on maxDate?
        firstCall.current
      ) {
        // Clear error
        // dispatch({
        //   type: SET_PAGE_ERROR,
        //   pageError: null,
        // })
        dispatch({
          type: SET_MEASUREMENTS_TIME_SERIES,
          measurementsTimeSeries: [],
        })
          // Fetch new data from API
          ; (async () => {
            const [queryMinDate, queryMaxDate] = getQueryDateInterval(
              minDate ?? defaultDuration,
              maxDate
            )
            if (
              setMinDate &&
              minDate instanceof Date &&
              isBefore(queryMinDate, minDate)
            ) {
              prevMinDate.current = undefined // Avoid refetching on the next effect update
              setMinDate(undefined)
            }
            const currentRequestId = requestId.current + 1
            requestId.current = currentRequestId
            try {
              dispatch({
                type: SET_WAITING_FOR_MEASUREMENTS_TIME_SERIES_DATA,
                isWaitingForTimeSeriesData: true,
              })
              const newCancelToken = axios.CancelToken.source()
              cancelToken.current = newCancelToken

              const urlWithParams = new URL(baseUrl)

              let drill_down = "global"
              let drill_down_key_values = ""

              // mandatory parameters
              urlWithParams.searchParams.append("asn", String(asn))
              urlWithParams.searchParams.append(
                "period_start",
                formatISO(queryMinDate)
              )
              urlWithParams.searchParams.append(
                "period_end",
                formatISO(queryMaxDate)
              )
              urlWithParams.searchParams.append("aggregation", "mean")
              urlWithParams.searchParams.append(
                "time_resolution",
                formatPeriodTimeResolution(periodo)
              )

              // optional parameters

              if (filterAgentFamilies.length > 0) {
                drill_down = "agent_family"

                formatAgentFamilyService(filterAgentFamilies).map((family) => {
                  urlWithParams.searchParams.append(
                    "drill_down_key_values",
                    family
                  )
                })
              }
              // else {
              //   // nao fazer query se nao tiver agent families
              //   return
              // }

              urlWithParams.searchParams.append("drill_down", drill_down)

              if (filterIpVersions === "ALL") {
                urlWithParams.searchParams.append("ip_family", "4")
                let data_teste: SerializedMeasurementTimeSeries[] = []

                const data_ipv4 = await getDataTimeSeries(urlWithParams.href)

                urlWithParams.searchParams.delete("ip_family")
                urlWithParams.searchParams.append("ip_family", "6")

                const data_ipv6 = await getDataTimeSeries(urlWithParams.href)

                data_teste.push(...data_ipv6, ...data_ipv4)

                dispatch({
                  type: SET_MEASUREMENTS_TIME_SERIES,
                  measurementsTimeSeries: data_teste.map(
                    deserializeMeasurementTimeSeries
                  ),
                })
              } else {
                urlWithParams.searchParams.delete("ip_family")
                urlWithParams.searchParams.append(
                  "ip_family",
                  formatIpVersion(filterIpVersions)
                )

                await getDataTimeSeries(urlWithParams.href).then((response) => {
                  if (response) {
                    dispatch({
                      type: SET_MEASUREMENTS_TIME_SERIES,
                      measurementsTimeSeries: response.map(
                        deserializeMeasurementTimeSeries
                      ),
                    })
                  }
                })
              }
            } catch (error: any) {
              if (axios.isCancel(error)) {
                console.log("MeasurementsTimeSeriesService request canceled")
                return
              }
              if (requestId.current !== currentRequestId) {
                return
              }
              dispatch({
                type: SET_ALERT,
                alert: {
                  message_key:
                    "http_error:" + (error?.response?.status ?? error?.message),
                },
              })

              // dispatch({
              //   type: SET_MEASUREMENTS_TIME_SERIES,
              //   measurementsTimeSeries: (dataTimeSeries as SerializedMeasurementTimeSeries[]).map(deserializeMeasurementTimeSeries)
              // })

              console.error("Error when fetching measurements time Series", error)
            } finally {
              dispatch({
                type: SET_WAITING_FOR_MEASUREMENTS_TIME_SERIES_DATA,
                isWaitingForTimeSeriesData: false,
              })
            }
          })()
      }
      // else {
      //   // New data is subset of current data; filter locally, without a new API request
      //   dispatch({ type: UPDATE_MEASUREMENTS_WITH_FILTERS })
      // }
      firstCall.current = false
      prevFilterIpVersions.current = filterIpVersions
      prevFilterAgentFamilies.current = filterAgentFamilies
      prevAsn.current = asn
      prevMinDate.current = minDate
      prevMaxDate.current = maxDate
    }
  }, [
    filterIpVersions,
    filterAgentFamilies,
    firstCall,
    dispatch,
    asn,
    periodo,
    minDate,
    maxDate,
    defaultDuration,
    setMinDate,
    baseUrl,
    keycloak,
  ])

  // Cleanup
  useEffect(() => {
    return () => {
      // Abort any current request
      requestId.current = 0
      cancelToken.current?.cancel(
        "MeasurementsTimeSeriesService request canceled"
      )
    }
  }, [dispatch])

  return <></>
}

export default memo(MeasurementsTimeSeriesService)
