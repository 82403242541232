import {
  Dispatch,
  SetStateAction,
  RefObject,
  useState,
  useRef,
  useEffect,
} from "react"

export function useTooltipStateRef<E extends HTMLElement>(): [
  boolean,
  Dispatch<SetStateAction<boolean>>,
  RefObject<E>
] {
  const [show, setShow] = useState(false)
  const ref = useRef<E>(null)
  useEffect(() => {
    if (show) {
      const handleClickOutside = (event: TouchEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setShow(false)
        }
      }
      document.addEventListener("touchstart", handleClickOutside)
      return () =>
        document.removeEventListener("touchstart", handleClickOutside)
    }
  }, [show])

  return [show, setShow, ref]
}
