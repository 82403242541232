import React, {
  FunctionComponent,
  memo,
  useEffect,
  useRef,
  useState,
} from "react"
import { useSelector, shallowEqual } from "react-redux"
import { AutonomousSystem } from "@models/autonomous-system"
import { ReducerState } from "@store/index"
import { useKeycloak } from "@react-keycloak/web"
import { ProblemError } from "@models/problem-error"
import ProblemErrorComponent from "../shared/problem-error-component"

interface Props {
  onSelectAutonomousSystem: (asn: number | undefined) => void
  onClearAutonomousSystem: (asn: number | undefined) => void
  asnScopedPage: boolean
}

interface ReducerSelected {
  isWaitingForData: boolean
  error: ProblemError | null
  availableAutonomousSystems: AutonomousSystem[] | null
  selectedAsn?: number
}

const AutonomousSystemSelector: FunctionComponent<Props> = ({
  onSelectAutonomousSystem,
  onClearAutonomousSystem,
  asnScopedPage,
}) => {
  /* React Hooks */
  const [
    ,
    // isHover
    setHover,
  ] = useState(false)

  const { isWaitingForData, error, availableAutonomousSystems, selectedAsn } =
    useSelector<ReducerState, ReducerSelected>((state) => {
      return {
        isWaitingForData: state.autonomousSystems.isWaitingForData,
        error: state.autonomousSystems.error,
        availableAutonomousSystems:
          state.autonomousSystems.availableAutonomousSystems,
        selectedAsn: state.navigation.selectedAsn,
      }
    }, shallowEqual)

  const [currentAsn, setCurrentAsn] = useState<number | undefined>(selectedAsn)

  const elAdminAsnInput = useRef<HTMLInputElement>(null)
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()

  const isAdmin = isKeycloakInitialized && keycloak.hasRealmRole("admin")

  useEffect(() => {
    if (isAdmin && elAdminAsnInput.current) {
      elAdminAsnInput.current.value = `${currentAsn ?? ""}`
    }
  }, [isAdmin, currentAsn])

  const handleSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const asn = parseInt(event.target.value, 10)
    if (asn === 0) {
      onClearAutonomousSystem(undefined)
    } else if (asn !== currentAsn) {
      setCurrentAsn(asn)
      onSelectAutonomousSystem(asn)
      localStorage.setItem("asn", JSON.stringify(asn))
    }
  }

  const handleForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (elAdminAsnInput.current) {
      const asValue = elAdminAsnInput.current.value.trim()
      if (asValue === "" || asValue === "0") {
        onClearAutonomousSystem(undefined)
      } else {
        const asn = parseInt(asValue, 10)
        setCurrentAsn(asn)
        onSelectAutonomousSystem(asn)
      }
    }
  }

  // TODO: Use isHover
  return (
    <>
      {isAdmin || !error ? (
        <div
          className={`relative mx-auto border-0 max-w-xl rounded-md text-slate-600 ${
            !isAdmin && !isWaitingForData
              ? "hover:text-slate-800 focus-within:text-slate-800"
              : ""
          } border-slate-200 font-medium `}
        >
          {isAdmin ? (
            <form
              className="flex flex-col items-center mt-12 px-3"
              onSubmit={handleForm}
            >
              <div className="flex items-center">
                <input
                  ref={elAdminAsnInput}
                  type="text"
                  defaultValue={currentAsn}
                  disabled={!asnScopedPage}
                  placeholder="Selecionar ASN"
                  className="border-0 w-full bg-slate-200 border-slate-600 text-slate-700 focus:outline-none p-1 focus:ring focus:ring-slate-300"
                />
              </div>
              <button
                type="submit"
                className="mt-2 px-3 py-2 rounded-lg bg-slate-300 text-slate-800 hover:bg-slate-400 focus:bg-slate-400 focus:outline-none focus:ring"
                disabled={!asnScopedPage}
              >
                Selecionar
              </button>
            </form>
          ) : (
            <>
              {isWaitingForData && (
                <div className="font-normal tracking-wide">Carregando...</div>
              )}
              {!isWaitingForData && (
                <>
                  <div className="flex w-full items-center justify-between">
                    <select
                      className="group w-full inline-flex items-center h-9 leading-tight rounded-full border-0 text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-blue-100 text-blue-600 hover:bg-blue-200 hover:text-blue-700 focus:ring-blue-500"
                      onChange={handleSelection}
                      onMouseEnter={() => setHover(true)}
                      onFocus={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      onBlur={() => setHover(false)}
                      value={currentAsn ?? 0}
                      disabled={!asnScopedPage}
                    >
                      {availableAutonomousSystems?.map((autonomousSystem) => {
                        const isSelected = currentAsn
                          ? autonomousSystem.asn === currentAsn
                          : false
                        return (
                          <option
                            key={autonomousSystem.asn}
                            value={autonomousSystem.asn}
                            className="text-black"
                          >
                            {`AS${autonomousSystem.asn}`}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="w-min mx-auto rounded-full bg-white text-red-600 shadow-lg px-6 py-4">
          <ProblemErrorComponent problemError={error} titleClassName="hidden" />
        </div>
      )}
    </>
  )
}

export default memo(AutonomousSystemSelector)
