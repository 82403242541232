import { ElementType } from "@_types/element-type"

export const IP_FAMILIES = [
  "ALL",
  "IPV4",
  "IPV6"
] as const

export type IpFamily = ElementType<typeof IP_FAMILIES>

export function formatIpFamily(ipFamily: IpFamily | undefined): string {
  switch (ipFamily) {
    case "IPV4":
      return "IPv4"
    case "IPV6":
      return "IPv6"
    case "ALL":
      return "IPv4 & IPv6"
    default:
      return "Desconhecido"
  }
  // Assert that all known types are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = ipFamily
  throw new Error("Unknown IpFamily")
}

export function formatIpVersion(ipFamily: IpFamily | undefined): string {
  switch (ipFamily) {
    case "IPV4":
      return "4"
    case "IPV6":
      return "6"
    default:
      return "4"
  }
  // Assert that all known types are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = ipFamily
  throw new Error("Unknown IpFamily")
}
