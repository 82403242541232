import { Menu } from "@headlessui/react"
import { useKeycloak } from "@react-keycloak/web"
import React, { FunctionComponent } from "react"
import { ParsedToken } from "@models/parsed-token"
import IconSpinner from "@icons/icon-spinner.svg"
import RequireAuthentication from "../shared/require-authentication"
import urljoin from "url-join"
import { navigate } from "gatsby"

interface Props {
  isMobile: boolean
  provedor?: string
  isActive: boolean
}

const UserComponent: FunctionComponent<Props> = ({ isMobile, provedor }) => {
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()

  // const [isUserSubmenuOpen, setUserSubmenuOpen] = useState(false)

  const username =
    (keycloak.tokenParsed as ParsedToken)?.preferred_username || "Anônimo(a)"

  return (
    <RequireAuthentication className="flex-shrink-0 flex border-t border-slate-200 p-4">
      <svg
        className="w-10 h-10 flex-shrink-0 text-slate-400 mr-1.5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
      {isKeycloakInitialized ? (
        keycloak?.authenticated ? (
          <div className="w-56 lg:w-44">
            <p className="font-medium text-slate-700 truncate text-sm">
              {username}
            </p>
            <Menu>
              <Menu.Button className="flex justify-between w-full">
                <Menu.Item>
                  <span
                    onClick={() => {
                      navigate("/account/autonomous-systems/")
                    }}
                    className="text-xs font-medium text-slate-500 hover:text-slate-700"
                  >
                    Gerenciar conta
                  </span>
                </Menu.Item>
                <Menu.Item>
                  <span
                    onClick={() => {
                      // remove asn do local storage
                      window.localStorage.removeItem("asn")
                      const urlLogout = keycloak.createLogoutUrl({
                        redirectUri: urljoin(
                          window.location.protocol,
                          window.location.hostname
                        ),
                      })
                      window.location.assign(urlLogout)
                    }}
                    className="text-xs font-medium text-slate-500 hover:text-slate-700"
                  >
                    Sair
                  </span>
                </Menu.Item>
              </Menu.Button>
            </Menu>
          </div>
        ) : (
          <button
            onClick={() => {
              let href = window.location.href
              if (!window.location.pathname.endsWith("/")) {
                href = `${window.location.origin}${window.location.pathname}/${window.location.search}${window.location.hash}`
              }
              keycloak?.login({ redirectUri: href })
            }}
            className="inline-block text-center px-4 py-2 mt-4 md:mt-0 w-full md:w-auto leading-none rounded text-black bg-slate-300 hover:text-slate-500 hover:bg-white focus:outline-none focus:ring focus:ring-blue-400"
          >
            Fazer login
          </button>
        )
      ) : (
        <button className="inline-block px-4 py-2 leading-none text-white cursor-default mt-4 md:mt-0">
          <IconSpinner className="animate-spin h-5 w-5 -m-1" />
        </button>
      )}
    </RequireAuthentication>
  )
}

export default UserComponent
