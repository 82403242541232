import React, { FunctionComponent } from "react"
import CustomLink from "../shared/custom-link"

interface Props {
  to: string
  isActive?: boolean
  disablePreloading?: boolean
  isInternal?: boolean
  children: any
}

const HeaderLink: FunctionComponent<Props> = ({
  to,
  isActive,
  isInternal,
  children,
}) => {
  return (
    <CustomLink
      to={to}
      isActive={Boolean(isActive)}
      isInternal={isInternal}
      activeClass="flex items-center whitespace-nowrap border-b-4 border-blue-500 py-2 px-2 pl-1 text-xl font-medium text-black"
      className="flex items-center whitespace-nowrap border-b-4 border-transparent py-2 px-2 pl-1 text-xl font-medium text-slate-600 hover:border-slate-300"
    >
      {children}
    </CustomLink>
  )
}

export default HeaderLink
