import { Reducer } from "redux"
import { Measurement, MeasurementHistory, AntiSpoofing } from "@models/simet-as"
import { IpFamily } from "@models/ip-family"
import { ProblemError } from "@models/problem-error"
import { MeasurementTableOrdering } from "@models/measurements/measurement-table-ordering"
import { sortSimetAS } from "@utils/simet-as/sort-simetas"
import { AntiSpoofingType } from "@models/anti-spoofing-type"

/* State */

export interface SimetASState {
  isWaitingForData: boolean
  isWaitingForHistoryData: boolean
  error: ProblemError | null
  measurementsList: Measurement | null
  measurementsHistoryList: MeasurementHistory[] | null
  selectedMeasurement: Measurement | null
  showSelectedMeasurement: boolean
  filterIpVersion: IpFamily | null
  filterProbeId: string | null
  filterAgentId: string | null
  filterAntiSpoofing: string | null
  tableOrdering: MeasurementTableOrdering
  tableOrderingAsc: boolean
}

const INITIAL_STATE: SimetASState = {
  isWaitingForData: false,
  isWaitingForHistoryData: false,
  error: null,
  measurementsList: { locality: [] },
  measurementsHistoryList: [],
  selectedMeasurement: null,
  showSelectedMeasurement: false,
  filterIpVersion: null,
  filterProbeId: null,
  filterAgentId: null,
  filterAntiSpoofing: null,
  tableOrdering: "LOCALITY",
  tableOrderingAsc: false,
}

/* Actions */

export const CLEAR_MEASUREMENTS_AND_FILTERS =
  "simet-as/CLEAR_MEASUREMENTS_AND_FILTERS"

interface ClearMeasurementsAndFiltersAction {
  type: typeof CLEAR_MEASUREMENTS_AND_FILTERS
}

export const SET_FILTER_IP_VERSION = "simet-as/SET_FILTER_IP_VERSION"

interface SetMeasurementsFilterIpVersionAction {
  type: typeof SET_FILTER_IP_VERSION
  filterIpVersion: IpFamily | null
}

export const SET_FILTER_PROBE_ID = "simet-as/SET_FILTER_PROBE_ID"

interface SetMeasurementsFilterProbeIdAction {
  type: typeof SET_FILTER_PROBE_ID
  filterProbeId: string | null
}

export const SET_FILTER_AGENT_ID = "simet-as/SET_FILTER_AGENT_ID"

interface SetMeasurementsFilterAgentIdAction {
  type: typeof SET_FILTER_AGENT_ID
  filterAgentId: string | null
}

export const SET_FILTER_ANTI_SPOOFING = "simet-as/SET_FILTER_ANTI_SPOOFING"

interface SetMeasurementsFilterAntiSpoofingAction {
  type: typeof SET_FILTER_ANTI_SPOOFING
  filterAntiSpoofing: AntiSpoofingType
}

export const SET_MEASUREMENTS = "simet-as/SET_MEASUREMENTS"

interface SetSimetASAction {
  type: typeof SET_MEASUREMENTS
  measurements: Measurement | null | undefined
}

export const SET_MEASUREMENTS_HISTORY = "simet-as/SET_MEASUREMENTS_HISTORY"

interface SetMeasurementsHistoryAction {
  type: typeof SET_MEASUREMENTS_HISTORY
  measurementsHistory: MeasurementHistory[] | null | undefined
}

export const SET_WAITING_FOR_MEASUREMENTS_DATA =
  "simet-as/SET_WAITING_FOR_MEASUREMENTS_DATA"

interface SetWaitingForMeasurementsDataAction {
  type: typeof SET_WAITING_FOR_MEASUREMENTS_DATA
  isWaitingForData: boolean
}

export const SET_WAITING_FOR_MEASUREMENTS_HISTORY_DATA =
  "simet-as/SET_WAITING_FOR_MEASUREMENTS_HISTORY_DATA"

interface SetWaitingForMeasurementsHistoryDataAction {
  type: typeof SET_WAITING_FOR_MEASUREMENTS_HISTORY_DATA
  isWaitingForHistoryData: boolean
}

export const SET_MEASUREMENTS_ERROR = "simet-as/SET_MEASUREMENTS_ERROR"

interface SetMeasurementsErrorAction {
  type: typeof SET_MEASUREMENTS_ERROR
  error: ProblemError | null
}

export const SET_TABLE_ORDERING = "simet-as/SET_TABLE_ORDERING"

interface SetTableOrderingAction {
  type: typeof SET_TABLE_ORDERING
  tableOrdering: MeasurementTableOrdering
  tableOrderingAsc: boolean
}

export type SimetASAction =
  | ClearMeasurementsAndFiltersAction
  | SetMeasurementsFilterIpVersionAction
  | SetMeasurementsFilterProbeIdAction
  | SetMeasurementsFilterAgentIdAction
  | SetMeasurementsFilterAntiSpoofingAction
  | SetSimetASAction
  | SetMeasurementsHistoryAction
  | SetWaitingForMeasurementsDataAction
  | SetWaitingForMeasurementsHistoryDataAction
  | SetMeasurementsErrorAction
  | SetTableOrderingAction

/* Reducer */

export const measurementsReducer: Reducer<SimetASState, SimetASAction> = (
  state = INITIAL_STATE,
  action
) => {
  if (action.type === CLEAR_MEASUREMENTS_AND_FILTERS) {
    return INITIAL_STATE
  }

  if (action.type === SET_FILTER_IP_VERSION) {
    const { filterIpVersion } = action
    const newState: SimetASState = {
      ...state,
      filterIpVersion,
    }
    return newState
  }

  if (action.type === SET_FILTER_PROBE_ID) {
    const { filterProbeId } = action
    const newState: SimetASState = {
      ...state,
      filterProbeId,
    }
    return newState
  }

  if (action.type === SET_MEASUREMENTS) {
    const { measurements } = action
    const newState: SimetASState = {
      ...state,
      isWaitingForData: false,
      error: null,
      measurementsList: measurements ?? null,
    }
    return newState
  }

  if (action.type === SET_FILTER_AGENT_ID) {
    const { filterAgentId } = action
    const newState: SimetASState = {
      ...state,
      filterAgentId,
    }
    return newState
  }

  if (action.type === SET_FILTER_ANTI_SPOOFING) {
    const { filterAntiSpoofing } = action
    const newState: SimetASState = {
      ...state,
      filterAntiSpoofing,
    }
    return newState
  }

  if (action.type === SET_MEASUREMENTS_HISTORY) {
    const { measurementsHistory } = action
    const newState: SimetASState = {
      ...state,
      isWaitingForData: false,
      error: null,
      measurementsHistoryList: measurementsHistory ?? null,
    }
    return newState
  }

  if (action.type === SET_WAITING_FOR_MEASUREMENTS_DATA) {
    const { isWaitingForData } = action
    const newState: SimetASState = {
      ...state,
      isWaitingForData,
    }
    return newState
  }
  if (action.type === SET_WAITING_FOR_MEASUREMENTS_HISTORY_DATA) {
    const { isWaitingForHistoryData } = action
    const newState: SimetASState = {
      ...state,
      isWaitingForHistoryData,
    }
    return newState
  }

  if (action.type === SET_MEASUREMENTS_ERROR) {
    const { error } = action
    const newState: SimetASState = {
      ...state,
      error,
      isWaitingForData: error ? true : state.isWaitingForData,
    }
    return newState
  }

  if (action.type === SET_TABLE_ORDERING) {
    const { tableOrdering, tableOrderingAsc } = action
    const newState: SimetASState = {
      ...state,
      tableOrdering,
      tableOrderingAsc,
      measurementsHistoryList: state.measurementsHistoryList
        ? sortSimetAS(
            state.measurementsHistoryList,
            tableOrdering,
            tableOrderingAsc
          )
        : null,
    }
    return newState
  }

  // Assert that all known actions are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = action
  return state
}
