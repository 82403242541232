import React, { FunctionComponent, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ReducerState } from "@store/index"
import { ProblemError } from "@models/problem-error"
import ProblemErrorComponent from "../shared/problem-error-component"
import { SimetasProbe } from "@models/simetas-probe"
import { useQueryParams } from "use-query-params"
import { QueryParamConfig } from "@utils/query-params"

interface ReducerSelected {
  isWaitingForData: boolean
  error: ProblemError | null
  simetasProbes: SimetasProbe[] | null
}

const SimetasProbeSelector: FunctionComponent = ({}) => {
  /* React Hooks */
  const [
    ,
    // isHover
    setHover,
  ] = useState(false)

  const { isWaitingForData, error, simetasProbes } = useSelector<
    ReducerState,
    ReducerSelected
  >((state) => {
    return {
      isWaitingForData: state.simetasProbes.isWaitingForData,
      error: state.simetasProbes.error,
      simetasProbes: state.simetasProbes.simetasProbes,
    }
  })

  const [_searchParams, setSearchParams] = useQueryParams(QueryParamConfig)

  const { probeId } = _searchParams

  const handleSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault()
    const selectedProbeId = event.target.value
    if (selectedProbeId === "") {
      setSearchParams({ probeId: undefined })
    } else if (selectedProbeId !== probeId) {
      setSearchParams({ probeId: selectedProbeId })
    }
  }

  useEffect(() => {
    const arry = simetasProbes ?? []
    setSearchParams({
      probeId: probeId
        ? probeId
        : (arry?.length ?? 0) > 0
        ? arry[0].probeID
        : undefined,
    })
  }, [probeId, simetasProbes])

  const selectedSimetasProbe = probeId
    ? simetasProbes?.find((probe) => probe.probeID === probeId)
    : undefined

  // TODO: Use isHover
  return (
    <>
      {!error ? (
        <div className="relative group inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 focus:ring-blue-500">
          {isWaitingForData && <div>Carregando...</div>}
          {!isWaitingForData && (
            <>
              <div className="group inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 focus:ring-blue-500">
                {simetasProbes?.length === 0
                  ? "Nenhuma sonda disponível"
                  : selectedSimetasProbe
                  ? selectedSimetasProbe.description + " (sonda Simet-AS)"
                  : "Selecione uma sonda"}
              </div>
              <select
                className="absolute inset-0 group border-transparent leading-none inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 focus:ring-blue-500"
                onChange={handleSelection}
                onMouseEnter={() => setHover(true)}
                onFocus={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onBlur={() => setHover(false)}
                value={probeId ?? ""}
              >
                {simetasProbes?.map((probe) => {
                  const isSelected = probeId ? probe.probeID === probeId : false
                  return (
                    <option key={probe.probeID} value={probe.probeID}>
                      {`${probe.description} (sonda Simet-AS)`}
                    </option>
                  )
                })}
              </select>
            </>
          )}
        </div>
      ) : (
        <div className="max-w-sm rounded-lg bg-gray text-red-600 shadow-lg px-3 py-2">
          <ProblemErrorComponent problemError={error} titleClassName="hidden" />
        </div>
      )}
    </>
  )
}

export default SimetasProbeSelector
