import {
  RESOURCE_ADMIN_SCOPE,
  ScopeDescription,
} from "@models/scope-description"

export function buildScopeDescriptionsSorter(options?: {
  showAdminScopeFirst?: boolean
  showAdminScopeLast?: boolean
}): (first: ScopeDescription, second: ScopeDescription) => number {
  const {
    showAdminScopeFirst: showResourceScopeFirst,
    showAdminScopeLast: showResourceScopeLast,
  } = options ?? {}
  if (showResourceScopeFirst && showResourceScopeLast) {
    throw new Error(
      "Cannot have both resourceAdminFirst and resourceAdminLast as true"
    )
  }

  if (showResourceScopeFirst || showResourceScopeLast) {
    const resourceAdminPrevalence = showResourceScopeFirst ? -1 : 1
    return (first: ScopeDescription, second: ScopeDescription) => {
      if (first.scope === RESOURCE_ADMIN_SCOPE) {
        return resourceAdminPrevalence
      }
      if (second.scope === RESOURCE_ADMIN_SCOPE) {
        return -resourceAdminPrevalence
      }
      return first.name.localeCompare(second.name)
    }
  }

  return (first: ScopeDescription, second: ScopeDescription) =>
    first.name.localeCompare(second.name)
}
