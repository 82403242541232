import { Duration as DateDuration, isBefore, sub as subDate } from "date-fns"
import { DEFAULT_DATE_DURATION } from "@constants/default-date-duration"

/**
 * Get a safe [minDate, maxDate] interval to use for querying timestamped data, by passing a tuple of dates,
 * or a duration and an end date.
 *
 * @param minDate Initial timestamp of data to query for. Can be a date, a `date-fns` duration, or
 * undefined. If undefined, or set to a date greater than `maxDate`, a 6 hours duration will be used
 * as the default.
 * @param maxDate Final timestamp of data to query for. Can be a date of undefined. If undefined, the
 * current date will be used as the default.
 */
export function getQueryDateInterval(
  minDate?: Date | DateDuration,
  maxDate?: Date
): [minDate: Date, maxDate: Date] {
  const queryMaxDate = maxDate ?? new Date()
  const queryMinDate =
    minDate instanceof Date
      ? isBefore(minDate, queryMaxDate)
        ? minDate
        : (console.log("minDate greater than maxDate; using default"),
          subDate(queryMaxDate, DEFAULT_DATE_DURATION))
      : subDate(queryMaxDate, minDate ?? DEFAULT_DATE_DURATION)
  return [queryMinDate, queryMaxDate]
}
