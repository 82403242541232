import React, { ReactElement, useEffect, useRef, useState } from "react"
import { graphql, Link, PageProps } from "gatsby"
import { useKeycloak } from "@react-keycloak/web"
import Helmet from "react-helmet"
import axios from "axios"
import Header from "@components/shared/header"
import { ParsedToken } from "@models/parsed-token"
import { ProblemError } from "@models/problem-error"
import ProblemErrorComponent from "@components/shared/problem-error-component"
import Footer from "@components/shared/footer"
import IconSpinner from "@icons/icon-spinner.svg"
import IconChevronDown from "@icons/icon-chevron-down.svg"
import { GraphQLResponse } from "@_types/graphql-response"

interface GraphQLProps {
  site: {
    siteMetadata: {
      pageData: {
        supportTicketMaxCharacters: number
      }
      externalServices: {
        asAuthorization: {
          baseUrl: string
        }
      }
    }
  }
}

const SupportPage: (_: PageProps<GraphQLProps>) => ReactElement = ({
  data,
}) => {
  /* GraphQL data */
  const { supportTicketMaxCharacters } = data.site.siteMetadata.pageData
  const asAuthorizationUrl =
    data.site.siteMetadata.externalServices.asAuthorization.baseUrl

  /* React Hooks */
  const [disabledUserFields, setDisabledUserFields] = useState(false)
  const [messageType, setMessageType] = useState<
    "PROBLEM" | "SUGGESTION" | "OTHER"
  >("PROBLEM")
  const [messageLength, setMessageLength] = useState(0)
  const [isSendingMessage, setSendingMessage] = useState(false)
  const [ticketId, setTicketId] = useState<number>()
  const [contactProblemError, setContactProblemError] = useState<ProblemError>()
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()

  const nameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const textRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (isKeycloakInitialized && keycloak.authenticated) {
      setDisabledUserFields(true)
      const token = keycloak.tokenParsed as ParsedToken
      if (nameRef.current) {
        nameRef.current.value = token.name?.trim() || nameRef.current.value
      }
      if (emailRef.current) {
        emailRef.current.value = token.email?.trim() || emailRef.current.value
      }
    }
  }, [keycloak, keycloak.authenticated, isKeycloakInitialized])

  const onSelectType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMessageType(event.target.value as any)
  }

  const onTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (textRef.current) {
      const currLength = textRef.current.value.length
      const newLength = Math.min(currLength, supportTicketMaxCharacters)
      if (newLength < currLength) {
        textRef.current.value = textRef.current.value.slice(0, newLength)
      }
      setMessageLength(newLength)
    }
  }

  const sendSupportMessage = async () => {
    setTicketId(undefined)
    const name = nameRef.current?.value?.trim()
    if (!name) {
      setContactProblemError({
        detail: "Por favor, preencha o seu nome.",
      })
      return
    }
    const email = emailRef.current?.value?.trim()
    if (!email) {
      setContactProblemError({
        detail: "Por favor, preencha o seu endereço de e-mail.",
      })
      return
    }
    const text = textRef.current?.value?.trim()
    if (!text || text.length < 5) {
      setContactProblemError({
        detail:
          "Por favor, escreva a mensagem a ser enviada (mín. 5 caracteres).",
      })
      return
    }
    if (text.length > supportTicketMaxCharacters) {
      setContactProblemError({
        detail: `Por favor, utilize no máximo ${supportTicketMaxCharacters} caracteres.`,
      })
      return
    }

    setContactProblemError(undefined)
    try {
      setSendingMessage(true)
      const response = await axios.post<
        GraphQLResponse<{ supportContact: { id: number } }>
      >(
        `${asAuthorizationUrl}`,
        {
          query:
            `` +
            `mutation ($name: String, $email: String, $type: SupportTicketType!, $text: String!) { ` +
            `  supportContact(name: $name, email: $email, type: $type, text: $text) { ` +
            `    id ` +
            `  } ` +
            `}`,
          variables: {
            name,
            email,
            type: messageType,
            text,
          },
        },
        {
          headers: {
            ...(keycloak.authenticated
              ? { Authorization: `Bearer ${keycloak.token}` }
              : {}),
          },
        }
      )
      if (response.data.errors) {
        if (response.data.errors[0]) {
          setContactProblemError(JSON.parse(response.data.errors[0].message))
          return
        } else {
          throw new Error("Unknown error")
        }
      }
      if (response.data.data?.supportContact?.id) {
        if (textRef.current) {
          setMessageLength(0)
          textRef.current.value = ""
        }
        setTicketId(response.data.data.supportContact.id)
      } else {
        setContactProblemError({
          detail: "Ocorreu um erro desconhecido. Tente novamente mais tarde.",
        })
      }
    } catch (err: any) {
      if (
        err.response?.data?.errors &&
        err.response?.data?.errors[0]?.message
      ) {
        setContactProblemError(JSON.parse(err.response.data.errors[0].message))
      } else {
        setContactProblemError({
          detail:
            "Ocorreu um erro de conexão. Verifique sua Internet e tente novamente mais tarde.",
        })
      }
    } finally {
      setSendingMessage(false)
    }
  }

  return (
    <div className="flex flex-col min-h-screen h-full bg-slate-200">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Suporte - PAS</title>
      </Helmet>
      <Header />
      <div className="flex-grow max-w-3xl mx-auto mt-16 pb-24">
        <div>
          <p className="text-slate-600 mb-6">
            Antes de mandar uma mensagem, verifique a nossa{" "}
            <Link
              className="underline text-blue-500 hover:text-indigo-700"
              to="/help"
            >
              página de ajuda
            </Link>{" "}
            para ver as dúvidas mais frequentes.
          </p>
          <h1 className="text-2xl text-slate-700 font-medium mb-2">Contato</h1>
          <form
            onSubmit={(event) => {
              event.preventDefault()
              if (!isSendingMessage) {
                sendSupportMessage().catch(console.error)
              }
            }}
          >
            <fieldset className="flex flex-wrap">
              <div className="w-full sm:w-1/2 px-3 mb-3">
                <div className="ml-1 font-light text-blue-500">Nome</div>
                <input
                  className="block w-full px-4 py-2 border border-blue-500 rounded-lg text-slate-700 mb-2 bg-slate-100 focus:bg-white disabled:bg-slate-300 disabled:text-blue-500 focus:outline-none focus:ring"
                  disabled={disabledUserFields}
                  ref={nameRef}
                />
              </div>
              <div className="w-full sm:w-1/2 px-3 mb-3">
                <div className="ml-1 font-light text-blue-500">
                  Endereço de e-mail
                </div>
                <input
                  className="block w-full px-4 py-2 border border-blue-500 rounded-lg text-slate-700 mb-2 bg-slate-100 focus:bg-white disabled:bg-slate-300 disabled:text-blue-500 focus:outline-none focus:ring"
                  disabled={disabledUserFields}
                  ref={emailRef}
                />
              </div>
              <div className="w-full px-3 mb-3">
                <div className="ml-1 font-light text-blue-500">Assunto</div>
                <div className="relative">
                  <select
                    className="appearance-none block w-full px-4 py-2 border border-blue-500 rounded-lg text-slate-700 mb-2 bg-slate-100 focus:outline-none focus:ring"
                    onChange={onSelectType}
                    defaultValue="PROBLEM"
                  >
                    <option value="PROBLEM">Problema</option>
                    <option value="SUGGESTION">Sugestão</option>
                    <option value="OTHER">Outro assunto</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-slate-700">
                    <IconChevronDown className="h-4 w-4" />
                  </div>
                </div>
              </div>
              <div className="w-full px-3 mb-3">
                <div className="ml-1 font-light text-blue-500">Mensagem</div>
                <div className="relative">
                  <textarea
                    className="resize-none block w-full h-64 px-4 pt-2 pb-10 border border-blue-500 rounded-lg text-slate-700 mb-2 bg-slate-100 focus:bg-white focus:outline-none focus:ring"
                    ref={textRef}
                    onChange={onTextAreaChange}
                  />
                  <div className="pointer-events-none absolute bottom-0 right-0 px-2 py-2 text-sm text-blue-800 border border-blue-500 rounded-lg bg-blue-100">
                    <span
                      className={
                        messageLength < 5 ||
                        messageLength >= supportTicketMaxCharacters
                          ? "text-red-600"
                          : ""
                      }
                    >
                      {messageLength}
                    </span>{" "}
                    /{" "}
                    <span className="font-thin">
                      {supportTicketMaxCharacters}
                    </span>
                  </div>
                </div>
              </div>
              <button
                className={`relative mx-auto mt-3 ${
                  isSendingMessage
                    ? "bg-blue-600 border-blue-900"
                    : "bg-blue-400 border-blue-500 hover:bg-blue-300 hover:border-blue-400 focus:bg-blue-300 active:bg-blue-500 active:border-transparent"
                } text-white font-bold text-lg py-3 px-8 border-b-4 focus:border-transparent rounded-lg focus:outline-none focus:ring`}
                type="submit"
              >
                <span
                  className={
                    isSendingMessage ? "text-white text-opacity-0" : ""
                  }
                >
                  Enviar
                </span>
                <IconSpinner
                  className={`absolute inset-0 mx-auto my-auto animate-spin h-6 w-6 object-center ${
                    isSendingMessage ? "" : "opacity-0"
                  }`}
                />
              </button>
            </fieldset>
          </form>
          {contactProblemError && (
            <ProblemErrorComponent
              problemError={contactProblemError}
              detailClassName="mt-4 text-center px-6 text-red-600"
            />
          )}
          {ticketId && (
            <div className="mt-4 text-center px-6 text-green-500">
              Sucesso! A mensagem foi enviada como ticket #{ticketId}. Logo
              entraremos em contato.
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default SupportPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        pageData {
          supportTicketMaxCharacters
        }
        externalServices {
          asAuthorization {
            baseUrl
          }
        }
      }
    }
  }
`
