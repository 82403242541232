type LocationType = {
  [_: string] : string
} 

export const getStateNameByUF = (locationData: LocationType, uf?: string): string | undefined => {
  if (!uf) {
    return undefined;
  }
  return locationData[uf.toUpperCase()] ?? uf.toUpperCase();
}
