import React, { useState, FunctionComponent } from "react"

const ShareButton: FunctionComponent = () => {
  const [effect, setEffect] = useState(false)

  let copyUrlToClipboard = async () => {
    await navigator.clipboard.writeText(window.location.href)
    setEffect(true)
  }

  return (
    <button onClick={copyUrlToClipboard}
    className={`${effect && "animate-wiggle"} w-full flex items-center text-sm px-4 py-2 text-gray-700 hover:bg-gray-100`}
    onAnimationEnd={() => setEffect(false)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clipRule="evenodd" />
      </svg>
      Copiar o endereço
    </button >
  )
}

export default ShareButton
