import React, { FunctionComponent, memo, useEffect, useRef } from "react"
import { useKeycloak } from "@react-keycloak/web"
import { graphql, useStaticQuery } from "gatsby"
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import axios, { CancelTokenSource } from "axios"
import { Duration as DateDuration, formatISO, isBefore } from "date-fns"
import { ReducerAction } from "@store/index"
import {
  SET_MEASUREMENTS,
  SET_WAITING_FOR_MEASUREMENTS_DATA,
} from "@store/measurements-store"
import { SET_ALERT } from "@store/navigation-store"
import { SET_PAGINATION } from "@store/navigation-store"
import { GraphQLResponse } from "@_types/graphql-response"
// import { getQueryDateInterval } from "@utils/get-query-date-interval"
import { isEqualToDateDuration } from "@utils/is-equal-to-date-duration"
import { MeasurementSubcategory } from "@models/measurement-subcategory"
import { SerializedMeasurement } from "@models/serialized/serialized-measurement"
import { deserializeMeasurement } from "../serdes/deserialize-measurement"
import { IpFamily } from "@models/ip-family"
import { AgentFamily } from "@models/agent-family"
import { useFilterState } from "@store/use-filter-state"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        anomalyDetectionApi: {
          baseUrl: string
        }
      }
    }
  }
}

interface Props {
  asn: number
  minDate?: Date
  maxDate?: Date
  defaultDuration?: DateDuration
  setMinDate?: (_?: Date | DateDuration) => void
}

const MeasurementsService: FunctionComponent<Props> = ({
  asn,
  minDate,
  maxDate,
  defaultDuration,
  setMinDate,
}) => {
  /* GraphQL data */
  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          externalServices {
            anomalyDetectionApi {
              baseUrl
            }
          }
        }
      }
    }
  `)

  const { baseUrl } =
    data.site.siteMetadata.externalServices.anomalyDetectionApi

  /* React Hooks */
  const {
    filterSubcategories,
    filterIpVersions,
    filterAgentFamilies,
    filterAgentId,
    filterCursor,
  } = useFilterState()

  const dispatch = useDispatch<Dispatch<ReducerAction>>()
  const { keycloak } = useKeycloak()
  const prevFilterSubcategories = useRef<MeasurementSubcategory[] | null>(null)
  const prevFilterIpVersions = useRef<IpFamily | null>(null)
  const prevFilterAgentFamilies = useRef<AgentFamily[] | null>(null)
  const prevFilterAgentId = useRef<string | null>(null)
  const prevFilterCursor = useRef<string | null>(null)
  const prevAsn = useRef<number>(0)
  const prevMinDate = useRef<Date | DateDuration | undefined>(minDate)
  const prevMaxDate = useRef<Date | undefined>(maxDate)
  const cancelToken = useRef<CancelTokenSource>()
  const requestId = useRef<number>(0)
  const firstCall = useRef(true)
  if (!keycloak?.authenticated) {
    throw new Error("Cannot use MeasurementsService without authentication")
  }

  useEffect(() => {
    // Check if filters changed or if it is the initial data population
    if (
      firstCall.current ||
      prevFilterSubcategories.current !== filterSubcategories ||
      prevFilterIpVersions.current !== filterIpVersions ||
      prevFilterAgentFamilies.current !== filterAgentFamilies ||
      prevFilterAgentId.current !== filterAgentId ||
      prevFilterCursor.current !== filterCursor ||
      prevAsn.current !== asn ||
      prevMinDate.current !== minDate ||
      prevMaxDate.current !== maxDate
    ) {
      // Abort any existing request
      cancelToken.current?.cancel("MeasurementsService request canceled")
      cancelToken.current = undefined
      // Check if filters have become less specific or if it is the initial data population
      console.log("minDate", minDate)
      console.log("maxDate", maxDate)

      console.log("prevFilterCursor.current", prevFilterCursor.current)
      console.log("filterCursor", filterCursor)
      console.log(
        "prevFilterCursor.current !== null",
        prevFilterCursor.current !== null
      )
      console.log(
        "prevFilterCursor.current !== filterCursor",
        prevFilterCursor.current !== filterCursor
      )

      if (
        // -> Measurement subcategories: less specific if array was cleared or new array doesn't have all of previous types
        minDate !== undefined &&
        maxDate !== undefined &&
        ((prevFilterSubcategories.current !== null &&
          (filterSubcategories === null ||
            filterSubcategories?.some(
              (type) => !prevFilterSubcategories.current?.includes(type)
            ))) || // -> IP versions: less specific if array was cleared or new array doesn't have all of previous types
          (prevFilterIpVersions.current !== null &&
            prevFilterIpVersions.current !== filterIpVersions) || // -> Agent families: less specific if array was cleared or new array doesn't have all of previous types
          (prevFilterAgentFamilies.current !== null &&
            (filterAgentFamilies === null ||
              filterAgentFamilies.length !==
                prevFilterAgentFamilies?.current.length)) ||
          (prevFilterAgentFamilies.current?.length === 0 &&
            filterAgentFamilies.length > 0) ||
          (prevFilterAgentId.current !== null &&
            prevFilterAgentId.current !== filterAgentId) ||
          prevFilterCursor.current !== filterCursor ||
          prevAsn.current !== asn ||
          !isEqualToDateDuration(prevMinDate.current, minDate) || // TODO: Evaluate specificity on minDate?
          !isEqualToDateDuration(prevMaxDate.current, maxDate) || // TODO: Evaluate specificity on maxDate?
          firstCall.current)
      ) {
        console.log("calling measure after second validation")
        // Fetch new data from API
        ;(async () => {
          // const [queryMinDate, queryMaxDate] = getQueryDateInterval(
          //   minDate ?? defaultDuration,
          //   maxDate
          // )
          // if (
          //   setMinDate &&
          //   minDate instanceof Date &&
          //   isBefore(queryMinDate, minDate)
          // ) {
          //   prevMinDate.current = undefined // Avoid refetching on the next effect update
          //   setMinDate(undefined)
          // }
          const currentRequestId = requestId.current + 1
          requestId.current = currentRequestId
          try {
            dispatch({
              type: SET_WAITING_FOR_MEASUREMENTS_DATA,
              isWaitingForData: true,
            })
            dispatch({
              type: SET_MEASUREMENTS,
              measurements: [],
            })
            const newCancelToken = axios.CancelToken.source()
            cancelToken.current = newCancelToken
            const query = await axios.post<
              GraphQLResponse<{ measurements: SerializedMeasurement }>
            >(
              baseUrl,
              {
                // TODO: Remove unnecessary fields
                query:
                  `query ( ` +
                  `  $asn: Int!, $minDate: DateTime!, $maxDate: DateTime!, $subcategories: [MeasurementSubcategory!],` +
                  `  $ipVersion: [IPVersion!], $agentFamilies: [AgentFamily!], $agentId: UUID, $cursor: String` +
                  `) { ` +
                  `  measurements( ` +
                  `    asn: $asn, minDate: $minDate, maxDate: $maxDate, agentId: $agentId, subcategories: $subcategories, ` +
                  `    ipVersion: $ipVersion, agentFamilies: $agentFamilies, cursor: $cursor` +
                  `  ) { ` +
                  `pageCursors{` +
                  `previous{` +
                  `cursor` +
                  `}` +
                  `next{` +
                  `cursor` +
                  `}` +
                  `current{` +
                  `cursor` +
                  `}` +
                  `}` +
                  `node{` +
                  `datetime agentId asn subcategory protocol ipFamily enhancementLevel agentFamily agentVersion reportId ` +
                  `rawMetricId category peer peerLocalType peerLocalName unit preferentialValueColumn values measurementValue behindNAT carrierGradeNAT clientIdAddress clientIdPort clientIdTimestamp ` +
                  `detectedAnomalies { ` +
                  `  datetime asn agentId reportId anomalyType ipFamily category subcategory agentFamily ` +
                  `  engineName engineVersion found valueType referenceValue measuredValue ip peer sectorId ` +
                  `} ` +
                  `} ` +
                  `} ` +
                  `}`,
                variables: {
                  asn,
                  minDate: formatISO(minDate),
                  maxDate: formatISO(maxDate),
                  subcategories: filterSubcategories,
                  ipVersion:
                    filterIpVersions === "ALL"
                      ? ["IPV4", "IPV6"]
                      : [filterIpVersions],
                  agentFamilies: filterAgentFamilies,
                  agentId: filterAgentId !== "" ? filterAgentId : null,
                  cursor: filterCursor !== "" ? filterCursor : null,
                },
                cancelToken: newCancelToken.token,
              },
              {
                headers: {
                  Authorization: `Bearer ${keycloak.token}`,
                },
              }
            )
            if (query.data.errors) {
              if (query.data.errors[0]) {
                dispatch({
                  type: SET_ALERT,
                  alert: {
                    message_key:
                      "graphql_error:" +
                      JSON.parse(query.data.errors[0].message).title_key,
                  },
                })
                return
              }
            }
            if (requestId.current !== currentRequestId) {
              return
            }
            if (query.data.data?.measurements) {
              dispatch({
                type: SET_PAGINATION,
                pagination: query.data.data.measurements?.pageCursors,
              })

              dispatch({
                type: SET_MEASUREMENTS,
                measurements: (query.data.data.measurements?.node).map((x) =>
                  deserializeMeasurement(x, false)
                ),
              })
              // Re-apply filters to be safe (in case of races with setting filters)
              // dispatch({
              //   type: UPDATE_MEASUREMENTS_WITH_FILTERS,
              // })
            } else {
              dispatch({
                type: SET_ALERT,
                alert: {
                  message_key: "http_error:" + query.status,
                },
              })
            }
          } catch (error: any) {
            if (axios.isCancel(error)) {
              console.log("MeasurementsService request canceled")
              return
            }
            if (requestId.current !== currentRequestId) {
              return
            }
            console.error("Error when fetching measurements", error)
            dispatch({
              type: SET_ALERT,
              alert: {
                message_key:
                  "http_error:" + (error?.response?.status ?? error?.message),
              },
            })
          } finally {
            dispatch({
              type: SET_WAITING_FOR_MEASUREMENTS_DATA,
              isWaitingForData: false,
            })
          }
        })()
      }
      // else {
      //   // New data is subset of current data; filter locally, without a new API request
      //   dispatch({ type: UPDATE_MEASUREMENTS_WITH_FILTERS })
      // }
      firstCall.current = false
      prevFilterSubcategories.current = filterSubcategories
      prevFilterIpVersions.current = filterIpVersions
      prevFilterAgentFamilies.current = filterAgentFamilies
      prevFilterAgentId.current = filterAgentId
      prevAsn.current = asn
      prevMinDate.current = minDate
      prevMaxDate.current = maxDate
      prevFilterCursor.current = filterCursor
    }
  }, [
    filterSubcategories,
    filterIpVersions,
    filterAgentFamilies,
    filterAgentId,
    filterCursor,
    firstCall,
    dispatch,
    asn,
    minDate,
    maxDate,
    defaultDuration,
    setMinDate,
    baseUrl,
    keycloak,
  ])

  // Cleanup
  useEffect(() => {
    return () => {
      // Abort any current request
      requestId.current = 0
      cancelToken.current?.cancel("MeasurementsService request canceled")
    }
  }, [dispatch])

  return <></>
}

export default memo(MeasurementsService)
