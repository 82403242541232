import React, { FunctionComponent, useState } from "react"
import { Link, withPrefix } from "gatsby"
import { useKeycloak } from "@react-keycloak/web"
import { ParsedToken } from "@models/parsed-token"
import { Transition } from "@headlessui/react"
import PasIcon from "@icons/pas-icon.svg"
import IconSpinner from "@icons/icon-spinner.svg"
import IconBurgerMenu from "@icons/icon-burger-menu.svg"
import IconChevronDown from "@icons/icon-chevron-down.svg"

const Header: FunctionComponent = () => {
  /* React Hooks */
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isUserSubmenuOpen, setUserSubmenuOpen] = useState(false)

  const username =
    (keycloak?.tokenParsed as ParsedToken)?.preferred_username || "Anônimo(a)"

  return (
    <nav className="flex items-center justify-between flex-wrap md:flex-no-wrap bg-blue-500 p-6 md:p-4">
      <Link className="flex-shrink-0 flex-grow md:flex-grow-0 md:mr-6" to="/">
        <button className="flex w-full items-center text-white md:pb-1 hover:underline focus:underline">
          <PasIcon className="h-8 w-8 mr-3" />
          <div className="font-semibold text-xl tracking-tight">
            Portal do AS
          </div>
        </button>
      </Link>
      <div className="block md:hidden">
        <button
          onClick={() => setMenuOpen((isMenuOpen) => !isMenuOpen)}
          className="flex items-center px-3 py-2 border rounded text-blue-200 border-blue-300 hover:text-white hover:border-white focus:text-white focus:border-white"
        >
          <IconBurgerMenu className="h-3 w-3" />
        </button>
      </div>
      <div
        className={`w-full ${
          isMenuOpen ? "block" : "hidden"
        } flex-grow md:flex-1 md:flex md:items-center md:w-auto md:flex-wrap`}
      >
        <div className="text-base md:text-sm flex-shrink-0 md:flex-grow">
          <Link to="/user-as-associations/">
            <button className="block text-left w-full mt-4 md:inline-block md:mt-0 md:w-auto text-blue-100 hover:text-white hover:underline focus:underline mr-4">
              Consultar ASN
            </button>
          </Link>
          <Link to="/as-permissions/">
            <button className="block text-left w-full mt-4 md:inline-block md:mt-0 md:w-auto text-blue-100 hover:text-white hover:underline focus:underline mr-4">
              Permissões
            </button>
          </Link>
          <Link to="/support/">
            <button className="block text-left w-full mt-4 md:inline-block md:mt-0 md:w-auto text-blue-100 hover:text-white hover:underline focus:underline mr-4">
              Suporte
            </button>
          </Link>
        </div>
        <div className="text-base md:text-sm md:flex-shrink">
          {isKeycloakInitialized ? (
            keycloak.authenticated ? (
              <div className="relative inline-block text-left w-full md:w-auto">
                <button
                  // onClick={() => keycloak.logout({redirectUri: window.location.origin + withPrefix('/')})}
                  onClick={() => setUserSubmenuOpen(!isUserSubmenuOpen)}
                  className="w-full px-4 py-2 leading-none rounded border text-white border-white hover:border-transparent hover:text-blue-500 hover:bg-white focus:text-blue-500 focus:bg-white mt-4 md:mt-0"
                >
                  <div className="flex items-center justify-between">
                    <div className="truncate">{username}</div>
                    <IconChevronDown
                      className={`ml-1 -mr-2 h-4 w-4 transform ${
                        isUserSubmenuOpen && "rotate-180"
                      }`}
                    />
                  </div>
                </button>
                <Transition
                  show={isUserSubmenuOpen}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                  className="origin-top-right absolute z-10 right-0 mt-2 w-64 rounded-md shadow-lg"
                >
                  <div className="border border-white rounded-md bg-blue-500 ring-1 ring-black ring-opacity-5">
                    <div className="py-2">
                      <button
                        onClick={() => keycloak.accountManagement()}
                        className="block w-full text-left px-4 py-2 text-sm leading-5 text-white hover:bg-white hover:text-blue-500 focus:outline-none focus:bg-white focus:text-blue-500"
                      >
                        Configurações da conta
                      </button>
                      <button
                        onClick={() =>
                          keycloak.logout({
                            redirectUri:
                              window.location.origin + withPrefix("/"),
                          })
                        }
                        className="block w-full text-left px-4 py-2 text-sm leading-5 text-white hover:bg-white hover:text-blue-500 focus:outline-none focus:bg-white focus:text-blue-500"
                      >
                        Sair
                      </button>
                    </div>
                  </div>
                </Transition>
              </div>
            ) : (
              <button
                onClick={() => {
                  let href = window.location.href
                  // if (!window.location.pathname.endsWith("/")) {
                  //   href = `${window.location.origin}${window.location.pathname}/${window.location.search}${window.location.hash}`
                  // }
                  keycloak.login({ redirectUri: href })
                }}
                className="inline-block text-left px-4 py-2 mt-4 md:mt-0 w-full md:w-auto leading-none rounded text-black bg-blue-300 hover:text-blue-500 hover:bg-white"
              >
                Fazer login
              </button>
            )
          ) : (
            <button className="inline-block px-4 py-2 leading-none text-white cursor-default mt-4 md:mt-0">
              <IconSpinner className="animate-spin h-5 w-5 -m-1" />
            </button>
          )}
        </div>
      </div>
    </nav>
  )
}

export default Header
