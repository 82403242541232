import { IP_FAMILIES } from "@models/ip-family"

import { MeasurementHistory } from "@models/simet-as"
import {
  MeasurementGroupedIpFamily,
  MeasurementIpFamily,
  MeasurementLocality,
} from "@models/serialized/serialized-simet-as"

const sortCode = (name: string) => {
  switch (name) {
    case "IX-sp":
      return "1"
    case "IX-ce":
      return "2"
    case "IX-rj":
      return "3"
    default:
      return name
  }
}

function getSortingIndexInList<T>(
  value: T | undefined,
  list: readonly T[]
): number {
  if (!value) {
    return list.length
  }
  const index = list.indexOf(value)
  return index < 0 ? list.length : index
}
export function compareMeasurementLocalityAsc(
  first: MeasurementHistory,
  second: MeasurementHistory
): number {
  return first.locality.localeCompare(second.locality)
}

export function compareMeasurementIpVersionAsc(
  first: MeasurementHistory,
  second: MeasurementHistory
): number {
  return (
    getSortingIndexInList(first.ipVersion, IP_FAMILIES) -
    getSortingIndexInList(second.ipVersion, IP_FAMILIES)
  )
}

export function compareMeasurementAvailabilityPercentageAsc(
  first: MeasurementHistory,
  second: MeasurementHistory
): number {
  return first.availabilityPercentage - second.availabilityPercentage
}
