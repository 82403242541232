import { Reducer } from "redux"
import { AutonomousSystem } from "@models/autonomous-system"
import { ProblemError } from "@models/problem-error"
import { ASUserAssociationMapping } from "@models/as-user-association-mapping"

/* State */

export interface AutonomousSystemsState {
  isWaitingForData: boolean
  error: ProblemError | null
  availableAutonomousSystems: AutonomousSystem[] | null
  userAssociationMapping: ASUserAssociationMapping | null
}

const INITIAL_STATE: AutonomousSystemsState = {
  isWaitingForData: true,
  error: null,
  availableAutonomousSystems: null,
  userAssociationMapping: null,
}

/* Actions */

export const SET_AVAILABLE_AUTONOMOUS_SYSTEMS =
  "autonomousSystems/SET_AVAILABLE_AUTONOMOUS_SYSTEMS"

interface SetAvailableAutonomousSystemsAction {
  type: typeof SET_AVAILABLE_AUTONOMOUS_SYSTEMS
  autonomousSystems: AutonomousSystem[]
}

export const CLEAR_AVAILABLE_AUTONOMOUS_SYSTEMS =
  "autonomousSystems/CLEAR_AVAILABLE_AUTONOMOUS_SYSTEMS"

interface ClearAvailableAutonomousSystemsAction {
  type: typeof CLEAR_AVAILABLE_AUTONOMOUS_SYSTEMS
}

export const SET_WAITING_FOR_AVAILABLE_AUTONOMOUS_SYSTEMS_DATA =
  "autonomousSystems/SET_WAITING_FOR_AVAILABLE_AUTONOMOUS_SYSTEMS_DATA"

interface SetWaitingForAvailableAutonomousSystemsAction {
  type: typeof SET_WAITING_FOR_AVAILABLE_AUTONOMOUS_SYSTEMS_DATA
  isWaitingForData: boolean
}

export const SET_AVAILABLE_AUTONOMOUS_SYSTEMS_ERROR =
  "autonomousSystems/SET_AVAILABLE_AUTONOMOUS_SYSTEMS_ERROR"

interface SetAvailableAutonomousSystemsErrorAction {
  type: typeof SET_AVAILABLE_AUTONOMOUS_SYSTEMS_ERROR
  error: ProblemError | null
}

export const SET_WAITING_FOR_USER_ASSOCIATIONS_DATA =
  "autonomousSystems/SET_WAITING_FOR_USER_ASSOCIATIONS_DATA"

interface SetWaitingForUserAssociationRequestAction {
  type: typeof SET_WAITING_FOR_USER_ASSOCIATIONS_DATA
  isWaitingForData: boolean
}

export const SET_USER_ASSOCIATIONS_ERROR =
  "autonomousSystems/SET_USER_ASSOCIATIONS_ERROR"

interface SetUserAssociationsErrorAction {
  type: typeof SET_USER_ASSOCIATIONS_ERROR
  error: ProblemError | null
}

export const SET_USER_ASSOCIATIONS =
  "autonomousSystems/SET_USER_ASSOCIATIONS"

interface SetUserAssociationsAction {
  type: typeof SET_USER_ASSOCIATIONS
  userAssociationMapping: ASUserAssociationMapping
}

export type AutonomousSystemsAction =
  | SetAvailableAutonomousSystemsAction
  | ClearAvailableAutonomousSystemsAction
  | SetWaitingForAvailableAutonomousSystemsAction
  | SetAvailableAutonomousSystemsErrorAction
  | SetWaitingForUserAssociationRequestAction
  | SetUserAssociationsErrorAction
  | SetUserAssociationsAction

/* Reducer */

export const autonomousSystemsReducer: Reducer<
  AutonomousSystemsState,
  AutonomousSystemsAction
> = (state = INITIAL_STATE, action) => {
  if (action.type === CLEAR_AVAILABLE_AUTONOMOUS_SYSTEMS) {
    return INITIAL_STATE
  }

  if (action.type === SET_AVAILABLE_AUTONOMOUS_SYSTEMS) {
    const newState: AutonomousSystemsState = {
      ...state,
      availableAutonomousSystems: action.autonomousSystems.sort(
        (first, second) => first.asn - second.asn
      ),
      // isWaitingForData: false,
      // error: null,
    }
    return newState
  }

  if (action.type === SET_WAITING_FOR_AVAILABLE_AUTONOMOUS_SYSTEMS_DATA) {
    const { isWaitingForData } = action
    const newState: AutonomousSystemsState = {
      ...state,
      isWaitingForData,
    }
    return newState
  }

  if (action.type === SET_AVAILABLE_AUTONOMOUS_SYSTEMS_ERROR) {
    const { error } = action
    const newState: AutonomousSystemsState = {
      ...state,
      error,
      // isWaitingForData: error ? true : state.isWaitingForData,
    }
    return newState
  }

  if (action.type === SET_WAITING_FOR_USER_ASSOCIATIONS_DATA) {
    const { isWaitingForData } = action
    const newState: AutonomousSystemsState = {
      ...state,
      isWaitingForData,
    }
    return newState
  }

  if (action.type === SET_USER_ASSOCIATIONS) {
    const newState: AutonomousSystemsState = {
      ...state,
      userAssociationMapping: action.userAssociationMapping,
      isWaitingForData: false,
      error: null,
    }
    return newState
  }

  // Assert that all known actions are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = action
  return state
}
