import { Reducer } from "redux"
import { SimetasProbe } from "@models/simetas-probe"
import { ProblemError } from "@models/problem-error"

/* State */

export interface SimetasProbesState {
  isWaitingForData: boolean
  error: ProblemError | null
  simetasProbes: SimetasProbe[] | null
}

const INITIAL_STATE: SimetasProbesState = {
  isWaitingForData: true,
  error: null,
  simetasProbes: null,
}

/* Actions */

export const SET_SIMETAS_PROBES = "simetasProbes/SET_AVAILABLE_SIMETAS_PROBES"

interface SetSimetasProbesAction {
  type: typeof SET_SIMETAS_PROBES
  simetasProbes: SimetasProbe[]
}

export const CLEAR_SIMETAS_PROBES = "simetasProbes/CLEAR_SIMETAS_PROBES"

interface ClearSimetasProbesAction {
  type: typeof CLEAR_SIMETAS_PROBES
}

export const SET_WAITING_FOR_SIMETAS_PROBES_DATA =
  "simetasProbes/SET_WAITING_FOR_SIMETAS_PROBES_DATA"

interface SetWaitingForSimetasProbesAction {
  type: typeof SET_WAITING_FOR_SIMETAS_PROBES_DATA
  isWaitingForData: boolean
}

export const SET_SIMETAS_PROBES_ERROR = "simetasProbes/SET_SIMETAS_PROBES_ERROR"

interface SetSimetasProbesErrorAction {
  type: typeof SET_SIMETAS_PROBES_ERROR
  error: ProblemError | null
}

export type SimetasProbesAction =
  | SetSimetasProbesAction
  | ClearSimetasProbesAction
  | SetWaitingForSimetasProbesAction
  | SetSimetasProbesErrorAction

/* Reducer */

export const simetasProbesReducer: Reducer<
  SimetasProbesState,
  SimetasProbesAction
> = (state = INITIAL_STATE, action) => {
  if (action.type === CLEAR_SIMETAS_PROBES) {
    return INITIAL_STATE
  }

  if (action.type === SET_SIMETAS_PROBES) {
    const newState: SimetasProbesState = {
      ...state,
      simetasProbes: action.simetasProbes.sort((first, second) =>
        first.probeID.localeCompare(second.probeID)
      ),
      isWaitingForData: false,
      error: null,
    }
    return newState
  }

  if (action.type === SET_WAITING_FOR_SIMETAS_PROBES_DATA) {
    const { isWaitingForData } = action
    const newState: SimetasProbesState = {
      ...state,
      isWaitingForData,
    }
    return newState
  }

  if (action.type === SET_SIMETAS_PROBES_ERROR) {
    const { error } = action
    const newState: SimetasProbesState = {
      ...state,
      error,
      isWaitingForData: error ? true : state.isWaitingForData,
    }
    return newState
  }

  // Assert that all known actions are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = action
  return state
}
