import { ElementType } from "@_types/element-type"

export const RESOURCE_ADMIN_SCOPE = "resource-admin"

export const SCOPE_NAMES = [
  RESOURCE_ADMIN_SCOPE,
  "as:anomaly-detection:view",
  "as:measurements:view",
  "as:simet-as:view",
] as const

export type ScopeName = ElementType<typeof SCOPE_NAMES>

export interface ScopeDescription {
  scope: ScopeName
  name: string
  appDescription: string
  permissionDescription: string
}
