import React, { FunctionComponent } from "react";
import { ReducerState } from "@store/index"
import { useSelector } from "react-redux"
import { AntiSpoofing } from "@models/simet-as"

interface ReducerSelected {
  antiSpoofingList: AntiSpoofing[] | null
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

const CardAntiSpoofingComponent: FunctionComponent = () => {
  const { antiSpoofingList } =
    useSelector<ReducerState, ReducerSelected>((state) => {
      return {
        antiSpoofingList: state.antiSpoofing.antiSpoofingList,
      }
    })

  const antispoofing = antiSpoofingList ?? []

  return (
    <>
      {
        antispoofing?.map((res, resIdx) => {

          const desconhecido = res.pontoMedicao == 0
          const conforme = res.semFiltragem == 0 && !desconhecido
          const naoConforme = res.semFiltragem >= 1 && !desconhecido

          return (
            <>
              {/* Conforme */}
              <div className={classNames(
                "w-full lg:w-96 h-36 flex flex-col items-center p-3 rounded-lg shadow-xl ring-1 ring-gray-200",
                conforme ? "text-white bg-green-600" :
                  naoConforme ? "text-white bg-red-600" :
                    "text-slate-400 bg-slate-200"
              )}
              key={resIdx}
              >
                <div className={classNames(
                  "flex items-center flex-grow text-3xl font-medium -pt-2",
                  desconhecido ? "text-center" : "")}
                >
                  Antispoofing
                  {desconhecido ? (
                    <div className="text-lg text-slate-500">Conformidade desconhecida</div>
                  ) : (<> </>)}
                </div>
                {/* {naoConforme ? (
                  <div className="flex justify-end w-full mb-0.5 -mt-0.5">
                    <div className="flex items-center text-sm font-medium leading-tight">
                      {res.semFiltragem ?? 0} sem filtragem
                    </div>
                  </div>
                ) : (<> </>)} */}
                <div className="flex justify-between w-full">
                  <div className="flex items-center text-sm font-medium leading-tight">
                    <svg xmlns="http://www.w3.org/2000/svg" className={classNames(
                      "h-4 w-4 mr-1 flex-shrink-0",
                      conforme ? "text-green-100" :
                        naoConforme ? "text-red-100" :
                          "text-slate-400"
                    )} viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-13a.75.75 0 00-1.5 0v5c0 .414.336.75.75.75h4a.75.75 0 000-1.5h-3.25V5z" clipRule="evenodd" />
                    </svg>
                    24h analisados
                  </div>
                  <div className="flex items-center text-sm font-medium leading-none">
                    <svg xmlns="http://www.w3.org/2000/svg" className={classNames(
                      "h-4 w-4 mr-1 flex-shrink-0",
                      conforme ? "text-green-100" :
                        naoConforme ? "text-red-100" :
                          "text-slate-400"
                    )} viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M2 4.25A2.25 2.25 0 014.25 2h11.5A2.25 2.25 0 0118 4.25v8.5A2.25 2.25 0 0115.75 15h-3.105a3.501 3.501 0 001.1 1.677A.75.75 0 0113.26 18H6.74a.75.75 0 01-.484-1.323A3.501 3.501 0 007.355 15H4.25A2.25 2.25 0 012 12.75v-8.5zm1.5 0a.75.75 0 01.75-.75h11.5a.75.75 0 01.75.75v7.5a.75.75 0 01-.75.75H4.25a.75.75 0 01-.75-.75v-7.5z" clipRule="evenodd" />
                    </svg>
                    {res.pontoMedicao ?? 0}{res.pontoMedicao == 1 ? " ponto" : " pontos"} de medição
                  </div>
                </div>
              </div>
            </>
          )
        })
      }
    </>
  )
}

export default CardAntiSpoofingComponent;