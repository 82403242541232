import React, { FunctionComponent, useState } from "react"
import { useSelector } from "react-redux"
import { AutonomousSystemScopeMapping } from "@models/autonomous-system-scope-mapping"
import { ProblemError } from "@models/problem-error"
import { ReducerState } from "@store/index"
import RequireAuthentication from "../shared/require-authentication"
import { AutonomousSystem } from "@models/autonomous-system"
import UserAssociationService from "@services/user-association-service"
import { ASUserAssociationMapping } from "@models/as-user-association-mapping"

interface Props {
  
}

interface ReducerSelected {
  scopeMappingList: AutonomousSystemScopeMapping[] | null
  isFetchingScopeMappings: boolean
  scopeMappingsError: ProblemError | null
  availableAutonomousSystems: AutonomousSystem[] | null
  userAssociationRequests: ASUserAssociationMapping | null
}

const ASPendigUserRequestTable: FunctionComponent<Props> = () => {
  /* Redux Hooks */
  const { userAssociationRequests } = useSelector<
    ReducerState,
    ReducerSelected
  >((state) => {
    return {
      scopeMappingList: state.asScopeMapping.scopeMappingList,
      isFetchingScopeMappings: state.asScopeMapping.isFetchingScopeMappings,
      scopeMappingsError: state.asScopeMapping.scopeMappingsError,
      availableAutonomousSystems:
        state.autonomousSystems.availableAutonomousSystems,
      userAssociationRequests: state.autonomousSystems.userAssociationMapping,
    }
  })

  const [setAsn] = useState<number>()
  let hasData = false
  let asInfoData: {} | null | undefined = null
  return (
    <>
      <RequireAuthentication>
        <>
          <UserAssociationService />
          {/* Solicitações pendentes */}
          <>
            <div>
              <h3 className="text-lg font-medium text-slate-600 mb-1">
                Solicitações pendentes
              </h3>
              <>
                {userAssociationRequests !== null &&
                  userAssociationRequests !== undefined ? (
                  <>
                    {userAssociationRequests?.userAssociationDateList !== null &&
                      userAssociationRequests?.userAssociationDateList.filter(
                        (resource) => {
                          return (
                            (resource.validation_date === null ||
                              resource.validation_date === undefined) &&
                            new Date(
                              new Date(
                                resource.expiration_date.toString()
                              ).toUTCString()
                            ).getTime() >=
                            new Date(new Date().toUTCString()).getTime()
                          )
                        }
                      ).length > 0
                      ? (hasData = true)
                      : (hasData = false)}
                    {hasData ? (
                      <>
                        <p className="text-sm text-slate-500 max-w-lg">
                          Aguarde a resposta. Cada solicitação é válida por 5
                          dias. Caso o responsável não recebeu o email de
                          solicitação, entre em contato com:{" "}
                          <span className="font-medium">suporte@pas.nic.br</span>
                        </p>
                        <table className="min-w-full divide-y divide-slate-300 mt-2">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-900 sm:pl-6 md:pl-0"
                              >
                                AS
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-3 text-left text-sm font-semibold text-slate-900"
                              >
                                Nome do provedor
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-3 text-left text-sm font-semibold text-slate-900"
                              >
                                Email notificado
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-3 text-left text-sm font-semibold text-slate-900"
                              >
                                Data da solicitação
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-slate-200">
                            {userAssociationRequests &&
                              userAssociationRequests.userAssociationDateList &&
                              userAssociationRequests?.userAssociationDateList
                                .filter((resource) => {
                                  return (
                                    (resource.validation_date === null ||
                                      resource.validation_date === undefined) &&
                                    new Date(
                                      new Date(
                                        resource.expiration_date.toString()
                                      ).toUTCString()
                                    ).getTime() >=
                                    new Date(new Date().toUTCString()).getTime()
                                  )
                                })
                                .map((request) => (
                                  <tr key={request.asn.toString()}>
                                    <>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-slate-900 sm:pl-6 md:pl-0">
                                      <>{request.asn}</>
                                    </td>
                                    {userAssociationRequests &&
                                      userAssociationRequests.aSInfoList &&
                                      userAssociationRequests.aSInfoList.length >
                                      0 &&
                                      userAssociationRequests.aSInfoList.forEach(
                                        (element) => {
                                          if (element.asn === request.asn) {
                                            asInfoData = element.asName
                                            return
                                          }
                                        }
                                      )}
                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-slate-500">
                                      <>{asInfoData}</>
                                    </td>
                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-slate-500 ">
                                      {request.admin_contact_email}
                                    </td>
                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-slate-500">
                                      {new Date(
                                        request.creation_date
                                      ).toLocaleDateString("pt-BR")}
                                    </td>
                                    </>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <p className="text-sm text-slate-500 max-w-lg">
                          Você não possuí solicitações de vínculo pendentes no
                          momento.
                        </p>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            </div>
          </>
        </>
      </RequireAuthentication>
    </>
  )
}
export default ASPendigUserRequestTable
