import React, {
  useState,
  FunctionComponent,
  useEffect,
  Fragment,
} from "react"
import { Transition, Listbox } from "@headlessui/react"
import {
  QueryParamConfig,
  selectedPeriod,
  formatISO,
} from "@utils/query-params"
import { useQueryParams } from "use-query-params"
import { ClockIcon } from "@heroicons/react/outline"
import { formatPeriodType, PeriodType, PERIOD_INTERVAL } from "@models/period-type"
import { DateRange } from 'react-date-range';
import { ptBR } from "date-fns/locale"

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

interface Props {
  period?: PeriodType[] | undefined;
}

const PeriodInput: FunctionComponent<Props> = ({ period }) => {
  const [_searchParams, setSearchParams] = useQueryParams(QueryParamConfig)

  const [showModalCalendar, setShowModalCalendar] = useState(false)
  const [customOpen, setCustomOpen] = useState(false)

  const { periodo, from, to } = _searchParams

  const period_arry = period?.slice() ?? PERIOD_INTERVAL.slice()

  const [selected, setSelected] = useState(periodo as PeriodType ?? period_arry[0])

  const [calendar, setCalendar] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  })

  const handleSelectionCalendar = (item: {
    selection: {
      startDate: Date,
      endDate: Date
    }
  }) => {
    const { startDate, endDate } = item.selection

    const [fromFormated, toFormated] = selectedPeriod(
      "CUSTOM",
      formatISO(startDate) as string,
      formatISO(endDate) as string,
      false
    )
    setSearchParams({cursor: undefined})
    setSearchParams({
      periodo: "CUSTOM",
      from: fromFormated as string,
      to: toFormated as string,
    })

    setCalendar({
      startDate: startDate,
      endDate: endDate,
      key: "selection"
    })

    setShowModalCalendar(false)
  }

  useEffect(() => {
    if(selected === "CUSTOM") setShowModalCalendar(true)
    setSearchParams({cursor: undefined})
    setSearchParams({
      periodo: selected,
      from: undefined,
      to: undefined,
    })
  }, [selected, periodo, from, to])

  return (
    <>
      <Listbox
        value={selected}
        onChange={setSelected}
        as="div"
        className="z-20 relative"
      >
        {({ open }) => (
          <>
            <Listbox.Button
              className="group inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 focus:ring-blue-500"
              onClick={() => {setCustomOpen(true); setShowModalCalendar(false)}}
            >
              <ClockIcon className="h-5 w-5 mr-2 flex-none" />
              {formatPeriodType(selected)}
            </Listbox.Button>
            {(open && customOpen) ? (
              <>
                <Transition
                  // as={Fragment}
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Listbox.Options className="font-normal origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none space-y-1 z-20">
                    {period_arry.filter(p => p !== "CUSTOM").map((res, idx) => (
                      <>
                        {(idx !== 0 && idx % 2 == 0) && (<hr />)}

                        <Listbox.Option
                          key={idx}
                          className={({ active }) =>
                            classNames(
                              active ? "bg-slate-100" : "",
                              "block px-4 py-2 text-sm text-slate-700"
                            )
                          }
                          value={res}
                        >
                          {({ selected }) => (
                            <>
                              <button
                                className={`block truncate ${selected ? "font-medium" : "font-normal"
                                  }`}
                              >
                                {formatPeriodType(res)}
                              </button>
                            </>
                          )}
                        </Listbox.Option>
                      </>
                    ))}
                  </Listbox.Options>
                </Transition>
              </>
            ) : (
              <>
                {!open && setCustomOpen(false)}
                {/* {(!open && !customOpen) && setModalCalendar(false)} */}
              </>
            )}
            <Transition
              as={Fragment}
              show={showModalCalendar}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <div className="top-0 absolute mt-12 right-0 z-20">
                <DateRange
                  ranges={[calendar]}
                  displayMode={'dateRange'}
                  editableDateInputs={false}
                  maxDate={new Date()}
                  locale={ptBR}
                  moveRangeOnFirstSelection={true}
                  onChange={(item: any) => handleSelectionCalendar(item)}
                  fixedHeight={false}
                  startDatePlaceholder={"Data de ínicio"}
                  endDatePlaceholder={"Data de término"}
                />
              </div>
            </Transition>
          </>
        )}
      </Listbox>

    </>
  )
}
export default PeriodInput
