import React, { FunctionComponent, memo } from "react";
import { XCircleIcon } from "@heroicons/react/solid"

interface Props {
  setCustomOpen: (_: boolean) => void
  agentID: string
  setAgentID: (_: string) => void
}

const AgentIdComponent: FunctionComponent<Props> = ({
  setCustomOpen,
  agentID,
  setAgentID
}) => {
  return (
    <>
      <div className="w-full">
        <label htmlFor="agentID" className="sr-only">
          ID do agente de medição
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type="text"
            id="agentID"
            onChange={(evt) => setAgentID(evt.target.value)}
            className="form-input w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-slate-300 rounded-md"
            placeholder="ID do medidor"
            value={agentID}
          />
          <div
            onClick={() => setAgentID('')}
            className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer text-slate-400 hover:text-red-400"
          >
            <XCircleIcon className="w-6 h-6" />
          </div>
        </div>
        <button
          type="button"
          onClick={() => setCustomOpen(false)}
          className="mt-4 w-full text-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Buscar medidor
        </button>
      </div>
    </>
  )
}

export default memo(AgentIdComponent);