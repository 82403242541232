import { AutonomousSystemScopeMapping } from "@models/autonomous-system-scope-mapping"
import { Reducer } from "redux"
import { rearrangeArrayBySelection } from "@utils/rearrange-array-by-selection"
import { ProblemError } from "@models/problem-error"

/* State */

export interface AsScopeMappingState {
  scopeMappingList: AutonomousSystemScopeMapping[] | null
  selectedScopeMapping: AutonomousSystemScopeMapping | null
  isFetchingScopeMappings: boolean
  scopeMappingsError: ProblemError | null
}

const INITIAL_STATE: AsScopeMappingState = {
  scopeMappingList: null,
  selectedScopeMapping: null,
  isFetchingScopeMappings: true,
  scopeMappingsError: null,
}

/* Actions */

export const BEGIN_FETCHING_SCOPE_MAPPING_LIST =
  "as-scope-mapping/BEGIN_FETCHING_SCOPE_MAPPING_LIST"

export interface BeginFetchingScopeMappingListAction {
  type: typeof BEGIN_FETCHING_SCOPE_MAPPING_LIST
}

export const STOP_FETCHING_SCOPE_MAPPING_LIST =
  "as-scope-mapping/STOP_FETCHING_SCOPE_MAPPING_LIST"

export interface StopFetchingScopeMappingListAction {
  type: typeof STOP_FETCHING_SCOPE_MAPPING_LIST
}

export const SET_SCOPE_MAPPING_LIST = "as-scope-mapping/SET_SCOPE_MAPPING_LIST"

export interface SetScopeMappingListAction {
  type: typeof SET_SCOPE_MAPPING_LIST
  scopeMappingList: AutonomousSystemScopeMapping[]
}

export const SELECT_SCOPE_MAPPING = "as-scope-mapping/SELECT_SCOPE_MAPPING"

export interface SelectScopeMappingAction {
  type: typeof SELECT_SCOPE_MAPPING
  scopeMapping: AutonomousSystemScopeMapping | null
}

export const SET_SCOPE_MAPPINGS_ERROR =
  "as-scope-mapping/SET_SCOPE_MAPPINGS_ERROR"

export interface SetScopeMappingsErrorAction {
  type: typeof SET_SCOPE_MAPPINGS_ERROR
  scopeMappingsError: ProblemError | null
}

export type AsScopeMappingAction =
  | BeginFetchingScopeMappingListAction
  | StopFetchingScopeMappingListAction
  | SetScopeMappingListAction
  | SelectScopeMappingAction
  | SetScopeMappingsErrorAction

/* Reducer */

export const asScopeMappingReducer: Reducer<
  AsScopeMappingState,
  AsScopeMappingAction
> = (
  state: AsScopeMappingState = INITIAL_STATE,
  action: AsScopeMappingAction
) => {
  if (action.type === BEGIN_FETCHING_SCOPE_MAPPING_LIST) {
    const newState: AsScopeMappingState = {
      ...state,
      isFetchingScopeMappings: true,
    }
    return newState
  }

  if (action.type === STOP_FETCHING_SCOPE_MAPPING_LIST) {
    const newState: AsScopeMappingState = {
      ...state,
      isFetchingScopeMappings: false,
    }
    return newState
  }

  if (action.type === SET_SCOPE_MAPPING_LIST) {
    let { scopeMappingList } = action
    if (state.selectedScopeMapping) {
      scopeMappingList = rearrangeArrayBySelection(
        state.scopeMappingList,
        state.selectedScopeMapping,
        (mapping) => mapping.resource.asn
      )
    }
    const newState: AsScopeMappingState = {
      ...state,
      scopeMappingList,
    }
    return newState
  }

  if (action.type === SELECT_SCOPE_MAPPING) {
    const { scopeMapping: selectedScopeMapping } = action
    let { scopeMappingList } = state
    if (selectedScopeMapping) {
      scopeMappingList = rearrangeArrayBySelection(
        state.scopeMappingList,
        selectedScopeMapping,
        (mapping) => mapping.resource.asn
      )
    }
    const newState: AsScopeMappingState = {
      ...state,
      selectedScopeMapping,
      scopeMappingList,
    }
    return newState
  }

  if (action.type === SET_SCOPE_MAPPINGS_ERROR) {
    const { scopeMappingsError } = action
    const newState: AsScopeMappingState = {
      ...state,
      isFetchingScopeMappings:
        Boolean(scopeMappingsError) || state.isFetchingScopeMappings,
      scopeMappingsError,
    }
    return newState
  }

  // Assert that all known actions are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = action
  return state
}
