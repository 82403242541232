import React, { FunctionComponent, useEffect } from "react"
import { useKeycloak } from "@react-keycloak/web"
import { graphql, useStaticQuery } from "gatsby"
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import axios from "axios"
import { ReducerAction } from "@store/index"
import {
  BEGIN_FETCHING_AUTONOMOUS_SYSTEM_LIST,
  SET_AS_ADMIN_LIST_ERROR,
  SET_AUTONOMOUS_SYSTEM_LIST,
  STOP_FETCHING_AUTONOMOUS_SYSTEM_LIST,
} from "@store/as-admin"
import { AutonomousSystem } from "@models/autonomous-system"
import { GraphQLResponse } from "@_types/graphql-response"
import { SET_ALERT } from "@store/navigation-store"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        asAuthorization: {
          baseUrl: string
        }
      }
    }
  }
}

const AsAdminListService: FunctionComponent = () => {
  /* GraphQL data */
  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          externalServices {
            asAuthorization {
              baseUrl
            }
          }
        }
      }
    }
  `)
  const {
    baseUrl: asAuthorizationUrl,
  } = data.site.siteMetadata.externalServices.asAuthorization

  /* React Hooks */
  const dispatch = useDispatch<Dispatch<ReducerAction>>()
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()

  if (!isKeycloakInitialized || !keycloak?.authenticated) {
    throw new Error("Cannot use AsAdminListService without authentication")
  }
  const isAdmin = keycloak.hasRealmRole("admin")

  useEffect(() => {
    // Don't fetch data for admin user
    if (isAdmin) {
      return
    }
    // Fetch new data from API
    const fetchAsAdminList = async () => {
      try {
        dispatch({
          type: BEGIN_FETCHING_AUTONOMOUS_SYSTEM_LIST,
        })
        const response = await axios.post<
          GraphQLResponse<{ asAdminList: AutonomousSystem[] }>
        >(
          `${asAuthorizationUrl}`,
          {
            query:
              `` +
              `query { ` +
              `  asAdminList { ` +
              `    asn name ` +
              `  } ` +
              `}`,
          },
          {
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
            },
          }
        )
        if (response.data.errors) {
          if (response.data.errors[0]) {
            dispatch({
              type: SET_AS_ADMIN_LIST_ERROR,
              asAdminListError: JSON.parse(response.data.errors[0].message),
            })
            dispatch({
              type: SET_ALERT,
              alert: {
                message_key: "graphql_error:" + JSON.parse(response.data.errors[0].message).title_key
              },
            })
            return
          }
        }
        if (response.data.data?.asAdminList) {
          dispatch({
            type: SET_AUTONOMOUS_SYSTEM_LIST,
            autonomousSystemList: response.data.data.asAdminList,
          })
        } else {
          dispatch({
            type: SET_AS_ADMIN_LIST_ERROR,
            asAdminListError: {
              title: "Erro ao obter permissões",
              detail:
                "Ocorreu um erro desconhecido. Tente novamente mais tarde.",
            },
          })
          dispatch({
            type: SET_ALERT,
            alert: {
              message_key: "http_error:" + response.status
            },
          })
        }
      } catch (error: any) {
        console.error("Error when fetching AS admin list", error)
        if (
          error.response?.data?.errors &&
          error.response?.data?.errors[0]?.message
        ) {
          dispatch({
            type: SET_AS_ADMIN_LIST_ERROR,
            asAdminListError: JSON.parse(error.response.data.errors[0].message),
          })
          dispatch({
            type: SET_ALERT,
            alert: {
              message_key: "graphql_error:" + JSON.parse(error.response.data.errors[0].message).title_key
            },
          })
        } else {
          dispatch({
            type: SET_AS_ADMIN_LIST_ERROR,
            asAdminListError: {
              title: "Erro ao obter permissões",
              detail:
                "Ocorreu um erro de conexão. Verifique sua Internet e tente novamente mais tarde.",
            },
          })
          dispatch({
            type: SET_ALERT,
            alert: {
              message_key: "http_error:" + (error?.response?.status ?? error?.message),
            },
          })
        }
      } finally {
        dispatch({
          type: STOP_FETCHING_AUTONOMOUS_SYSTEM_LIST,
        })
      }
    }
    fetchAsAdminList()
  }, [isAdmin, dispatch, asAuthorizationUrl, keycloak])

  return <></>
}

export default AsAdminListService
