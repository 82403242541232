/**
 * Simple safari detection based on user agent test
 */
export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const isJsons = (array: any[]) =>
  Array.isArray(array) &&
  array.every((row) => typeof row === "object" && !(row instanceof Array))

export const isArrays = (array: any[]) =>
  Array.isArray(array) && array.every((row) => Array.isArray(row))

export const jsonsHeaders = (array: any[]) =>
  Array.from(
    array
      .map((json: {}) => Object.keys(json))
      .reduce((a: any, b: any) => new Set([...a, ...b]), [])
  )

export const jsons2arrays = (jsons: any[], headers?: any[]) => {
  headers = headers || jsonsHeaders(jsons)

  // allow headers to have custom labels, defaulting to having the header data key be the label
  let headerLabels = headers
  let headerKeys = headers
  if (isJsons(headers)) {
    headerLabels = headers.map((header: { label: any }) => header.label)
    headerKeys = headers.map((header: { key: any }) => header.key)
  }

  const data = jsons.map((object: any) =>
    headerKeys.map((header: any) => getHeaderValue(header, object))
  )
  return [headerLabels, ...data]
}

export const getHeaderValue = (property: string, obj: { [x: string]: any }) => {
  const foundValue = property
    .replace(/\[([^\]]+)]/g, ".$1")
    .split(".")
    .reduce(function (
      o: { [x: string]: any },
      p: string | number,
      i: any,
      arr: any[]
    ) {
      // if at any point the nested keys passed do not exist, splice the array so it doesnt keep reducing
      const value = o[p]
      if (value === undefined || value === null) {
        arr.splice(1)
      } else {
        return value
      }
    },
    obj)
  // if at any point the nested keys passed do not exist then looks for key `property` in object obj
  return foundValue === undefined
    ? property in obj
      ? obj[property]
      : ""
    : foundValue
}

export const elementOrEmpty = (element: null) =>
  typeof element === "undefined" || element === null ? "" : element

export const joiner = (
  data: any[],
  separator = ",",
  enclosingCharacter = '"'
) => {
  return data
    .filter((e: any) => e)
    .map((row: any[]) =>
      row
        .map((element: any) => elementOrEmpty(element))
        .map(
          (column: any) => `${enclosingCharacter}${column}${enclosingCharacter}`
        )
        .join(separator)
    )
    .join(`\n`)
}

export const arrays2csv = (
  data: any,
  headers?: any,
  separator?: string,
  enclosingCharacter?: string
) => joiner(headers ? [headers, ...data] : data, separator, enclosingCharacter)

export const jsons2csv = (
  data: any,
  headers?: any,
  separator?: string,
  enclosingCharacter?: string
) => joiner(jsons2arrays(data, headers), separator, enclosingCharacter)

export const string2csv = (
  data: string,
  headers?: any[],
  separator?: any,
  enclosingCharacter?: any
) =>
  headers ? `${headers.join(separator)}\n${data}` : data.replace(/"/g, '""')

export const toCSV = (
  data: any,
  headers?: any,
  separator?: any,
  enclosingCharacter?: any
) => {
  if (isJsons(data))
    return jsons2csv(data, headers, separator, enclosingCharacter)
  if (isArrays(data))
    return arrays2csv(data, headers, separator, enclosingCharacter)
  if (typeof data === "string") return string2csv(data, headers, separator)
  throw new TypeError(
    `Data should be a "String", "Array of arrays" OR "Array of objects" `
  )
}

export const buildURI = (
  data: any,
  uFEFF?: any,
  headers?: any,
  separator?: any,
  enclosingCharacter?: any
) => {
  const csv = toCSV(data, headers, separator, enclosingCharacter)
  const type = isSafari() ? "application/csv" : "text/csv"
  const blob = new Blob([uFEFF ? "\uFEFF" : "", csv], { type })
  const dataURI = `data:${type};charset=utf-8,${uFEFF ? "\uFEFF" : ""}${csv}`

  const URL = window.URL || window.webkitURL

  return typeof URL.createObjectURL === "undefined"
    ? dataURI
    : URL.createObjectURL(blob)
}
