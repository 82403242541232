import { parseISO } from "date-fns"
import { SerializedMeasurementNodes, SerializedMeasurementTimeSeries } from "@models/serialized/serialized-measurement"
import { Measurement } from "@models/measurement"
import { deserializeAnomalyDetection } from "./deserialize-anomaly-detection"
import {MeasurementTimeSeries} from "@models/measurements/measurement-time-series"


export function deserializeMeasurement(
  data: SerializedMeasurementNodes,
  realTime: boolean = false
): Measurement {
  return {
    ...data,
    datetime: parseISO(data.datetime),
    detectedAnomalies:
      (data.detectedAnomalies &&
        data.detectedAnomalies.map((anomaly) =>
          deserializeAnomalyDetection(anomaly)
        )) ||
      undefined,
    realTime: realTime
  }
}

export function deserializeMeasurementTimeSeries(
  data: SerializedMeasurementTimeSeries
): MeasurementTimeSeries {
  return {
    ...data,
    period_start: parseISO(data.period_start),
    period_end: parseISO(data.period_end)
  }
}