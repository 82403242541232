import { Measurement } from "@models/measurement"
import { MeasurementTableOrdering } from "@models/measurements/measurement-table-ordering"
import {
  compareMeasurementDateAsc,
  compareMeasurementAgentFamilyAsc,
  compareMeasurementAgentIdAsc,
  compareMeasurementIpVersionAsc,
  compareMeasurementSubcategoriesAsc,
} from "./compare-measurements"

export function sortMeasurements(
  measurements: Measurement[],
  orderBy: MeasurementTableOrdering,
  isAscending: boolean
): Measurement[] {
  let sortFn: (first: Measurement, second: Measurement) => number
  // TODO: Cleanup code to avoid duplication
  switch (orderBy) {
    case "DATE":
      sortFn = (first, second) =>
        (isAscending
          ? compareMeasurementDateAsc(first, second)
          : -compareMeasurementDateAsc(first, second)) ||
        compareMeasurementAgentFamilyAsc(first, second) ||
        compareMeasurementAgentIdAsc(first, second) ||
        compareMeasurementSubcategoriesAsc(first, second) ||
        compareMeasurementIpVersionAsc(first, second)
      break
    case "AGENT_FAMILY":
      sortFn = (first, second) =>
        (isAscending
          ? compareMeasurementAgentFamilyAsc(first, second)
          : -compareMeasurementAgentFamilyAsc(first, second)) ||
        -compareMeasurementDateAsc(first, second) ||
        compareMeasurementAgentIdAsc(first, second) ||
        compareMeasurementSubcategoriesAsc(first, second) ||
        compareMeasurementIpVersionAsc(first, second)
      break
    case "AGENT_ID":
      sortFn = (first, second) =>
        (isAscending
          ? compareMeasurementAgentIdAsc(first, second)
          : -compareMeasurementAgentIdAsc(first, second)) ||
        -compareMeasurementDateAsc(first, second) ||
        compareMeasurementAgentFamilyAsc(first, second) ||
        compareMeasurementSubcategoriesAsc(first, second) ||
        compareMeasurementIpVersionAsc(first, second)
      break
    case "SUBCATEGORY":
      sortFn = (first, second) =>
        (isAscending
          ? compareMeasurementSubcategoriesAsc(first, second)
          : -compareMeasurementSubcategoriesAsc(first, second)) ||
        -compareMeasurementDateAsc(first, second) ||
        compareMeasurementAgentFamilyAsc(first, second) ||
        compareMeasurementAgentIdAsc(first, second) ||
        compareMeasurementIpVersionAsc(first, second)
      break
    case "IP_VERSION":
      sortFn = (first, second) =>
        (isAscending
          ? compareMeasurementIpVersionAsc(first, second)
          : -compareMeasurementIpVersionAsc(first, second)) ||
        -compareMeasurementDateAsc(first, second) ||
        compareMeasurementAgentFamilyAsc(first, second) ||
        compareMeasurementAgentIdAsc(first, second) ||
        compareMeasurementSubcategoriesAsc(first, second)
      break
    default:
      // Assert that all known types are used
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let _: never = orderBy
      throw new Error("Unknown MeasurementTableOrdering")
  }
  return measurements.sort(sortFn)
}
