import React, { Dispatch, ReactElement, useEffect } from "react"
import { useKeycloak } from "@react-keycloak/web"
import { PageProps } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { ReducerAction, ReducerState } from "@store/index"
import IspAppSelection from "@components/index/isp-app-selection"
import { AutonomousSystemScopeMapping } from "@models/autonomous-system-scope-mapping"
import { AutonomousSystem } from "@models/autonomous-system"
import ASPendigUserRequestTable from "@components/settings-user-as/as-pending-user-request-table"
import { DELETE_ALERT } from "@store/navigation-store"

interface ReducerSelected {
  isFetchingScopeMappings: boolean
  scopeMappingList: AutonomousSystemScopeMapping[] | null
  availableAutonomousSystems: AutonomousSystem[] | null
}

export interface IndexLocationState {
  isFetchingScopeMappings: boolean
  scopeMappingList?: AutonomousSystemScopeMapping[]
  availableAutonomousSystems: AutonomousSystem[] | null
}


interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        asAuthorization: {
          baseUrl: string
        }
        asMeasurementsApi: {
          baseUrl: string
        }
      }
    }
  }
}

const SettingsUserASPage: (
  _: PageProps<GraphQLProps, object, IndexLocationState | undefined> & {
    asn?: string
  }
) => ReactElement = ({ asn: asnString }) => {
  /* React Hooks */
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()
  const { isFetchingScopeMappings, scopeMappingList } = useSelector<
    ReducerState,
    ReducerSelected
  >((state) => {
    return {
      isFetchingScopeMappings: state.asScopeMapping.isFetchingScopeMappings,
      scopeMappingList: state.asScopeMapping.scopeMappingList,
      availableAutonomousSystems: state.autonomousSystems.availableAutonomousSystems,
    }
  })

  const dispatch = useDispatch<Dispatch<ReducerAction>>()

  useEffect(() => {
    // Clear error
    dispatch({
      type: DELETE_ALERT,
      alert: null,
    })
    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const isAdmin = keycloak?.hasRealmRole("admin")
  return (

    <main className="flex-1">
      <div className="py-8 px-4 sm:px-6 lg:px-8 h-full">
        <div className="contents">
          <div className="flex-grow max-w-3xl mx-auto mt-16 pb-32">
            {isKeycloakInitialized && keycloak.authenticated && (
                <IspAppSelection />
            )}
          </div>
          <>
            {!isAdmin ? (
              (scopeMappingList == null ||
                scopeMappingList.length === 0) &&
                !isFetchingScopeMappings ? (
                <p>
                  Você não está associado à nenhum Sistema Autônomo no
                  momento.
                </p>
              ) : (
                scopeMappingList !== null &&
                scopeMappingList.length > 0 && (
                  <ASPendigUserRequestTable />
                )
              )
            ) : (
              <p>
                Você é administrador do Simet e está associado à todos
                os Sistemas Autônomos.
              </p>
            )}
          </>
        </div>
      </div>
    </main>

  )
}

export default SettingsUserASPage
