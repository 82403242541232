import { Reducer } from "redux"
import { AntiSpoofing, AntiSpoofingHistory } from "@models/simet-as"
import { ProblemError } from "@models/problem-error"

/* State */

export interface MeasurementsState {
  isWaitingForData: boolean
  error: ProblemError | null
  isWaitingForHistoryData: boolean,
  errorHistory: ProblemError | null,
  antiSpoofingList: AntiSpoofing[] | null
  antiSpoofingHistoryList: AntiSpoofingHistory[] | null
}

const INITIAL_STATE: MeasurementsState = {
  isWaitingForData: false,
  error: null, 
  isWaitingForHistoryData: false,
  errorHistory: null,
  antiSpoofingList: [],
  antiSpoofingHistoryList: [],
}

/* Actions */

export const CLEAR_ANTISPOOFING_AND_FILTERS =
  "antiSpoofing/CLEAR_ANTISPOOFINGS_AND_FILTERS"

interface ClearMeasurementsAndFiltersAction {
  type: typeof CLEAR_ANTISPOOFING_AND_FILTERS
}

export const SET_ANTISPOOFING_HISTORY = "antiSpoofing/SET_ANTISPOOFING_HISTORY"

interface SetAntiSpoofingHistoryAction {
  type: typeof SET_ANTISPOOFING_HISTORY
  antiSpoofingHistory: AntiSpoofingHistory[] | null | undefined
}

export const SET_ANTISPOOFING = "antiSpoofing/SET_ANTISPOOFING"

interface SetAntiSpoofingAction {
  type: typeof SET_ANTISPOOFING
  antiSpoofing: AntiSpoofing[] | null | undefined
}

export const SET_WAITING_FOR_ANTISPOOFING_DATA =
  "antiSpoofing/SET_WAITING_FOR_ANTISPOOFING_DATA"

interface SetWaitingForAntiSpoofingDataAction {
  type: typeof SET_WAITING_FOR_ANTISPOOFING_DATA
  isWaitingForData: boolean
}

export const SET_ANTISPOOFING_ERROR = "antiSpoofing/SET_ANTISPOOFING_ERROR"

interface SetAntiSpoofingErrorAction {
  type: typeof SET_ANTISPOOFING_ERROR
  error: ProblemError | null
}

export const SET_WAITING_FOR_ANTISPOOFING_HISTORY_DATA =
  "antiSpoofing/SET_WAITING_FOR_ANTISPOOFING_HISTORY_DATA"

interface SetWaitingForAntiSpoofingHistoryDataAction {
  type: typeof SET_WAITING_FOR_ANTISPOOFING_HISTORY_DATA
  isWaitingForHistoryData: boolean
}

export const SET_ANTISPOOFING_HISTORY_ERROR = "antiSpoofing/SET_ANTISPOOFING_HISTORY_ERROR"

interface SetAntiSpoofingHistoryErrorAction {
  type: typeof SET_ANTISPOOFING_HISTORY_ERROR
  errorHistory: ProblemError | null
}

export type MeasurementsAction =
  | ClearMeasurementsAndFiltersAction
  | SetAntiSpoofingAction
  | SetAntiSpoofingHistoryAction
  | SetWaitingForAntiSpoofingDataAction
  | SetWaitingForAntiSpoofingHistoryDataAction
  | SetAntiSpoofingErrorAction
  | SetAntiSpoofingHistoryErrorAction

/* Reducer */

export const antiSpoofingReducer: Reducer<
  MeasurementsState,
  MeasurementsAction
> = (state = INITIAL_STATE, action) => {
  if (action.type === CLEAR_ANTISPOOFING_AND_FILTERS) {
    return INITIAL_STATE
  }

  if (action.type === SET_ANTISPOOFING) {
    const { antiSpoofing } = action
    const newState: MeasurementsState = {
      ...state,
      isWaitingForData: false,
      error: null,
      antiSpoofingList: antiSpoofing ?? null
    }
    return newState
  }

  if (action.type === SET_ANTISPOOFING_HISTORY) {
    const { antiSpoofingHistory } = action
    const newState: MeasurementsState = {
      ...state,
      isWaitingForHistoryData: false,
      errorHistory: null,
      antiSpoofingHistoryList: antiSpoofingHistory ?? null,
    }
    return newState
  }

  if (action.type === SET_ANTISPOOFING_ERROR) {
    const { error } = action
    const newState: MeasurementsState = {
      ...state,
      error,
      isWaitingForData: error ? true : state.isWaitingForData,
    }
    return newState
  }

  if (action.type === SET_ANTISPOOFING_HISTORY_ERROR) {
    const { errorHistory } = action
    const newState: MeasurementsState = {
      ...state,
      errorHistory,
      isWaitingForHistoryData: errorHistory ? true : state.isWaitingForHistoryData,
    }
    return newState
  }

  if (action.type === SET_WAITING_FOR_ANTISPOOFING_HISTORY_DATA) {
    const { isWaitingForHistoryData } = action
    const newState: MeasurementsState = {
      ...state,
      isWaitingForHistoryData,
    }
    return newState
  }

  if (action.type === SET_WAITING_FOR_ANTISPOOFING_DATA) {
    const { isWaitingForData } = action
    const newState: MeasurementsState = {
      ...state,
      isWaitingForData,
    }
    return newState
  }

  // Assert that all known actions are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = action
  return state
}
