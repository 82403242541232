import { AutonomousSystem } from "@models/autonomous-system"
import { AutonomousSystemScopeMapping } from "@models/autonomous-system-scope-mapping"
import { PageMetadataObject } from "@models/page-metadata-type"
import { createContext } from "react"

interface PropsContext {
  availableAutonomousSystemsList: AutonomousSystem[] | null
  scopeMapList: AutonomousSystemScopeMapping[] | null
  pageMetadata: PageMetadataObject | undefined
  setPageMetadata: (_: PageMetadataObject) => void
  isAvailableAutonomousSystemsListReady: boolean
  isScopeMapListReady: boolean
}

const defaultContext: PropsContext = {
  availableAutonomousSystemsList: [],
  scopeMapList: [],
  pageMetadata: undefined,
  setPageMetadata: (_) => {},
  isAvailableAutonomousSystemsListReady: false,
  isScopeMapListReady: false,
}

export const CurrentContext = createContext(defaultContext)
