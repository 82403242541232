import clsx from "clsx"
import React, { FunctionComponent } from "react"
import { Link as GatsbyLink } from "gatsby"

interface Props {
  className: string
  href: string
  skipDefaultStyling?: boolean
  children?: any
}

const defaultClasses =
  "inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"

export const Link: FunctionComponent<Props> = ({
  href,
  className,
  skipDefaultStyling = false,
  children,
}) => {
  className = skipDefaultStyling ? className : clsx(defaultClasses, className)

  return (
    <GatsbyLink to={href} className={className}>
      {children}
    </GatsbyLink>
  )
}
