import React, { FunctionComponent } from "react"
import { Provider as ReduxProvider } from "react-redux"
import { createStore as reduxCreateStore, Store } from "redux"
import reducer from "../store"

export const createStore = () =>
  reduxCreateStore(
    reducer,
    (process.env.NODE_ENV === "development" &&
      typeof window !== "undefined" &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
      undefined
  )

interface Props{
  store?: Store

}
const ReduxWrapper: FunctionComponent <Props>= ({ store, children }) => {
  return <ReduxProvider store={store ?? createStore()}>{children}</ReduxProvider>
}

export default ReduxWrapper
