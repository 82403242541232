import React, { FunctionComponent, useEffect, useRef } from "react"
import { useKeycloak } from "@react-keycloak/web"
import { graphql, useStaticQuery } from "gatsby"
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import axios, { CancelTokenSource } from "axios"
import { ReducerAction } from "@store/index"
import { GraphQLResponse } from "@_types/graphql-response"
import { SimetasProbe } from "@models/simetas-probe"
import {
  SET_SIMETAS_PROBES,
  SET_WAITING_FOR_SIMETAS_PROBES_DATA,
} from "@store/simetas-probes-store"
import { SET_ALERT } from "@store/navigation-store"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        asSimetasApi: {
          baseUrl: string
        }
      }
    }
  }
}

interface Props {
  asn: number
}

const SimetasProbesService: FunctionComponent<Props> = ({ asn }) => {
  /* GraphQL data */
  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          externalServices {
            asSimetasApi {
              baseUrl
            }
          }
        }
      }
    }
  `)
  const { baseUrl } = data.site.siteMetadata.externalServices.asSimetasApi

  /* React Hooks */
  const dispatch = useDispatch<Dispatch<ReducerAction>>()
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()
  const prevAsn = useRef<number>(0)
  const cancelToken = useRef<CancelTokenSource>()
  const requestId = useRef<number>(0)
  const firstCall = useRef(true)
  if (!isKeycloakInitialized || !keycloak?.authenticated) {
    throw new Error("Cannot use SimetasProbesService without authentication")
  }

  const isAdmin = keycloak.hasRealmRole("admin")

  useEffect(() => {
    // Check if ASN changed or if it is the initial data population
    if (firstCall.current || prevAsn.current !== asn) {
      // Abort any existing request
      cancelToken.current?.cancel("MeasurementsService request canceled")
      cancelToken.current = undefined
      // Fetch new data from API
      ;(async () => {
        const currentRequestId = requestId.current + 1
        requestId.current = currentRequestId
        try {
          dispatch({
            type: SET_WAITING_FOR_SIMETAS_PROBES_DATA,
            isWaitingForData: true,
          })
          const newCancelToken = axios.CancelToken.source()
          cancelToken.current = newCancelToken
          const query = await axios.post<
            GraphQLResponse<{ probes: SimetasProbe[] }>
          >(
            baseUrl,
            {
              query:
                `query( ` +
                `  $asn: Int! ` +
                `) { ` +
                `  probes(asn: $asn) { ` +
                `    probeID asn localities simetAS description` +
                `  } ` +
                `}`,
              variables: {
                asn,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${keycloak.token}`,
              },
              cancelToken: newCancelToken.token,
            }
          )
          if (query.data.errors) {
            if (query.data.errors[0]) {
              dispatch({
                type: SET_ALERT,
                alert: {
                  message_key: "graphql_error:" + JSON.parse(query.data.errors[0].message).title_key
                },
              })
              return
            }
          }
          if (requestId.current !== currentRequestId) {
            return
          }
          if (query.data.data?.probes) {
            dispatch({
              type: SET_SIMETAS_PROBES,
              simetasProbes: query.data.data.probes,
            })
          } else {
            dispatch({
              type: SET_ALERT,
              alert: {
                message_key: "http_error:" + query.status
              },
            })
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log("SimetasProbesService request canceled")
            return
          }
          if (requestId.current !== currentRequestId) {
            return
          }
          console.error("Error when fetching SIMET-AS probes", error)
          dispatch({
            type: SET_ALERT,
            alert: {
              message_key: "http_error:" + (error?.response?.status ?? error?.message),
            },
          })
        }finally {
          dispatch({
            type: SET_WAITING_FOR_SIMETAS_PROBES_DATA,
            isWaitingForData: false,
          })
        }
      })()
      firstCall.current = false
      prevAsn.current = asn
    }
  }, [isAdmin, dispatch, baseUrl, keycloak, asn])

  // Cleanup
  useEffect(() => {
    return () => {
      // Abort any current request
      requestId.current = 0
      cancelToken.current?.cancel("SimetasProbesService request canceled")
    }
  }, [])

  return <></>
}

export default SimetasProbesService
