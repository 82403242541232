import React, { FunctionComponent, ReactElement, useEffect } from "react"
import { useKeycloak } from "@react-keycloak/web"

interface Props {
  className?: string
  placeholder?: ReactElement
  children: any
}

const RequireAuthentication: FunctionComponent<Props> = ({
  children,
  className,
  placeholder,
}) => {
  /* React Hooks */
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()

  useEffect(() => {
    if (isKeycloakInitialized && !keycloak.authenticated) {
        let href = window.location.href
        if (!window.location.pathname.endsWith("/")) {
          href = `${window.location.origin}${window.location.pathname}/${window.location.search}${window.location.hash}`
        }
        if (!keycloak.authenticated) {
          keycloak.login({
            redirectUri: href,
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isKeycloakInitialized])

  if (!isKeycloakInitialized || !keycloak.authenticated) {
    return <div className={className}>{placeholder}</div>
  }

  return <div className={className}>{children}</div>
}

export default RequireAuthentication
