import {
  RESOURCE_ADMIN_SCOPE,
  ScopeDescription,
  ScopeName,
} from "@models/scope-description"

export function describeScope(scope: ScopeName): ScopeDescription | null {
  if (scope === RESOURCE_ADMIN_SCOPE) {
    return {
      scope: RESOURCE_ADMIN_SCOPE,
      name: "Administração",
      appDescription: "Configurar permissões de usuários no PAS",
      permissionDescription:
        "Permite visualizar, adicionar e remover permissões de outros usuários, além de dar acesso a todos os sistemas SIMET.",
    }
  }

  if (scope === "as:anomaly-detection:view") {
    return {
      scope: "as:anomaly-detection:view",
      name: "Consulta de anomalias",
      appDescription: "Painel de detecção de anomalias na rede em tempo real",
      permissionDescription:
        "Permite visualizar o painel de detecção de anomalias na rede do Sistema Autônomo em tempo real.",
    }
  }

  if (scope === "as:measurements:view") {
    return {
      scope: "as:measurements:view",
      name: "Medições do AS",
      appDescription: "Visualização das medições na rede do Sistema Autônomo",
      permissionDescription:
        "Permite visualizar todas as medições realizadas por dispositivos na rede do Sistema Autônomo.",
    }
  }

  if (scope === "as:simet-as:view") {
    return {
      scope: "as:simet-as:view",
      name: "SIMET-AS ",
      appDescription:
        "Painel de medições não-volumétricas entre Sistemas Autônomos",
      permissionDescription:
        "Permite visualizar os dados da qualidade da conexão dos dispositivos SIMET-AS do Sistema Autônomo.",
    }
  }

  // Assert that all known ScopeName values are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = scope
  return null
}
