import { AgentFamily } from "@models/agent-family"

/**
 * Given agent data, try to infer the best human-readable name. Returns an
 * empty string if not a valid input.
 *
 * @param agentFamily Agent family as described by the AgentInfo metric.
 * @param engineName Engine name as described by the AgentInfo metric.
 */
export function describeAgent(
  agentFamily?: AgentFamily,
  engineName?: string
): string {
  if (!agentFamily && !engineName) {
    return ""
  }

  if (agentFamily === "EMBEDDED" && engineName === "nic_simet2_simet-ma") {
    return "SIMET Box"
  }
  if (agentFamily === "MOBILE" && engineName === "nic_simet2_simet-ma") {
    return "App SIMET"
  }
  if (agentFamily === "WEB" && engineName === "nic_simet2_simet-lite") {
    return "SIMET Lite"
  }

  if (engineName === "nic_simet2_simet-ma") {
    return "SIMET-MA"
  }

  if (agentFamily === "EMBEDDED") {
    return "Medidor embarcado"
  }
  if (agentFamily === "WEB") {
    return "Medidor web"
  }
  if (agentFamily === "MOBILE") {
    return "Medidor móvel"
  }
  if (agentFamily === "SYSTEM_SERVICE") {
    return "Medidor de sistema"
  }
  if (agentFamily === "DESKTOP") {
    return "Medidor desktop"
  }

  return "Medidor desconhecido"
}
