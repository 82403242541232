import React, { FunctionComponent, Fragment, useState, useCallback, memo, useMemo } from "react";
import { Dialog, Transition } from '@headlessui/react'
import TimeSeriesChartWrapper from "../graphics/time-series-chart-wrapper";
import { MEASUREMENT_SUBCATEGORIES, formatSubcategoryHistoricalByAgent, MeasurementSubcategory } from "@models/measurement-subcategory";
import { configByPeriodAndMetric, convertToUTCDate } from "../graphics/utils";
import { selectedPeriod } from "@utils/query-params";
import { SerializedDayMeasurementStatisticsList } from '@models/day-measurement-statistics-list'
import { IpFamily, IP_FAMILIES } from "@models/ip-family";
import { parseISO, add } from "date-fns";
import { PERIOD_INTERVAL } from "@models/period-type";
import SubcategoryInput from "../filter/subcategories-input"
import { AgentFamily, formatAgentFamily } from "@models/agent-family"
import { useKeycloak } from "@react-keycloak/web"
import { useGetQuerySubcategory } from "@hooks/use-get-query-subcategory"
import {XIcon} from "@heroicons/react/solid"
type Statistics = {
  dateTime: string
  y: number
}


type OptionsFlags<Type extends string | number | symbol> = {
  [_ in Type]: Statistics[];
};


type SubcategoriesWithStatistics = OptionsFlags<MeasurementSubcategory>;


type OptionsFlags2<Type extends string | number | symbol> = {
  [_ in Type]: SubcategoriesWithStatistics;
};

// type FeatureOptions2 = OptionsFlags2<IpFamily>

type IpFamilyWithSubcategories = OptionsFlags2<IpFamily>

interface Props {
  isOpen: boolean
  closeModal: () => void
  datetime?: Date
  agentId?: string
  asn?: number
  agentFamily?: AgentFamily
  idx?: number
}


// const measurementsTimeSeriesList =
//   [
//     // {
//     //   "date": "2023-03-24",
//     //   "dateTime": "2023-03-24T12:43:08-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 108.880351,
//     //   "uploadMbps": 75.337472,
//     //   "rttMs": 2.337,
//     //   "jitterRttMs": 0.164,
//     //   "jitterDownloadMs": 0.078,
//     //   "jitterUploadMs": 0.09,
//     //   "packetLossPercentage": 0
//     // },
//     // {
//     //   "date": "2023-03-24",
//     //   "dateTime": "2023-03-24T16:46:51-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 79.168894,
//     //   "uploadMbps": 74.02176,
//     //   "rttMs": 2.319,
//     //   "jitterRttMs": 0.151,
//     //   "jitterDownloadMs": 0.041,
//     //   "jitterUploadMs": 0.114,
//     //   "packetLossPercentage": 0
//     // },
//     // {
//     //   "date": "2023-03-24",
//     //   "dateTime": "2023-03-24T20:46:16-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 102.321495,
//     //   "uploadMbps": 66.399488,
//     //   "rttMs": 2.33,
//     //   "jitterRttMs": 0.133,
//     //   "jitterDownloadMs": 0.059,
//     //   "jitterUploadMs": 0.087,
//     //   "packetLossPercentage": 0
//     // },
//     // {
//     //   "date": "2023-03-25",
//     //   "dateTime": "2023-03-25T00:46:11-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 105.043712,
//     //   "uploadMbps": 71.334272,
//     //   "rttMs": 2.234,
//     //   "jitterRttMs": 0.108,
//     //   "jitterDownloadMs": 0.034,
//     //   "jitterUploadMs": 0.085,
//     //   "packetLossPercentage": 0
//     // },
//     // {
//     //   "date": "2023-03-25",
//     //   "dateTime": "2023-03-25T04:43:55-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 108.46416,
//     //   "uploadMbps": 76.73984,
//     //   "rttMs": 2.261,
//     //   "jitterRttMs": 0.096,
//     //   "jitterDownloadMs": 0.035,
//     //   "jitterUploadMs": 0.066,
//     //   "packetLossPercentage": 0
//     // },
//     // {
//     //   "date": "2023-03-25",
//     //   "dateTime": "2023-03-25T08:43:29-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 106.85837,
//     //   "uploadMbps": 70.639232,
//     //   "rttMs": 2.217,
//     //   "jitterRttMs": 0.102,
//     //   "jitterDownloadMs": 0.028,
//     //   "jitterUploadMs": 0.078,
//     //   "packetLossPercentage": 0
//     // },
//     // {
//     //   "date": "2023-03-25",
//     //   "dateTime": "2023-03-25T12:45:00-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 106.92032,
//     //   "uploadMbps": 76.009344,
//     //   "rttMs": 2.227,
//     //   "jitterRttMs": 0.114,
//     //   "jitterDownloadMs": 0.038,
//     //   "jitterUploadMs": 0.084,
//     //   "packetLossPercentage": 0
//     // },
//     // {
//     //   "date": "2023-03-25",
//     //   "dateTime": "2023-03-25T16:43:10-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 106.34112,
//     //   "uploadMbps": 73.048704,
//     //   "rttMs": 2.239,
//     //   "jitterRttMs": 0.12,
//     //   "jitterDownloadMs": 0.038,
//     //   "jitterUploadMs": 0.084,
//     //   "packetLossPercentage": 0
//     // },
//     // {
//     //   "date": "2023-03-25",
//     //   "dateTime": "2023-03-25T20:42:20-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 108.663458,
//     //   "uploadMbps": 75.7728,
//     //   "rttMs": 2.216,
//     //   "jitterRttMs": 0.076,
//     //   "jitterDownloadMs": 0.029,
//     //   "jitterUploadMs": 0.06,
//     //   "packetLossPercentage": 0
//     // },
//     // {
//     //   "date": "2023-03-26",
//     //   "dateTime": "2023-03-26T00:42:38-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 97.666299,
//     //   "uploadMbps": 73.836416,
//     //   "rttMs": 2.235,
//     //   "jitterRttMs": 0.102,
//     //   "jitterDownloadMs": 0.038,
//     //   "jitterUploadMs": 0.074,
//     //   "packetLossPercentage": 0
//     // },
//     // {
//     //   "date": "2023-03-26",
//     //   "dateTime": "2023-03-26T04:46:10-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 106.313836,
//     //   "uploadMbps": 72.05248,
//     //   "rttMs": 2.256,
//     //   "jitterRttMs": 0.064,
//     //   "jitterDownloadMs": 0.037,
//     //   "jitterUploadMs": 0.06,
//     //   "packetLossPercentage": 0
//     // },
//     // {
//     //   "date": "2023-03-26",
//     //   "dateTime": "2023-03-26T08:43:26-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 47.355392,
//     //   "uploadMbps": 68.3456,
//     //   "rttMs": 8.979,
//     //   "jitterRttMs": 0.088,
//     //   "jitterDownloadMs": 0.037,
//     //   "jitterUploadMs": 0.071,
//     //   "packetLossPercentage": 0
//     // },
//     // {
//     //   "date": "2023-03-26",
//     //   "dateTime": "2023-03-26T12:42:01-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 54.398464,
//     //   "uploadMbps": 80.068608,
//     //   "rttMs": 8.911,
//     //   "jitterRttMs": 0.118,
//     //   "jitterDownloadMs": 0.039,
//     //   "jitterUploadMs": 0.11,
//     //   "packetLossPercentage": 0
//     // },
//     // {
//     //   "date": "2023-03-26",
//     //   "dateTime": "2023-03-26T16:43:11-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 58.770413,
//     //   "uploadMbps": 82.848768,
//     //   "rttMs": 9.004,
//     //   "jitterRttMs": 0.117,
//     //   "jitterDownloadMs": 0.042,
//     //   "jitterUploadMs": 0.089,
//     //   "packetLossPercentage": 0
//     // },
//     // {
//     //   "date": "2023-03-26",
//     //   "dateTime": "2023-03-26T20:45:42-03:00",
//     //   "ipFamily": "IPV4",
//     //   "downloadMbps": 111.252736,
//     //   "uploadMbps": 75.082624,
//     //   "rttMs": 2.216,
//     //   "jitterRttMs": 0.123,
//     //   "jitterDownloadMs": 0.031,
//     //   "jitterUploadMs": 0.096,
//     //   "packetLossPercentage": 0
//     // },
//     {
//       "date": "2023-04-03",
//       "dateTime": "2023-03-31T00:45:29-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 104.024782,
//       "uploadMbps": 65.33376,
//       "rttMs": 2.374,
//       "jitterRttMs": 0.128,
//       "jitterDownloadMs": 0.042,
//       "jitterUploadMs": 0.106,
//       "packetLossPercentage": 0
//     },
//     {
//       "date": "2023-03-31",
//       "dateTime": "2023-03-31T04:45:40-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 98.544926,
//       "uploadMbps": 74.994816,
//       "rttMs": 2.259,
//       "jitterRttMs": 0.086,
//       "jitterDownloadMs": 0.034,
//       "jitterUploadMs": 0.078,
//       "packetLossPercentage": 0
//     },
//     {
//       "date": "2023-03-31",
//       "dateTime": "2023-03-31T08:42:31-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 107.192463,
//       "uploadMbps": 76.166656,
//       "rttMs": 2.221,
//       "jitterRttMs": 0.131,
//       "jitterDownloadMs": 0.03,
//       "jitterUploadMs": 0.105,
//       "packetLossPercentage": 0
//     },
//     {
//       "date": "2023-03-31",
//       "dateTime": "2023-03-31T12:42:17-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 54.689402,
//       "uploadMbps": 77.103104,
//       "rttMs": 2.341,
//       "jitterRttMs": 0.192,
//       "jitterDownloadMs": 0.084,
//       "jitterUploadMs": 0.125,
//       "packetLossPercentage": 1
//     },
//     {
//       "date": "2023-03-31",
//       "dateTime": "2023-03-31T16:45:29-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 88.0384,
//       "uploadMbps": 75.620352,
//       "rttMs": 2.295,
//       "jitterRttMs": 0.185,
//       "jitterDownloadMs": 0.06,
//       "jitterUploadMs": 0.104,
//       "packetLossPercentage": 0
//     },
//     {
//       "date": "2023-03-31",
//       "dateTime": "2023-03-31T20:44:40-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 94.618112,
//       "uploadMbps": 64.350976,
//       "rttMs": 2.285,
//       "jitterRttMs": 0.104,
//       "jitterDownloadMs": 0.044,
//       "jitterUploadMs": 0.08,
//       "packetLossPercentage": 0
//     },
//     {
//       "date": "2023-04-01",
//       "dateTime": "2023-04-01T00:43:19-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 100.803968,
//       "uploadMbps": 57.317632,
//       "rttMs": 2.45,
//       "jitterRttMs": 0.147,
//       "jitterDownloadMs": 0.069,
//       "jitterUploadMs": 0.106,
//       "packetLossPercentage": 2.5
//     },
//     {
//       "date": "2023-04-01",
//       "dateTime": "2023-04-01T04:44:14-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 111.994112,
//       "uploadMbps": 73.859584,
//       "rttMs": 2.237,
//       "jitterRttMs": 0.091,
//       "jitterDownloadMs": 0.031,
//       "jitterUploadMs": 0.073,
//       "packetLossPercentage": 0
//     },
//     {
//       "date": "2023-04-01",
//       "dateTime": "2023-04-01T08:42:17-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 111.646592,
//       "uploadMbps": 73.002368,
//       "rttMs": 2.278,
//       "jitterRttMs": 0.082,
//       "jitterDownloadMs": 0.026,
//       "jitterUploadMs": 0.063,
//       "packetLossPercentage": 0
//     },
//     {
//       "date": "2023-04-01",
//       "dateTime": "2023-04-01T12:42:57-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 99.830912,
//       "uploadMbps": 76.940928,
//       "rttMs": 2.32,
//       "jitterRttMs": 0.196,
//       "jitterDownloadMs": 0.059,
//       "jitterUploadMs": 0.127,
//       "packetLossPercentage": 0.5
//     },
//     {
//       "date": "2023-04-01",
//       "dateTime": "2023-04-01T16:46:49-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 95.099784,
//       "uploadMbps": 73.427968,
//       "rttMs": 2.354,
//       "jitterRttMs": 0.218,
//       "jitterDownloadMs": 0.064,
//       "jitterUploadMs": 0.174,
//       "packetLossPercentage": 0.5
//     },
//     {
//       "date": "2023-04-01",
//       "dateTime": "2023-04-01T20:42:07-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 100.926467,
//       "uploadMbps": 76.143488,
//       "rttMs": 2.284,
//       "jitterRttMs": 0.117,
//       "jitterDownloadMs": 0.032,
//       "jitterUploadMs": 0.101,
//       "packetLossPercentage": 0
//     },
//     {
//       "date": "2023-04-02",
//       "dateTime": "2023-04-02T00:45:47-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 106.517418,
//       "uploadMbps": 55.765376,
//       "rttMs": 2.516,
//       "jitterRttMs": 0.161,
//       "jitterDownloadMs": 0.101,
//       "jitterUploadMs": 0.101,
//       "packetLossPercentage": 1.5
//     },
//     {
//       "date": "2023-04-02",
//       "dateTime": "2023-04-02T04:44:53-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 110.244535,
//       "uploadMbps": 76.940928,
//       "rttMs": 2.185,
//       "jitterRttMs": 0.082,
//       "jitterDownloadMs": 0.024,
//       "jitterUploadMs": 0.066,
//       "packetLossPercentage": 0
//     },
//     {
//       "date": "2023-04-02",
//       "dateTime": "2023-04-02T08:43:40-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 106.317952,
//       "uploadMbps": 65.574016,
//       "rttMs": 2.262,
//       "jitterRttMs": 0.106,
//       "jitterDownloadMs": 0.032,
//       "jitterUploadMs": 0.069,
//       "packetLossPercentage": 0
//     },
//     {
//       "date": "2023-04-02",
//       "dateTime": "2023-04-02T12:42:32-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 90.779793,
//       "uploadMbps": 76.704384,
//       "rttMs": 2.288,
//       "jitterRttMs": 0.169,
//       "jitterDownloadMs": 0.059,
//       "jitterUploadMs": 0.093,
//       "packetLossPercentage": 0.5
//     },
//     {
//       "date": "2023-04-02",
//       "dateTime": "2023-04-02T16:46:30-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 100.062592,
//       "uploadMbps": 75.198464,
//       "rttMs": 2.241,
//       "jitterRttMs": 0.137,
//       "jitterDownloadMs": 0.052,
//       "jitterUploadMs": 0.082,
//       "packetLossPercentage": 0
//     },
//     {
//       "date": "2023-04-02",
//       "dateTime": "2023-04-02T20:42:03-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 110.526976,
//       "uploadMbps": 77.412864,
//       "rttMs": 2.371,
//       "jitterRttMs": 0.22,
//       "jitterDownloadMs": 0.083,
//       "jitterUploadMs": 0.178,
//       "packetLossPercentage": 0
//     },
//     {
//       "date": "2023-04-03",
//       "dateTime": "2023-04-03T04:43:44-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 109.4688,
//       "uploadMbps": 80.21504,
//       "rttMs": 2.264,
//       "jitterRttMs": 0.11,
//       "jitterDownloadMs": 0.026,
//       "jitterUploadMs": 0.087,
//       "packetLossPercentage": 0
//     },
//     {
//       "date": "2023-04-03",
//       "dateTime": "2023-04-03T08:42:05-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 109.207412,
//       "uploadMbps": 77.723776,
//       "rttMs": 2.277,
//       "jitterRttMs": 0.105,
//       "jitterDownloadMs": 0.025,
//       "jitterUploadMs": 0.093,
//       "packetLossPercentage": 0
//     },
//     {
//       "date": "2023-04-03",
//       "dateTime": "2023-04-03T12:44:16-03:00",
//       "ipFamily": "IPV4",
//       "downloadMbps": 87.57504,
//       "uploadMbps": 73.599872,
//       "rttMs": 2.267,
//       "jitterRttMs": 0.132,
//       "jitterDownloadMs": 0.05,
//       "jitterUploadMs": 0.08,
//       "packetLossPercentage": 0
//     }
//   ]

const transformData = (data: SerializedDayMeasurementStatisticsList[] | undefined) => {

  let timeSeries: IpFamilyWithSubcategories = {
    ALL: {
      PACKET_LOSS: [],
      RTT: [],
      JITTER_UPLOAD: [],
      JITTER_DOWNLOAD: [],
      UPLOAD: [],
      DOWNLOAD: [],
      JITTER_RTT: []
    },
    IPV4: {
      PACKET_LOSS: [],
      RTT: [],
      JITTER_UPLOAD: [],
      JITTER_DOWNLOAD: [],
      UPLOAD: [],
      DOWNLOAD: [],
      JITTER_RTT: []
    },
    IPV6: {
      PACKET_LOSS: [],
      RTT: [],
      JITTER_UPLOAD: [],
      JITTER_DOWNLOAD: [],
      UPLOAD: [],
      DOWNLOAD: [],
      JITTER_RTT: []
    },
  }

  const transformedData: {
    ipFamily: IpFamily
    timeSeries: SubcategoriesWithStatistics
  }[] = []

  // let ipv4: typeof timeSeries[] = []
  // let ipv6: typeof timeSeries[] = []

  data?.forEach((list) => {

    if (!list['ipFamily']) return

    timeSeries[list['ipFamily']].PACKET_LOSS.push({
      dateTime: list['dateTime'],
      y: list['packetLossPercentage']
    })

    timeSeries[list['ipFamily']].RTT.push({
      dateTime: list['dateTime'],
      y: list['rttMs']

    })

    timeSeries[list['ipFamily']].JITTER_UPLOAD.push({
      dateTime: list['dateTime'],
      y: list['jitterUploadMs']
    })

    timeSeries[list['ipFamily']].JITTER_DOWNLOAD.push({
      dateTime: list['dateTime'],
      y: list['jitterDownloadMs']
    })

    timeSeries[list['ipFamily']].UPLOAD.push({
      dateTime: list['dateTime'],
      y: list['uploadMbps']
    })

    timeSeries[list['ipFamily']].DOWNLOAD.push({
      dateTime: list['dateTime'],
      y: list['downloadMbps']
    })

    // if (list['ipFamily'] === 'IPV4') {
    //   ipv4.push({...timeSeries})
    // }

    // if (list['ipFamily'] === 'IPV6') {
    //   ipv6.push({...timeSeries})
    // }
  })

  transformedData.push(
    {
      ipFamily: 'IPV4',
      timeSeries: timeSeries['IPV4']
    },
    {
      ipFamily: 'IPV6',
      timeSeries: timeSeries['IPV6']
    }
  )

  return transformedData
}


const GraphicSubcategoryByAgentModal: FunctionComponent<Props> = ({
  isOpen,
  closeModal,
  datetime,
  agentId,
  asn,
  agentFamily,
  idx,
}) => {
  const { keycloak } = useKeycloak()

  if (!keycloak?.authenticated) {
    throw new Error("Cannot use MeasurementsService without authentication")
  }

  const periodo = PERIOD_INTERVAL[2] // 7D

  const [min, max] = selectedPeriod(periodo, undefined, undefined, true)

  const config = configByPeriodAndMetric(periodo, min as Date, add(max as Date, {days: 1}), true)

  const [selectedSubcategory, setSelectedSubcategory] = useState<{ title: string, metric: MeasurementSubcategory }>({
    title: formatSubcategoryHistoricalByAgent(MEASUREMENT_SUBCATEGORIES[1]),
    metric: MEASUREMENT_SUBCATEGORIES[1]
  })

  const variables = useMemo(() => ({
    agentId: agentId,
    minDate: min as Date,
    maxDate: add(max as Date, {days:1}),
    referentialDate: datetime,
    asn: asn,
    ipFamily: [IP_FAMILIES[1], IP_FAMILIES[2]]
  }), [
    agentId,
    min,
    max,
    datetime,
    asn
  ])

  const { result: measurementsTimeSeriesList, fetching } = useGetQuerySubcategory(variables, keycloak.token)

  const onFormatLines = useCallback((metric: MeasurementSubcategory) => {
    let pointY: number[] = []

    const data = transformData(measurementsTimeSeriesList)?.map((list) => {
      let timesSerieslist = list.timeSeries[metric]

      let dataRes = timesSerieslist?.sort((a, b) => parseISO(a.dateTime).getTime() - parseISO(b.dateTime).getTime())
        .map((series) => {
          pointY.push(Number(series.y))

          return {
            "x": convertToUTCDate(series.dateTime),
            "y": series.y
          }
        })
      return {
        "id": list.ipFamily,
        data: dataRes
      }

    }) ?? []

    return { lines: data, pointY }

  }, [measurementsTimeSeriesList])

  // if(error) // dispatch error alert

  const handleCloseModal = () => {
    const doc = document.getElementById((agentId ?? '') + (idx ?? ''))
    // console.log(doc)
    doc?.style?.removeProperty('background-color')
    closeModal()
    const subcategoryFormated = formatSubcategoryHistoricalByAgent(MEASUREMENT_SUBCATEGORIES[1])
    setSelectedSubcategory({ title: subcategoryFormated, metric: MEASUREMENT_SUBCATEGORIES[1] })
  }

  return (
    <>
      {measurementsTimeSeriesList !== undefined && (
        <Transition.Root show={isOpen} as={Fragment}>
          <Dialog as="div" className="fixed z-20 inset-0"
            onClose={() => handleCloseModal()}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0" />
            </Transition.Child>
            <div className="fixed inset-0 z-20">
              <div className="flex min-h-screen mx-8 lg:pl-64 items-end justify-center text-center sm:items-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative w-full max-w-4xl border transform overflow-hidden text-left rounded-lg bg-white px-4 pt-5 pb-4 shadow-xl transition-all sm:my-8 sm:p-6 w-420">
                    <div className="absolute right-0 top-0 pr-4 pt-4 mr-4 sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleCloseModal()}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <Dialog.Title className=" mx-auto w-full mb-10 text-slate-700 text-center font-semibold py-1">
                      Medições dos últimos 7 dias
                    </Dialog.Title>

                    <div className="mb-6 rounded-lg text-slate-700">
                      <span className=" mx-auto w-full px-2 mt-2 flex flex-start text-md font-semibold">
                        Medidor:<span className="text-slate-700 pl-1 font-semibold">{agentId}</span>
                      </span>
                      <span className="mx-auto w-full px-2 mb-2 flex flex-start text-md text-slate-700">
                        {/* Tipo Medidor: <span className="text-slate-500 pl-2">{formatAgentFamily(agentFamily ?? "UNKNOWN")}</span> */}
                        Tipo: <span className="text-slate-700 pl-1">{formatAgentFamily(agentFamily ?? "UNKNOWN")}</span>
                      </span>
                    </div>

                    <div className="mx-auto">
                      <div className="flex space-x-8 text-slate-700 text-sm font-medium">
                        <SubcategoryInput onSelectSubcategory={(evt) => {
                          const subcategoryFormated = formatSubcategoryHistoricalByAgent(evt)
                          setSelectedSubcategory({ title: subcategoryFormated, metric: evt })
                        }} />
                      </div>

                      <div className="pt-5">
                        <TimeSeriesChartWrapper
                          title={selectedSubcategory.title}
                          metric={selectedSubcategory.metric}
                          config={config}
                          isWaitingForData={fetching}
                          onFormatLines={onFormatLines}
                        />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )
      }
    </>
  )
}

export default memo(GraphicSubcategoryByAgentModal)
