import React, { FunctionComponent } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import urlJoin from "url-join"
import NicbrTechnology from "@icons/nicbr-technology.svg"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        ispUserPortal: {
          baseUrl: string
          endpoints: {
            terms: {
              path: string
            }
          }
        }
      }
    }
  }
}

const Footer: FunctionComponent = () => {
  /* GraphQL data */
  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          externalServices {
            ispUserPortal {
              baseUrl
              endpoints {
                terms {
                  path
                }
              }
            }
          }
        }
      }
    }
  `)
  const { baseUrl: ispUserPortalUrl } =
    data.site.siteMetadata.externalServices.ispUserPortal
  const { path: termsPath } =
    data.site.siteMetadata.externalServices.ispUserPortal.endpoints.terms

  return (
    <div className="flex flex-wrap sm:flex-nowrap items-center bg-slate-800 text-slate-500 text-sm">
      <a href="https://nic.br/" className="mx-2 my-2">
        <NicbrTechnology className="h-5 sm:h-8 ml-4 my-4 sm:my-2 text-slate-100" />
      </a>
      <div className="py-4 flex-1 flex flex-wrap divide-x divide-slate-500">
        <Link className="px-3 py-2 hover:underline focus:underline" to="/help">
          Ajuda
        </Link>
        <a
          className="px-3 py-2 hover:underline focus:underline"
          href={urlJoin(ispUserPortalUrl, termsPath)}
          target="_blank"
          rel="noreferrer"
        >
          Termos de uso
        </a>
        <Link
          className="px-3 py-2 hover:underline focus:underline"
          to="/licenses"
        >
          Licenças
        </Link>
      </div>
      <div className="mx-4 mb-5 sm:mb-0 text-center text-slate-600 w-full sm:w-auto">
        &copy; {new Date().getFullYear()}
      </div>
      <></>
    </div>
  )
}

export default Footer
