import React, { FunctionComponent } from "react"
import TableByMeasurement from "@components/table/table-by-measurement"
import { useSubscription } from "urql"
import { useFilterState } from "@store/use-filter-state"
import { useKeycloak } from "@react-keycloak/web"
import { SerializedMeasurementNodes } from "@models/serialized/serialized-measurement"
import { deserializeMeasurement } from "@serdes/deserialize-measurement"
import { Measurement } from "@models/measurement"
import { SET_MEASUREMENTS } from "@store/measurements-store"
import { ReducerAction, ReducerState } from "@store/index"
import { Dispatch } from "redux"
import { useDispatch, useSelector } from "react-redux"
interface Props {
  asn: number
}

const querySubscription = `
  subscription(
    $asn: Int!
    $agentId: UUID
    $subCategories: [MeasurementSubcategory!]
    $ipVersion: [IPVersion!]
    $agentFamilies: [AgentFamily!]
    $isAnomaly: Boolean
    $token: String!
  ) {
    measurements(
      asn: $asn
      agentId: $agentId
      subCategories: $subCategories
      ipVersion: $ipVersion
      agentFamilies: $agentFamilies
      isAnomaly: $isAnomaly
      token: $token
    ) {
      datetime agentId asn subcategory protocol ipFamily enhancementLevel agentFamily agentVersion reportId
      rawMetricId category peer peerLocalType peerLocalName unit preferentialValueColumn values measurementValue behindNAT carrierGradeNAT
      detectedAnomalies {
        datetime asn agentId reportId anomalyType ipFamily category subcategory agentFamily
        engineName engineVersion found valueType referenceValue measuredValue ip peer sectorId
      }
    }
  }
`

interface ReducerSelected {
  measurementsList: Measurement[] | null
}

const TableWrapper: FunctionComponent<Props> = ({ asn }) => {
  const { keycloak } = useKeycloak()

  const dispatch = useDispatch<Dispatch<ReducerAction>>()

  if (!keycloak?.authenticated) {
    throw new Error("Cannot use subscription without authentication")
  }

  const {
    filterSubcategories,
    filterIpVersions,
    filterAgentFamilies,
    filterAgentId,
    filterIsAnomaly,
    filterRealTime,
    filterCursor,
  } = useFilterState()

  const { measurementsList } = useSelector<ReducerState, ReducerSelected>(
    (state) => {
      return {
        measurementsList: state.measurements.measurementsList,
      }
    }
  )

  useSubscription<Measurement[]>(
    {
      query: querySubscription,
      variables: {
        asn: asn,
        agentId: filterAgentId !== "" ? filterAgentId : null,
        subCategories: filterSubcategories,
        ipVersion:
          filterIpVersions === "ALL" ? ["IPV4", "IPV6"] : [filterIpVersions],
        agentFamilies: filterAgentFamilies,
        isAnomaly: filterIsAnomaly === "ANOMALY",
        token: `Bearer ${keycloak.token}`,
      },
    },
    (messages: any, response: any) => {
      const measurement = (
        response.measurements as SerializedMeasurementNodes[]
      ).map((x) => deserializeMeasurement(x, true)) as Measurement[]

      if (filterRealTime) {
        dispatch({
          type: SET_MEASUREMENTS,
          measurements: !filterCursor
            ? measurementsList?.concat(measurement ?? []) ?? []
            : measurement,
        })
      }
      return measurement
    }
  )

  return <TableByMeasurement />
}

export default TableWrapper
