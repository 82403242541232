export const wellDefinedAlerts: {
  [_: string]: {
    title: string,
    lines: string[]
  }
} = {
  "graphql_error:E_TIT_UNAUTHORIZED_ACCESS_ASN": {
    title: "Erro de permissão. Tente:",
    lines: [
      "Recarregar a página para renovar seu token de autenticação.",
      "Navegar para a <a class='font-medium underline' href='/'>página inicial </a>."
    ]
  },
  "graphql_error:E_TIT_DATE_INTERVAL": {
    title: "Intervalo de consulta maior do que permitido. Tente:",
    lines: [
      "Escolher um período menor.",
      "Navegar para a <a class='font-medium underline' href='/'>página inicial </a>.",
    ]
  },
  "graphql_error:E_TIT_CANNOT_FETCH_AVAILABLE_AS": {
    title: "Erro ao carregar os sistemas autônomos vinculados. Tente:",
    lines: [
      "Recarregar a página mais tarde.",
    ]
  },
  "graphql_error:E_TIT_CANNOT_FETCH_SIMETAS_PROBES": {
    title: "Erro ao carregar suas sondas SIMETAS. Tente:",
    lines: [
      "Recarregar a página mais tarde.",
    ]
  },
  "graphql_error:E_TIT_CANNOT_VALIDATE_SIMETAS_PROBE": {
    title: "Erro ao carregar suas sondas SIMETAS. Tente:",
    lines: [
      "Recarregar a página mais tarde.",
    ]
  },
  "graphql_error:E_TIT_QUERY_TOO_BIG": {
    title: "A consulta excede o tamanho permitido. Tente:",
    lines: [
      "Repetir a consulta com novos parâmetros.",
    ]
  },
  "graphql_error:E_TIT_SERVER_ERROR": {
    title: "Erro de servidor. Tente:",
    lines: [
      "Repetir a consulta com novos parâmetros.",
      "Tentar a consulta mais tarde.",
    ]
  },
  "javascript_error:ECONNABORTED": {
    title: "Erro de conexão. Tente:",
    lines: [
      "Verificar sua conexão de internet.",
      "Tentar a consulta mais tarde.",
    ]
  },
  "http_error:4xx": {
    title: "Consulta inválida. Tente:",
    lines: [
      "Repetir a consulta com novos parâmetros.",
      "Navegar para a <a href='/'>página inicial </a>.",
    ]
  },
  "http_error:5xx": {
    title: "Erro de servidor. Tente:",
    lines: [
      "Repetir a consulta com novos parâmetros.",
      "Tentar a consulta mais tarde.",
    ]
  },
  "http_error:Network Error": {
    title: "Servidor Indisponível. Tente:",
    lines: [
      "Acessar o conteúdo mais tarde.",
      "Caso o problema persista, entre em contato com o suporte.",
    ]
  },
  "success:resource_created": {
    title: "O recurso foi criado.",
    lines: []
  }
}