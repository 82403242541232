import React, { useEffect, FunctionComponent } from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { useKeycloak } from "@react-keycloak/web"
import { Script } from "gatsby"
interface GraphQLProps {
  site: {
    siteMetadata: {
      feedbackFeaturebaseConfig: {
        organization: string
      }
    }
  }
}

const FeedbackButtonComponent: FunctionComponent = () => {
  /* GraphQL data */
  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          feedbackFeaturebaseConfig {
            organization
          }
        }
      }
    }
  `)
  //   const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()

  const { feedbackFeaturebaseConfig } = data.site.siteMetadata

  useEffect(() => {
    const win = window as any

    if (typeof win.Featurebase !== "function") {
      win.Featurebase = function () {
        // eslint-disable-next-line prefer-rest-params
        ;(win.Featurebase.q = win.Featurebase.q || []).push(arguments)
      }
    }
    win.Featurebase("initialize_portal_widget", {
      organization: feedbackFeaturebaseConfig.organization, // required
      placement: "right", // optional
      fullScreen: false, // optional
      initialPage: "MainView", // optional (MainView, RoadmapView, CreatePost, PostsView, ChangelogView)
      //   email: keycloak.profile?.email,
    })
  }, [])

  return (
    // <div>
    //   {/*If you wish to open the widget using your own button you can do so here.
    //             To get rid of our floating button, remove 'placement' from the Featurebase('initialize_feedback_widget') call above.
    //         */}
    //   <button data-featurebase-feedback>Open Widget</button>
    // </div>
    <Script
      src="https://do.featurebase.app/js/sdk.js"
      id="featurebase-sdk"
      // data-nscript="afterInteractive"
    />
  )
}

export default FeedbackButtonComponent
