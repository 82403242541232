import { MeasurementSubcategory } from "@models/measurement-subcategory";
import { IpFamily } from "@models/ip-family";
import { AgentFamily } from "@models/agent-family";
import { ValueType } from "@models/value-type";
import { shallowEqual, useSelector } from "react-redux";
import { ReducerState } from "./index";


interface ReducerSelected {
  filterSubcategories: MeasurementSubcategory[]
  filterIpVersions: IpFamily
  filterAgentFamilies: AgentFamily[]
  filterAgentId: string | null
  filterIsAnomaly: ValueType
  filterCursor: string | null
  filterRealTime: boolean
}


export const useFilterState = () => {
  return useSelector<ReducerState, ReducerSelected>((state) => {
    return {
      filterSubcategories: state.measurements.filterSubcategories,
      filterIpVersions: state.measurements.filterIpVersions,
      filterAgentFamilies: state.measurements.filterAgentFamilies,
      filterAgentId: state.measurements.filterAgentId,
      filterIsAnomaly: state.measurements.filterValueType,
      filterCursor: state.navigation.filterCursor,
      filterRealTime: state.measurements.filterRealTime,
    }
  }, shallowEqual)
}
