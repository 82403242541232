export function rearrangeArrayBySelection<
  Type extends any,
  KeyFunction extends (arg0: Type) => Key,
  SortFunction extends (arg0: Type, arg1: Type) => number,
  Key extends any = number
>(
  array: Type[] | null,
  selectedItem: Type,
  keyFunction: KeyFunction,
  ...[sortFunction]: Key extends number ? [SortFunction?] : [SortFunction]
) {
  if (!array) {
    return []
  }
  const selectedItemIndex = array.findIndex(
    (item) => keyFunction(item) === keyFunction(selectedItem)
  )
  // Modify array if item is found in index 1 or above
  if (selectedItemIndex > 0) {
    // Get sorting function
    let currentSortFunction: (arg0: Type, arg1: Type) => number
    if (sortFunction) {
      currentSortFunction = sortFunction
    } else {
      currentSortFunction = (first: Type, second: Type) =>
        (keyFunction(first) as number) - (keyFunction(second) as number)
    }
    // Sort remaining items of the array
    const mappingListWithoutSelected = [
      ...(array.slice(0, selectedItemIndex) || []),
      ...(array.slice(selectedItemIndex + 1) || []),
    ].sort(currentSortFunction)
    // Add selected item to the first position of the array
    return [selectedItem, ...mappingListWithoutSelected]
  }
  return array
}
