import React, { ReactElement, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { AutonomousSystemScopeMapping } from "@models/autonomous-system-scope-mapping"
import { ProblemError } from "@models/problem-error"
import { ReducerState } from "@store/index"
import ProblemErrorComponent from "../shared/problem-error-component"
import { useKeycloak } from "@react-keycloak/web"
import AsnQueryCard from "../settings-user-as/asn-query-card"
import axios from "axios"
import { AsInfo } from "@models/as-info"
import { parseISO, formatRelative } from "date-fns"
import { ptBR } from "date-fns/locale"
import RequireAuthentication from "../shared/require-authentication"
import ConfirmationModal from "../shared/confirmation-modal"
import IconSpinner from "@icons/icon-spinner.svg"
import { GraphQLResponse } from "@_types/graphql-response"
import { useStaticQuery, graphql } from "gatsby"
import { AutonomousSystem } from "@models/autonomous-system"

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        asAuthorization: {
          baseUrl: string
        }
      }
    }
  }
}

interface ReducerSelected {
  scopeMappingList: AutonomousSystemScopeMapping[] | null
  selectedScopeMapping: AutonomousSystemScopeMapping | null
  isFetchingScopeMappings: boolean
  scopeMappingsError: ProblemError | null
  availableAutonomousSystems: AutonomousSystem[] | null
}

const IspAppSelection: () => ReactElement = () => {
  /* Redux Hooks */
  const {
    selectedScopeMapping,
    scopeMappingsError: fetchUserPermissionsError,
    // availableAutonomousSystems,
  } = useSelector<ReducerState, ReducerSelected>((state) => {
    return {
      scopeMappingList: state.asScopeMapping.scopeMappingList,
      selectedScopeMapping: state.asScopeMapping.selectedScopeMapping,
      isFetchingScopeMappings: state.asScopeMapping.isFetchingScopeMappings,
      scopeMappingsError: state.asScopeMapping.scopeMappingsError,
      availableAutonomousSystems:
        state.autonomousSystems.availableAutonomousSystems,
    }
  })

  const data = useStaticQuery<GraphQLProps>(graphql`
    query {
      site {
        siteMetadata {
          externalServices {
            asAuthorization {
              baseUrl
            }
          }
        }
      }
    }
  `)
  const asAuthorizationUrl =
    data.site.siteMetadata.externalServices.asAuthorization.baseUrl

  /* React Hooks */
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()
  /* -- Fetch AS info -- */
  const [showQueryCard, setShowQueryCard] = useState(false)
  const [isFetchingAsInfo, setFetchingAsInfo] = useState(false)
  const [asInfo, setAsInfo] = useState<AsInfo>()
  const [fetchAsInfoError, setFetchAsInfoError] = useState<ProblemError>()
  /* -- Request admin access -- */
  const [showModal, setShowModal] = useState(false)
  const [isRequestingAdminAccess, setRequestingAdminAccess] = useState(false)
  const [requestedAdminAccess, setRequestedAdminAccess] = useState(false)
  const [expirationDate, setExpirationDate] = useState<Date>()
  const [requestAdminAccessError, setRequestAdminAccessError] =
    useState<ProblemError>()
  /* React Hooks */
  const isAdmin = useKeycloak().keycloak?.hasRealmRole("admin")

  const [asn, setAsn] = useState<number>()

  useEffect(() => {
    if (!isAdmin) {
      setAsn(selectedScopeMapping?.resource?.asn)
    }
  }, [isAdmin, selectedScopeMapping])

  const asnInputRef = useRef<HTMLInputElement>(null)

  /* Helpers */
  const getAsnFromInput = () => {
    if (!asnInputRef.current) {
      throw new Error("No input found.")
    }
    const match = asnInputRef.current.value.match(/^\s*(?:ASN?\s*)?(\d+)\s*$/i)
    if (!match || !match[0]) {
      throw new Error("Invalid ASN format.")
    }
    return parseInt(match[1], 10)
  }

  const fetchAsInfo = async () => {
    setFetchAsInfoError(undefined)
    setRequestAdminAccessError(undefined)
    setExpirationDate(undefined)
    if (!keycloak.authenticated) {
      // console.log('fetchAsInfo not authenticated', keycloak);
      setFetchAsInfoError({
        detail: "Usuário não autenticado.",
      })
      let href = window.location.href
      if (!window.location.pathname.endsWith("/")) {
        href = `${window.location.origin}${window.location.pathname}/${window.location.search}${window.location.hash}`
      }
      keycloak.login({ redirectUri: href })
      return
    }
    setShowQueryCard(false)
    setAsInfo(undefined)
    setRequestedAdminAccess(false)
    if (asnInputRef.current && asnInputRef.current.value.trim() === "") {
      asnInputRef.current.focus()
      return
    }
    let asn: number
    try {
      asn = getAsnFromInput()
    } catch (err: any) {
      // console.log('getAsnFromInput error', err);
      if (err?.message?.includes("No input found")) {
        setFetchAsInfoError({
          detail: "Consulta não pode ser vazia.",
        })
      } else if (err?.message?.includes("Invalid ASN format")) {
        setFetchAsInfoError({
          detail: "ASN inválido.",
        })
      } else {
        setFetchAsInfoError({
          detail:
            "Não foi possível identificar o ASN. Recarregue a página e tente novamente.",
        })
      }
      return
    }
    try {
      setFetchingAsInfo(true)
      const response = await axios.post<GraphQLResponse<{ asInfo: AsInfo }>>(
        `${asAuthorizationUrl}`,
        {
          query:
            `` +
            `query ($asn: Int!) { ` +
            `  asInfo(asn: $asn) { ` +
            `    asn asName asAdminEmail ` +
            `  } ` +
            `}`,
          variables: {
            asn,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        }
      )
      if (response.data.errors) {
        if (response.data.errors[0]) {
          setFetchAsInfoError(JSON.parse(response.data.errors[0].message))
          return
        } else {
          throw new Error("Unknown error")
        }
      }
      if (response.data.data?.asInfo?.asName) {
        setAsInfo(response.data.data.asInfo)
        setShowQueryCard(true)
      } else {
        setFetchAsInfoError({
          title: "Sistema Autônomo não encontrado",
          detail:
            "O sistema autônomo não foi encontrado. Verifique o ASN e tente novamente.",
        })
      }
    } catch (err: any) {
      // console.log('fetchAsInfo error', err);
      if (err.response?.data?.errors && err.response.data.errors[0]?.message) {
        setFetchAsInfoError(JSON.parse(err.response.data.errors[0].message))
      } else {
        setFetchAsInfoError({
          title: "Erro ao consultar ASN",
          detail:
            "Ocorreu um erro de conexão. Verifique sua Internet e tente novamente mais tarde.",
        })
      }
    } finally {
      setFetchingAsInfo(false)
    }
  }

  const requestAdminAccess = async () => {
    setRequestAdminAccessError(undefined)
    if (!keycloak.authenticated) {
      // console.log('requestAdminAccess not authenticated', keycloak);
      setRequestAdminAccessError({
        detail: "Usuário não autenticado.",
      })
      let href = window.location.href
      if (!window.location.pathname.endsWith("/")) {
        href = `${window.location.origin}${window.location.pathname}/${window.location.search}${window.location.hash}`
      }
      keycloak.login({ redirectUri: href })
      return
    }
    const asn = asInfo?.asn
    if (!asn) {
      // console.log('requestAdminAccess no ASN');
      setRequestAdminAccessError({
        detail:
          "Não foi possível identificar o ASN. Recarregue a página e tente novamente.",
      })
      return
    }
    const requesterEmail: string = (keycloak.tokenParsed as any)?.email
    if (!requesterEmail) {
      // console.log('requestAdminAccess no requester email', keycloak);
      setRequestAdminAccessError({
        detail:
          "Não foi possível identificar o e-mail de usuário. Recarregue a página e tente novamente.",
      })
      return
    }

    // DEBUG: Mock call and return values
    /*
      setRequestingAdminAccess(true);
      const _expiration_date = new Date();
      _expiration_date.setHours(_expiration_date.getHours() + 12);
      const response = {data: {expiration_date: formatISO(_expiration_date)}};
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setExpirationDate(parseISO(response.data.expiration_date));
      setRequestingAdminAccess(false);
      setRequestedAdminAccess(true);
      return;
    */

    try {
      setRequestingAdminAccess(true)
      const response = await axios.post<
        GraphQLResponse<{ requestAdminAccess: { expirationDate: string } }>
      >(
        `${asAuthorizationUrl}`,
        {
          query:
            `` +
            `mutation ($asn: Int!) { ` +
            `  requestAdminAccess(asn: $asn) { ` +
            `    expirationDate ` +
            `  } ` +
            `}`,
          variables: {
            asn,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        }
      )
      if (response.data.errors) {
        if (response.data.errors[0]) {
          setRequestAdminAccessError(
            JSON.parse(response.data.errors[0].message)
          )
          return
        } else {
          throw new Error("Unknown error")
        }
      }
      if (response.data.data?.requestAdminAccess?.expirationDate) {
        setExpirationDate(
          parseISO(response.data.data.requestAdminAccess.expirationDate)
        )
        setRequestedAdminAccess(true)
      } else {
        setRequestAdminAccessError({
          title: "Erro ao pedir acesso de administração",
          detail: "Ocorreu um erro desconhecido. Tente novamente mais tarde.",
        })
      }
    } catch (err: any) {
      // console.log('requestAdminAccess error', err);
      if (
        err.response?.data?.errors &&
        err.response?.data?.errors[0]?.message
      ) {
        setRequestAdminAccessError(
          JSON.parse(err.response.data.errors[0].message)
        )
      } else {
        setRequestAdminAccessError({
          title: "Erro ao pedir acesso de administração",
          detail:
            "Ocorreu um erro de conexão. Verifique sua Internet e tente novamente mais tarde.",
        })
      }
    } finally {
      setRequestingAdminAccess(false)
    }
  }

  const dialogMessage = asInfo?.asAdminEmail
    ? `Um e-mail será enviado para ${asInfo.asAdminEmail}.`
    : "Um e-mail será enviado para o endereço de administração do AS."

  const successMessage = expirationDate
    ? `E-mail enviado com sucesso! Verifique a caixa de entrada e spam, e confirme o acesso até ${formatRelative(
        expirationDate,
        new Date(),
        { locale: ptBR }
      )}.`
    : "E-mail enviado com sucesso! Verifique a caixa de entrada e spam, e confirme o acesso em breve."

  return (
    <div className="space-y-12">
      {fetchUserPermissionsError && (
        <ProblemErrorComponent
          problemError={fetchUserPermissionsError}
          titleClassName="text-3xl text-red-600 font-semibold mb-3"
          detailClassName="text-red-600"
        />
      )}
      <div className="w-full px-5 text-lg text-slate-900">
        <RequireAuthentication>
          <div className="space-y-12">
            {/* Bloco 1 - Solicitação de vínculo */}
            <div className="max-w-2xl">
              <div className="p-4 rounded-md shadow-lg bg-slate-200">
                <h3 className="text-lg font-medium text-slate-900 mb-1">
                  Solicitar vínculo com AS
                </h3>
                <p className="text-sm text-slate-600 max-w-lg leading-snug">
                  Para ver ou gerenciar um sistema autônomo no Portal do AS,
                  solicite o vínculo com este sistema ao seu responsável,
                  conforme cadastro no Registro.br.
                </p>
                <div className="flex rounded-md shadow-sm mt-4">
                  <form
                    onSubmit={(event) => {
                      event.preventDefault()
                      !showModal && !isFetchingAsInfo && fetchAsInfo()
                    }}
                    className="w-full"
                  >
                    <fieldset disabled={isFetchingAsInfo}>
                      <div className="flex rounded-md shadow-sm mt-2">
                        <input
                          ref={asnInputRef}
                          type="number"
                          name="email"
                          id="email"
                          className="block w-full rounded-none rounded-l-md border-slate-300 focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                          placeholder="Buscar por ASN"
                        />
                        <button
                          type="submit"
                          className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-500 focus:border-blue-500 focus:outline-none focus:ring ring-offset-1 ring-offset-slate-200 focus:ring-blue-500"
                        >
                          {isFetchingAsInfo ? (
                            <IconSpinner className="animate-spin h-4 w-4 object-center" />
                          ) : (
                            <>
                              <svg
                                className="flex-shrink-0 w-5 h-5 text-slate-200"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                />
                              </svg>
                              <span>Buscar</span>
                            </>
                          )}
                        </button>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
              {fetchAsInfoError && (
                <div className="text-red-600 mt-8 px-6">
                  <ProblemErrorComponent
                    problemError={fetchAsInfoError}
                    titleClassName="text-2xl font-semibold mb-2 leading-tight"
                    detailClassName="text-xl"
                  />
                </div>
              )}
              {asInfo && (
                <AsnQueryCard
                  onCloseCard={() => setShowQueryCard(false)}
                  onRequestAdminAccess={() => setShowModal(true)}
                  showCard={showQueryCard}
                  autonomousSystem={asInfo}
                />
              )}

              <ConfirmationModal
                onCloseModal={() => setShowModal(false)}
                onConfirmRequest={() => requestAdminAccess()}
                showModal={showModal}
                isBusy={isRequestingAdminAccess}
                isDone={requestedAdminAccess}
                dialogTitle={"Confirme a solicitação."}
                dialogMessage={dialogMessage}
                successMessage={successMessage}
                confirmationError={requestAdminAccessError}
              />
            </div>
          </div>
        </RequireAuthentication>
      </div>
    </div>
  )
}
export default IspAppSelection
