import React, { FunctionComponent } from "react"
import EmptyStateSimetAS from "./empty-state-simetas";

const EmptyStateSimetASAntiSpoofing: FunctionComponent = () => {
  return (
    <div className="flex flex-col">
      <h3 className="text-lg font-medium text-slate-600 mb-1">Filtragem Antispoofing</h3>
      <p className="text-sm text-slate-500 max-w-lg">
        O PAS monitora se o sistema autônomo pratica a validação do endereço de origem dos pacotes IP.
        Esta validação protege contra ataques de spoofing e é uma medida conhecida como prática operacional
        <a className="text-blue-600 font-medium ml-1" href="https://www.rfc-editor.org/rfc/rfc2827" target="_blank">
          BCP-38
        </a>, indicada também pela iniciativa internacional
        <a className="text-blue-600 font-medium ml-1" href="https://www.manrs.org/" target="_blank">
          MANRS
        </a>.
      </p>
      <p className="text-sm text-slate-500 max-w-lg mt-4">
        O PAS realiza a medição da filtragem Antispoofing através da <span className="font-semibold">sonda de medição SIMET-AS</span>, um componente
        do servidor SIMET-ISP.
      </p>
      <EmptyStateSimetAS />
    </div>
  )
}

export default EmptyStateSimetASAntiSpoofing;