import React, { FunctionComponent } from "react"
import { Button } from "./Button"
import { Container } from "./Container"
import { useKeycloak } from "@react-keycloak/web"

export const CallToAction: FunctionComponent = () => {
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()
  let kCHref
  if (isKeycloakInitialized && keycloak) {
    kCHref = keycloak?.createRegisterUrl()
  }
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-blue-600 py-32"
    >
      <img
        className="absolute top-1/2 left-1/2 max-w-none transform -translate-x-1/2 -translate-y-1/2"
        src="/images/landing/background-call-to-action.jpg"
        alt=""
        width={2347}
        height={1244}
      />
      <Container className="relative">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            Disponível agora para seu provedor
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            O PAS é um <span className="">serviço gratuito</span> do NIC.br.
            Criamos o PAS para apoiar o provedor a melhorar a sua Internet.
            Melhorar a Internet no Brasil é a missão do NIC.br.
          </p>
          <Button href={kCHref} color="white" className="mt-10 px-10 py-3">
            Ative para seu provedor
          </Button>
        </div>
      </Container>
    </section>
  )
}
