import React, { ReactElement } from "react"
import Helmet from "react-helmet"
import Footer from "@components/shared/footer"
import Header from "@components/shared/header"
import PaddedParagraph from "@components/shared/padded-paragraph"

const TermsPage: () => ReactElement = () => {
  return (
    <div className="flex flex-col min-h-screen h-full bg-slate-200">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Termos de Uso - PAS</title>
      </Helmet>
      <Header />
      <div className="flex-grow max-w-3xl w-full mx-auto mt-16 pb-32 px-2">
        <div className="text-2xl text-slate-700 font-medium mb-1">
          Termos de Uso e Política de Privacidade
        </div>
        <div className="text-sm text-slate-500">
          Atualizado em 18 de maio de 2021
        </div>
        <div className="mt-2 text-slate-600 text-base">
          <PaddedParagraph className="mt-1">
            A nossa Política de Privacidade foi criada com o intuito de
            disponibilizar o conteúdo dos nossos sítios web de forma segura e
            permitir que o usuário acesse e utilize corretamente as informações
            fornecidas. Sugerimos a leitura cuidadosa de todos os itens desta
            página para que você possa aproveitar de forma consciente o conteúdo
            oferecido.
          </PaddedParagraph>
          <PaddedParagraph className="mt-1">
            Após a leitura desta Política de Privacidade, se você continuar a
            utilizar os nossos sítios web, você aceitará as condições e termos
            de uso do nosso portal e estará sujeito as regras aqui apresentadas.
          </PaddedParagraph>
          <h2 className="text-xl mt-2">Direitos autorais</h2>
          <PaddedParagraph className="mt-1">
            O conteúdo disponível nos sítios web do CGI.br, NIC.br e dos nossos
            departamentos, projetos e serviços, como o Registro.br, Cetic.br.br,
            CERT.br, Ceptro.br, Simet, Ceweb.br, Escritório Brasileiro do W3C,
            PAS e outros, podem ser utilizados, reproduzidos, divulgados e
            traduzidos para fins meramente informativos (não comercial) e desde
            que a fonte e seus autores sejam citados. Fica proibida a utilização
            destas informações para fins comerciais. Nossos conteúdos não
            possuem restrições de cópia ou reprodução, mas podem ocorrer
            situações isoladas em que obras publicadas em nossos sítios web
            exijam licenças restritivas, nestes casos, os conteúdos serão
            acompanhados de orientações para o seu uso correto.
          </PaddedParagraph>
          <h2 className="text-xl mt-2">Marcas Registradas</h2>
          <PaddedParagraph className="mt-1">
            As marcas registradas do CGI.br, NIC.br e dos nossos departamentos,
            projetos e serviços não podem ser utilizadas e reproduzidas sem
            autorização prévia e expressa do NIC.br. Também é estritamente
            proibido fazer associações das nossas marcas registradas com
            produtos e serviços que não sejam de autoria do CGI.br, NIC.br e dos
            nossos departamentos para evitar confusões, difamações e
            depreciações da imagem da entidade.
          </PaddedParagraph>
          <h2 className="text-xl mt-2">Privacidade</h2>
          <PaddedParagraph className="mt-1">
            Com o objetivo de garantir a privacidade e a proteção dos dados dos
            usuários que acessam os portais do CGI.br, do NIC.br e dos nossos
            departamentos, eventuais informações ou dados de usuários coletados
            através destes canais ficarão protegidos e só serão fornecidos a
            terceiros, se previamente autorizado ou em decorrência de ordem
            judicial.
          </PaddedParagraph>
          <PaddedParagraph className="mt-1">
            O site do Registro.br fornece alguns dados dos titulares de domínios
            que podem ser consultados apenas para fins de contato, ou ainda,
            para pesquisas de blocos de endereço, sendo terminantemente proibida
            a distribuição, transformação, modificação, comercialização e
            reprodução destas informações, em particular para fins publicitários
            ou similares. Se for comprovada qualquer forma de utilização
            indevida ou coleta abusiva dos dados dos titulares de domínios
            “.br”, o NIC.br poderá, sem prévio aviso, impor uma restrição de
            acesso, sem prejuízo de serem adotadas medidas legais para punição
            deste ato. O Cetic.br preza pela relação de confiança entre
            usuários, empresas e governos no meio digital, por isso, a entidade
            toma todas as medidas que impeçam o acesso a dados coletados ou
            armazenados por nós.
          </PaddedParagraph>
          <h3 className="font-semibold mt-2">
            Que informações pessoais recolhemos das pessoas que visitam o nosso
            sítio ou aplicativo?
          </h3>
          <p className="mt-1">
            Quando entrar em no nosso site, conforme o caso, você pode ser
            solicitado a inserir seu nome, endereço de e-mail, endereço ou
            outros detalhes para ajudá-lo com sua experiência.
          </p>
          <h3 className="font-semibold mt-2">
            Quando é que vamos coletar informações?
          </h3>
          <p className="mt-1">
            Coletamos informações quando você se cadastra em nosso site,
            preenche um formulário ou insere informações em nosso site.
          </p>
          <h3 className="font-semibold mt-2">
            Como usamos as suas informações?
          </h3>
          <p className="mt-1">
            Podemos usar as informações que coletamos quando você navega pelo
            site ou usa certos recursos locais das seguintes maneiras:
          </p>
          <ul className="pl-7 list-disc py-1">
            <li>Para personalizar a experiência do usuário.</li>
            <li>Para melhorar o nosso site a fim de melhor atendê-lo.</li>
          </ul>
          <h3 className="font-semibold mt-2">
            Como protegemos as informações do visitante?
          </h3>
          <p className="mt-1">
            Nós não usamos a digitalização e/ou varredura de vulnerabilidades
            aos padrões PCI. Nós não usamos Malware Scanning. As suas
            informações pessoais estão contidas em redes seguras e é acessível
            somente por um número limitado de pessoas que têm direitos especiais
            de acesso a tais sistemas, e são obrigados a manter as informações
            confidenciais. Além disso, todas as informações sensíveis fornecidas
            são criptografados através da tecnologia Secure Socket Layer (SSL).
            Implementamos uma série de medidas de segurança quando um usuário
            faz um pedido para manter a segurança de suas informações pessoais.
            Todas as transações são processadas através de um provedor de acesso
            e não são armazenados ou processados ​​em nossos servidores.
          </p>
          <h3 className="font-semibold mt-2">Usamos "cookies"?</h3>
          <p className="mt-1">
            Sim. Cookies são pequenos arquivos que um site transfere para o
            disco rígido do seu computador através de seu navegador de Internet,
            que permitem que os sistemas reconheçam o seu browser e
            capture/lembre de certas informações. Nós usamos cookies para:
          </p>
          <ul className="pl-7 list-disc py-1">
            <li>
              Compreender e guardar as preferências do usuário para futuras
              visitas.
            </li>
            <li>Divulgação a terceiros</li>
          </ul>
          <PaddedParagraph className="mt-1">
            Nós não vendemos, comercializamos, ou transferimos a terceiros as
            suas informações.
          </PaddedParagraph>
          <h3 className="font-semibold mt-2">
            Como é que o nosso site lida com sinais de rastreamento?
          </h3>
          <p className="mt-1">
            Nós nos honramos em não rastrear os sinais e não acompanhar cookies,
            ou usar a publicidade quando o mecanismo Do Not Track (DNT) do
            navegador está em vigor.
          </p>
          <h2 className="text-xl mt-2">AntiSpam</h2>
          <PaddedParagraph className="mt-1">
            O CGI.br possui um compromisso com a segurança da informação na
            Internet e por isso seguimos regras e dicas de boas práticas
            antispam. O CGI.br compromete-se a não enviar spam, malas-diretas ou
            e-mails de qualquer natureza sem o consentimento prévio do usuário.
            O usuário que acessa o sítio web do CGI.br tem a liberdade de
            decidir pelo recebimento de informações ou pelo cancelamento destas
            a qualquer momento. Todas as práticas que seguimos estão presentes
            na política do sítio web{" "}
            <a
              className="text-indigo-500 underline hover:text-indigo-800 focus:text-indigo-800"
              href="https://antispam.br/"
            >
              Antispam.br
            </a>
            .
          </PaddedParagraph>
          <h2 className="text-xl mt-2">Links de terceiros</h2>
          <PaddedParagraph className="mt-1">
            Nós não incluímos ou oferecemos produtos ou serviços de terceiros no
            nosso site.
          </PaddedParagraph>
          <h2 className="text-xl mt-2">Google</h2>
          <PaddedParagraph className="mt-1">
            Requisitos de publicidade do Google podem ser resumidos pelo{" "}
            <a
              className="text-indigo-500 underline hover:text-indigo-800 focus:text-indigo-800"
              href="https://support.google.com/adspolicy/answer/6008942?hl=pt"
            >
              Princípios de publicidade do Google
            </a>
            . Eles são colocados em prática para proporcionar uma experiência
            positiva para os usuários. Nós não habilitamos o Google AdSense em
            nosso site.
          </PaddedParagraph>
          <h2 className="text-xl mt-2">Comunicação por meio eletrônico</h2>
          <PaddedParagraph className="mt-1">
            O Simet recebe e armazena de forma segura todas as informações
            enviadas para o nosso sítio web por meios eletrônicos. Os meios
            eletrônicos garantem um diálogo ágil e eficiente, por isso, são
            ferramentas fundamentais para a comunicação entre o Simet e os
            usuários de Internet.
          </PaddedParagraph>
          <p className="mt-1">
            O NIC.br reserva-se o direito de atualizar esta Política de
            Privacidade sempre que julgar necessário complementar as informações
            aqui dispostas para proteger os conteúdos disponíveis e os usuários
            que os acessam. Para tanto, recomendamos que você acesse esta página
            periodicamente para verificar possíveis mudanças.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default TermsPage
