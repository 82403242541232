import React, { FunctionComponent } from "react"

interface Props {
  className?: string
  value: boolean
  defaultValue?: boolean
  isActive?: boolean
  onClick: () => void
}

const ToggleCheckbox: FunctionComponent<Props> = ({
  className,
  value,
  defaultValue,
  isActive,
  onClick,
}) => {
  if (typeof isActive === "undefined") {
    isActive = true
  }
  const hasDefaultValue = typeof defaultValue !== "undefined"

  return (
    <button
      className={`flex items-center cursor-pointer focus:outline-none focus:ring ${
        className || ""
      }`}
      onClick={() => isActive && onClick()}
    >
      {/*<!-- toggle -->*/}
      <div className="relative">
        {/* <!-- input -->*/}
        <input type="checkbox" className="hidden" checked={value} readOnly />
        {/*<!-- line -->*/}
        <div className="relative w-10 h-4">
          <div
            className={`absolute transition-colors top-0 left-0 w-full h-full ${
              value
                ? isActive
                  ? "bg-blue-600"
                  : "bg-slate-200"
                : isActive
                ? "bg-slate-200"
                : "bg-blue-600"
            } rounded-full shadow-inner`}
          >
            <div
              className={`-mt-1 -mr-1 absolute transform transition-transform w-6 h-6 bg-white rounded-full shadow inset-y-0 right-0 border border-slate-200 ${
                value ? "translate-x-0" : "-translate-x-full"
              }`}
            />
          </div>
        </div>
        {/* <div
          className="w-10 h-4 bg-blue-300 rounded-full shadow-inner"
        /> */}
      </div>
    </button>
  )
}

export default ToggleCheckbox
