/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./src/css/index.css"

import React from "react"
import KeycloakWrapper from "./src/wrappers/keycloak"
import ReduxWrapper from "./src/wrappers/redux"
import DefaultLoggedInLayout from "./src/components/layouts/loggedIn/index"
import DefaultLoggedOutLayout from "./src/components/layouts/loggedOut/index"
import UrqlProvider from "./src/components/shared/urql-provider"
import InitialDataLayout from "./src/components/layouts/initial-data-layout"

const isBrowser = typeof window !== "undefined"
let pasURL = "/"
if (isBrowser) {
  pasURL = window.location.pathname.toString().split("/")
}

const wrapRootElement = ({ element }) => {
  return (
    <KeycloakWrapper>
      <ReduxWrapper>
        <UrqlProvider>
          <InitialDataLayout>{element}</InitialDataLayout>
        </UrqlProvider>
      </ReduxWrapper>
    </KeycloakWrapper>
  )
}

export { wrapRootElement }

const wrapPageElement = ({ element, props }) => {
  let DefaultLayout

  if (isBrowser) {
    pasURL = props.location.pathname.toString().split("/")
  }

  if (
    isBrowser &&
    pasURL[1] !== null &&
    pasURL[1].replace(" ", "") !== "" &&
    pasURL[1].toLocaleLowerCase() !== "home"
  ) {
    DefaultLayout = DefaultLoggedInLayout
  } else {
    DefaultLayout = DefaultLoggedOutLayout
  }

  //   if (
  //     isBrowser &&
  //     pasURL[1] !== null &&
  //     pasURL[1].replace(" ", "") !== "" &&
  //     pasURL[1].toLocaleLowerCase() === "account"
  //   ){
  //     DefaultLayout = DefaultLoggedInLayout
  //   }

  if (
    isBrowser &&
    pasURL[2] &&
    pasURL[2] !== null &&
    pasURL[2].replace(" ", "") !== "" &&
    pasURL[2].toLocaleLowerCase() == "approve-binding-request"
  ) {
    DefaultLayout = DefaultLoggedOutLayout
  }

  if (DefaultLayout) {
    return (
      <DefaultLayout location={props.location.pathname}>
        {element}
      </DefaultLayout>
    )
  }
}
export { wrapPageElement }
