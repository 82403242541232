import React, { FunctionComponent } from "react"
import { Switch } from "@headlessui/react"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

interface Props {
  isActive: boolean
  text: string
  onChange: (value: Boolean) => void
}

const ToggleComponent: FunctionComponent<Props> = ({
  isActive,
  text,
  onChange
}) => {
  return (
    <>
      <Switch.Group as="div" className="flex items-center">
        <Switch
          checked={isActive}
          onChange={onChange}
          className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className="pointer-events-none absolute bg-white w-full h-full rounded-md"
          />
          <span
            aria-hidden="true"
            className={classNames(
              isActive ? "bg-blue-600" : "bg-slate-200",
              "pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"
            )}
          />
          <span
            aria-hidden="true"
            className={classNames(
              isActive ? "translate-x-5" : "translate-x-0",
              "pointer-events-none absolute left-0 inline-block h-5 w-5 border border-slate-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200"
            )}
          />
        </Switch>
        <Switch.Label as="span" className="ml-4">
          <span className="text-sm text-slate-700">
            {text}
          </span>
        </Switch.Label>
      </Switch.Group>

    </>
  )
}

export default ToggleComponent
