import React, { ReactNode, FunctionComponent } from "react"
import { useTooltipStateRef } from "@utils/use-tooltip-state-ref"
import { Transition } from "@headlessui/react"

interface Props {
  message?: string
  messageElement?: ReactNode
  children: ReactNode
}

const Tooltip: FunctionComponent<Props> = ({
  message,
  children,
  messageElement,
}) => {
  const [
    showMeasurementDataHelp,
    setShowMeasurementDataHelp,
    showMeasurementDataHelpRef,
  ] = useTooltipStateRef<HTMLDivElement>()

  return (
    <>
      <div
        className="self-end py-1 transition ease-in-out"
        onMouseEnter={() => setShowMeasurementDataHelp(true)}
        onTouchStart={() => setShowMeasurementDataHelp(true)}
        onMouseLeave={() => setShowMeasurementDataHelp(false)}
        ref={showMeasurementDataHelpRef}
      >
        {children}
      </div>
      <Transition
        show={showMeasurementDataHelp}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="absolute z-20 mt-2 p-2 bg-slate-500 shadow-md text-white"
        // className="z-10 mt-8 mx-1 px-3 py-2 bg-slate-800 bg-opacity-90 shadow-md text-white text-xs font-normal"
        // className="absolute left-0 max-w-xs sm:max-w-xl z-10 ml-2 mt-1 p-2 bg-slate-800 bg-opacity-90 shadow-md text-white text-xs font-normal flex flex-col space-y-1"
      >
        {messageElement ? messageElement : message}
      </Transition>
    </>
  )
}

export default Tooltip
