import React, { FunctionComponent, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Measurement } from "@models/measurement"
import { ReducerState } from "@store/index"
import { formatIpFamily } from "@models/ip-family"
import { formatAgentFamily } from "@models/agent-family"
import { MEASUREMENT_SUBCATEGORIES } from "@models/measurement-subcategory"
import {
  DownloadIcon,
  UploadIcon,
  ChartSquareBarIcon,
} from "@heroicons/react/solid"
import { ValueType, VALUE_TYPE } from "@models/value-type"
import { sortMeasurements } from "@utils/measurements/sort-measurements"
import { formatDateWithLocale } from "@utils/query-params"
import { TableByMeasurementsType } from "./table-type"
import { convertUnits } from "@utils/convert-unit"
import PageSpinner from "../shared/page-spinner"
import EmptyStateTable from "./empty-state-table"
import { shortenUUID } from "@utils/shorten-uuid"
import { getMeasurementPeer } from "@utils/get-measurement-peer"
import { AutonomousSystem } from "@models/autonomous-system"
import Tooltip from "../shared/tooltip"
import GraphicSubcategoryByAgentModal from "../modal/graphic-subcategory-by-agent-modal"
import { shallowEqual } from "react-redux"
import { formatISO, parseISO } from "date-fns"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

const formatMeasurementData = (
  data: Measurement[],
  isAnomaly: boolean
): TableByMeasurementsType[] => {
  let measurements: any = {}
  let measurementsFiltered: Measurement[] = []

  // verifica se filtro anomalia esta setado e se possui nas medições
  if (isAnomaly) {
    const anomaly = data.filter(
      (measurement) => measurement.detectedAnomalies.length > 0
    )

    // usa reducer para ver se possui 2 anomalias com o mesmo report e datetime, se sim, junta em um so
    const anomaly_reducer = anomaly.reduce((acc: Measurement[], line) => {
      const idx = acc.findIndex((e) => {
        return (
          line.agentId &&
          e.reportId === line.reportId &&
          e.datetime.getTime() === line.datetime.getTime()
        )
      })

      if (idx >= 0) {
        acc[idx] = line
      } else {
        acc.push(line)
      }

      // idx > -1 ? acc[idx] = line : acc.push(line)

      return acc
    }, [])

    // recupera os outros elementos da medição.
    for (let x of anomaly_reducer) {
      measurementsFiltered.push(
        ...data.filter((res) => {
          return (
            x.agentId === res.agentId &&
            x.reportId === res.reportId &&
            x.datetime.getTime() == res.datetime.getTime()
          )
        })
      )
    }
    data = measurementsFiltered
  }

  let dataSorted = sortMeasurements(data, "SUBCATEGORY", true)

  // removing jitter_rtt from measurements

  data = dataSorted.filter(
    (measurement) => measurement.subcategory !== MEASUREMENT_SUBCATEGORIES[6]
  )

  //group measurements by agentId and datetime
  for (let measurement of data) {
    let key =
      measurement.agentId +
      "_" +
      measurement.datetime.toString() +
      "_" +
      measurement.ipFamily

    if (!(key in measurements)) {
      measurements[key] = measurement
    }

    if (!((measurement.subcategory as string) in measurements[key])) {
      measurements[key][measurement.subcategory as string] = {}
    }

    measurements[key]["detectedAnomalies"].concat(measurement.detectedAnomalies)

    measurements[key][measurement.subcategory as string] = {
      value: measurement.measurementValue,
      unit: measurement.unit,
      detectedAnomalies: measurement.detectedAnomalies,
    }

    // Object.assign(measurements[key], measurement ?? {})
    // Object.assign(measurements[key]["detectedAnomalies"], measurement.detectedAnomalies)
    // Object.assign(measurements[key][measurement.subcategory as string], {
    //   value: measurement.measurementValue, unit: measurement.unit, detectedAnomalies: measurement.detectedAnomalies
    // } ?? {})

    // measurements[key]["detectedAnomalies"].push(...measurement.detectedAnomalies)
  }

  return Object.values<TableByMeasurementsType>(measurements).sort(
    (a, b) => b.datetime.getTime() - a.datetime.getTime()
  )
}

const columnsTable = [
  {
    name: "",
    isPrimary: true,
  },
  {
    name: "Perda",
    isPrimary: false,
    unit: "(%)",
  },
  {
    name: "Latência",
    isPrimary: false,
    unit: "(ms)",
  },
  {
    name: "Jitter",
    isPrimary: false,
    icon: UploadIcon,
    unit: "(ms)",
  },
  {
    name: "Jitter",
    isPrimary: false,
    icon: DownloadIcon,
    unit: "(ms)",
  },
  {
    name: "Vazão",
    isPrimary: false,
    icon: UploadIcon,
    unit: "(Mb/s)",
  },
  {
    name: "Vazão",
    isPrimary: false,
    icon: DownloadIcon,
    unit: "(Mb/s)",
  },
  {
    name: "Família IP",
    isPrimary: false,
  },
  {
    name: "Tipo Medidor",
    isPrimary: false,
  },
  //   {
  //     name: "Id Medidor",
  //     isPrimary: false,
  //   },
  {
    name: "Assinante",
    isPrimary: false,
  },
  {
    name: "Servidor de Teste",
    isPrimary: false,
  },
  {
    name: "Data",
    isPrimary: false,
  },
]

interface ReducerSelected {
  measurementsList: Measurement[] | null
  filterValueType: ValueType
  isWaitingForData: boolean
  availableAutonomousSystems: AutonomousSystem[] | null
  filterRealTime: boolean
}

const TableByMeasurements: FunctionComponent = () => {
  const {
    measurementsList,
    filterValueType,
    isWaitingForData,
    availableAutonomousSystems,
    filterRealTime,
  } = useSelector<ReducerState, ReducerSelected>((state) => {
    return {
      measurementsList: state.measurements.measurementsList,
      filterValueType: state.measurements.filterValueType,
      isWaitingForData: state.measurements.isWaitingForData,
      availableAutonomousSystems:
        state.autonomousSystems.availableAutonomousSystems,
      filterRealTime: state.measurements.filterRealTime,
    }
  }, shallowEqual)

  const [isOpen, setIsOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState<any | undefined>(undefined)

  const isAnomaly = filterValueType === VALUE_TYPE[1]

  const dataCache = useMemo(() => {
    if (!measurementsList) return []
    // console.log("formatMeasurementData rendered: " + JSON.stringify(measurementsList) + ', anomaly: '+ JSON.stringify(isAnomaly))
    return formatMeasurementData(measurementsList, isAnomaly)
  }, [measurementsList, isAnomaly])

  return (
    <>
      <div className="inline-block min-w-full align-middle mt-2">
        <div className="-mt-3">
          <table
            className="border-separate w-full"
            style={{ borderSpacing: 0 }}
          >
            <thead>
              <tr className="text-left">
                {columnsTable.map((column, idx) => {
                  return (
                    <th
                      key={idx}
                      scope="col"
                      className={classNames(
                        column.isPrimary ? "pl-8" : "",
                        "sticky top-0 z-10 border-b whitespace-normal border-slate-300 px-2.5 text-sm font-semibold text-slate-900 backdrop-blur backdrop-filter"
                      )}
                    >
                      <div className="flex items-baseline leading-none">
                        {column.name}
                        {column.icon && (
                          <column.icon className="h-4 w-4 text-slate-600 " />
                        )}
                      </div>
                      <div className="pr-1 font-medium text-xs">
                        {column?.unit ?? ""}
                      </div>
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody className="bg-white/25">
              {(!isWaitingForData || filterRealTime) &&
                measurementsList &&
                measurementsList.length > 0 &&
                dataCache.map((measurement, measurementIdx) => {
                  return (
                    <>
                      <tr
                        key={measurement.agentId + measurementIdx}
                        id={measurement.agentId + measurementIdx}
                        className={classNames(
                          measurement.realTime
                            ? measurementIdx == 0
                              ? "first:animate-bounce-short"
                              : ""
                            : "",
                          "",
                          "text-left"
                        )}
                      >
                        <td
                          className={classNames(
                            "delay-200",
                            "whitespace-normal px-2  text-sm border-b text-slate-500",
                            "border-slate-200"
                          )}
                        >
                          <button
                            data-id={measurement?.agentId + measurementIdx}
                            onClick={(evt) => {
                              evt.preventDefault()
                              const doc = document.getElementById(
                                evt.currentTarget.getAttribute("data-id") ?? ""
                              )
                              // console.log(doc)
                              doc?.style?.setProperty(
                                "background-color",
                                "rgb(212 212 216)"
                              )
                              // console.log(measurement)
                              setSelectedRow({
                                subCategory: measurement?.subcategory,
                                datetime: measurement?.datetime,
                                agentId: measurement?.agentId,
                                asn: measurement?.asn,
                                agentFamily: measurement?.agentFamily,
                                idx: measurementIdx,
                              })
                              setIsOpen(true)
                            }}
                            className="px-1.5"
                          >
                            <ChartSquareBarIcon
                              className={classNames(
                                "h-6 w-6",
                                measurement.detectedAnomalies.length > 0
                                  ? "text-red-500"
                                  : "text-blue-600"
                              )}
                            />
                          </button>
                        </td>
                        {MEASUREMENT_SUBCATEGORIES.slice()
                          .filter((sub) => sub !== "JITTER_RTT")
                          .map((sub, idx) => {
                            let subcategory = measurement[sub]
                            const [value, unit] = convertUnits(
                              subcategory?.value,
                              subcategory?.unit
                            )
                            return (
                              <td
                                key={measurement.agentId + sub + idx}
                                className={classNames(
                                  "whitespace-normal border-b px-2  text-sm",
                                  isAnomaly &&
                                    (subcategory?.detectedAnomalies?.length ??
                                      0) > 0
                                    ? "text-red-500 font-semibold"
                                    : "text-slate-500",
                                  "border-slate-200",
                                  "delay-200"
                                )}
                              >
                                {value}
                              </td>
                            )
                          })}
                        <td
                          className={classNames(
                            "delay-200",
                            "whitespace-normal px-2  text-sm border-b text-slate-500",
                            "border-slate-200"
                          )}
                        >
                          {formatIpFamily(measurement?.ipFamily)}
                        </td>
                        <td
                          className={classNames(
                            "delay-200",
                            "whitespace-normal px-2  text-sm border-b text-slate-500",
                            "border-slate-200"
                          )}
                        >
                          {formatAgentFamily(
                            measurement?.agentFamily ?? "UNKNOWN"
                          )}
                        </td>
                        {/* <td
                          onCopy={(evt) => {
                            evt.preventDefault()
                            window.navigator.clipboard.writeText(
                              measurement?.agentId ?? ""
                            )
                          }}
                          className={classNames(
                            "delay-200",
                            "whitespace-normal px-2  text-sm border-b text-slate-500",
                            "border-slate-200"
                          )}
                        >
                          <Tooltip message={measurement?.agentId ?? ""}>
                            {shortenUUID(measurement?.agentId) ?? "--"}
                          </Tooltip>
                        </td> */}
                        <td
                          onCopy={(evt) => {
                            evt.preventDefault()
                            const formatedIpAddress =
                              "[" +
                              measurement?.clientIdAddress +
                              "]:" +
                              (measurement?.clientIdPort ?? "")
                            const formatedTextToClipboard =
                              measurement?.clientIdTimestamp
                                ? formatISO(
                                    new Date(
                                      measurement?.clientIdTimestamp.replace(
                                        " ",
                                        "T"
                                      ) + "Z"
                                    )
                                  )
                                : ""

                            window.navigator.clipboard.writeText(
                              measurement?.clientIdAddress
                                ? formatedIpAddress +
                                    "@" +
                                    formatedTextToClipboard
                                : measurement?.agentId ?? ""
                            )
                          }}
                          className={classNames(
                            "delay-200",
                            "whitespace-normal px-2  text-sm border-b text-slate-500",
                            "border-slate-200"
                          )}
                        >
                          <Tooltip
                            messageElement={
                              measurement?.clientIdAddress ? (
                                <div>
                                  <span className="block">
                                    {" "}
                                    Endereço IP:{" "}
                                    {measurement?.clientIdAddress ?? ""}{" "}
                                  </span>
                                  <span className="block">
                                    {" "}
                                    Porta do Assinante:{" "}
                                    {measurement?.clientIdPort ?? ""}{" "}
                                  </span>
                                  <span className="block">
                                    {" "}
                                    Data/Hora:{" "}
                                    {measurement?.clientIdTimestamp
                                      ? formatISO(
                                          new Date(
                                            measurement?.clientIdTimestamp.replace(
                                              " ",
                                              "T"
                                            ) + "Z"
                                          )
                                        )
                                      : ""}{" "}
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  <span className="block">
                                    ID Medidor: {measurement?.agentId}
                                  </span>
                                  <span className="block">
                                    Endereço IP: desconhecido
                                  </span>
                                </div>
                              )
                            }
                          >
                            {measurement?.clientIdAddress ??
                              shortenUUID(measurement?.agentId) ??
                              "--"}
                          </Tooltip>
                        </td>
                        <td
                          className={classNames(
                            "delay-200",
                            "whitespace-nowrap px-2 py-2 text-sm border-b text-slate-500",
                            "border-slate-200"
                          )}
                        >
                          <Tooltip message={measurement?.peer ?? ""}>
                            {getMeasurementPeer(
                              measurement,
                              availableAutonomousSystems
                            ) ?? "--"}
                          </Tooltip>
                        </td>
                        <td
                          className={classNames(
                            "delay-200",
                            "whitespace-nowrap px-2 py-2 text-sm border-b text-slate-500",
                            "border-slate-200"
                          )}
                        >
                          {measurement?.datetime
                            ? formatDateWithLocale(measurement.datetime)
                            : "--"}
                          {/* -- */}
                        </td>
                      </tr>
                    </>
                  )
                })}
            </tbody>
          </table>

          {isWaitingForData && <PageSpinner />}
          {!isWaitingForData &&
            measurementsList &&
            measurementsList.length == 0 && <EmptyStateTable />}
        </div>
      </div>
      <GraphicSubcategoryByAgentModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        datetime={selectedRow?.datetime}
        agentId={selectedRow?.agentId}
        asn={selectedRow?.asn}
        agentFamily={selectedRow?.agentFamily}
        idx={selectedRow?.idx}
        // {...selectedRow}
      />
    </>
  )
}
export default TableByMeasurements
