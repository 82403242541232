import React, { FunctionComponent, useEffect, useState } from "react"
import { CurrentContext } from "../context"
import { useKeycloak } from "@react-keycloak/web"
import { AutonomousSystemScopeMapping } from "@models/autonomous-system-scope-mapping"
import { availableAutonomousSystemsService } from "@services/available-autonomous-systems-service"
import { scopeMappingListService } from "@services/scope-mapping-list-service"
import { useSiteMetadata } from "@hooks/use-site-metadata"
import { AutonomousSystem } from "@models/autonomous-system"
import { PageMetadataObject } from "@models/page-metadata-type"
import { useDispatch } from "react-redux"
import { ReducerAction } from "@store/index"
import { Dispatch } from "redux"

const InitialDataLayout: FunctionComponent = ({ children }) => {
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()
  const [scopeMapList, setScopeMapList] = useState<
    AutonomousSystemScopeMapping[] | null
  >(null)
  const [isScopeMapListReady, setIsScopeMapListReady] = useState(false)

  const [availableAutonomousSystemsList, setAvailableAutonomousSystemsList] =
    useState<AutonomousSystem[] | null>(null)
  const [
    isAvailableAutonomousSystemsListReady,
    setIsAvailableAutonomousSystemsListReady,
  ] = useState(false)

  const [pageMetadata, setPageMetadata] = useState<PageMetadataObject>()

  const data = useSiteMetadata()

  const { asMeasurementsApi, asAuthorization } = data.externalServices

  const dispatch = useDispatch<Dispatch<ReducerAction>>()

  useEffect(() => {
    if (
      isKeycloakInitialized &&
      keycloak.authenticated &&
      !isScopeMapListReady
    ) {
      scopeMappingListService(asAuthorization.baseUrl, keycloak)
        .then((res) => {
          setScopeMapList(res)
        })
        .finally(() => {
          setIsScopeMapListReady(true)
        })
    }
  }, [
    isKeycloakInitialized,
    keycloak,
    isScopeMapListReady,
    asAuthorization.baseUrl,
  ])

  useEffect(() => {
    if (
      keycloak.token &&
      isKeycloakInitialized &&
      keycloak.authenticated &&
      !isAvailableAutonomousSystemsListReady
    ) {
      availableAutonomousSystemsService(
        asMeasurementsApi.baseUrl,
        keycloak.token,
        dispatch
      )
        .then((res) => {
          setAvailableAutonomousSystemsList(res)
          res &&
            res.length > 0 &&
            localStorage.setItem("asn", JSON.stringify(res?.at(0)?.asn))
        })
        .finally(() => {
          setIsAvailableAutonomousSystemsListReady(true)
        })
    }
  }, [
    isKeycloakInitialized,
    keycloak,
    asMeasurementsApi.baseUrl,
    isAvailableAutonomousSystemsListReady,
  ])

  return (
    // <RequireAuthentication>
    <CurrentContext.Provider
      value={{
        availableAutonomousSystemsList,
        scopeMapList,
        pageMetadata,
        setPageMetadata,
        isAvailableAutonomousSystemsListReady,
        isScopeMapListReady,
      }}
    >
      {children}
    </CurrentContext.Provider>
    // </RequireAuthentication>
  )
}

export default InitialDataLayout
