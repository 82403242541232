import React, { FunctionComponent } from "react"
import CustomLink from "../shared/custom-link"

interface Props {
  to: string
  isActive?: boolean
  disablePreloading?: boolean
  isInternal?: boolean
  className?: string
  activeClassName?: string
  children: any
}

const SidebarLink: FunctionComponent<Props> = ({
  to,
  isActive,
  isInternal,
  className,
  activeClassName,
  children,
}) => {
  return (
    <CustomLink
      to={to}
      isActive={Boolean(isActive)}
      isInternal={isInternal}
      activeClass={
        activeClassName ||
        "group flex items-center rounded-md bg-slate-200 px-2 py-2 text-sm font-medium text-slate-900"
      }
      className={
        className ||
        "group flex items-center rounded-md px-2 py-2 text-sm font-medium text-slate-700 hover:bg-slate-50 hover:text-slate-900"
      }
    >
      {children}
    </CustomLink>
  )
}

export default SidebarLink
