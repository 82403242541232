import React, { ReactElement } from "react"
import Helmet from "react-helmet"
import Footer from "@components/shared/footer"
import Header from "@components/shared/header"

const LicensesPage: () => ReactElement = () => {
  const paragraphs = [
    <>Iconografia adicional por Daniel Bruce: www.entypo.com (CC BY-SA 4.0).</>,
  ]

  return (
    <div className="flex flex-col min-h-screen h-full bg-slate-200">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Licenças - PAS</title>
      </Helmet>
      <Header />
      <div className="flex-grow max-w-3xl w-full mx-auto mt-16 pb-32 px-2">
        <div className="text-2xl text-slate-700 font-medium mb-2">Licenças</div>
        <div className="space-y-2 text-slate-600 text-base">
          {paragraphs.map((paragraph) => (
            <p className="leading-snug">
              <div className="float-left w-8 sm:w-10">&nbsp;</div>
              {paragraph}
            </p>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default LicensesPage
