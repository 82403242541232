import React, { FunctionComponent } from "react"
import {
  createClient,
  defaultExchanges,
  subscriptionExchange,
  Provider,
} from "urql"
import { createClient as createWSClient } from "graphql-ws"
import { useSiteMetadata } from "@hooks/use-site-metadata"
import { useKeycloak } from "@react-keycloak/web"

const UrqlProvider: FunctionComponent = ({ children }) => {
  const data = useSiteMetadata()

  const { asMeasurementsSubscriptions, leaderboardApi } = data.externalServices

  const wsClient = createWSClient({
    url: asMeasurementsSubscriptions.subscriptions,
    connectionParams: {},
  })

  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()

  const client = createClient({
    url: leaderboardApi.baseUrl,
    fetchOptions: {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    },
    exchanges: [
      ...defaultExchanges,
      subscriptionExchange({
        forwardSubscription: (operation) => ({
          subscribe: (sink) => ({
            unsubscribe: wsClient.subscribe(
              { query: operation.query, variables: operation.variables },
              sink
            ),
          }),
        }),
      }),
    ],
  })

  return <Provider value={client}>{children}</Provider>
}

export default UrqlProvider
