import { Listbox, Transition } from "@headlessui/react"
import { AdjustmentsIcon } from "@heroicons/react/outline"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useQueryParams } from "use-query-params"
import { QueryParamConfig } from "@utils/query-params"
import { formatIpFamily, IpFamily, IP_FAMILIES } from "@models/ip-family"

const Ipfamily: FunctionComponent = () => {
  const [_searchParams, setSearchParams] = useQueryParams(QueryParamConfig)

  const { ipFamily } = _searchParams

  const [selected, setSelected] = useState(ipFamily as IpFamily ?? IP_FAMILIES[0])

  useEffect(() => {
    setSearchParams({ ipFamily: selected.toString() })
    setSearchParams({ cursor: undefined })

  }, [selected, ipFamily])

  return (
    <Listbox
      value={selected}
      onChange={setSelected}
      as="div"
      className="z-20 relative"
    >
      {({ open }) => (
        <>
          <Listbox.Button className="group inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 focus:ring-blue-500">
            <AdjustmentsIcon className="h-5 w-5 mr-2 flex-none" />

            {formatIpFamily(selected)}
          </Listbox.Button>
          <Transition
            // as={Fragment}
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Listbox.Options className="font-normal origin-top-right z-20 absolute left-0 mt-2 w-32 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none space-y-1">
              {IP_FAMILIES.slice().map((res, idx) => (
                <Listbox.Option
                  key={idx}
                  className={({ active }) =>
                    `block px-4 py-2 text-sm text-slate-700" ${active ? "bg-slate-100" : ""
                    } `
                  }
                  value={res}
                >
                  {({ selected }) => (
                    <div className="">
                      <span
                        className={`block truncate ${selected ? "font-medium" : "font-normal"
                          }`}
                      >
                        {formatIpFamily(res)}
                      </span>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  )
}

export default Ipfamily;
