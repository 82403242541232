import { Duration as DateDuration, formatDuration, intervalToDuration, parseISO, formatDistance } from "date-fns"
import {ptBR} from "date-fns/locale"
import {add} from "date-fns"

// date-fns Duration elements to locate in duration strings.
//
// The left hand side of the tuple is the Duration key, and the right hand side
// is the regex to match the specified type.
//
// Order of tuples in the list will be the same of the types inside of the duration
// string, eg. "9d 10h" will be valid but "10h 9d" will not.
const durationRegexGroups: [keyof DateDuration, RegExp][] = [
  ["weeks", /wk?|weeks?|semanas?/],
  ["days", /d|days?|dias?/],
  ["hours", /h|hours?|horas?/],
  ["minutes", /m|mins?|minutes?|minutos?/],
  ["seconds", /s|secs?|segs?|seconds?|segundos?/],
]

/**
 * Regex to identify a duration string. Separate parts of the
 * duration contain a number and the unit, and can be separated from other parts
 * by a comma surrounded by spaces, just spaces, or nothing.
 *
 * Examples of valid inputs:
 *   - `1w2d3h4m5s`
 *   - `1 week, 4 mins 30 segundo`
 *   - `1 wk 3 horas  2 minute ,`
 */
// The regex is built dynamically to make it easier for later customizations to the
// regex, as most new changes will only require modifying the durationRegexGroups
// array above. However, being a constant at a module-level, it essentially is static.
const durationRegex = new RegExp(
  [
    "^",
    ...durationRegexGroups
      .slice(0, durationRegexGroups.length - 1)
      .map(([, regex]) => `(?:(\\d+) *(?:${regex.source}) *,? *)?`),
    `(?:(\\d+) *(?:${
      durationRegexGroups[durationRegexGroups.length - 1][1].source
    }))?`,
    "$",
  ].join("")
)

export function stringToDuration(value: string): DateDuration {
  value = value.trim()
  const durationMatch = durationRegex.exec(value)
  if (!durationMatch || !value) {
    throw new Error("Invalid duration input")
  }
  return Object.fromEntries(
    durationRegexGroups.map(([key], i) => [
      key,
      durationMatch[i + 1] ? parseInt(durationMatch[i + 1], 10) : 0,
    ])
  )
}

export function parseMinutes(minutes: number, dateInicial: string, dateFinal?: string, shortDate: boolean = false): string {
  const dateInicialOriginal = parseISO(dateInicial)

  let newDateFinal: Date;

  if(dateFinal){
    newDateFinal = parseISO(dateFinal)

  }else{
    newDateFinal = parseISO(dateInicial)

    newDateFinal = add(newDateFinal, {minutes: minutes})
  }

  const dateInterval = intervalToDuration({end:newDateFinal, start: dateInicialOriginal})

  const options = {
    locale: {
      code: ptBR.code,
      formatDistance: (unit: string, count: number) => {
        switch (true) {
          case unit === 'xDays':
            return `${count}d`;

          case unit === 'xHours':
            return `${count}h`;

          case unit === 'xMinutes':
            return `${count}m`;

          case unit === 'xMonths':
            return `${count}mo`;

          case unit === 'xSeconds':
            return `${count}s`;

          case unit === 'xYears':
            return `${count}a`;
        }

        return '%d horas';
      },
    },
  };
  if(shortDate){
    return formatDistance(dateInicialOriginal, newDateFinal, {locale: ptBR});
  }
  return formatDuration(dateInterval, options);
}
