import { ElementType } from "@_types/element-type"

export const AGENT_FAMILIES = [
  "DESKTOP",
  "EMBEDDED",
  "MOBILE",
  "SYSTEM_SERVICE",
  "WEB",
  "UNKNOWN",
] as const

export type AgentFamily = ElementType<typeof AGENT_FAMILIES>

export function formatAgentFamily(agentFamily: AgentFamily): string {
  switch (agentFamily) {
    case "DESKTOP":
    case "SYSTEM_SERVICE":
      return "Desktop"
    case "EMBEDDED":
      return "Embarcado"
    case "MOBILE":
      return "Mobile"
    case "WEB":
      return "Web"
    case "UNKNOWN":
      return "Desconhecido"
    default:
      return "Desconhecido"
  }
  // Assert that all known types are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = agentFamily
  throw new Error("Unknown AgentFamily")
}

export function getAgentFamilyFromString(agentFamily: string): AgentFamily {
  return (AGENT_FAMILIES as any)[agentFamily]
}

export function formatAgentFamilyService(agentFamily: AgentFamily[]): string[]{
  return agentFamily.map((response)=>{
    switch (response) {
      case "EMBEDDED":
        return "embedded"
      case "MOBILE":
        return "mobile"
      case "DESKTOP":
      case "SYSTEM_SERVICE":
        return "system_service"
      case "WEB":
        return "web_html5"
      case "UNKNOWN":
        return "other"
      default:
        return "other"
    }
  })
}