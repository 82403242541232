import { ElementType } from "@_types/element-type"

export const PERIOD_INTERVAL = [
  "TODAY",
  "YESTERDAY",
  "7D",
  "30D",
  "CURRENT_MONTH",
  "LAST_MONTH",
  "CUSTOM"
] as const


export type PeriodType = ElementType<typeof PERIOD_INTERVAL>

export function formatPeriodType(periodType: PeriodType | undefined): string {
  switch (periodType) {
    case "YESTERDAY":
      return "Ontem"
    case "TODAY":
      return "Hoje"
    case "7D":
      return "Últimos 7 dias"
    case "30D":
      return "Últimos 30 dias"
    case "CURRENT_MONTH":
      return "Mês Atual"
    case "LAST_MONTH":
      return "Mês Passado"
    case "CUSTOM":
      return "Período Flexível"
    default:
      return "Desconhecido"
  }
  // Assert that all known types are used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _: never = periodType
  throw new Error("Unknown periodType")
}

export const formatPeriodTimeResolution = (period: PeriodType): ("hourly" | "daily") => {
  switch (period) {
    case "YESTERDAY":
    case "TODAY":
      return "hourly"
    case "7D":
    case "30D":
    case "CURRENT_MONTH":
    case "LAST_MONTH":
      return "daily"
    case "CUSTOM":
      return "daily"
    default:
      return "hourly"
  }
}
