import React, { FunctionComponent } from "react"
import { Container } from "./Container"

const faqs = [
  [
    {
      question: "O que é o PAS?",
      answer:
        "O PAS, ou Portal do AS, é um portal desenvolvido pelo NIC.br, para entregar ao provedor de Internet brasileiro o resultado de todos os projetos de medição do NIC.br.",
    },
    {
      question: "Quem pode usar o PAS?",
      answer:
        "O PAS é aberto a todos os sistemas autônomos brasileiros, ou seja instituições com ASN e recursos de numeração atribuidos pelo Registro.br.",
    },
    {
      question: "Como posso começar?",
      answer:
        "Basta criar uma conta de usuário pessoal e em seguida solicitar que este usuário seja vinculado ao seu sistema autônomo. O pedido de vinculo será direcionado ao email cadastrado como contato técnico do AS no Registro.br. O processo é simples e guiado pelo proprio portal.",
    },
  ],
  [
    {
      question: "Assumo algum compromisso ou obrigação?",
      answer:
        "Não. O portal PAS e todas as demais soluções de medição são uma oferta gratuita do NIC.br. O usuário e o sistema autônomo assumem nenhum compromisso.",
    },
    {
      question: "Qual a relação do PAS com o SIMET?",
      answer:
        "O SIMET é o projeto de medição original do NIC.br. Seu foco é a medição da qualidade de Internet dos usuários finais. Para isto o SIMET mantem uma familia de medidores. Já o PAS é o portal onde o provedor encontra o resultado destas medições do SIMET, alem de outras medições. Ou seja, o PAS é o cockpit central do provedor.",
    },
    {
      question: "Qual a relação do PAS com o IX.br?",
      answer:
        "O PAS é um projeto distinto, porém desenvolvido pela mesma diretoria do NIC.br. Como portal único de medição do NIC.br o PAS monitora também a conexão do sistema autônomo ao IX.br. Com isto o PAS é indicado a todos os participantes do IX.br.",
    },
  ],
  [
    {
      question: "O que é o servidor SIMET-ISP?",
      answer:
        "O SIMET-ISP é o servidor de teste que o provedor pode instalar na sua rede. Com isto as medições de qualidade do SIMET são executadas contra este servidor na rede do provedor. Para usar o servidor de teste SIMET-ISP, o provedor precisa necessáriamente instalar o medidor SIMET-AS no mesmo ponto da rede.",
    },
    {
      question: "O que é o medidor SIMET-AS?",
      answer:
        "O SIMET-AS é o medidor que complementa o servidor SIMET-ISP para não perder o princípio de monitoramento fim-a-fim até o IX.br. O SIMET-AS realiza medições não-volumétricas até IX.br e um conjunto crescente de medições complementares para ajudar o provedor a operar a sua rede, todas entregues no portal PAS.",
    },
    {
      question: "Como posso instalar o servidor de teste?",
      answer:
        " Ambos SIMET-ISP e SIMET-AS são máquinas virtuais (VMs) que o provedor pode executar em um servidor num ponto central da sua rede. Os requisitos e o manual de instalação encontram-se no próprio PAS.",
    },
  ],
]

export const Faqs: FunctionComponent = () => {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        className="absolute top-0 left-1/2 max-w-none transform -translate-x-30 -translate-y-1/4"
        src="/images/landing/background-faqs.jpg"
        alt=""
        width={1558}
        height={946}
      />
      {/* <img
        
      /> */}
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Perguntas Frequentes
          </h2>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
