import React, { FunctionComponent, memo, useEffect, useState } from "react";
import { Transition, Listbox } from "@headlessui/react"
import { QueryParamConfig } from "@utils/query-params"
import { AntiSpoofingType, ANTI_SPOOFING, formatAntiSpoofing } from "@models/anti-spoofing-type"
import { useQueryParams } from "use-query-params"
import { CheckCircleIcon } from "@heroicons/react/outline"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

const AntiSpoofingInput: FunctionComponent = () => {
  const [_searchParams, setSearchParams] = useQueryParams(QueryParamConfig)

  const { antiSpoofing } = _searchParams

  const [controlAntispoofingType, setControlAntispoofingType] = useState(antiSpoofing ?? ANTI_SPOOFING[0])

  useEffect(() => {
    setSearchParams({ antiSpoofing: controlAntispoofingType })
    setSearchParams({ cursor: undefined })
  }, [controlAntispoofingType, antiSpoofing])

  return (
    <Listbox
      value={controlAntispoofingType}
      onChange={setControlAntispoofingType}
      as="div"
      className="z-20 relative"
    >
      {({ open }) => (
        <>
          <Listbox.Button className="group inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 focus:ring-blue-500">
            <CheckCircleIcon className="h-5 w-5 mr-2 flex-none" />
            {formatAntiSpoofing(controlAntispoofingType as AntiSpoofingType)}
          </Listbox.Button>
          <Transition
            // as={Fragment}
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Listbox.Options className="font-normal origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none space-y-1 z-20">
              {ANTI_SPOOFING.slice().map((res, idx) => (
                <Listbox.Option
                  key={idx}
                  value={res}
                // as={Fragment}
                >
                  {({ active, selected }) => (
                    <>
                      {idx !== 0 && (idx == 1 || idx % 2 == 0) && (<hr />)}
                      <div
                        className={
                          classNames(
                            active ? "bg-slate-100" : "",
                            "block px-4 py-2 text-sm text-slate-700"
                          )}
                      >
                        <div>
                          <span
                            className={`block truncate ${selected ? "font-medium" : "font-normal"
                              }`}
                          >
                            {formatAntiSpoofing(res)}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  )
}

export default memo(AntiSpoofingInput);