import React, { FunctionComponent } from "react"
import { ReducerState } from "@store/index"
import { useSelector } from "react-redux"
import { AntiSpoofingHistory } from "@models/simet-as"
import { formatDateWithLocale } from "@utils/query-params"
import { XIcon, CheckCircleIcon } from "@heroicons/react/outline"

interface ReducerSelected {
  antiSpoofingHistoryList: AntiSpoofingHistory[] | null
}

const CardAntiSpoofingHistoryComponent: FunctionComponent = () => {
  const { antiSpoofingHistoryList } = useSelector<
    ReducerState,
    ReducerSelected
  >((state) => {
    return {
      antiSpoofingHistoryList: state.antiSpoofing.antiSpoofingHistoryList,
    }
  })

  return (
    <div className="flex flex-col -mx-8">
      <div className="-mt-3">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="">
            <table
              className="min-w-full border-separate"
              style={{ borderSpacing: 0 }}
            >
              <thead className="">
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 px-3 pl-8 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    IP privado filtrado
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    IP público filtrado
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Medidor
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Data
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white/25">
                {antiSpoofingHistoryList?.map((res, idx) => {
                  return (
                    <tr className="h-full" key={idx}>
                      {/* IP privado */}
                      <td className="whitespace-nowrap border-b border-gray-200 px-3 pl-8 py-3 text-sm">
                        {res.ipPrivateFiltered ? (
                          <CheckCircleIcon className="h-6 w-6 text-green-600" />
                        ) : (
                          <XIcon className="h-6 w-6 text-red-600" />
                        )}
                      </td>
                      {/* IP publico */}
                      <td className="whitespace-nowrap border-b border-gray-200 px-3 py-3 text-sm text-gray-500">
                        {res.ipPublicFiltered ? (
                          <CheckCircleIcon className="h-6 w-6 text-green-600" />
                        ) : (
                          <XIcon className="h-6 w-6 text-red-600" />
                        )}
                      </td>
                      <td className="whitespace-nowrap border-b border-gray-200 px-3 py-3 text-sm text-gray-500">
                        {res?.agentID}
                      </td>
                      <td className="whitespace-nowrap border-b border-gray-200 px-3 py-3 text-sm text-gray-500">
                        {res?.datetime
                          ? formatDateWithLocale(res.datetime)
                          : "--"}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardAntiSpoofingHistoryComponent
